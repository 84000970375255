import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid'
import { withStyles } from '@material-ui/core/styles';
import ContenidoCatalogoContainer from '../../containers/ContenidoCatalogo'
import './styles.css'

const styles = theme => ({
    rootDialog:{
      zIndex: 1550,
    },
    rootContent:{
        borderRadius: '21px',
        minWidth: '300px'
    }
  })

class Ofertas extends React.Component {

  render() {

    const { classes } = this.props

    return (
        <div>
            {/*<div>No se encontraron resultados</div>*/}

            {this.props.existeCatalogo === true ?
                <ContenidoCatalogoContainer
                    history={this.props.history}
                    idCatalogoSeleccionado={this.props.catalogos[0].id}
                    catalogoUnico={true}
                    esOferta={true}
                    oferta={this.props.oferta}
              />
            :
                <div>No se encontraron resultados</div>
            }
            
        </div>
    );
  }
}

Ofertas.defaultProps = {
}

Ofertas.propTypes = {
    existeCatalogo: PropTypes.bool.isRequired,
    history: PropTypes.object,
    catalogos:  PropTypes.array,
    oferta: PropTypes.object.isRequired,
};

export default withStyles(styles)(Ofertas);

