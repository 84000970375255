import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as confirmarCorreoActions from '../../reducers/ConfirmarCorreoReducer/actions'
import * as layoutActions from '../../reducers/LayoutReducer/actions'
import { confirmarCorreoDataSelector } from '../../selectors/confirmarCorreoSelector'
import { headerSelector } from '../../selectors/layoutSelector'
import { instalacionSelector } from '../../selectors/sessionSelector'
import ConfirmarCorreo from '../../components/ConfirmarCorreo'

class ConfirmarCorreoContainer extends React.Component {

    constructor(props) {
        super(props)
        
        this.state = {
            inicializando: true,
            usuarioHabilitado: undefined,
            correoConfirmado: undefined,
            email: ''
        }

        // inicializo el formulario
        this.props.initializeForm()

        // oculto el header y el footer
        this.showHeaderAndFooter(false)

        // busco el usuario a través del token
        this.props.sagaConfirmarCorreo({ idUsuario: this.props.match.params.idUsuario, token: this.props.match.params.token })
    }

    componentDidUpdate = () => {
        
        if(this.state.inicializando === true && this.props.data.usuario !== undefined){
            this.setState({ inicializando: false }, this.loadUsuarioData)
        }
    }

    componentDidMount = () => {
        this.props.setPermiteUsuarioAnonimo(true)
    }

    componentWillUnmount = () => {
        this.props.setPermiteUsuarioAnonimo(false)

        // muestro el header y el footer
        this.showHeaderAndFooter(true)
    }

    showHeaderAndFooter = (mostrar) => {
        this.props.setHeaderShow(mostrar)
        this.props.setFooterShow(mostrar)
    }

    loadUsuarioData = () => {
        
        if(this.props.data.usuario.habilitado && this.props.data.usuario.confirmado)
            window.location.hash = `/Login/${this.props.data.usuario.id}/${this.props.data.usuario.token}`
        
        else
        {
            this.setState({
                usuarioHabilitado: this.props.data.usuario.habilitado,
                correoConfirmado: this.props.data.usuario.confirmado,
                email: this.props.data.usuario.email
            })
        }
    }

    render() {
        return (
            <ConfirmarCorreo 
                instalacion={this.props.instalacion}
                usuarioHabilitado={this.state.usuarioHabilitado}
                correoConfirmado={this.state.correoConfirmado}
                email={this.state.email}
                token={this.props.match.params.token}
            />
        )
    }
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        ...confirmarCorreoActions,
        ...layoutActions
	}, dispatch)
}

function mapStateToProps(state) {
	return {
        instalacion: instalacionSelector(state),
        header: headerSelector(state),
        data: confirmarCorreoDataSelector(state)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmarCorreoContainer)