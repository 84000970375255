import React from 'react'
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as Notification from '../../components/Common/notification';
import * as facturacionActions from '../../reducers/FacturacionReducer/actions'
import { facturacionSelector } from '../../selectors/facturacionSelector'
import { usuarioSelector } from '../../selectors/sessionSelector'
import Facturacion from '../../components/Facturacion';

const TIPO_FACTURACION = {
  CONSUMIDOR_FINAL: 1,
  IVA: 2
}

const INITIAL_STATE = {
  datosFacturacion: {
    id: undefined,
    cuit: '',
    nombreRazonSocial: '',
  },
  formularioCondicionIVA: {
    cuit: '',
    nombreRazonSocial: '',
    domicilio: '',
    condicionIVA: undefined,
  },
}

class FacturacionContainer extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      tipoFacturacion: this.props.tipoFacturacion,
      datosFacturacion: { ...INITIAL_STATE.datosFacturacion, ...{ id: this.props.idClienteDatosFacturacion } },
      showFormularioCondicionIVA: false,
      formularioCondicionIVA: { ...INITIAL_STATE.formularioCondicionIVA },
      errores: {},
      idClienteDatoFacturacionToDelete: undefined,
    }
  }

  componentWillMount = () => {
    if (!this.props.facturacion.condicionesIVA) {
      this.props.sagaFetchCondicionesIVA();
    }

    if (this.props.facturacion.clienteDatosFacturacion) {
      this.inicializarDatosFacturacion(this.props.facturacion.clienteDatosFacturacion);
    }
  }

  componentDidUpdate = () => {
    if (!this.props.facturacion.clienteDatosFacturacion) {
      const payload = {};
      payload.idCliente = this.props.usuario.idCliente;
      payload.callback = this.inicializarDatosFacturacion;
      this.props.sagaFetchDatosFacturacion(payload);
    }
  }

  inicializarDatosFacturacion = (clienteDatosFacturacion) => {
    // id cliente datos facturacion seleccionado por preferencias del ultimo pedido
    const { id: idSeleccionado } = this.state.datosFacturacion;

    // validacion...
    if (!clienteDatosFacturacion || !idSeleccionado) return;

    // busco los datos del cliente datos facturacion seleccionado
    const cdf = clienteDatosFacturacion.find(x => x.id === idSeleccionado);
    if (!cdf) return;

    const myCdf = { ...cdf };
    const shouldParseCuit = myCdf.cuit.indexOf('-') === -1;
    if (shouldParseCuit)
    myCdf.cuit = this.parseCuit(myCdf.cuit);

    // actualizo el estado completando los datos    
    this.handleOnChangeDatosFacturacion(myCdf);
  }

  parseCuit = (cuit) => `${cuit.substring(0, 2)}-${cuit.substring(2, 10)}-${cuit.substring(10, 11)}`;

  handleOnChangeTipoFacturacion = (value) => {
    this.setState({
      tipoFacturacion: value,
      datosFacturacion: { ...INITIAL_STATE.datosFacturacion },
      formularioCondicionIVA: { ...INITIAL_STATE.formularioCondicionIVA },
      showFormularioCondicionIVA: false,
    });

    // notifico al contenedor padre sobre los datos de facturacion seleccionado
    this.props.onChange(value, null);
  }

  handleOnAgregarDatosFacturacion = () => {
    this.setState({ showFormularioCondicionIVA: true })
  }

  handleOnChangeFormularioCondicionIVA = (e) => {
    let value = e.target.value
    let name = e.target.name

    let form = this.state.formularioCondicionIVA
    form[name] = value

    this.setState({ formularioCondicionIVA: form });
  };

  handleOnSaveFormularioCondicionIVA = () => {
    let errores = {}
    const {
      cuit,
      nombreRazonSocial,
      domicilio,
      condicionIVA
    } = this.state.formularioCondicionIVA;

    // valido los datos del formulario
    if (cuit.replace(/\s/g, '').length !== 13) errores.cuit = 'CUIT inválido';
    if (nombreRazonSocial.trim().length === 0) errores.nombreRazonSocial = 'Ingrese el nombre o razón social'
    if (domicilio.trim().length === 0) errores.domicilio = 'Ingrese el domicilio'
    if (!condicionIVA) errores.condicionIVA = 'Seleccione la condición frente al IVA'

    // controlo que no haya datos de facturacion repetidos
    if (Object.keys(errores).length === 0) {
      var i = 0;
      var repetido = false;
      while (i < this.props.facturacion.clienteDatosFacturacion.length && !repetido) {
        const cdf = this.props.facturacion.clienteDatosFacturacion[i];

        // comparo propiedad por propiedad
        const eqCuit = cdf.cuit === cuit && cuit.replace(/-/g, '');
        const eqNombreRazonSocial = cdf.nombreRazonSocial.toLowerCase() === nombreRazonSocial.trim().toLocaleLowerCase();
        const eqDomicilio = cdf.domicilio.toLocaleLowerCase() === domicilio.trim().toLocaleLowerCase();
        const eqCondicionIVA = cdf.condicionIVA.id === condicionIVA;

        if (cdf.activo && eqCuit && eqNombreRazonSocial && eqDomicilio && eqCondicionIVA) {
          repetido = true;
          errores.datosRepetidos = cdf.id;
        }

        i++;
      }
    }

    this.setState({ errores: errores });

    if (Object.keys(errores).length === 0) {
      this.saveFormularioCondicionIVA();
    }
  }

  handleOnCancelarReutilarDatos = () => {
    const errores = { ...this.state.errores };
    errores.datosRepetidos = undefined;
    this.setState({ errores: errores });
  }

  saveFormularioCondicionIVA = () => {
    const {
      cuit,
      nombreRazonSocial,
      domicilio,
      condicionIVA,
    } = this.state.formularioCondicionIVA;

    const payload = {};
    payload.idCliente = this.props.usuario.idCliente;
    payload.idCondicionIVA = condicionIVA;
    payload.cuit = cuit.replace(/-/g, '');
    payload.nombreRazonSocial = nombreRazonSocial.trim();
    payload.domicilio = domicilio.trim();

    payload.callback = (idClienteDatosFacturacion) => {
      const newDatosFacturacion = { id: idClienteDatosFacturacion, cuit, nombreRazonSocial };
      this.handleOnChangeDatosFacturacion(newDatosFacturacion);
    }

    payload.callbackError = (error) => {
      Notification.showError('Error al guardar los datos de facturación: ' + error)
    }

    this.props.sagaSaveDatosFacturacion(payload);
  }

  handleOnChangeDatosFacturacion = (newDatosFacturacion) => {
    const { id, cuit, nombreRazonSocial } = newDatosFacturacion;
    this.setState({
      datosFacturacion: { id, cuit, nombreRazonSocial },
      showFormularioCondicionIVA: false,
      formularioCondicionIVA: { ...INITIAL_STATE.formularioCondicionIVA },
      errores: {},
    });

    // notifico al contenedor padre sobre los datos de facturacion seleccionado
    this.props.onChange(TIPO_FACTURACION.IVA, id);
  }

  handleOnClickDeleteDatoFacturacion = (id) => {
    this.setState({ idClienteDatoFacturacionToDelete: id });
  }

  handleOnDeleteFacturacion = () => {
    const payload = {};
    payload.id = this.state.idClienteDatoFacturacionToDelete;
    payload.activo = false;
    payload.callback = () => this.setState({ idClienteDatoFacturacionToDelete: undefined });

    this.props.sagaSetDatosFacturacionActivo(payload);
  }

  render() {
    const { clienteDatosFacturacion } = this.props.facturacion;
    const existenDatosFacturacionActivos = clienteDatosFacturacion && clienteDatosFacturacion.filter(x => x.activo).length > 0;

    return (
      <Facturacion
        tipoFacturacionEnum={TIPO_FACTURACION}
        tipoFacturacion={this.state.tipoFacturacion}
        isTipoFacturacionSelected={this.state.tipoFacturacion === TIPO_FACTURACION.CONSUMIDOR_FINAL || this.state.datosFacturacion.id}
        datosFacturacionSelected={this.state.datosFacturacion}
        formularioCondicionIVA={this.state.formularioCondicionIVA}
        showFormularioCondicionIVA={this.state.showFormularioCondicionIVA || !existenDatosFacturacionActivos}
        erroresFormulario={this.state.errores}
        handleOnCancelarReutilarDatos={this.handleOnCancelarReutilarDatos}
        condicionesIVA={this.props.facturacion.condicionesIVA}
        clienteDatosFacturacion={this.props.facturacion.clienteDatosFacturacion}
        showModalDeleteDatoFacturacion={!!this.state.idClienteDatoFacturacionToDelete}
        handleOnChangeTipoFacturacion={this.handleOnChangeTipoFacturacion}
        handleOnAgregarDatosFacturacion={this.handleOnAgregarDatosFacturacion}
        handleOnChangeFormularioCondicionIVA={this.handleOnChangeFormularioCondicionIVA}
        handleOnSaveFormularioCondicionIVA={this.handleOnSaveFormularioCondicionIVA}
        handleOnChangeDatosFacturacion={this.handleOnChangeDatosFacturacion}
        handleOnClickDeleteDatoFacturacion={this.handleOnClickDeleteDatoFacturacion}
        handleOnDeleteFacturacion={this.handleOnDeleteFacturacion}
      />
    )
  }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      ...facturacionActions,
    }, dispatch)
}

function mapStateToProps(state) {
    return {
      usuario: usuarioSelector(state),
      facturacion: facturacionSelector(state),
    }
}

FacturacionContainer.defaultProps = {
  tipoFacturacion: null,
  idClienteDatosFacturacion: null,
  onChange: () => {},
}

FacturacionContainer.propTypes = {
  tipoFacturacion: PropTypes.number,
  idClienteDatosFacturacion: PropTypes.number,
  onChange: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(FacturacionContainer)