import React from 'react';
import * as Notification from '../../Common/notification';

const useSelectorCantidad = ({
  value,
  onChange,
  incremento,
  cantidadLimMin,
  cantidadLimMax,
  productoPesable,
}) => {
  const deltaIncremento = React.useMemo(() => {
    if (incremento !== null && incremento !== undefined) return incremento;
    return productoPesable ? 0.25 : 1;
  }, [incremento, productoPesable]);

  const handleOnChangeInput = ({ target }) => {
    let newValue = target.value;

    if(newValue.includes('.')) return;

    newValue = Number(newValue);
    if(isNaN(newValue) || !Number.isInteger(newValue)) return;

    if(cantidadLimMax && newValue > cantidadLimMax){
      newValue = cantidadLimMax
      Notification.showInfo(`La cantidad máxima para este producto es de ${cantidadLimMax}`)
      return;
    }

    if(newValue < 0){
      return;
    }

    onChange(newValue);
  };

  const handleOnIncrementDecrement = (delta) => {
    if(value === 0 && delta < 0) return;

    if(delta > 0 && cantidadLimMax && value >= cantidadLimMax) {
      Notification.showInfo(`La cantidad máxima para este producto es de ${cantidadLimMax}`)
      return;
    }

    let newValue = value;

    if(newValue == 0) {
      newValue = cantidadLimMin && cantidadLimMin > 0 ? cantidadLimMin : Math.abs(delta);
    }
    else{
      if(delta < 0 && cantidadLimMin && cantidadLimMin > 0 && (newValue + delta) < cantidadLimMin) {
        newValue = 0;
      }
      else{
        newValue = productoPesable ? +(newValue + delta).toFixed(2) : newValue + delta;
      }
    }    

    if(cantidadLimMax && newValue >= cantidadLimMax){
      newValue = cantidadLimMax;
    }

    onChange(newValue);
  };

  const handleOnIncrement = () => {
    handleOnIncrementDecrement(deltaIncremento);
  }

  const handleOnDecrement = () => {
    handleOnIncrementDecrement(-deltaIncremento);
  }

  const handleOnFocusInput = (event) => event.target.select();

  return {
    handleOnIncrement,
    handleOnDecrement,
    handleOnChangeInput,
    handleOnFocusInput,
  }
};

export default useSelectorCantidad;
