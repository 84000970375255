import React from 'react'
import PropTypes from 'prop-types'
import CustomModal from '../UI/Modals/CustomModal'

import './styles.css'

class MensajeCanceladoModal extends React.Component {

    render() {
        return (
            <CustomModal
                open={this.props.open}
                onClose={this.handleClose}
                title="Se canceló tu pedido."
                buttonNames={["ENTENDIDO!"]}
                buttonActions={
                    [() => { this.props.handleOnClose(3) }]
                }
            >
                <div className='modalTextContent'>
                </div>
            </CustomModal>
        )
    }
}

MensajeCanceladoModal.propTypes = {
    handleOnClose: PropTypes.func,
    open: PropTypes.object,
}

MensajeCanceladoModal.defaultProps = {
    handleOnClose: f => f
}

export default MensajeCanceladoModal