import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import ArticuloItem from './articuloItem';
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from '@material-ui/core/CircularProgress';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { formatMoney } from '../Common/decimalHelper'
import Banner from "../Banner/Banner"
import BusquedaArticulos from './busqueda';
import Heading from '../UI/Texts/Heading'
import CustomModal from '../UI/Modals/CustomModal'
import Paragraph from '../UI/Texts/Paragraph'

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  root: {
    flexGrow: 1,
  },
  show: {
    transform: 'translateY(0)',
    transition: 'transform .5s',
  },
  hide: {
    transform: 'translateY(+135%)',
    transition: 'transform .5s',
  },
  icon: {
    color: '#B4C4D5'
  },
  select: {
    textAlign: 'left',
    font: '400 16px/23px Poppins',
    letterSspacing: '0px',
    color: '#B4C4D5',
    opacity: 1,
  },
  rootDialog: {
    zIndex: 1550,
  },
  rootContent: {
    borderRadius: '21px',
    minWidth: '300px'
  }
});

class Articulos extends React.Component {

  constructor(props) {
    super(props)

    window.scrollTo(0, 0)

    this.state = {
      indiceActual: 0,
      hasMore: true,
      shouldShow: null,
      subFiltrosSeleccionados: []
    }

    this.lastScroll = null;

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidUpdate = (nextProps) => {
    if (/*this.props.listaArticulos && nextProps.listaArticulos && */this.props.cantidadArticulos !== nextProps.cantidadArticulos) {
      this.setState({ hasMore: true })
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, { passive: true });
    this.props.handleShowResumenPedido(true)
    this.props.handleOnShowBusqueda(true)

  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    this.props.handleShowResumenPedido(false)
    this.props.handleOnShowBusqueda(false)
  }

  handleScroll(evt) {
    const lastScroll = window.scrollY;

    if (lastScroll === this.lastScroll) {
      return;
    }

    const shouldShow = (this.lastScroll !== null) ? (lastScroll < this.lastScroll) : null;

    if (shouldShow !== this.state.shouldShow) {
      this.setState((prevState, props) => ({
        ...prevState,
        shouldShow,
      }));
    }

    this.lastScroll = lastScroll;
  }

  handleChangeSubFiltro = (event) => {
    let value = event.target.value

    this.setState({ subFiltrosSeleccionados: value === '' ? [] : value })

  }

  fetchMoreData = () => {
    if ((this.props.cantidadArticulos && this.props.cantidadArticulosFiltrados <= this.props.cantidadArticulos) || this.props.vistaFavoritos) {
      this.setState({
        hasMore: false
      })
    }
    else {
      this.setState({
        hasMore: true
      }, this.props.handleBuscarMasArticulos)
    }
  }

  articuloFiltrado(a) {
    return this.state.subFiltrosSeleccionados.length === 0 || a.listaFiltros.some(r => this.state.subFiltrosSeleccionados.indexOf(r) >= 0)
  }

  render() {

    const { classes } = this.props;

    return (
      <div className='containerPageArticulos' style={{ marginTop: '7.5px' }}>
        {/* BANNER */}
        <Banner pedido={this.props.pedido} vistaFavoritos={this.props.vistaFavoritos} instalacion={this.props.instalacion} filterBusqueda={this.props.filterBusqueda} />
        {!this.props.vistaFavoritos && this.props.instalacion.cobroDelivery && this.props.instalacion.deliveryBonificado && this.props.filterBusqueda === undefined && (
          <div className='diaNoLaborable'>
            <InfoOutlinedIcon />
            <div>Delivery bonificado en compras superiores a {formatMoney(this.props.instalacion.montoDeliveryBonificado)}</div>
          </div>
        )}

        <BusquedaArticulos
          pedido={this.props.pedido}
          filterBusqueda={this.props.filterBusqueda}
          cantidadArticulosFiltrados={this.props.cantidadArticulosFiltrados}
          buscandoCatalogo={this.props.buscandoCatalogo}
          errorBusqueda={this.props.errorBusqueda}
          vistaFavoritos={this.props.vistaFavoritos}
          ordenamiento={this.props.ordenamiento}
          handleChangeOrdenamiento={this.props.handleChangeOrdenamiento}
          handleOnClickVerTodosLosProductos={this.props.handleOnClickVerTodosLosProductos}
          getSubFiltrosArticulos={this.props.getSubFiltrosArticulos}
          subFiltrosArticulos={this.props.subFiltrosArticulos}
          handleOnChangeFiltrosArticulos={this.props.handleOnChangeFiltrosArticulos}
        />

        {/* Articulos */}
        {this.props.listadoSecciones && this.props.listadoSecciones.length > 0 &&
          <InfiniteScroll
            style={{ paddingBottom: '50px' }}
            dataLength={this.props.cantidadArticulos}
            next={this.fetchMoreData}
            hasMore={this.state.hasMore}
            loader={this.props.buscandoCatalogo && <div className='containerMsgInfinite'>
              <CircularProgress style={{}} />
              <div className='msgInfiniteScroll'>Cargando articulos... </div>
            </div>}
            endMessage={
              <p className='msgInfiniteScroll'>
                {<b>No hay más artículos.</b>}
              </p>
            }
          >
            <div>
              {this.props.listadoSecciones.map((s, j) => {
                return <div>

                  {s.nombreSeccion && <div className='tituloOrdenContainer' style={{ marginTop: '10px' }}>
                    {/* TITULO */}
                    <div className='tituloSeccion'>
                      <Heading tag="h3" weight="bold"> {s.nombreSeccion}</Heading>
                    </div>
                  </div>}

                  {<div className='flexCardContainer'>
                    {s.listaArticulos !== undefined && s.listaArticulos.map((a, i) => {
                      return <div className={`flexCard ${this.articuloFiltrado(a) ? '' : 'itemHide'}`}>
                        <ArticuloItem
                          item={a}
                          instalacion={this.props.instalacion}
                          handleOnButtonPedilo={this.props.handleOnButtonPedilo}
                          handleOnBorrar={this.props.handleOnBorrar}
                          pedido={this.props.pedido}
                          imagenes={this.props.imagenesArticulos !== undefined ? this.props.imagenesArticulos[a.idArticulo] : undefined}
                          precioListaPedido={this.props.precioListaPedido}
                          getBandaDePreciosCasoDos={this.props.getBandaDePreciosCasoDos}
                          agregarQuitarUnidad={this.props.agregarQuitarUnidad}
                          cantidadInicial={this.props.listaArticulosPedidos !== undefined && this.props.listaArticulosPedidos[a.idArticulo] !== undefined ? this.props.listaArticulosPedidos[a.idArticulo] : 0}
                          srcImgOferta={this.props.oferta && this.props.oferta.pathSello ? this.props.oferta.pathSello : s.pathSello || a.pathSello}
                        />
                      </div>
                    })}
                  </div>}
                </div>
              })}
            </div>
          </InfiniteScroll>}

        {/* MODAL CONFIRMACION DE VACIAR CARRITO */}
        <CustomModal
          open={this.props.openModalVaciarCarrito}
          onClose={() => { this.props.handleOnClickVaciarCarrtio(false) }}
          title="Seguro que quieres vaciar tu carrito?"
          buttonNames={["CANCELAR", "VACIAR CARRO"]}
          buttonActions={
            [
              () => { this.props.handleOnClickVaciarCarrtio(false) },
              () => { this.props.handleOnClickVaciarCarrtio(true) }
            ]
          }
        ></CustomModal>
        {/* MODAL AVISO DE RETIRO POR SUCURSAL */}
        <CustomModal
          open={this.props.openModalAvisoDeRetiro}
          onClose={this.props.handleOnClickCloseModalAviso}
          title=""
          buttonNames={["ENTENDIDO"]}
          buttonActions={
            [this.props.handleOnClickCloseModalAviso]
          }
        >
          <div className='modalTextContent'>
            <Paragraph size="big" weight="regular">
              Cuando finalices tu compra, nos comunicaremos con vos para consultarte tu preferencia de entrega:
            </Paragraph>
            <Paragraph size="big" weight="regular">
              Pickup: San Jerónimo 3250 - Santa fe
            </Paragraph>
            <Paragraph size="big" weight="regular">
              Envío a domicilio: Santa Fe, Santo Tomé y Countries
            </Paragraph>
          </div>
        </CustomModal>

        {/* MODAL OPCIONES DE PRODUCTO */}
        <CustomModal
          open={this.props.showModalOpcionesProducto}
          onClose={this.props.handleOnCloseModalOpcionesProducto}
          title="Opciones del producto"
          buttonNames={["CONTINUAR COMPRANDO"]}
          buttonActions={
            [this.props.handleOnCloseModalOpcionesProducto]
          }
        >
          <div className='modalTextContent'>
            <Paragraph size="big" weight="regular">
              Puedes cambiar las opciones elegidas para el producto desde el carrito de compras.
            </Paragraph>
          </div>
        </CustomModal>
      </div>
    )
  }
}

Articulos.propTypes = {
  classes: PropTypes.object.isRequired,
  listaArticulos: PropTypes.object.isRequired,
  instalacion: PropTypes.object.isRequired,
  pedido: PropTypes.object.isRequired,
  idArticuloModal: PropTypes.object.isRequired,
  idPedidoDetalleModal: PropTypes.object.isRequired,
  precioListaPedido: PropTypes.object.isRequired,
  getBandaDePreciosCasoDos: PropTypes.func.isRequired,

  imagenesArticulos: PropTypes.object.isRequired,

  oferta: PropTypes.object,

  handleOnBorrar: PropTypes.func.isRequired,
  handleOnButtonPedilo: PropTypes.func.isRequired,
  handleOnButtonConfirmarPedido: PropTypes.func.isRequired,
  handleOnPedidoDetalleModalClose: PropTypes.func.isRequired,

  agregarQuitarUnidad: PropTypes.func.isRequired,
  handleShowResumenPedido: PropTypes.func.isRequired,
  handleBuscarMasArticulos: PropTypes.func.isRequired,
  filterBusqueda: PropTypes.string,
  buscandoCatalogo: PropTypes.bool.isRequired,
  listaArticulosPedidos: PropTypes.object,
  ordenamiento: PropTypes.number,
  handleChangeOrdenamiento: PropTypes.func.isRequired,
  cantidadArticulos: PropTypes.number,
  errorBusqueda: PropTypes.bool.isRequired,
  openModalVaciarCarrito: PropTypes.bool.isRequired,
  handleOnClickVaciarCarrtio: PropTypes.func.isRequired,
  handleOnShowBusqueda: PropTypes.func.isRequired,
  vistaFavoritos: PropTypes.bool.isRequired,
  openModalAvisoDeRetiro: PropTypes.bool.isRequired,
  handleOnClickCloseModalAviso: PropTypes.func.isRequired,
  handleOnClickWhatsApp: PropTypes.func.isRequired,
  handleOnClickVerTodosLosProductos: PropTypes.func.isRequired,

  showModalOpcionesProducto: PropTypes.bool,
  handleOnCloseModalOpcionesProducto: PropTypes.func,

  getSubFiltrosArticulos: PropTypes.func,
  subFiltrosArticulos: PropTypes.array,
  handleOnChangeFiltrosArticulos: PropTypes.func,
};

export default withStyles(styles)(Articulos)