import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import { Row, Col } from 'react-flexbox-grid'
import './style.css'

const Footer = (props) => {

  useEffect(() => {
    const box = document.getElementById('box');
    const heart = document.getElementById('heart');

    box.addEventListener('click', () => {
      heart.classList.add('animate-up');
    });
  }, [])

  const renderDomicilio = (datosContacto) => {
    let domicilio = ''
    if (datosContacto.calle)
      domicilio += datosContacto.calle + ' '
    if (datosContacto.numero)
      domicilio += datosContacto.numero + ' '
    if (datosContacto.piso)
      domicilio += `Piso: ${datosContacto.piso} `
    if (datosContacto.departamento)
      domicilio += `Dpto.: ${datosContacto.departamento}`
    return domicilio
  }

  return (
    <div id='footer'>
      <div id="box" class="box"> </div>
      <div id="heart" class="heart"></div>
      {props.instalacion.promosBandaDescuentos === true &&
        <div className='descuentos'>
          <div className='title'>DESCUENTOS</div>
          <div className='descriptionText'>CONOZCA NUESTRA ESCALA DE DESCUENTOS!</div>
          {props.instalacion.bandaDescuento !== null ?
            <div className='imageContainer'>
              <img src={props.instalacion.bandaDescuento} />
            </div>
            :
            <div className='imageContainer'>
              <div class="outerD">
                <div class="middleD">
                  <div class="innerD">
                    <CircularProgress />
                    <div className='nombreItemDescuento'>Descuentos</div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>}
      {props.instalacion.verPiePagina === true &&
        <div className='datosContactoContainer'>
          <Row style={{ margin: '0 0 20px 0' }}>
            <Col xs={12} md={12} lg={1} xl={1}> </Col>
            <Col xs={12} md={12} lg={4} xl={4}>
              <div className={`imageContainer ${props.existePedido ? 'imageContainerAux' : ''}`}>
                <img alt="Logo" src={props.instalacion.logoFooter} />
                <div className='labelContacto'>
                  {(/*props.instalacion.datosContacto.telefono !== undefined &&*/
                    <div>
                      0800-555-ALVEAR (258327)
                    </div>)}
                  {(props.instalacion.datosContacto && props.instalacion.datosContacto.email !== undefined &&
                    <div className='emailLabel'>
                      {props.instalacion.datosContacto.email}
                    </div>)}
                </div>
              </div>
            </Col>
            {(props.instalacion.datosContacto.calle !== undefined || props.instalacion.datosContacto.telefono !== undefined || props.instalacion.datosContacto.email !== undefined) &&
              <Col xs={12} md={12} lg={2} xl={2}>
                <div className={`datosContacto ${props.existePedido ? 'datosContactoAux' : ''}`}>
                  <div className="title">INFORMACIÓN</div>
                  <div className={`data ${props.existePedido ? 'dataAux' : ''}`} onClick={props.handleOnClickBasesCondiciones}>Bases y condiciones</div>
                </div>
              </Col>}
            {(props.instalacion.datosContacto.facebook !== undefined
              || props.instalacion.datosContacto.twitter !== undefined
              || (props.instalacion.datosContacto.telefono !== undefined && window.chrome !== undefined)
              || props.instalacion.datosContacto.instagram !== undefined) &&
              <Col xs={12} md={12} lg={1} xl={1}>
                <div className={`redesSociales ${props.existePedido ? 'redesSocialesAux' : ''}`}>
                  <div className="title">REDES SOCIALES</div>
                  <div className={`logosContainer ${props.existePedido ? 'logosContainerAux' : ''}`}>
                    {props.instalacion.datosContacto.facebook !== undefined &&
                      <a className='redSocial' href={props.instalacion.datosContacto.facebook} target="_blank">
                        <FacebookIcon className='logoRed' /> Facebook
                      </a>
                    }
                    {props.instalacion.datosContacto.twitter !== undefined &&
                      <a className='redSocial' href={props.instalacion.datosContacto.twitter} target="_blank">
                        <InstagramIcon className='logoRed' /> Instagram
                      </a>
                    }
                    {props.instalacion.datosContacto.instagram !== undefined &&
                      <a className='redSocial' href={props.instalacion.datosContacto.instagram} target="_blank">
                        <TwitterIcon className='logoRed' /> Twitter
                      </a>
                    }
                  </div>
                </div>
              </Col>}
            <Col xs={12} md={12} lg={4} xl={4} style={{ padding: '0px' }}>
              <div className='containerImgFooter'>
                <div className='containerImgCybermonday'>
                  <img src={require('../../../images/Cybermonday.png')} />
                </div>
                <div className='imageAfip'>
                  <a href="http://qr.afip.gob.ar/?qr=C0MQgeU2wps5MugnBNxBIQ,," target="_F960AFIPInfo">
                    <img src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg" border="0" />
                  </a>
                </div>
                <div className='containerImgPiePagina'>
                  <img src={require('../../../images/cace.png')} />
                </div>
                {props.instalacion.imgPiePagina && <div className='containerImgPiePagina'>
                  <img src={props.instalacion.imgPiePagina} />
                </div>}
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: '0px', justifyContent: 'center' }}>
            <Col xs={12} md={12} lg={props.existePedido ? 12 : 8} xl={8} style={{ padding: '0px' }}>
            </Col>
            <Col xs={12} md={12} lg={12}>
              <div className={`desarrolloBy`}>
                <a className='desarrolloByAnchor' href="https://www.diveria.com/en/" target="_blank">
                  Diseño y desarrollo <span>Diveria SRL - Ideas that really work</span>
                </a>
              </div>
            </Col>
          </Row>
        </div>}
    </div>
  )
}

Footer.propTypes = {
  instalacion: PropTypes.object.isRequired,
  marginBottomFooter: PropTypes.bool.isRequired,
  existePedido: PropTypes.bool.isRequired,
  handleOnClickBasesCondiciones: PropTypes.func.isRequired,
}

export default Footer