import { createSelector } from 'reselect'

const facturacionReducerDataSelector = state => state.facturacionReducer.getIn(["data"]).toJS()
export const facturacionSelector = createSelector(
  facturacionReducerDataSelector, (dataField) => {

    const parseCuit = (cuit) => `${cuit.substring(0, 2)}-${cuit.substring(2, 10)}-${cuit.substring(10, 11)}`;

    const clienteDatosFacturacion = dataField.clienteDatosFacturacion;
    if (clienteDatosFacturacion) {
      clienteDatosFacturacion.forEach(cdf => {
        cdf.cuit = parseCuit(cdf.cuit);
      })
    }

    return {
        condicionesIVA: dataField.condicionesIVA,
        clienteDatosFacturacion: clienteDatosFacturacion,
    }
})