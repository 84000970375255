import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText'
import CustomInput from '../Common/textInput/CustomInput'
import './styles.css'
import CustomModal from '../UI/Modals/CustomModal'
import MaskedInput from 'react-text-mask';
import Input from '@material-ui/core/Input';
import { ListItem } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PedidoItem from './PedidoItem';
import PedidoInfoLabel from './PedidoInfoLabel';
import PedidoHeader from './PedidoHeader'
import moment from 'moment'
import PedidoDatosAdicionales from './PedidoDatosAdicionales'
import CustomButton from '../Common/wrappers/CustomButton';
import PedidoConfirmacion from './PedidoConfirmacion'

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['+', '5', '4', ' ', '9', ' ', /[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      //mask={['+54', ' ', '9', ' ', /[1-9]/, /[1-9]/, /[1-9]/, ' ', /[1-9]/, /[1-9]/, /[1-9]/,'-', /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/]}
      placeholderChar={'_'}
      showMask
      keepCharPositions={false}
      guide={true}
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  root: {
    flexGrow: 1,
    outline: 'none',
    borderBottom: 'none !important'
  },
  show: {
    transform: 'translateY(0)',
    transition: 'transform .5s',
  },
  hide: {
    transform: 'translateY(+110%)',
    transition: 'transform .5s',
  },
  icon: {
    color: '#B4C4D5',
    right: '20px'
  },
  select: {
    textAlign: 'left',
    font: '400 19px/30px Poppins',
    letterSpacing: '0px',
    borderRadius: '10px !important',
    padding: '10px 15px',
    height: '25px',
    color: '#2E266F !important',
    border: '2px solid #F68523',
    opacity: 1,
    display: 'flex',
    alignItems: 'center',
  },
  disabled: {
    background: '#8080801a',
    color: '#848484 !important',
  },
  rootDialog: {
    zIndex: 1550,
  },
  rootContent: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '-5px 4px 10px #8E8E8E29',
    borderRadius: '21px',
    opacity: 1,
    maxWidth: '850px',
    minWidth: '300px',
    padding: '0px !important'
  },
  rootRadioGroup: {
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'row',
  },
  rootFormLabel: {
    marginLeft: '0px',
    marginRight: '0px'
  },
  rootListText: {
    overflow: 'hidden'
  },
  label: {
    fontSize: '14px'
  },
})

function new_script(src) {
  return new Promise(function (resolve, reject) {
    var script = document.createElement('script');
    script.src = src;
    script.addEventListener('load', function () {
      resolve();
    });
    script.addEventListener('error', function (e) {
      reject(e);
    });
    document.body.appendChild(script);
  })
};

var my_script = new_script('https://secure.mlstatic.com/sdk/javascript/v1/mercadopago.js');

class FinalizarCompra extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      status: 'startMP'
    };
  }


  hideInDateRange = (min, max) => {
    return moment().isBetween(min, max, undefined, '[]')
  }

  toFixedCustomize = (num) => {
    var resultado = num.toFixed(6).slice(0, -4)
    return resultado
  }

  getItemValue = (item) => {
    return `${item.nombre}`;
  }

  renderItem(item, isHighlighted) {
    return (
      <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
        {item.nombre}
      </div>
    );
  }

  do_loadMP = () => {
    var self = this;
    my_script.then(function () {
      self.setState({ 'status': 'doneMP' });
    }).catch(function () {
      self.setState({ 'status': 'errorMP' });
    })
  }

  _renderInstallments() {
    return this.props.installments && this.props.installments.map(el => {
      return <ListItem value={el.value} >
        <ListItemText classes={{ primary: styles.rootListText }} primary={el.text}></ListItemText>
      </ListItem>

    })
  }

  render() {

    var self = this;
    if (self.state.status === 'startMP') {
      self.state.status = 'loadingMP';
      setTimeout(function () {
        self.do_loadMP()
      }, 0);
    }

    const { classes } = this.props;

    const { tipoFacturacion, idClienteDatosFacturacion } = this.props.datosFacturacion;
    const preferenciaFacturacionElegida = tipoFacturacion === 1 || (tipoFacturacion === 2 && !!idClienteDatosFacturacion);

    return (

      <div>
        {/*self.state.status && self.state.status === 'doneMP'*/}
        {/*window.Mercadopago ? window.Mercadopago.setPublishableKey('APP_USR-6a837c70-3529-4da3-ad23-3342ef620f04'): undefined*/}
        {/* srv009 */}
        {/*window.Mercadopago ? window.Mercadopago.setPublishableKey('APP_USR-c18772bc-7c5a-40dd-9096-2c4f4c7c4b8d'): undefined*/}

        <Row style={{ margin: '0px' }}>

          <Col xs={12} md={12} lg={6} style={{ padding: '0px' }}>

            {/* DATOS RESUMEN COMPRA + SELECCION DE OPCION DE CAMBIO DE PRODUCTO */}
            <div className='containerResumenCompra'>
              <div className='resumenCompra'>
                <div className='containerBtnBack'>
                  <CustomButton color="success" startIcon={<ArrowBackIosIcon />} onClick={this.props.handleOnClickBackToCatalogo} classProp='btnBackFinalizarCompra' variant="outlined" message="Continuar comprando" />
                </div>
                <div id='sectionTicket' className='containerTicket'>
                  <PedidoHeader pedido={this.props.pedido} toFixedCustomize={this.toFixedCustomize} />
                  <PedidoInfoLabel
                    reemplazarProductos={this.props.reemplazarProductos}
                    classes={this.props.classes}
                    handleChangeRadioButton={this.props.handleChangeRadioButton} />
                  <PedidoItem
                    toFixedCustomize={this.toFixedCustomize}
                    handleOnClickVerMasPedidosDetalle={this.props.handleOnClickVerMasPedidosDetalle}
                    handleChangeRadioButtonIndividual={this.props.handleChangeRadioButtonIndividual}
                    pedido={this.props.pedido}
                    instalacion={this.props.instalacion}
                    verMasPedidosDetalle={this.props.verMasPedidosDetalle}
                    classes={this.props.classes} />
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={12} lg={6} style={{ padding: '0px' }}>
            {<PedidoDatosAdicionales props={this.props} preferenciaFacturacionElegida={preferenciaFacturacionElegida} />}
          </Col>
        </Row>
        {self.state.status && self.state.status === 'doneMP'}   { }
        {/* MODAL CONFIRMACION DE PEDIDO */}
        {(this.props.usuario && this.props.usuario.dni && this.props.usuario.telefono) ?
          null
          :
          <CustomModal
            open={this.props.openModal}
            //onClose={this.handleClose}
            title="Completa estos datos"
            buttonNames={["Continuar compra"]}
            buttonActions={
              [this.props.handleOnSaveDatosAdicionales]
            }
            maxWidth='sm'
            fullWidth={true}
          >
            <div className='modalTextContent'>
              <Row style={{ margin: '0px' }}>
                <Col xs={12} md={12} lg={12} style={{ padding: '0px' }}>
                  <div className='datosAdicionales'>
                    <div className='containerFormAdicionales'>
                      <span class="label">DNI</span>
                      <div className='inputContainer'>
                        <CustomInput
                          placeholder="Nro. Documento"
                          value={this.props.form.documento}
                          name="documento"
                          onChange={this.props.handleOnDataChange}
                          error={this.props.errores.documento !== undefined}
                        />
                        {this.props.errores.documento !== undefined &&
                          <FormHelperText error style={{ marginTop: '3px' }}>{this.props.errores.documento}</FormHelperText>}
                      </div>
                      <span class="label">Celular</span>
                      <div className='inputContainer'>
                        <Input
                          placeholder="Teléfono"
                          value={this.props.form.telefono}
                          onChange={this.props.handleOnDataChange}
                          name="telefono"
                          inputComponent={TextMaskCustom}
                          error={this.props.errores.telefono !== undefined}
                        />
                        {this.props.errores.telefono !== undefined &&
                          <FormHelperText error>{this.props.errores.telefono}</FormHelperText>}
                        <span class="label" style={{ fontSize: 12, position: "inherit", margin: "5px 0 0 0" }}>*sin 0 y sin 15</span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </CustomModal>
        }
        {/* MODAL CONFIRMACION DE PEDIDO */}
        <PedidoConfirmacion classes={classes}
          openModalCompraExitosa={this.props.openModalCompraExitosa}
          handleOnClickEntendido={this.props.handleOnClickEntendido} />
      </div>
    )
  }
}

FinalizarCompra.propTypes = {
  instalacion: PropTypes.object.isRequired,
  pedido: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  formMercadoPago: PropTypes.object.isRequired,
  errores: PropTypes.object.isRequired,
  handleOnDataChange: PropTypes.func,
  handleOnDataChangeMercadoPago: PropTypes.func,
  handleOnSaveDatosAdicionales: PropTypes.func,
  handleOnPagar: PropTypes.func,
  handleChangeRadioButton: PropTypes.func,
  handleOnClickBtnFormaPago: PropTypes.func,
  handleOnClickFinalizarCompra: PropTypes.func,
  handleChangeRadioButtonIndividual: PropTypes.func,
  reemplazarProductos: PropTypes.number.isRequired,
  showMetodosPago: PropTypes.number.isRequired,
  openModal: PropTypes.bool.isRequired,
  openModalCompraExitosa: PropTypes.bool.isRequired,
  calendario: PropTypes.array,
  diasAdelante: PropTypes.number,
  showTurnero: PropTypes.bool.isRequired,
  disabledPagar: PropTypes.bool.isRequired,
  verMasPedidosDetalle: PropTypes.bool.isRequired,
  datosFacturacion: PropTypes.object,
  handleOnChangeFacturacion: PropTypes.func,
  preferenciaEntregaElegida: PropTypes.bool.isRequired,
  entregaElegida: PropTypes.number,
  permitePago: PropTypes.bool.isRequired,
  fechaHoraSeleccionada: PropTypes.bool.isRequired,
  fechaSeleccionada: PropTypes.object,
  horaSeleccionada: PropTypes.object,
  handleOnClickHorario: PropTypes.func,
  handleOnClickCambiarFechaHora: PropTypes.func,
  handleOnClickDia: PropTypes.func,
  handleOnClickCambiarDomicilio: PropTypes.func,
  handleOnClickEntendido: PropTypes.func,
  handleOnClickBackToCatalogo: PropTypes.func,
  handleOnClickCambiarPreferencia: PropTypes.func,
  domicilioSeleccionado: PropTypes.string,
  domicilioSeleccionadoItem: PropTypes.object,
  domicilios: PropTypes.array.isRequired,
  handleChangeRadioButtonDomicilio: PropTypes.func.isRequired,
  handleAfterSaveNuevoDomicilio: PropTypes.func.isRequired,
  handleOnClickSaveDomicilioAlternativo: PropTypes.func.isRequired,
  renderMobbexButton: PropTypes.func.isRequired,
}

FinalizarCompra.defaultProps = {
  pedido: undefined,
  handleOnDataChange: f => f,
  handleOnDataChangeMercadoPago: f => f,
  handleOnSaveDatosAdicionales: f => f,
  handleOnPagar: f => f,
  handleChangeRadioButton: f => f,
  handleOnClickBtnFormaPago: f => f,
  handleOnClickFinalizarCompra: f => f,
  handleChangeRadioButtonIndividual: f => f,
  handleOnClickBackToCatalogo: f => f,
  handleOnClickEntendido: f => f,
  datosFacturacion: {},
  handleOnChangeFacturacion: () => { },
  handleOnClickHorario: f => f,
  handleOnClickDia: f => f,
  handleOnClickCambiarDomicilio: f => f,
  handleAfterSaveNuevoDomicilio: f => f,
  handleOnClickSaveDomicilioAlternativo: f => f,
  reemplazarProductos: 1,
  openModal: true,
  calendario: [],
}

export default withStyles(styles)(FinalizarCompra)