// acciones
export const INITIALIZE_FORM = 'calendarioReducer/INITIALIZE_FORM'
export const SAGA_FETCH_CALENDARIO = 'calendarioReducer/SAGA_FETCH_CALENDARIO'
export const SET_CALENDARIO_PICKUP = 'calendarioReducer/SET_CALENDARIO_PICKUP'
export const SET_CALENDARIO_DELIVERY = 'calendarioReducer/SET_CALENDARIO_DELIVERY'

// funciones
export const initializeForm = (payload) => ({
    type: INITIALIZE_FORM,
    payload
})

export const sagaFetchCalendario = (payload) => ({
    type: SAGA_FETCH_CALENDARIO,
    payload
})

export const setCalendarioPickup = (payload) => ({
    type: SET_CALENDARIO_PICKUP,
    payload
})

export const setCalendarioDelivery = (payload) => ({
  type: SET_CALENDARIO_DELIVERY,
  payload
})
