import { takeLatest, all, put } from 'redux-saga/effects'
import * as calls from './calls'
import * as reducerActions from '../../reducers/FacturacionReducer/actions'
import * as layoutActions from '../../reducers/LayoutReducer/actions'

function* genGetCondicionesIVA(e){
  try {
    // llamo a la acción que busca las condiciones de IVA
    const condicionesIVA = yield calls.getCondicionesIVA()

    // almaceno los datos en el estado del reductor
    yield put(reducerActions.setCondicionesIVA(condicionesIVA))
  }
  catch (error) 
  {
    if (error.Message) {
        console.log(error.Message)
        return;
    }
  }
}

function* genGetDatosFacturacion(e){
  try {
    // llamo a la acción que busca los datos de facturacion
    const datosFacturacion = yield calls.getDatosFacturacion(e.payload)

    if (e.payload.callback) e.payload.callback(datosFacturacion);
    
    // almaceno los datos en el estado del reductor
    yield put(reducerActions.setDatosFacturacion(datosFacturacion))
  }
  catch (error) 
  {
    if (error.Message) {
        console.log(error.Message)
        return;
    }
  }
}

function* genSaveDatosFacturacion(e){
  try {
    // muestro el loader
    yield put(layoutActions.setLoading({ show: true, message: 'Guardando datos de facturación' }));

    // guardo los datos de facturacion
    const idClienteDatosFacturacion = yield calls.saveDatosFacturacion(e.payload);

    // limpio los datos de facturacion para que luego se recarguen
    yield put(reducerActions.setDatosFacturacion(undefined))

    // llamo a la callback con el resultado del save
    if (e.payload.callback) e.payload.callback(idClienteDatosFacturacion);
  }
  catch (error) 
  {
    if(e.payload.callbackError){
      e.payload.callbackError(error.error)
  }
  
    if (error.Message) {
        console.log(error.Message)
        return;
    }
  }
  finally {
    // oculto el loader
    yield put(layoutActions.setLoading({ show: false }))
  }
}


function* genSetDatosFacturacionActivo(e) {
  try {
    // muestro el loader
    yield put(layoutActions.setLoading({ show: true, message: 'Actualizando datos de facturación' }))

    // actualizo el bit activo de los datos de facturacion
    yield calls.setDatosFacturacionActivo(e.payload)

    // actualizo el reducer
    yield put(reducerActions.setDatosFacturacionActivo(e.payload))

    // llamo a la funcion de callback
    if (e.payload.callback) e.payload.callback();
  }
  catch (error) 
  {
    if (error.Message) {
        console.log(error.Message)
        return;
    }
  }
  finally {
    // oculto el loader
    yield put(layoutActions.setLoading({ show: false }))
  }
}

function* defaultSaga() {
    yield all([
      takeLatest(reducerActions.SAGA_FETCH_CONDICIONES_IVA, genGetCondicionesIVA),
      takeLatest(reducerActions.SAGA_FETCH_DATOS_FACTURACION, genGetDatosFacturacion),
      takeLatest(reducerActions.SAGA_SAVE_DATOS_FACTURACION, genSaveDatosFacturacion),
      takeLatest(reducerActions.SAGA_SET_DATOS_FACTURACION_ACTIVO, genSetDatosFacturacionActivo)
    ])
  }
  
  export const sagas = [
    defaultSaga
  ]