import { createSelector } from 'reselect'

const pedidoReducerDataSelector = state => state.pedidoReducer.getIn(["data"]).toJS()
export const pedidoDataSelector = createSelector(
    pedidoReducerDataSelector, (dataField) => {      
      
      // pedido borrador de un usuario

      let myPedidoBorrador = undefined

      if(dataField.pedido)
      {
        myPedidoBorrador = {}
        let listaPedidoDetalle = []

        // cargo los datos del pedido 

        myPedidoBorrador.id = dataField.pedido.idPedido
        myPedidoBorrador.precioLista = dataField.pedido.precioLista
        myPedidoBorrador.precioFinal = dataField.pedido.precioFinal
        myPedidoBorrador.idBandaDescuento = dataField.pedido.idBandaDescuento
        myPedidoBorrador.limiteInferior = dataField.pedido.limiteInferior

        dataField.pedido.pedidoDetalles.forEach(pd => {
          
          // cargo algunos detalles del pedido
          let pedidoDetalle = {}
          pedidoDetalle.idPedidoDetalle = pd.idPedidoDetalle
          pedidoDetalle.idCatalogoEntrada = pd.idCatalogoEntrada
          pedidoDetalle.idCatalogo = pd.idCatalogo
          pedidoDetalle.idArticulo = pd.idArticulo
          pedidoDetalle.cantidad = pd.cantidad
          pedidoDetalle.alternativa = pd.alternativa
          pedidoDetalle.imagenArticulo = pd.imagenArticulo
          pedidoDetalle.descripcion = pd.descripcion
          pedidoDetalle.precioLista = pd.precioLista
          pedidoDetalle.precioListaDescuento = pd.precioListaDescuento
          pedidoDetalle.productoPesable = pd.productoPesable
          pedidoDetalle.cantidadLimMax = pd.cantidadLimMax
          pedidoDetalle.cantidadLimMin = pd.cantidadLimMin
          pedidoDetalle.incremento = pd.incremento
          pedidoDetalle.precioUnitario = pd.precioUnitario
          pedidoDetalle.permisoReemplazo = pd.permisoReemplazo

          listaPedidoDetalle.push(pedidoDetalle)
        });

        myPedidoBorrador.listaPedidoDetalle = listaPedidoDetalle
        
      }

      let idPDAgregado = undefined

      if(dataField.idPedidoDetalleAgregado)
      {
        idPDAgregado = dataField.idPedidoDetalleAgregado
      }
      
      return {
        pedido: myPedidoBorrador,
        idPedidoDetalleAgregado: idPDAgregado,
        pedidosUser:dataField.pedidosUser,
        pedidosCliente: dataField.pedidosCliente
      }
    }
)

const idPedidoDetalleAgregadoReducerSelector = state => state.pedidoReducer.getIn(["idPedidoDetalleAgregado"])
export const idPedidoDetalleAgregadoSelector = createSelector(
  idPedidoDetalleAgregadoReducerSelector, (idPedidoDetalleAgregadoField) => {    
    return idPedidoDetalleAgregadoField
  }
)


const PedidosBorradorCliente = state => state.pedidoReducer.getIn(["data"]).toJS()
export const pedidosBorradorCliente = createSelector(
  PedidosBorradorCliente, (data) => {
    if (data.pedidosBorradorCliente === "") return undefined  
    return data.pedidosBorradorCliente
  }
)

const showResumenPedidoReducerSelector = state => state.pedidoReducer.getIn(["data"]).toJS()
export const showResumenPedidoSelector = createSelector(
  showResumenPedidoReducerSelector, (data) => {
    return data.showResumenPedido
  }
)

const openModalVaciarCarritoReducerSelector = state => state.pedidoReducer.getIn(["data"]).toJS()
export const openModalVaciarCarritoSelector = createSelector(
  openModalVaciarCarritoReducerSelector, (data) => {
    return data.openModalVaciarCarrito
  }
)
