import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import {
  withStyles,
  Dialog,
  DialogContent,
  Button,
  CircularProgress,
} from '@material-ui/core';
import VisualizadorImagenes from '../visualizadorImagenes/visualizadorImagenes';
import DatosExtra from '../datosExtra/datosExtra';
import SelectorCantidad from '../../Common/selectorCantidad/selectorCantidad';
import VisualizadorPrecio from '../visualizadorPrecio/visualizadorPrecio';
import ItemAlternativa from './itemAlternativa';
import useArticuloAlternativa from './useArticuloAlternativa';
import './styles.css';

const styles = () => ({
  rootDialog: {},
  rootContent: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '-5px 4px 10px #8E8E8E29',
    borderRadius: '21px',
    opacity: 1,
    maxWidth: '587px',
    padding: '0px !important',
    overflow: 'visible',
  },
});

const ArticuloAlternativa = (props) => {
  const {
    classes,
    open,
    itemId,
    item,
    initialValue,
    alternativaSeleccionada,
    onClose,
    onConfirm,
  } = props;

  const {
    articulo,
    cantidad,
    setCantidad,
    cantidadAlternativasPermitidas,
    cantidadAlternativasSeleccionadas,
    alternativas,
    onSelectAlternativa,
    onAgregarAlCarrito,
  } = useArticuloAlternativa({
    itemId,
    item,
    cantidadInicial: initialValue,
    alternativaSeleccionadaInicial: alternativaSeleccionada,
    onConfirm,
  });

  return (
    <Dialog
      classes={{root: classes.rootDialog, paperScrollPaper: classes.rootContent}}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth='sm'
      fullWidth={true}
    >
      <DialogContent classes={{root: classes.rootContent}}>
        <div className="cantidadAlternativaModal">
          { articulo && (
            <>
              { articulo.srcImgOferta && articulo.precioPromocional &&
                <div className='selloOferta'>
                  <img alt="" src={articulo.srcImgOferta} />
                </div>
              }
    
              <div className="closeIcon">
                <img alt="" src={require('../../../images/iconClose.svg')} onClick={onClose}></img>
              </div>
    
              <Row className="articuloContainer" bottom="xs">
                <Col xs={12} sm={4}>
                  <VisualizadorImagenes item={articulo} />
                  
                  {articulo.porcentajeDescuento && <div className='labelDescuento'>{articulo.porcentajeDescuento.toFixed(0)}%</div>}
                </Col>
    
                <Col xs={12} sm={8}>
                  <Row className="nombreArticuloContainer">{articulo.nombre}</Row>
                  <Row className="datosExtraContainer"><DatosExtra {...articulo} /></Row>
                  <Row className="selectorCantidadContainer">
                    <Col xs={6}>
                      <SelectorCantidad {...articulo} value={cantidad} onChange={setCantidad} />
                    </Col>

                    {/* <Col xs={6} className="visualizadorPrecioContainer">
                      <VisualizadorPrecio precioLista={articulo.precioLista} precioPromocional={articulo.precioPromocional} />  
                    </Col> */}
                  </Row>
                </Col>
              </Row>
    
              <div className="seccionAlternativas">
                <Row className="headerListaAlternativas">
                  <Col xs={12} sm={7} className="encabezadoAlternativas">Elige una de las opciones</Col>
                  <Col xs={12} sm={5} className="textoCantidadSeleccionada">{`${cantidadAlternativasSeleccionadas} de ${cantidadAlternativasPermitidas} seleccionada`}</Col>
                </Row>
    
                <div className="listaAlternativas">
                  { alternativas.map((opcion) => <ItemAlternativa {...opcion} onSelect={onSelectAlternativa} />) }
                </div>
              </div>
    
              <Row center="xs" className="footer">
                <Col xs={12}>
                  <Button className='btnAgregar' onClick={onAgregarAlCarrito} disabled={cantidadAlternativasSeleccionadas === 0}>
                    AGREGAR AL CARRITO
                  </Button>
                </Col>
              </Row>
            </>
          )}
          
          { !articulo && (
            <Row center="xs">
              <Col xs={12}><CircularProgress /></Col>
              <Col xs={12} className="loadingMessage">Cargando artículo</Col>
            </Row>
          )}
          
        </div>
      </DialogContent>
    </Dialog>
  );
};

ArticuloAlternativa.defaultProps = {
  open: false,
  itemId: undefined,
  item: undefined,
  initialValue: 0,
  alternativaSeleccionada: undefined,
  onClose: () => {},
  onConfirm: () => {},
};

ArticuloAlternativa.propTypes = {
  open: PropTypes.bool,
  itemId: PropTypes.number,
  item: PropTypes.instanceOf(Object),
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  alternativaSeleccionada: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default withStyles(styles)(ArticuloAlternativa);
