import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Dropdown = ({ summary, detail }) => {
   return <ExpansionPanel>
      <ExpansionPanelSummary
         className='panelNotificacion'
         expandIcon={<ExpandMoreIcon style={{ color: '#FFFFFF' }} />}
      >
         {summary}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ padding: '0px', display: 'block' }}>
         {detail}
      </ExpansionPanelDetails>
   </ExpansionPanel>
}

export default Dropdown;