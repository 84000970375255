// acciones
export const INITIALIZE_FORM = 'sucursalesReducer/INITIALIZE_FORM'
export const SET_SUCURSALES = 'sucursalesReducer/SET_SUCURSALES'
export const SAGA_FETCH_SUCURSALES = 'sucursalesReducer/SAGA_FETCH_SUCURSALES'

// funciones
export const initializeForm = (payload) => ({
    type: INITIALIZE_FORM,
    payload
})

export const sagaFetchSucusales = (payload) => ({
    type: SAGA_FETCH_SUCURSALES,
    payload
})

export const setSucursales= (payload) => ({
    type: SET_SUCURSALES,
    payload
})

