import { takeLatest, takeEvery, all, put } from 'redux-saga/effects'
import * as calls from './calls'
import * as reducerActions from '../../reducers/ConfirmarCorreoReducer/actions'


function* genGetUsuario(e){
    try {

        // llamo a la acción que busca el usuario
        let usuario = yield calls.confirmarCorreo(e.payload)

        // almaceno los datos en el estado del reductor
        yield put(reducerActions.setUsuario(usuario))
    }
    catch (error) 
    {
        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
}

function* defaultSaga() {
    yield all([
      takeLatest(reducerActions.SAGA_CONFIRMAR_CORREO, genGetUsuario)
    ])
  }
  
  export const sagas = [
    defaultSaga
  ]