import { takeLatest, all, put } from 'redux-saga/effects'
import * as calls from './calls'
import * as reducerActions from '../../reducers/RegistrarUsuarioReducer/actions'
import * as layoutActions from '../../reducers/LayoutReducer/actions'
import * as sessionActions from '../../reducers/SessionReducer/actions'
import * as Notification from '../../components/Common/notification'


function* genGetCliente(e){
    try {

        // limpio el cliente actual
        yield put(reducerActions.setCliente(undefined))

        // llamo a la acción que busca el cliente
        let cliente = yield calls.getCliente(e.payload)

        // almaceno los datos en el estado del reductor
        yield put(reducerActions.setCliente(cliente))
    }
    catch (error) 
    {
        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
}

function* genSaveUsuario(e) {

    let payload = {}
    payload.callback = e.payload.callback
    payload.callbackError = e.payload.callbackError

    try {

        e.payload.callback = undefined
        e.payload.callbackError = undefined

        // muestro el loader
        yield put(layoutActions.setLoading({ show: true }))

        // llamo a la acción que persiste el usuario
        let response = yield calls.saveUsuario(e.payload)

        // indico que se creó el usuario
        yield put(reducerActions.setUsuarioCreado(true))

        // indico que se finalizó con el proceso de recuperación de contraseña
        yield put(sessionActions.setResultadoAutenticacion({ usuarioId: response.usuarioId, token: response.token}))

        if(payload.callback){
            payload.callback()
        }
    }
    catch(e) {
        // muestro el mensaje de error
        Notification.showError(e.error)
    }
    finally {
        // oculto el loader
        yield put(layoutActions.setLoading({ show: false }))
    }
}

function* genGetPaises() {
    try {
      
        // llamo a la acción que persiste el usuario
        let paises = yield calls.getPaises()

        // indico que se creó el usuario
        yield put(reducerActions.setPaises(paises))
    }
    catch(e) {
        // muestro el mensaje de error
        Notification.showError(e.error)
    }
    
}

function* genGetPaisDefault() {
    try {
      
        // llamo a la acción que persiste el usuario
        let pais = yield calls.getPaisDefault()

        // indico que se creó el usuario
        yield put(reducerActions.setPaisDefault(pais))
    }
    catch(e) {
        // muestro el mensaje de error
        Notification.showError(e.error)
    }
    
}

function* genGetValidarCodigoConfirmacionEmail(e) {

    let payload = {}
    payload.callback = e.payload.callback
    payload.callbackError = e.payload.callbackError

    try {
        // muestro el loader
        yield put(layoutActions.setLoading({ show: true }))

        e.payload.callback = undefined
        e.payload.callbackError = undefined

        // llamo a la acción que persiste el usuario
        let resultadoAutenticacion = yield calls.validarCodigoConfirmacionEmail(e.payload)

        // almaceno el resultado de la autenticación en el reducer
        yield put(sessionActions.setResultadoAutenticacion({ 
            error: resultadoAutenticacion.error || undefined,
            autenticado: resultadoAutenticacion.autenticado,
            restablecerContrasenia: false,
        }))

        // almaceno el usuario en el reductor
        yield put(sessionActions.setUsuario(resultadoAutenticacion.usuario))

        if(payload.callback){
            payload.callback()
        }
    }
    catch(e) {
        // muestro el mensaje de error
        yield put(sessionActions.setResultadoAutenticacion({ error: e }))
        if(payload.callbackError){
            payload.callbackError(e)
        }
    }
    finally {
        // oculto el loader
        yield put(layoutActions.setLoading({ show: false }))
    }
    
}

function* genReenviarCodigoConfirmacionEmail(e) {

    try {
        // muestro el loader
        yield put(layoutActions.setLoading({ show: true,  message: 'Enviando nuevo código' }))

        // llamo a la acción que persiste el usuario
        let response = yield calls.reenviarCodigoConfirmacionEmail(e.payload)

        yield put(sessionActions.setResultadoAutenticacion({ usuarioId: response.usuarioId, token: response.token}))

    }
    catch(e) {
        // muestro el mensaje de error
        yield put(sessionActions.setResultadoAutenticacion({ error: e }))
    }
    finally {
        // oculto el loader
        yield put(layoutActions.setLoading({ show: false }))
    }
    
}

function* defaultSaga() {
    yield all([
      takeLatest(reducerActions.SAGA_FETCH_CLIENTE, genGetCliente),
      takeLatest(reducerActions.SAGA_SAVE_USUARIO, genSaveUsuario),
      takeLatest(reducerActions.GET_PAISES, genGetPaises),
      takeLatest(reducerActions.GET_PAIS_DEFAULT, genGetPaisDefault),
      takeLatest(reducerActions.SAGA_VALIDAR_CODIGO_CONFIRMACION_EMAIL, genGetValidarCodigoConfirmacionEmail),
      takeLatest(reducerActions.SAGA_REENVIAR_CODIGO_CONFIRMACION_MAIL, genReenviarCodigoConfirmacionEmail)
    ])
  }
  
  export const sagas = [
    defaultSaga
  ]