import _axios from '../../apiClient'

export function getInstalacion(payload) {
    return _axios.get(`/api/Instalacion/Get`,
    { 
        params: { url: payload } 
    },
    {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw data
    })
}

export function getUsuarioLogeado() {
  return _axios.get(`/api/Usuario/CurrentUser`,
    {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw data
    })
}

export function autenticarUsuario(payload) {
  return _axios.post(`/api/Usuario/Autenticar`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw data
    })
}

export function restablecerContrasenia(payload) {
  return _axios.post(`/api/Usuario/RestablecerContrasenia`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw data
    })
}

export function olvidoContrasenia(payload) {
  return _axios.post(`/api/Usuario/OlvidoContrasenia`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw data
    })
}

export function validarCodigo(payload) {
  return _axios.post(`/api/Usuario/ValidarCodigo`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw data
    })
}

export function logOutUsuario() {
  return _axios.post(`/api/Usuario/logOutUsuario`,
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw data
    })
}

export function saveDatosAdicionales(payload) {
  return _axios.post(`/api/Usuario/SaveMoreInfoUsuario`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw data
    })
}

export function getImagenLogin(payload) {
  return _axios.get(`/api/Instalacion/GetImagenLogin`,
  { 
    params: { idInstalacion: payload } 
  },
  {
    headers: { 'Content-Type': 'application/json;charset=UTF-8' }
  })
  .then(response => response.data)
  .catch((error) => {
    const data = error.response.data
    throw (data)
  })
}

export function getImagenDescuentos(payload) {
  return _axios.get(`/api/Instalacion/GetImagenDescuentos`,
  { 
    params: { idInstalacion: payload } 
  },
  {
    headers: { 'Content-Type': 'application/json;charset=UTF-8' }
  })
  .then(response => response.data)
  .catch((error) => {
    const data = error.response.data
    throw (data)
  })
}

export function marcarNotificacionesComoVistas(payload) {
  return _axios.get(`/api/Usuario/MarcarNotificacionesComoVistas`,
    { 
      params: { idUsuario: payload } 
    },
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw data
    })
}

export function marcarNotificacionVista(payload) {
  return _axios.get(`/api/Usuario/MarcarNotificacionVista`,
    { 
      params: { idNotificacion: payload } 
    },
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw data
    })
}

export function getMasNotificaciones(payload) {
  return _axios.get(`/api/Usuario/GetMasNotificaciones`,
    { 
      params: { idCliente: payload.idCliente, page: payload.page } 
    },
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw data
    })
}

export function saveDireccionEntrega(payload) {
  return _axios.post(`/api/Usuario/SaveDireccionEntrega`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw data
    })
}

export function deleteDireccionEntrega(payload) {
  return _axios.get(`/api/Usuario/DeleteDireccionEntrega`,
    { 
      params: { idDomicilioEntrega: payload.idDomicilioEntrega } 
    },
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw data
    })
}

export function getPreguntasFrecuentes(payload) {
  return _axios.get(`/api/Contenido/GetPreguntasFrecuentes`,
    { 
      params: { nombre: payload.nombre } 
    },
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw data
    })
}