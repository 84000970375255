import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { instalacionSelector } from '../../../selectors/sessionSelector';
import ImageGallery from 'react-image-gallery';
import { CircularProgress } from '@material-ui/core';
import useVisualizadorImagenes from './useVisualizadorImagenes';
import './styles.css';

const VisualizadorImagenes = ({ item, instalacion }) => {

  const renderImageItem = (imageItem) => (
    <div className='containerImage'
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '200px' }}
    >
      {imageItem.cargando === true &&
        <div className="circularProgress"
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}
        >
          <CircularProgress />
        </div>}
      <img
        alt=""
        src={imageItem.original}
        onLoad={() => onImageLoad(imageItem.index)}
        style={{ display: imageItem.cargando ? 'none' : 'block', height: '100%' }}
      />
    </div>
  );

  const {
    listaImagenes,
    onImageLoad,
    onImageGallerySlide,
  } = useVisualizadorImagenes({ item, renderImageItem });

  return (
    <div className='visualizadorImagenes'>
      {listaImagenes === undefined &&
        <div className='imageDefault'>
          <div>
            <img alt="" src={instalacion.logoCard}></img>
            <div>Producto sin foto</div>
          </div>
        </div>
      }

      {listaImagenes !== undefined &&
        <ImageGallery
          showBullets={false}
          showFullscreenButton={false}
          showPlayButton={false}
          showThumbnails={false}
          showIndex={false}
          items={listaImagenes}
          onSlide={onImageGallerySlide}
        />
      }
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
};

function mapStateToProps(state) {
  return {
    instalacion: instalacionSelector(state),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(VisualizadorImagenes);
