import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputBase from '@material-ui/core/InputBase';
import './styles.css'
import CustomButton from '../Common/wrappers/CustomButton'
import CustomInput from '../Common/textInput/CustomInput'

class RegistrarUsuario extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      value: '',
      suggestions: [],
      update: false
    }
  }

  render() {

    return (
      <div className='registrarUsuarioFormContainer'>
        <div className='containerForm'>

          <div className='logoContainer center'>
            <img src={this.props.instalacion.logoLogin} alt='Logo' />
          </div>

          {!this.props.usuarioCreado &&
            <div style={{ width: '100%' }}>
              <div className='inputContainer'>
                <CustomInput
                  placeholder="Nombre"
                  value={this.props.usuario.nombre}
                  name="nombre"
                  onChange={this.props.handleOnDataChange}
                  error={this.props.errores.nombre !== undefined}
                />
                {this.props.errores.nombre !== undefined &&
                  <FormHelperText error>{this.props.errores.nombre}</FormHelperText>}
              </div>

              <div className='inputContainer'>
                <CustomInput
                  placeholder="Apellido"
                  value={this.props.usuario.apellido}
                  name="apellido"
                  onChange={this.props.handleOnDataChange}
                  error={this.props.errores.apellido !== undefined}
                />
                {this.props.errores.apellido !== undefined &&
                  <FormHelperText error>{this.props.errores.apellido}</FormHelperText>}
              </div>

              <div className='inputContainer'>
                <CustomInput
                  placeholder="Email"
                  value={this.props.usuario.email}
                  name="email"
                  onChange={this.props.handleOnDataChange}
                  error={this.props.errores.email !== undefined}
                />
                {this.props.errores.email !== undefined &&
                  <FormHelperText error>{this.props.errores.email}</FormHelperText>}
              </div>

              <div className='inputContainer'>
                <CustomInput
                  placeholder="Contraseña"
                  value={this.props.usuario.password}
                  type="password"
                  name="password"
                  onChange={this.props.handleOnDataChange}
                  error={this.props.errores.password !== undefined}
                />
                {this.props.errores.password !== undefined &&
                  <FormHelperText error>{this.props.errores.password}</FormHelperText>}
              </div>
              <div className='myLabel error' style={{ visibility: this.props.error ? 'visible' : 'hidden' }} >{this.props.errorAutenticacion || ''}</div>
              <div className='buttonContainer'>
                <CustomButton color="secondary" onClick={this.props.handleOnSave} size="large" message="Registrarme" />
              </div>
              <Link to='/Login'>
                <div className='myLabel link' style={{ textAlign: 'center' }}>Ya tengo cuenta</div>
              </Link>
            </div>}

          {this.props.usuarioCreado && !this.props.codigoVerificado &&
            <div>
              <div className='labelInfoCodigo'>{`Le enviamos un código a su correo ${this.props.usuario.email}`}</div>

              <div className='inputContainer' style={{ margin: '30px 0px' }}>
                <CustomInput
                  placeholder="Código"
                  value={this.props.usuario.codigoVerificacion}
                  name="codigoVerificacion"
                  onChange={this.props.handleOnDataChange}
                  error={this.props.errores.codigoVerificacion !== undefined}
                />
                {this.props.errores.codigoVerificacion !== undefined &&
                  <FormHelperText error>{this.props.errores.codigoVerificacion}</FormHelperText>}
              </div>
              <div className='buttonContainer'>
                <Button className='btnPrincipal' onClick={this.props.handleOnConfirmarCódigo}>Confirmar código</Button>
              </div>
              <div style={{ marginTop: '10px' }}>
                <div className='labelReenvioCodigo'>No encuentras el código? <b onClick={this.props.handleOnReenviarCodigo} className='linkReenviar'>Reenviar código</b></div>
                <div className='labelReenvioCodigo'>No olvides buscar en tu carpeta de Spam</div>
              </div>
            </div>}

          {this.props.usuarioCreado && this.props.codigoVerificado &&
            <div>
              <div className='labelInfoCodigo' style={{ marginBottom: '40px' }}>{`Su cuenta ha sido verificada con éxito!`}</div>

              <Link to='/Catalogo'>
                <div className='buttonContainer'>
                  <Button className='btnPrincipal'>Ir al Catálogo</Button>
                </div>
              </Link>
            </div>
          }
        </div>
      </div>
    )
  }
}

RegistrarUsuario.propTypes = {
  instalacion: PropTypes.object.isRequired,
  usuario: PropTypes.object.isRequired,
  datosClienteHabilitados: PropTypes.bool.isRequired,
  handleOnCancelar: PropTypes.func.isRequired,
  handleOnSave: PropTypes.func.isRequired,
  errores: PropTypes.object,
  usuarioCreado: PropTypes.bool.isRequired,
  handleOnDataChange: PropTypes.func.isRequired,
  handleOnConfirmarCódigo: PropTypes.func.isRequired,
  handleOnReenviarCodigo: PropTypes.func.isRequired,
  codigoVerificado: PropTypes.bool.isRequired,
}

RegistrarUsuario.defaultProps = {
  errores: {}
}

export default RegistrarUsuario