import React from 'react';

const useVisualizadorImagenes = ({ item, renderImageItem }) => {
  const inicializarListaImagenes = () => {
    if (item.listaImagenesArticulos === undefined || item.listaImagenesArticulos.length === 0) {
      return undefined;
    }

    let lista = [];

    item.listaImagenesArticulos.forEach((i, index) => {
      let imagen = {};
      imagen.original = index === 0 ? i.path : undefined;
      imagen.articuloItem = i;
      imagen.renderItem = renderImageItem;
      imagen.cargando = true;
      imagen.index = index;

      lista.push(imagen);
    });

    return lista;
  };

  const [listaImagenes, setListaImagenes] = React.useState(inicializarListaImagenes());

  const onImageLoad = (index) => {
    let newListaImagenes = [...listaImagenes]
    newListaImagenes[index].cargando = false;

    setListaImagenes(newListaImagenes);
  }

  const onImageGallerySlide = (index) => {
    let newListaImagenes = [...listaImagenes];
    newListaImagenes[index].original = item.listaImagenesArticulo[index].path;

    setListaImagenes(newListaImagenes);
  }

  return {
    listaImagenes,
    onImageLoad,
    onImageGallerySlide,
  }
};

export default useVisualizadorImagenes;
