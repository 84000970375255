// acciones
export const SAVE_LOADING = 'layoutReducer/SAVE_LOADING'
export const SAVE_HEADER_DEFAULT = 'layoutReducer/SAVE_HEADER_DEFAULT'
export const SAVE_HEADER_SHOW = 'layoutReducer/SAVE_SHOW_HEADER'
export const SAVE_HEADER = 'layoutReducer/SAVE_HEADER'
export const SAVE_FOOTER_SHOW = 'layoutReducer/SAVE_SHOW_FOOTER'
export const SAVE_INICIALIZANDO_USUARIO = 'layoutReducer/SAVE_INICIALIZANDO_USUARIO'
export const SAVE_PERMITE_USUARIO_ANONIMO = 'layoutReducer/SAVE_PERMITE_USUARIO_ANONIMO'
export const SET_SHOW_BUSQUEDA = 'layoutReducer/SET_SHOW_BUSQUEDA'
export const SET_LIMPIAR_FILTRO = 'layoutReducer/SET_LIMPIAR_FILTRO'
export const SET_VISTA_FAVORITOS = 'layoutReducer/SET_VISTA_FAVORITOS'

//acciones timer
export const SET_TIMER_REINICIAR = 'layoutReducer/SET_TIMER_REINICIAR'
export const SET_TIMER_BANDERA_PEDIDO = 'layoutReducer/SET_TIMER_BANDERA_PEDIDO'
export const SET_TIMER_TIEMPO_AGOTADO = 'layoutReducer/SET_TIMER_TIEMPO_AGOTADO'

// funciones
export const showBusqueda = (payload) => ({
    type: SET_SHOW_BUSQUEDA,
    payload
})

export const setLoading = (payload) => ({
    type: SAVE_LOADING,
    payload
})

export const setHeaderDefault = (payload) => ({
    type: SAVE_HEADER_DEFAULT,
    payload
})

export const setHeaderShow = (payload) => ({
    type: SAVE_HEADER_SHOW,
    payload
})

export const setHeader = (payload) => ({
    type: SAVE_HEADER,
    payload
})

export const setFooterShow = (payload) => ({
    type: SAVE_FOOTER_SHOW,
    payload
})

export const setInicializandoUsuario = (payload) => ({
    type: SAVE_INICIALIZANDO_USUARIO,
    payload
})

export const setPermiteUsuarioAnonimo = (payload) => ({
    type: SAVE_PERMITE_USUARIO_ANONIMO,
    payload
})

export const setTimerReiniciar = (payload) => ({
    type: SET_TIMER_REINICIAR,
    payload
})

export const setBanderaPedido = (payload) => ({
    type: SET_TIMER_BANDERA_PEDIDO,
    payload
})

export const setTiempoAgotado = (payload) => ({
    type: SET_TIMER_TIEMPO_AGOTADO,
    payload
})

export const setLimpiarFiltro = (payload) => ({
    type: SET_LIMPIAR_FILTRO,
    payload
})

export const setVistaFavoritos = (payload) => ({
    type: SET_VISTA_FAVORITOS,
    payload
})