// acciones
export const INITIALIZE_FORM = 'registrarUsuarioReducer/INITIALIZE_FORM'
export const SAGA_FETCH_CLIENTE = 'registrarUsuarioReducer/SAGA_FETCH_CLIENTE'
export const SAGA_SAVE_USUARIO = 'registrarUsuarioReducer/SAGA_SAVE_USUARIO'

export const SAVE_CLIENTE = 'registrarUsuarioReducer/SAVE_CLIENTE'
export const SAVE_USUARIO_CREADO = 'registrarUsuarioReducer/SAVE_USUARIO_CREADO'

export const GET_PAISES = 'registrarUsuarioReducer/GET_PAISES'
export const SET_PAISES = 'registrarUsuarioReducer/SET_PAISES'

export const GET_PAIS_DEFAULT = 'registrarUsuarioReducer/GET_PAIS_DEFAULT'
export const SET_PAIS_DEFAULT = 'registrarUsuarioReducer/SET_PAIS_DEFAULT'

export const SAGA_VALIDAR_CODIGO_CONFIRMACION_EMAIL = 'registrarUsuarioReducer/SAGA_VALIDAR_CODIGO_CONFIRMACION_EMAIL'

export const SAGA_REENVIAR_CODIGO_CONFIRMACION_MAIL = 'registrarUsuarioReducer/SAGA_REENVIAR_CODIGO_CONFIRMACION_MAIL'

// funciones
export const initializeForm = (payload) => ({
    type: INITIALIZE_FORM,
    payload
})

export const sagaFetchCliente = (payload) =>({
    type: SAGA_FETCH_CLIENTE,
    payload
})

export const setCliente = (payload) => ({
    type: SAVE_CLIENTE,
    payload
})

export const sagaSaveUsuario = (payload) => ({
    type: SAGA_SAVE_USUARIO,
    payload
})

export const setUsuarioCreado = (payload) => ({
    type: SAVE_USUARIO_CREADO,
    payload
})

export const sagaGetPaises = () => ({
    type: GET_PAISES
  })

export const setPaises = (payload) => ({
    type: SET_PAISES,
    payload
  })

  export const sagaGetPaisDefault = () => ({
    type: GET_PAIS_DEFAULT
  })

export const setPaisDefault = (payload) => ({
    type: SET_PAIS_DEFAULT,
    payload
  })
  
export const sagaValidarCodigoConfirmacionEmail = (payload) => ({
  type: SAGA_VALIDAR_CODIGO_CONFIRMACION_EMAIL,
  payload
})

export const sagaReenviarCodigoConfirmacionMail = (payload) => ({
  type: SAGA_REENVIAR_CODIGO_CONFIRMACION_MAIL,
  payload
})