import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { 
  Select,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './selector.css';

const styles = () => ({
  icon:{
    color: '#B4C4D5'
  },
  select:{
    textAlign: 'left',
    font: '400 16px/23px Poppins',
    letterSspacing: '0px',
    color: '#B4C4D5',
    opacity: 1,
  },
});

const Selector = (props) => {
  const { children, placeholder, classes, ...selectProps } = props;

  return (
    <FormControl className="selector">
      <InputLabel className='placeholder' id="demo-simple-select-label">{placeholder}</InputLabel>
      <Select
        classes={{ icon: classes.icon, select: classes.select }}
        IconComponent={ExpandMoreIcon}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        {...selectProps}
      >
        {children}
      </Select>
    </FormControl>
  );
};

export default withStyles(styles)(Selector);
