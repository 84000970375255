import React from 'react';
import { Tooltip } from '@material-ui/core';
import '../style.css';

const DatosExtra = ({ datosExtra }) => {
  const ref = React.useRef();

  const hasOverflow = React.useMemo(() => {
    let result = false;
    if (ref.current) {
      result = ref.current.offsetWidth < ref.current.scrollWidth;
    }
    return result;
  }, [ref.current]);

  const child = React.useMemo(() => (
    <div className="datoExtra" ref={ref}>{datosExtra}</div>
  ), [datosExtra]);

  return (
    <div>
      {hasOverflow ? <Tooltip title={datosExtra} arrow>{child}</Tooltip> : child}
    </div>
  );
};

export default DatosExtra;
