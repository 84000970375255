import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Button } from '@material-ui/core'

const styles = theme => ({
  label: {
    font: '500 16px/20px Poppins',
    letterSpacing: '0px',
    color: '#0C3653',
  },
  rootContent:{
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '-5px 4px 10px #8E8E8E29',
    borderRadius: '21px',
    opacity: 1,
    minWidth: '300px',
    padding: '0px !important'
  },
});

const DatosFacturacionRepetidosModal = (props) => {
  const {
    classes,
    handleOnConfirm,
    handleOnCancel,
  } = props;

  return (
    <Dialog
      classes={{paperScrollPaper: classes.rootContent}}
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth='sm'
      fullWidth={true}
    >
      <DialogContent classes={{root: classes.rootContent}}>
        <div className='facturacionModal'>                          
          <div className='modalBodyContainer'>
              
            <div className='labelDescripcion'>
              Ya tenías registrados estos datos de facturación. ¿Quieres utilizarlos?
            </div>

            <div className='buttonContainer'>
              <Button className='btnCancelar' onClick={handleOnCancel}>CANCELAR</Button>
              <Button onClick={handleOnConfirm}>UTILIZAR</Button>
            </div>

          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(DatosFacturacionRepetidosModal)
