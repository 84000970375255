import { takeLatest, all, put } from 'redux-saga/effects'
import * as calls from './calls'
import * as reducerActions from '../../reducers/SessionReducer/actions'
import * as layoutActions from '../../reducers/LayoutReducer/actions'
import { setUserContext } from "@smiledev/react-web-feedback";

function* genGetInstalacion(e){
    try {
        
        // llamo a la acción que busca la configuración de la instalación
        let instalacion = yield calls.getInstalacion(e.payload)

        // almaceno la configuración de la instalación en el reducer
        yield put(reducerActions.setInstalacion(instalacion))
    }
    catch (error) 
    {
        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
}

function* genGetUsuarioLogeado(e){
    try 
    {
        // llamo a la acción que busca el usuario autenticado
        let usuario = yield calls.getUsuarioLogeado(e.payload)

        if(usuario.idUsuario !== null)
        {
            // almaceno el usuario en el reductor
            yield put(reducerActions.setUsuario(usuario))
            
            // indico que el usuario está autenticado
            yield put(reducerActions.setResultadoAutenticacion({ autenticado: true }))

            setTimeout(() => {
                setUserContext({
                  idUsuario: usuario.idUsuario,
                  idCliente: usuario.idCliente,
                  nombreUsuario: usuario.nombreUsuario,
                  nombre: usuario.nombre,
                  apellido: usuario.apellido,
                  telefono: usuario.telefono,
                  direccion: usuario.direccion,
                  dni: usuario.dni,
                  idLocalidad: usuario.idLocalidad,
                  nombreLocalidad: usuario.nombreLocalidad,
                  idRol: usuario.idRol,
                  ultimaSucursalDelivery: usuario.ultimaSucursalDelivery,
                  ultimaSucursalPickup: usuario.ultimaSucursalPickup
                });
              }, 1000)
        }
    }
    catch (e) 
    {
    }
    finally
    {
        // indico que se finalizó la búsqueda de usuario logeado
        yield put(layoutActions.setInicializandoUsuario(false))
    }
}

function* genAutenticarUsuario(e){
    try {
        
        // muestro el loader
        yield put(layoutActions.setLoading({ show: true, message: 'Verificando sus credenciales' }))

        // limpio el resultado de autenticación
        yield put(reducerActions.setDefaultResultadoAutenticacion())

        // llamo a la acción que autentica al usuario
        let resultadoAutenticacion = yield calls.autenticarUsuario(e.payload)

        if(resultadoAutenticacion.mailNoConfirmado === true){
            e.payload.callbackMailNoConfirmado(resultadoAutenticacion.usuarioToken.usuarioId, resultadoAutenticacion.usuarioToken.token)
        }
        else{
            // almaceno el resultado de la autenticación en el reducer
            yield put(reducerActions.setResultadoAutenticacion({ 
                error: resultadoAutenticacion.error || undefined,
                autenticado: resultadoAutenticacion.autenticado,
                restablecerContrasenia: false,
            }))
            yield put(reducerActions.setFlagDatosAdicionales(resultadoAutenticacion.usuario && resultadoAutenticacion.usuario.dni && resultadoAutenticacion.usuario.telefono ? true : false))
            // almaceno el usuario en el reductor
            yield put(reducerActions.setUsuario(resultadoAutenticacion.usuario))

            // llamo a la callback de usuario autenticado
            if(resultadoAutenticacion.autenticado === true)
                e.payload.onUsuarioAutenticado()

            if(resultadoAutenticacion.autenticado === true && resultadoAutenticacion.usuario !== undefined){
                
                setTimeout(() => {
                    setUserContext({
                        idUsuario: resultadoAutenticacion.usuario.idUsuario,
                        idCliente: resultadoAutenticacion.usuario.idCliente,
                        nombreUsuario: resultadoAutenticacion.usuario.nombreUsuario,
                        nombre: resultadoAutenticacion.usuario.nombre,
                        apellido: resultadoAutenticacion.usuario.apellido,
                        telefono: resultadoAutenticacion.usuario.telefono,
                        direccion: resultadoAutenticacion.usuario.direccion,
                        dni: resultadoAutenticacion.usuario.dni,
                        idLocalidad: resultadoAutenticacion.usuario.idLocalidad,
                        nombreLocalidad: resultadoAutenticacion.usuario.nombreLocalidad,
                        idRol: resultadoAutenticacion.usuario.idRol,
                        ultimaSucursalDelivery: resultadoAutenticacion.usuario.ultimaSucursalDelivery,
                        ultimaSucursalPickup: resultadoAutenticacion.usuario.ultimaSucursalPickup
                    });
                  }, 1000)
            }
        }
        
    }
    catch (e) 
    {
        // muestro el mensaje de error
        yield put(reducerActions.setResultadoAutenticacion({ error: e.error }))
    }
    finally 
    {
        // oculto el loader
        yield put(layoutActions.setLoading({ show: false }))
    }
}

function* genRestablecerContrasenia(e){
    try
    {
        // muestro el loader
        yield put(layoutActions.setLoading({ show: true, message: 'Restableciendo claves' }))
        
        // llamo a la acción que restablece la contraseña
        let resultado = yield calls.restablecerContrasenia(e.payload)

        resultado.restablecerContrasenia = false
        resultado.mailNoConfirmado = false
        // indico que el usuario fue autenticado
        yield put(reducerActions.setUsuario(resultado.usuario))
        yield put(reducerActions.setResultadoAutenticacion(resultado))

        // llamo a la función de callback
        e.payload.onContraseniaRestablecida()
    }
    catch (e)
    {
        // muestro el mensaje de error
        yield put(reducerActions.setResultadoAutenticacion({ error: e.error }))
    }
    finally 
    {
        // oculto el loader
        yield put(layoutActions.setLoading({ show: false }))
    }
}

function* genOlvidoContrasenia(e) {
    try
    {
        // muestro el loader
        yield put(layoutActions.setLoading({ show: true }))

        // llamo a la acción que ejecuta la lógica de olvido de contraseña
        yield calls.olvidoContrasenia(e.payload)

        // indico que se finalizó con el proceso de recuperación de contraseña
        yield put(reducerActions.setResultadoAutenticacion({ claveProvisoriaGenerada: true }))
    }
    catch(e)
    {
        // muestro el mensaje de error
        yield put(reducerActions.setResultadoAutenticacion({ error: e.error }))
    }
    finally
    {
        // oculto el loader
        yield put(layoutActions.setLoading({ show: false }))
    }
}

function* genValidarCodigo(e) {

    let payload = {}
    payload.callback = e.payload.callback
    payload.callbackError = e.payload.callbackError

    try
    {
        e.payload.callback = undefined
        e.payload.callbackError = undefined
        // muestro el loader
        yield put(layoutActions.setLoading({ show: true }))

        // llamo a la acción que ejecuta la lógica de validación de codigo
        let response = yield calls.validarCodigo(e.payload)

        // indico que se finalizó con el proceso de recuperación de contraseña
        yield put(reducerActions.setResultadoAutenticacion({ claveProvisoriaGenerada: true , usuarioId: response.usuarioId, token: response.token}))

        if(payload.callback){
            payload.callback()
        }
    }
    catch(e)
    {
        // muestro el mensaje de error
        yield put(reducerActions.setResultadoAutenticacion({ error: e }))
        if(payload.callbackError){
            payload.callbackError(e)
        }
    }
    finally
    {
        // oculto el loader
        yield put(layoutActions.setLoading({ show: false }))
    }
}

function* genLogOutUsuario(e) {
    try
    {
        // muestro el loader
        yield put(layoutActions.setLoading({ show: true , message:'Cerrando Sessión'}))

        // llamo a la acción que ejecuta la lógica de cerrar la sesion
        yield calls.logOutUsuario()

        //armo objeto de autenticacion como default
        let resultadoAutenticacion = {
            error: undefined,
            autenticado: false,
            restablecerContrasenia: false,
            claveProvisoriaGenerada: false,
        }
        
        yield put(reducerActions.setFlagDatosAdicionales(false))

        //Seteo autenticacion como default
        yield put(reducerActions.setUsuario(undefined))

        setUserContext({});

        //Seteo autenticacion como default
        yield put(reducerActions.setDefaultResultadoAutenticacion(resultadoAutenticacion))

        //Pongo usuario como undefined 
        yield put(reducerActions.setUsuario(undefined))
    }
    catch(e)
    {
        // muestro el mensaje de error
        //yield put(reducerActions.setResultadoAutenticacion({ error: e.error }))
    }
    finally
    {
        // oculto el loader
        yield put(layoutActions.setLoading({ show: false }))
    }
}

function* genGetImagenLogin(e){
    try {
        // busco la imagen de login
        
        let imagen = yield calls.getImagenLogin(e.payload)
        
        //seteo las imagen de login
        yield put(reducerActions.setImagenLogin(imagen))
    }
    catch (error) 
    {
        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
    finally 
    {
    }
}

function* genGetImagenDescuentos(e){
    try {
        // busco la imagen de login
        
        let imagen = yield calls.getImagenDescuentos(e.payload)
        
        //seteo las imagen de login
        yield put(reducerActions.setImagenDescuentos(imagen))
    }
    catch (error) 
    {
        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
    finally 
    {
    }
}

function* genSaveDatosAdicionales(e){

    let payload = {}
    payload.callbackError = e.payload.callbackError

    try {
        // muestro el loader
        yield put(layoutActions.setLoading({ show: true , message:'Guardando datos'}))
        // busco la imagen de login
        payload.callback = e.payload.callback
        e.payload.callbackError = undefined
        e.payload.callback = undefined

        let resultadoAutenticacion = yield calls.saveDatosAdicionales(e.payload)

        // almaceno el usuario en el reductor
        yield put(reducerActions.setUsuario(resultadoAutenticacion.usuario))

        yield put(reducerActions.setFlagDatosAdicionales(true))

        if(payload.callback){
            payload.callback()
        }

    }
    catch (error) 
    {
        if(payload.callbackError){
            payload.callbackError()
        }

        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
    finally 
    {
        // oculto el loader
        yield put(layoutActions.setLoading({ show: false }))
    }
}

function* genMarcarNotificacionesVistas(e){
    try {
        // hago la llamada a la call
        let usuario = yield calls.marcarNotificacionesComoVistas(e.payload)

        //seteo la notificacion como lista
        yield put(reducerActions.setUsuario(usuario))
    }
    catch (error) 
    {
        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
}

function* genMarcarNotificacionVista(e){
    try {
        // hago la llamada a la call
        yield calls.marcarNotificacionVista(e.payload)

        //seteo la notificacion como lista
        yield put(reducerActions.setNotificacionVista(e.payload))
    }
    catch (error) 
    {
        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
}

function* genGetMasNotificaciones(e){
    try {

        // muestro el loader
        yield put(layoutActions.setLoading({ show: true , message:'Cargando notificaciones'}))

        // hago la llamada a la call
        let notificaciones = yield calls.getMasNotificaciones(e.payload)

        //seteo la notificacion como lista
        yield put(reducerActions.setNotificaciones(notificaciones))

        if(e.payload.callback){
            e.payload.callback()
        }
    }
    catch (error) 
    {
        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
    finally 
    {
        // oculto el loader
        yield put(layoutActions.setLoading({ show: false }))
    }
}

function* genSaveDireccionEntrega(e){
    try {
        // muestro el loader
        yield put(layoutActions.setLoading({ show: true , message:'Guardando dirección de entrega'}))

        // hago la llamada a la call
        let direccion = yield calls.saveDireccionEntrega(e.payload)

        //seteo la notificacion como lista
        yield put(reducerActions.setdireccionesEntrega(direccion))

        if(e.payload.callback){
            e.payload.callback(direccion)
        }
    }
    catch (error) 
    {
        if(e.payload.callbackError){
            e.payload.callbackError(error.error)
        }

        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
    finally 
    {
        // oculto el loader
        yield put(layoutActions.setLoading({ show: false }))
    }
}

function* genDeleteDireccionEntrega(e){
    try {
        // muestro el loader
        yield put(layoutActions.setLoading({ show: true , message:'Eliminando dirección de entrega'}))

        // hago la llamada a la call
        yield calls.deleteDireccionEntrega(e.payload)

        //seteo la notificacion como lista
        yield put(reducerActions.setDeleteDireccionEntrega(e.payload.idDomicilioEntrega))

        if(e.payload.callback){
            e.payload.callback()
        }
    }
    catch (error) 
    {
        if(e.payload.callbackError){
            e.payload.callbackError(error.error)
        }

        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
    finally 
    {
        // oculto el loader
        yield put(layoutActions.setLoading({ show: false }))
    }
}

function* genGetPreguntasFrecuentes(e){
    try {

        // muestro el loader
        //yield put(layoutActions.setLoading({ show: true , message:'Cargando preguntas frecuentes'}))

        // hago la llamada a la call
        let preguntasFrecuentes = yield calls.getPreguntasFrecuentes(e.payload)

        //seteo la notificacion como lista
        yield put(reducerActions.setPreguntasFrecuentes(preguntasFrecuentes))

        if(e.payload.callback){
            e.payload.callback()
        }
    }
    catch (error) 
    {
        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
    finally 
    {
        // oculto el loader
        //yield put(layoutActions.setLoading({ show: false }))
    }
}

function* defaultSaga() {
    yield all([
      takeLatest(reducerActions.SAGA_FETCH_INSTALACION, genGetInstalacion),
      takeLatest(reducerActions.SAGA_FETCH_USUARIO_LOGEADO, genGetUsuarioLogeado),
      takeLatest(reducerActions.SAGA_AUTENTICAR_USUARIO, genAutenticarUsuario),
      takeLatest(reducerActions.SAGA_RESTABLECER_CONTRASENIA, genRestablecerContrasenia),
      takeLatest(reducerActions.SAGA_OLVIDO_CONTRASENIA, genOlvidoContrasenia),
      takeLatest(reducerActions.SAGA_VALIDAR_CODIGO, genValidarCodigo),
      takeLatest(reducerActions.SAGA_LOGOUT_USUARIO, genLogOutUsuario),
      takeLatest(reducerActions.SAGA_FETCH_IMAGEN_LOGIN, genGetImagenLogin),
      takeLatest(reducerActions.SAGA_FETCH_IMAGEN_DESCUENTOS, genGetImagenDescuentos),
      takeLatest(reducerActions.SAGA_SAVE_DATOS_ADICIONALES, genSaveDatosAdicionales),
      takeLatest(reducerActions.SAGA_MARCAR_NOTIFICACION_VISTA, genMarcarNotificacionVista),
      takeLatest(reducerActions.SAGA_BUSCAR_MAS_NOTIFICACIONES, genGetMasNotificaciones),
      takeLatest(reducerActions.SAGA_SAVE_DIRECCION_ENTREGA, genSaveDireccionEntrega),
      takeLatest(reducerActions.SAGA_DELETE_DIRECCION_ENTREGA, genDeleteDireccionEntrega),
      takeLatest(reducerActions.SAGA_GET_PREGUNTAS_FRECUENTES, genGetPreguntasFrecuentes),
      takeLatest(reducerActions.SAGA_MARCAR_NOTIFICACIONES_COMO_VISTAS, genMarcarNotificacionesVistas),
    ])
  }
  
  export const sagas = [
    defaultSaga
  ]