import _axios from '../../apiClient'

export function getCliente(payload) {
    return _axios.get(`/api/Cliente/GetByCuit`,
    { 
        params: { cuit: payload } 
    },
    {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}

export function getPaises() {
  return _axios.get(`/api/Pais/GetAll`,
  
  {
    headers: { 'Content-Type': 'application/json;charset=UTF-8' }
  })
  .then(response => response.data)
  .catch((error) => {
    const data = error.response.data
    throw (data)
  })
}

export function getPaisDefault() {
  return _axios.get(`/api/Pais/GetPaisDefault`,
  
  {
    headers: { 'Content-Type': 'application/json;charset=UTF-8' }
  })
  .then(response => response.data)
  .catch((error) => {
    const data = error.response.data
    throw (data)
  })
}



export function saveUsuario(payload) {
  return _axios.post(`/api/Usuario/SaveUsuario`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}

export function validarCodigoConfirmacionEmail(payload) {
  return _axios.post(`/api/Usuario/ValidarCodigoConfirmacionEmail`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}

export function reenviarCodigoConfirmacionEmail(payload) {
  return _axios.post(`/api/Usuario/ReenviarCodigoConfirmacionEmail`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}