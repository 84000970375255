import { fromJS } from 'immutable'
import * as ReducerActions from './actions'

const initialState = fromJS({
    data: { 
        provincias: undefined,
        localidadesActualizadas: false,
        localidadesCombo: undefined,
        sugerenciasLocalidades: []
    }
})

export default function provinciaLocalidadReducer(state = initialState, action) {

    if (action.type === ReducerActions.SAVE_PROVINCIAS) {
        return state.setIn(["data", "provincias"], action.payload)
    }

    if(action.type === ReducerActions.SAVE_LOCALIDADES) {
        let data = state.getIn(["data"]).toJS()
        let newProvincias = data.provincias

        let provincia = newProvincias.find((p) => { if(p.idProvincia === action.payload.idProvincia) return p })
        if(provincia !== undefined)
            provincia.localidades = action.payload.localidades

        return state.setIn(["data", "provincias"], newProvincias)
    }

    if(action.type === ReducerActions.SAVE_LOCALIDADES_ACTUALIZADAS) {
        return state.setIn(["data", "localidadesActualizadas"], action.payload)
    }

    if(action.type === ReducerActions.SET_LOCALIDADES_COMBO) {
        return state.setIn(["data", "localidadesCombo"], action.payload)
    }

    if(action.type === ReducerActions.SAVE_SUGERENCIAS_LOCALIDADES){
        return state.setIn(["data", "sugerenciasLocalidades"], action.payload)
    }

    return state
}