import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import './catalogo.css'

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
});


class CatalogoSinPortada extends React.Component {

  constructor(props) {
    super(props)

    //document.getElementById('footer').getElementsByClassName('descuentos')[0].hidden = true
  }

  render() {

    const { classes } = styles;

    return (
      <div>

        <Row className='rowTelefono'>
          {this.props.instalacion.datosContacto.telefono !== undefined &&
            <a className='enlace' href={"https://wa.me/" + this.props.instalacion.datosContacto.telefono} target="_blank">
              {<img className='img' src={require('../../images/icon-whatsapp.png')} />}
              <div className='numero'>
                {this.props.instalacion.datosContacto.telefono}
              </div>
            </a>}
        </Row>

        <Row className='promo'>
          {this.props.instalacion.promosBandaDescuentos === true &&
            <div className='descuentos'>
              <div className='descriptionText'>CONOZCA NUESTRA ESCALA DE DESCUENTOS!</div>

              <div className='imageContainer'>
                <img src={this.props.instalacion.bandaDescuento} />
              </div>
            </div>}
        </Row>

        <Row className={`rowBtn ${this.props.instalacion.verPiePagina === false ? 'margenPie' : ''}`}>
          <Col xs={12} >
            <Button onClick={() => this.props.handleVerCatalogo(this.props.catalogo.id)} size="small" className={`${styles.margin} btnVerCatalogo`}>
              VER CATÁLOGO
                            </Button>
          </Col>
        </Row>


      </div>
    )
  }
}

CatalogoSinPortada.propTypes = {
  classes: PropTypes.object.isRequired,
  catalogo: PropTypes.object.isRequired,
  instalacion: PropTypes.object.isRequired,
  handleVerCatalogo: PropTypes.func.isRequired,
};

CatalogoSinPortada.defaultProps = {
  instalacion: {},
}

export default withStyles(styles)(CatalogoSinPortada)