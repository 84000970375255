// acciones
export const INITIALIZE_FORM = 'confirmarPedidoReducer/INITIALIZE_FORM'
export const SAGA_CONFIRMAR_CORREO = 'confirmarCorreoReducer/SAGA_CONFIRMAR_CORREO'

export const SAVE_USUARIO = 'confirmarCorreoReducer/SAVE_USUARIO'

// funciones
export const initializeForm = (payload) => ({
    type: INITIALIZE_FORM,
    payload
})

export const sagaConfirmarCorreo = (payload) => ({
    type: SAGA_CONFIRMAR_CORREO,
    payload
})

export const setUsuario = (payload) => ({
    type: SAVE_USUARIO,
    payload
})