export const FONT_WEIGHT = {
    light: "font-poppins-light",
    regular: "font-poppins-regular",
    medium: "font-poppins-medium",
    semi_bold: "font-poppins-semi-bold",
    bold: "font-poppins-bold",
    extra_bold: "font-poppins-extra-bold",
    black: "font-poppins-black",
}

export const PARAGRAPH_SIZE = {
    small: "small-paragraph",
    medium: "medium-paragraph",
    big: "big-paragraph",
}