import { createSelector } from 'reselect'

const layoutReducerLoadingSelector = state => state.layoutReducer.getIn(["loading"])
export const loadingSelector = createSelector(
  layoutReducerLoadingSelector, (loadingField) => {    
    return loadingField
  }
)

const layoutReducerHeaderSelector = state => state.layoutReducer.getIn(["header"]).toJS()
export const headerSelector = createSelector(
  layoutReducerHeaderSelector, (headerField) => {    
    return headerField
  }
)

const layoutReducerFooterSelector = state => state.layoutReducer.getIn(["footer"]).toJS()
export const footerSelector = createSelector(
  layoutReducerFooterSelector, (footerField) => {    
    return footerField
  }
)

const layoutReducerTimerSelector = state => state.layoutReducer.getIn(["timer"]).toJS()
export const timerSelector = createSelector(
  layoutReducerTimerSelector, (timerField) => {    
    return timerField
  }
)

const layoutReducerInicializandoUsuario = state => state.layoutReducer.getIn(["inicializandoUsuario"])
export const inicializandoUsuarioSelector = createSelector(
  layoutReducerInicializandoUsuario, (inicializandoUsuarioField) => {    
    return inicializandoUsuarioField
  }
)

const layoutReducerUsuarioAnonimo = state => state.layoutReducer.getIn(["permiteUsuarioAnonimo"])
export const permiteUsuarioAnonimoSelector = createSelector(
  layoutReducerUsuarioAnonimo, (permiteUsuarioAnonimoField) => {    
    return permiteUsuarioAnonimoField
  }
)

const showBusquedaReducerSelector = state => state.layoutReducer.getIn(["showBusqueda"])
export const showBusquedaSelector = createSelector(
  showBusquedaReducerSelector, (showBusquedaField) => {    
    return showBusquedaField
  }
)

const limpiarFiltroReducerSelector = state => state.layoutReducer.getIn(["limpiarFiltro"])
export const limpiarFiltroSelector = createSelector(
  limpiarFiltroReducerSelector, (limpiarFiltro) => {    
    return limpiarFiltro
  }
)

const vistaFavoritosReducerSelector = state => state.layoutReducer.getIn(["vistaFavoritos"])
export const vistaFavoritosSelector = createSelector(
  vistaFavoritosReducerSelector, (vistaFavoritos) => {    
    return vistaFavoritos
  }
)