import { takeLatest,takeEvery, all, put } from 'redux-saga/effects'
import * as calls from './calls'
import * as reducerActions from '../../reducers/DetallePedidoReducer/actions'
import * as layoutActions from '../../reducers/LayoutReducer/actions'
import * as Notification from '../../components/Common/notification'


function* genGetArticulo(e){
    try {
        
        // muestro el loader
        yield put(layoutActions.setLoading({ show: true }))

        // llamo a la acción que busca el artículo
        let articulo = yield calls.getArticulo(e.payload.idArticulo)

        // almaceno los datos en el estado del reductor
        yield put(reducerActions.setArticulo(articulo))

        // si se pasó un pedido detalle como parámetro
        if(e.payload.idPedidoDetalle) {

            // busco los datos de pedido detalle
            let pedidoDetalle = yield calls.getPedidoDetalle(e.payload.idPedidoDetalle)

            // almecano los datos en el estado del reductor
            yield put(reducerActions.setPedidoDetalle(pedidoDetalle))
        }
    }
    catch (error) 
    {
        // indico que hubo un error
        yield put(reducerActions.setError(true))

        // muestro el mensaje de error
        Notification.showError('Error al recuperar los datos')
        

        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
    finally 
    {
        // oculto el loader
        yield put(layoutActions.setLoading({ show: false }))
    }
}

function* defaultSaga() {
    yield all([
      takeEvery(reducerActions.SAGA_FETCH_ARTICULO, genGetArticulo)
    ])
  }
  
  export const sagas = [
    defaultSaga
  ]