import React from 'react'
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SearchHeader from '../../components/SearchHeader'
import { instalacionSelector } from '../../selectors/sessionSelector'
import { rubrosSubrubrosSelector } from '../../selectors/catalogoSelector'
import * as catalogoActions from '../../reducers/CatalogoReducer/actions' 

class SearchHeaderContainer extends React.Component {

    constructor(props) {
        super(props)

        window.scrollTo(0, 0)

        this.state = {
            openModalRubros: false,
            openModalOfertas: false,
        }
    }

    handleOnClickOpenModalRubros = () => {
        this.setState({openModalRubros: true}, () => {
            this.props.sagaFetchRubrosSubrubros({idInstalacion: this.props.instalacion.id})
        })
    }

    handleOnClickCloseModalRubros = () => {
        this.setState({openModalRubros: false})
    }

    handleOnClickSubrubro = (item) => {

        if(item === undefined || item === null){
            return
        }

        let value = item.nombre
        let idSubrubro = item.idSubrubro
        let esRubro = item.esRubro

        if(!value){
            return
        }

        let payload = {
            target: {
                value: value
            }
        }

        this.setState({openModalRubros: false}, () => {
            this.props.handleOnChangeFilter(payload, idSubrubro, esRubro)
        })
    }

    handleOnClickOpenModalOfertas = () => {
        this.setState({ openModalOfertas: true });
    }

    handleOnClickCloseModalOfertas = () => {
        this.setState({ openModalOfertas: false });
    }

    handleOnClickOferta = (oferta) => {
        const customEvent = { target: { value: oferta.nombre } };
        this.props.handleOnChangeFilter(customEvent, undefined, undefined, oferta);
        this.setState({ openModalOfertas: false });
    }

    render() {

        return (
        <SearchHeader
            pedido={this.props.pedido}
            filterArticulos={this.props.filterArticulos}
            handleOnChangeFilter={this.props.handleOnChangeFilter}
            handleOnClearBusqueda={this.props.handleOnClearBusqueda}
            openModalRubros={this.state.openModalRubros}
            rubrosSubrubros={this.props.rubrosSubrubros}
            handleOnClickSubrubro={this.handleOnClickSubrubro}
            handleOnClickOpenModalRubros={this.handleOnClickOpenModalRubros}
            handleOnClickCloseModalRubros={this.handleOnClickCloseModalRubros}

            openModalOfertas={this.state.openModalOfertas}
            ofertas={this.props.instalacion.listaOfertas}
            handleOnClickOpenModalOfertas={this.handleOnClickOpenModalOfertas}
            handleOnClickCloseModalOfertas={this.handleOnClickCloseModalOfertas}
            handleOnClickOferta={this.handleOnClickOferta}
        />
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ...catalogoActions,
    }, dispatch)
}

function mapStateToProps(state) {
    return {
        instalacion: instalacionSelector(state),
        rubrosSubrubros: rubrosSubrubrosSelector(state),
    }
}

SearchHeader.propTypes = {
    pedido: PropTypes.object.isRequired,
    filterArticulos: PropTypes.string.isRequired,
    handleOnChangeFilter: PropTypes.func.isRequired,
    handleOnClearBusqueda: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchHeaderContainer)