import { takeLatest, all, put } from 'redux-saga/effects'
import * as calls from './calls'
import * as reducerActions from '../../reducers/ProvinciaLocalidadReducer/actions'
import * as Notification from '../../components/Common/notification'

function* genGetProvincias(e){
    try {

        // llamo a la acción que busca las provincias
        let provincias = yield calls.getProvincias()

        // almaceno los datos en el estado del reductor
        yield put(reducerActions.setProvincias(provincias))
    }
    catch (error) 
    {
        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
}

function* genGetLocalidades(e){
    try {

        // llamo a la acción que busca las localidades
        let idProvincia = e.payload
        let localidades = yield calls.getLocalidades(idProvincia)

        // almaceno los datos en el estado del reductor
        yield put(reducerActions.setLocalidades({ idProvincia: idProvincia, localidades: localidades }))
        yield put(reducerActions.setLocalidadesActualizadas(true))
    }
    catch (error) 
    {
        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
}

function* genGetLocalidadesCombo(e){
    try {

        // llamo a la acción que busca las loc
        let localidades = undefined

        if(e.payload !== undefined){
            localidades = yield calls.getLocalidadesCombo(e.payload)
        }
        else{
            localidades = yield calls.getAllLocalidades()
        }

        // almaceno los datos en el estado del reductor
        yield put(reducerActions.setLocalidadesCombo(localidades))
    }
    catch (error) 
    {
        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
}

function* genSugerenciasLocalidades(e){
    try {

        let callbackLoader = e.payload.callbackLoader
        e.payload.callbackLoader = undefined

        let sugerencias = yield calls.sugerenciasLocalidades( e.payload)
            
        yield put(reducerActions.saveSugerenciasLocalidades(sugerencias))
        
        if(callbackLoader){
            callbackLoader()
        }
    }
    catch (e) 
    {
        // muestro el mensaje de error
        Notification.showError(e.error)
    }
}

function* defaultSaga() {
    yield all([
      takeLatest(reducerActions.SAGA_FETCH_PROVINCIAS, genGetProvincias),
      takeLatest(reducerActions.SAGA_FETCH_LOCALIDADES, genGetLocalidades),
      takeLatest(reducerActions.GET_LOCALIDADES_COMBO, genGetLocalidadesCombo),
      takeLatest(reducerActions.SAGA_FETCH_SUGERENCIAS_LOCALIDADES, genSugerenciasLocalidades),
    ])
  }
  
  export const sagas = [
    defaultSaga
  ]