import { takeLatest, all, put } from 'redux-saga/effects'
import * as calls from './calls'
import * as testReducerActions from '../../reducers/TestReducer/actions'


function* genGetTestData(e){
    try {

        // llamo a la acción que busca los datos
        let testData = yield calls.getTestData(e.payload)

        // almaceno los datos en el estado del reductor
        yield put(testReducerActions.setTestData(testData))
    }
    catch (error) 
    {
        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
}

function* defaultSaga() {
    yield all([
      takeLatest(testReducerActions.SAGA_FETCH_TEST_DATA, genGetTestData),
    ])
  }
  
  export const sagas = [
    defaultSaga
  ]