import { fromJS } from 'immutable'
import * as ReducerActions from './actions'

const initialState = fromJS({
    data: { 
        usuario: undefined
    }
})

export default function confirmarCorreoReducer(state = initialState, action) {

    if(action.type === ReducerActions.INITIALIZE_FORM) {
        return initialState
    }

    if (action.type === ReducerActions.SAVE_USUARIO) {
        return state.setIn(["data", "usuario"], action.payload)
    }

    return state
}