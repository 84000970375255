import * as React from 'react'
import PropTypes from 'prop-types'

class Test extends React.Component {

    constructor(props){
        super(props)
    }

    render() {
        return <div>
            Componente de test:
            <ul>
            {this.props.data.map((d, i) => {     
                return <li>{d.name}</li> 
            })}
            </ul>            
        </div>
    }
}

Test.propTypes = {
    data: PropTypes.array
}

Test.defaultProps = {
    data: []
}

export default Test