import React from 'react';
import { ListItemText, MenuItem, ListSubheader } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import Selector from './selector';
import Button from '@material-ui/core/Button';

const Filtros = (props) => {
  const {
    show,
    subFiltrosArticulos,
    subfiltrosSeleccionados,
    onSelectionChange,
    onFilter,
    setCerrarModal,
  } = props;

  const [cerrar, setCerrar] = React.useState(false);

  const articuloFiltros = React.useMemo(() => subFiltrosArticulos.filter((x) => x.tipo === 'ARTICULO_FILTRO'), [JSON.stringify(subFiltrosArticulos)]);
  const marcaFiltros = React.useMemo(() => subFiltrosArticulos.filter((x) => x.tipo === 'MARCA_FILTRO'), [JSON.stringify(subFiltrosArticulos)]);
  const subrubros = React.useMemo(() => subFiltrosArticulos.filter((x) => x.tipo === 'SUBRUBRO'), [JSON.stringify(subFiltrosArticulos)]);

  const renderValue = (selected) => {
    if (selected.length === 0) {
      setCerrar(true);
      return 'Ninguno';
    }
    else if (selected.length > 1) {
      setCerrar(false);
      return selected.length + ' seleccionados';
    }

    const filterSelected = subFiltrosArticulos.find((x) => x.valor === selected[0]);
    setCerrar(false);
    return filterSelected ? filterSelected.descripcion : selected.join(', ');
  }

  const renderSubHeader = (title) => <ListSubheader style={{ position: 'relative', color: '#46C082', minWidth:'300px' }}>{title}</ListSubheader>;

  const renderOption = (option) => (
    <MenuItem key={option.valor} value={option.valor} className='itemSeleccion'>
      <ListItemText primary={option.descripcion} />
      { subfiltrosSeleccionados.includes(option.valor) && <CheckIcon style={{ color: '#46C082' }} /> }
    </MenuItem>
  );

  if (!show || (articuloFiltros.length === 0 && marcaFiltros.length === 0 && subrubros.length === 0)) return null;
  return (
      <Selector
        multiple
        displayEmpty
        placeholder="Filtrar Por"
        value={subfiltrosSeleccionados}
        onChange={({ target }) => onSelectionChange(target.value)}
        onClose={onFilter}
        renderValue={renderValue}
      >
          <>
            <div className='buttonFlotanteContainerFiltrado'>
              {!cerrar?
              <Button className='btnFlotanteFiltrar' onClick={() => {onFilter();}}>FILTRAR</Button>
              :
              <Button className='btnFlotanteCerrar' onClick={() => {onFilter(); setCerrarModal(true);}}>CERRAR</Button>
              }
            </div>
          </>
          <>{ articuloFiltros.length > 0 && renderSubHeader('CARACTERÍSTICAS') }</>
          { articuloFiltros.map((x) => renderOption(x)) }
          <>{ marcaFiltros.length > 0 && renderSubHeader('MARCAS') }</>
          { marcaFiltros.map((x) => renderOption(x)) }
          <>{ subrubros.length > 0 && renderSubHeader('SUB CATEGORÍAS') }</>
          { subrubros.map((x) => renderOption(x)) }
      </Selector>
  );
}


export default Filtros;
