import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Row, Col } from 'react-flexbox-grid'
import FormHelperText from '@material-ui/core/FormHelperText'
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PlacesAutocomplete from 'react-places-autocomplete';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import CustomModal from '../UI/Modals/CustomModal'
import CustomInput from '../Common/textInput/CustomInput'
import './styles.css'

const styles = theme => ({
  label: {
    font: '500 16px/20px Poppins',
    letterSpacing: '0px',
    color: '#0C3653',
  },
  rootContent: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '-5px 4px 10px #8E8E8E29',
    borderRadius: '21px',
    opacity: 1,
    minWidth: '300px',
    padding: '0px !important'
  },
})

const styledMapType = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#c9c9c9"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
]

const RadioPurple = withStyles({
  root: {
    color: 'var(--purple)',
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

class DomicilioEntrega extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      bandera: false,
      erroresDomicilio: undefined
    }

    this.refMarker = React.createRef();

    this.searchOptions = {
      types: ['address'],
      componentRestrictions: { country: 'ar' },
      location: new this.props.google.maps.LatLng(-31.638377, -60.7041932),
      radius: 2000,
      strictbounds: true
    }
  }

  _mapLoaded = (mapProps, map) => {
    map.setOptions({
      styles: styledMapType
    })
  }

  renderMarkers() {
    return <Marker
      position={this.props.position}
    />
  }

  handleOnClickUbicacion = () => {
    let geocoder = new this.props.google.maps.Geocoder();

    this.props.handleOnClickUbicacion(1, undefined, geocoder)
  }

  render() {

    const { classes } = this.props;

    return (
      <div className='containerDomicilioEntrega'>

        {this.props.domicilios && this.props.domicilios.length > 0
          && this.props.tipoUbicacionElegida === undefined &&
          <div className='containerDomiciliosOptions'>
            {this.props.domicilios.map((de) => {
              return <div className='containerRadioBtn'>
                <FormControlLabel onChange={() => this.props.handleChangeRadioButton(de.idDomicilioEntrega.toString())} classes={{ label: classes.label }} value={de.idDomicilioEntrega.toString()} control={<RadioPurple />} label={de.domicilio + (de.pisoDepto ? " " + de.pisoDepto : '') + (de.opcional ? " " + de.opcional : '')} />
                <div><DeleteForeverIcon onClick={() => this.props.handleOnClickDeleteDireccion(de.idDomicilioEntrega)} style={{ cursor: 'pointer' }} /></div>
              </div>
            })}

            <div className='containerBtnsForm'>
              <div className={`btn`} onClick={() => this.props.handleOnClickUbicacion(2)}>Agregar domicilio</div>
            </div>
          </div>
        }

        {(this.props.domicilios === undefined || this.props.domicilios && this.props.domicilios.length === 0)
          && this.props.tipoUbicacionElegida === undefined &&
          <div className='containerButtons'>
            <div className={`btn`} onClick={this.handleOnClickUbicacion}>Mi ubicación actual</div>
            <div className={`btn`} onClick={() => this.props.handleOnClickUbicacion(2)}>Otra ubicación</div>
          </div>}

        {this.props.tipoUbicacionElegida &&
          <div className='containerFormMap'>
            <div className='containerForm'>
              <Row>
                <Col xs={12}>
                <span class="label">Calle, número y ciudad</span>
                  <div className='inputContainer inputAutocomplete'>
                    <PlacesAutocomplete
                      value={this.props.formUbicacion.domicilio}
                      onChange={this.props.handleChangeAutocomplete}
                      onSelect={this.props.handleSelect}
                      searchOptions={this.searchOptions}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: 'Calle, número y ciudad',
                              className: 'location-search-input',
                            })}
                          />
                          <div className={`autocomplete-dropdown-container ${loading || suggestions.length > 0 ? 'containerSugerencias' : ''}`}>
                            {loading && <div className='itemSugerencia'>Loading...</div>}
                            {suggestions.map(suggestion => {
                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };

                              if ((suggestion.types) && (suggestion.types.includes("street_address") || suggestion.types.includes("route") || suggestion.types.includes("premise"))) {
                                return <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                  className='itemSugerencia'
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              }
                              else {
                                return undefined
                              }
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                    {this.props.errores.domicilio !== undefined &&
                      <FormHelperText error>{this.props.errores.domicilio}</FormHelperText>}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <span className='linkDomicilioAlt' onClick={() => this.props.handleOnClicNoEncuentraDomicilio(true)}>
                    No encuentras tu domicilio?
                  </span>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <span class="label">Piso, departamento</span>
                  <CustomInput
                    placeholder="Piso, departamento"
                    value={this.props.formUbicacion.pisoDepto}
                    onChange={this.props.handleChangeForm}
                    name="pisoDepto"
                    error={this.props.errores.pisoDepto !== undefined}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <span class="label">CP</span>
                  <CustomInput
                    placeholder="CP"
                    value={(!this.state.bandera && (this.props.errores.domicilio === undefined)) ? this.props.formUbicacion.cp : ''}
                    onChange={this.props.handleChangeForm}
                    name="cp"
                    error={this.props.errores.cp !== undefined}
                    disabled={true}
                  />
                  {this.props.errores.cp !== undefined &&
                    <FormHelperText error>{this.props.errores.cp}</FormHelperText>}
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <span class="label">Localidad</span>
                  <CustomInput
                    placeholder="Localidad"
                    value={(!this.state.bandera && (this.props.errores.domicilio === undefined)) ? this.props.formUbicacion.localidad : ''}
                    onChange={this.props.handleChangeForm}
                    name="localidad"
                    error={this.props.errores.localidad !== undefined}
                    disabled={true}
                  />
                  {this.props.errores.localidad !== undefined &&
                    <FormHelperText error>{this.props.errores.localidad}</FormHelperText>}
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <span class="label">Opcional</span>
                  <CustomInput
                    placeholder="Opcional. Ejemplo: planta alta"
                    value={this.props.formUbicacion.opcional}
                    onChange={this.props.handleChangeForm}
                    name="opcional"
                  />
                </Col>
              </Row>

              <div className='containerBtnsForm'>
                <div className={`btn btnSmall`} onClick={() => this.props.handleOnClickUbicacion(undefined, true)}>CANCELAR</div>
                <div className={`btn btnSmall`} onClick={this.props.handleOnClickSave}>GUARDAR</div>
              </div>

            </div>

            <div className="containerMap">
              <Map
                zoom={this.props.zoom}
                google={this.props.google}
                style={{
                  width: 250,
                  height: 350,
                  position: "relative"
                }}
                initialCenter={{ lat: this.props.position.lat, lng: this.props.position.lng }}
                center={{ lat: this.props.position.lat, lng: this.props.position.lng }}
                onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}
              >
                {this.props.existeSeleccion && <Marker
                  position={this.props.position}
                  onClick={this.onMarkerClick}
                  name={'Current location'}
                />}
              </Map>
            </div>

          </div>}

        {/* MODAL CONFIRMACION DE PEDIDO */}
        <CustomModal
          open={this.props.openModalBorrarDireccion}
          onClose={() => { }}
          title="Esta seguro que desea eliminar su dirección?"
          buttonNames={["CANCELAR", "ELIMINAR"]}
          buttonActions={
            [
              () => this.props.handleOnClickDeleteDireccion(undefined, undefined, true),
              () => this.props.handleOnClickDeleteDireccion(undefined, true, undefined)
            ]
          }
        >
          <div className='modalTextContent'>
          </div>
        </CustomModal>

        {/* MODAL CONFIRMACION DE PEDIDO */}
        <CustomModal
          open={this.props.openModalNoEncuentraDomicilio}
          onClose={() => this.props.handleOnClicNoEncuentraDomicilio(false)}
          title="Ingresa tu domicilio"
          buttonNames={["CANCELAR", "GUARDAR"]}
          buttonActions={
            [
              () => this.props.handleOnClicNoEncuentraDomicilio(false),
              this.props.handleOnClickSaveDomicilioAlternativo
            ]
          }
        >
          <div className='modalTextContent'>
            <div className='inputContainer'>
              <span class="label">Calle, número y ciudad</span><br />
              <CustomInput
                placeholder="Calle, número y ciudad"
                value={this.props.domicilioAlternativo}
                name="domicilioAlternativo"
                onChange={this.props.handleChangeDomicilioAlternativo}
                error={this.props.errores.domicilioAlternativo !== undefined}
              />
              {this.props.errores.domicilioAlternativo !== undefined &&
                <FormHelperText error style={{ marginTop: '3px' }}>{this.props.errores.domicilioAlternativo}</FormHelperText>}
            </div>
          </div>
        </CustomModal>
      </div>
    );
  }
}

DomicilioEntrega.defaultProps = {
  domicilios: [],
  openModalBorrarDireccion: false,
  openModalNoEncuentraDomicilio: false,
  handleOnClickUbicacion: f => f,
  handleSelect: f => f,
  handleChangeAutocomplete: f => f,
  handleChangeForm: f => f,
  handleChangeRadioButton: f => f,
  handleOnClickSave: f => f,
  handleOnClickDeleteDireccion: f => f,
  handleOnClicNoEncuentraDomicilio: f => f,
  handleChangeDomicilioAlternativo: f => f,
  handleOnClickSaveDomicilioAlternativo: f => f,
}

DomicilioEntrega.propTypes = {
  domicilios: PropTypes.array.isRequired,
  domicilioSeleccionado: PropTypes.string.isRequired,
  domicilioAlternativo: PropTypes.string.isRequired,
  tipoUbicacionElegida: PropTypes.number.isRequired,
  position: PropTypes.object.isRequired,
  zoom: PropTypes.number.isRequired,
  existeSeleccion: PropTypes.bool.isRequired,
  formUbicacion: PropTypes.object.isRequired,
  errores: PropTypes.object.isRequired,
  openModalBorrarDireccion: PropTypes.bool.isRequired,
  openModalNoEncuentraDomicilio: PropTypes.bool.isRequired,
  handleOnClickUbicacion: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  handleChangeAutocomplete: PropTypes.func.isRequired,
  handleChangeForm: PropTypes.func.isRequired,
  handleChangeRadioButton: PropTypes.func.isRequired,
  handleOnClickSave: PropTypes.func.isRequired,
  handleOnClickDeleteDireccion: PropTypes.func.isRequired,
  handleOnClicNoEncuentraDomicilio: PropTypes.func.isRequired,
  handleChangeDomicilioAlternativo: PropTypes.func.isRequired,
  handleOnClickSaveDomicilioAlternativo: PropTypes.func.isRequired,
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyAGe37J-Cp4mPYGC_dt-ykvOPCa2HgcR_8",
  libraries: ['places'],
  v: "3",
  language: "es",
})(withStyles(styles)(DomicilioEntrega))

