import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import {
  ArrowBack as ArrowBackIcon,
  CalendarToday as CalendarTodayIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import Icon from "@mdi/react";
import { mdiCalendarMonth } from "@mdi/js";
import { mdiCart } from '@mdi/js';
import MyDrawer from "./myDrawer";
import SearchHeaderContainer from "../../containers/SearchHeaderContainer";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";

const drawerWidth = 240;
const styles = (theme) => ({
  appBar: {
    height: "80px",
    background: "#ffffff",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    zIndex: 100,
  },
  menuButtonColor: {
    color: "#FFFFFF !important",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 9 + 1,
    },
  },
  root: {
    flexGrow: 1,
  },
  show: {
    transform: "translateY(0)",
    transition: "transform .5s",
  },
  hideTop: {
    transform: "translateY(-175%)",
    transition: "transform .5s",
  },
  tooltipCalendar: {
    pointerEvents: "auto",
    maxWidth: 200,
  },
  containerIconsHeader: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "30px",
  },
  icon: {
    color: "white",
    fontSize: "24px",
    marginRight: "10px",
  },
  carritoContainer: {
    position: "relative",
  },
  cantidadCarrito: {
    position: "absolute",
    top: "-10px",
    right: "-10px",
    backgroundColor: "#46C082",
    color: "white",
    width: "20px",   // Establecer el ancho
    height: "20px",  // Establecer la altura
    borderRadius: "50%", // Hacer que sea un círculo perfecto
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
  }

});

class Header extends React.Component {
  constructor(props) {
    super(props);

    // verifica si debe mostrar el tooltip del calendario
    let showTooltipCalendario = false;
    if (this.props.usuario) {
      const showPopOverCalendarioKey =
        this.props.usuario.id + "_showPopOverCalendario";
      const showPopOverCalendarioValue = localStorage.getItem(
        showPopOverCalendarioKey
      );

      if (!showPopOverCalendarioValue) {
        showTooltipCalendario = true;
      }

      localStorage.setItem(showPopOverCalendarioKey, true);
    }

    this.state = {
      shouldShow: null,
      isScrollYAtZero: window.scrollY === 0,
      showTooltipCalendario,
    };

    this.lastScroll = null;

    this.handleScroll = this.handleScroll.bind(this);
    // Alternatively, you can throttle scroll events to avoid
    // updating the state too often. Here using lodash.
    // this.handleScroll = _.throttle(this.handleScroll.bind(this), 100);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, { passive: true });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll(evt) {
    const { scrollY } = window;
    const lastScroll = scrollY;

    if (
      (this.state.isScrollYAtZero && scrollY !== 0) ||
      (!this.state.isScrollYAtZero && scrollY === 0)
    ) {
      const isAtZero = scrollY === 0;
      setTimeout(() => this.setState({ isScrollYAtZero: isAtZero }), 300);
    }

    if (lastScroll === this.lastScroll) {
      return;
    }

    const shouldShow =
      this.lastScroll !== null ? lastScroll < this.lastScroll : null;

    if (
      shouldShow !== this.state.shouldShow &&
      Math.abs(lastScroll - this.lastScroll) > 15
    ) {
      this.setState((prevState, props) => ({
        ...prevState,
        shouldShow,
      }));
    }

    this.lastScroll = lastScroll;
  }

  calendarTooltip = () => (
    <div className="calendarTooltip">
      <div className="closeIcon">
        <CloseIcon
          onClick={() => this.setState({ showTooltipCalendario: false })}
        />
      </div>

      <Typography className="text">
        Ahora puedes consultar los turnos disponibles haciendo click en el icono
        del calendario!
      </Typography>
    </div>
  );

  render() {
    const { classes } = this.props;

    return (
      <>
        <div
          className={`menuSuperior ${this.props.pedido !== undefined &&
            this.props.showResumenPedido === true
            ? "appWithResumePedido"
            : ""
            } ${process.env.REACT_APP_ENVIROMENT === "testing"
              ? "menuSuperior-test"
              : ""
            }`}
        >
          <AppBar
            position="fixed"
            className={classNames(
              classes.appBar,
              {
                [classes.appBarShift]: this.props.drawerOpen,
              },
              `${classes.root} ${this.state.shouldShow === null
                ? ""
                : this.state.shouldShow
                  ? classes.show
                  : classes.hideTop
              }`
            )}
          >
            <Toolbar disableGutters={!this.props.drawerOpen}>
              {/* ícono para el drawer */}
              {this.props.showDrawer && (
                <div
                  onClick={this.props.handleDrawerOpen}
                  className={classNames(
                    classes.menuButton,
                    classes.menuButtonColor,
                    { [classes.hide]: this.props.drawerOpen },
                    "headerButton"
                  )}
                >
                  <div>
                    {this.props.usuario !== undefined &&
                      this.props.usuario.notificacionesNoVistas > 0 && (
                        <div
                          style={{
                            position: "relative",
                            top: "4px",
                            right: "-10px",
                          }}
                        >
                          <NotificationsActiveIcon fontSize="small" className="iconFav notificaciones" />
                        </div>
                      )}
                    <img alt="" src={require("../../images/menu.svg")}></img>
                  </div>
                </div>
              )}

              {/* ícono para volver */}
              {this.props.showBackButton && (
                <IconButton
                  color="default"
                  aria-label="Back"
                  className={classNames(classes.menuButton, "headerButton")}
                  onClick={this.props.handleOnBackClick}
                >
                  <ArrowBackIcon />
                </IconButton>
              )}

              {/* logo */}
              {!this.props.drawerOpen && (
                <div
                  onClick={() => {
                    this.props.handleOnClickLogo();
                  }}
                  className={`logoContainer center ${this.props.pedido ? "logoContainerAux" : ""
                    }`}
                    style={{marginLeft: 10}}
                >
                  <img alt="" src={this.props.instalacion.logoSuperior} />
                </div>
              )}

              {/* buscador */}

              {this.props.showBusqueda === true && (
                <SearchHeaderContainer
                  pedido={this.props.pedido}
                  filterArticulos={this.props.filterArticulos}
                  handleOnChangeFilter={this.props.handleOnChangeFilter}
                  handleOnClearBusqueda={this.props.handleOnClearBusqueda}
                />
              )}

              <div className={classes.containerIconsHeader}>
                {this.props.usuario !== undefined && (
                  <Tooltip
                    arrow
                    open={
                      this.state.showTooltipCalendario &&
                      this.state.isScrollYAtZero &&
                      (this.state.shouldShow === null ||
                        this.state.shouldShow === true)
                    }
                    classes={{ tooltip: classes.tooltipCalendar }}
                    title={this.calendarTooltip()}
                    placement="bottom-end"
                  >
                    <div onClick={this.props.handleOnClickOpenCalendario}>
                      <Icon
                        path={mdiCalendarMonth}
                        size={1}
                        className={classes.icon}
                      />
                    </div>
                  </Tooltip>
                )}
                {/* ICONO NOTIFICACIONES */}
                <div
                  onClick={this.props.handleOnClickFavoritos}
                  className="favIconSpaceBetween"
                >
                  <FavoriteBorderIcon className={classes.icon} />
                </div>

                {this.props.pedido !== undefined && (
                  <div
                    className={classes.carritoContainer}
                    onClick={this.props.handleOnClickVerCarrito}
                  >
                    <div style={{ position: "relative" }}>
                      <Icon path={mdiCart} size={1} color="#ffffff" />
                      <div className={classes.cantidadCarrito}>
                        {this.props.pedido.listaPedidoDetalle &&
                          this.props.pedido.listaPedidoDetalle.length > 0
                          ? this.props.pedido.listaPedidoDetalle.length
                          : undefined}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Toolbar>

            {/* contenido extra */}
            {this.props.contenidoExtra && (
              <div id="headerContenidoExtra">{this.props.contenidoExtra}</div>
            )}
          </AppBar>
        </div>
        {/* menú lateral */}
        <div className="swaggerMenuSuperiorLeft">
          {this.props.showDrawer && (
            <MyDrawer
              drawerOpen={this.props.drawerOpen}
              handleDrawerClose={this.props.handleDrawerClose}
              instalacion={this.props.instalacion}
              preguntasFrecuentes={this.props.preguntasFrecuentes}
              handleOnCerrarSesion={this.props.handleOnCerrarSesion}
              handleOnClickLogo={this.props.handleOnClickLogo}
              handleOnClickIniciarSesion={this.props.handleOnClickIniciarSesion}
              usuario={this.props.usuario}
              handleOnClickOpenNotidicaciones={
                this.props.handleOnClickOpenNotidicaciones
              }
              handleOnClickBasesCondiciones={
                this.props.handleOnClickBasesCondiciones
              }
            />
          )}
        </div>
      </>
    );
  }
}

Header.propTypes = {
  showDrawer: PropTypes.bool.isRequired,
  drawerOpen: PropTypes.bool.isRequired,
  handleDrawerOpen: PropTypes.func.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
  showBackButton: PropTypes.bool.isRequired,
  handleOnBackClick: PropTypes.func,
  instalacion: PropTypes.object.isRequired,
  contenidoExtra: PropTypes.node,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  preguntasFrecuentes: PropTypes.object,
  usuario: PropTypes.object,
  handleOnCerrarSesion: PropTypes.func.isRequired,
  handleOnClickLogo: PropTypes.func.isRequired,
  showResumenPedido: PropTypes.bool.isRequired,
  filterArticulos: PropTypes.string.isRequired,
  handleOnChangeFilter: PropTypes.func.isRequired,
  handleOnClearBusqueda: PropTypes.func.isRequired,
  handleOnClickIniciarSesion: PropTypes.func.isRequired,
  handleOnClickVerCarrito: PropTypes.func.isRequired,
  showBusqueda: PropTypes.bool.isRequired,
  handleOnClickBasesCondiciones: PropTypes.func.isRequired,

  handleOnClickOpenModalRubros: PropTypes.func.isRequired,
  handleOnClickOpenNotidicaciones: PropTypes.func.isRequired,
  handleOnClickFavoritos: PropTypes.func.isRequired,
};

Header.defaultProps = {
  showResumenPedido: false,
  showDrawer: false,
  drawerOpen: false,
  handleDrawerOpen: (f) => f,
  handleDrawerClose: (f) => f,
  showBackButton: false,
  handleOnBackClick: (f) => f,
  handleOnChangeFilter: (f) => f,
  handleOnClickBasesCondiciones: (f) => f,
  handleOnClickOpenModalRubros: (f) => f,
  handleOnClickOpenNotidicaciones: (f) => f,
  handleOnClickFavoritos: (f) => f,
};

export default withStyles(styles, { withTheme: true })(Header);
