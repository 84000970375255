import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid'
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CustomModal from '../UI/Modals/CustomModal'
import { formatMoney } from '../Common/decimalHelper';
import Paragraph from '../UI/Texts/Paragraph';
import Heading from '../UI/Texts/Heading';
import './styles.css'
import { showInDateRange } from '../../utils';
import {
  Select,
  MenuItem,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import moment from "moment"

const styles = theme => ({
  rootDialog: {
    zIndex: 1550,
    width: '450px',
  },
  rootContent: {
    borderRadius: '21px',
    width: '450px',
    minWidth: '300px'
  },
  icon: {
    color: '#B4C4D5'
  },
  select: {
    textAlign: 'left',
    font: '400 16px/23px Poppins',
    letterSspacing: '0px',
    opacity: 1,
    paddingLeft: '10px'
  },
})

const Turnero = (props) => {

  const { classes } = props

  //const showDeliveryBonificado = props.instalacion.listaOfertas.find((x) => x.tag === 'Cybermonday' || x.tag.toLowerCase()==='hotsale') === undefined;

  return (
    <div className='containerTurnero'>

      {/* DESCRIPCION PRINCIPAL */}
      {props.showDiv ?
        <div className='labelDescripcionTopHeader'>
          {props.tipo === 'pickup' ?
            <Heading tag="h4" weight="bold">Elige la sucursal, el día y la hora en la que pasarás a retirar tu pedido</Heading>
            :
            <Heading tag="h4" weight="bold">Elige el día y la hora para que te enviemos el pedido</Heading>
          }
        </div>
        : <div></div>}

      {/* DESCRIPCION LARGA DEL DIA */}
      {props.indiceDiaSelected !== undefined && props.listaFechaHora && props.listaFechaHora.length > 0 &&
        props.listaFechaHora[props.indiceDiaSelected] !== undefined &&
        <div className='labelDescripcion'>
          <Paragraph size="big" weight="semi_bold">{`Día y hora: ${props.listaFechaHora[props.indiceDiaSelected].descripcionLarga}`}
          </Paragraph>
        </div>}
      {props.tipo === 'pickup' && (
        <div className='inputContainer' style={{ textAlign: 'center', marginTop: '20px' }}>
          <span class="label">Sucursal</span>
          <Select
            classes={{
              icon: classes.icon,
              select: classes.select
            }}
            style={{ maxWidth: '340px' }}
            IconComponent={ExpandMoreIcon}
            value={props.sucursalPickupSeleccionada}
            onChange={props.handleOnChangeSucursalPickup}
            name="sucursalPickup"
          >
            {props.sucursalesPickup.map((x) => (
              <MenuItem value={x.idSucursal}>{x.nombre} {x.direccion}</MenuItem>
            ))}
          </Select>
        </div>
      )}
      {/* DIAS DISPONIBLES */}
      <div className='containerOptionsDias'>

        {(props.index + 1) > props.cantItemShow ?
          <div className='itemArrow' onClick={(props.index + 1) > props.cantItemShow ? props.handleOnClickAnterior : undefined}>
            <ArrowBackIosIcon className='marginIcon' />
          </div>
          :
          <div className='itemNotArrow'></div>
        }

        {props.listaFechaHora && props.listaFechaHora.length > 0 && props.listaFechaHora.map((fh, indice) => {
          if (indice >= (props.index + 1 - props.cantItemShow) && indice <= props.index) {
            return <div key={fh.idDiaCalendario} className={`itemDia ${props.idDiaSelected === fh.idDiaCalendario ? 'active' : ''}`} onClick={() => props.handleOnClickDia(fh, indice)}>
              <div className={`diaNumero ${props.idDiaSelected === fh.idDiaCalendario ? 'activeDiaNumero' : ''}`}>{fh.diaNumero}</div>
              <div className={`diaNombre ${props.idDiaSelected === fh.idDiaCalendario ? 'activeDiaNombre' : ''}`}>{fh.diaNombre}</div>
            </div>
          }
          else {
            return <></>
          }
        })}

        <div className='itemArrow'
          onClick={props.listaFechaHora &&
            props.listaFechaHora.length > 0 &&
            props.listaFechaHora.length > props.cantItemShow &&
            (props.index + 1) < props.listaFechaHora.length
            ? props.handleOnClickSiguiente
            :
            props.handleShowMsgTopeDias}
        >
          <ArrowForwardIosIcon />
        </div>
      </div>


      <Row style={{ justifyContent: 'center', marginTop: '10px' }}>
        <Col>
          {props.tipo === 'delivery' && props.instalacion && props.instalacion.cobroDelivery && props.showDiv ?
            showInDateRange('2022-06-30', '2022-07-06') ?
              (
                <div className='diaNoLaborable'>
                  <InfoOutlinedIcon />
                  <div>Delivery gratis por una semana, la vigencia es desde el 30-6-22 al 06-07-22</div>
                </div>
              )
              :
              (
                <>
                  <div className='diaNoLaborable'>
                    <InfoOutlinedIcon />
                    <div>El envío tiene un costo de {props.instalacion.montoDelivery ? formatMoney(props.instalacion.montoDelivery) : '$-'}</div>
                  </div>

                  {props.instalacion.cobroDelivery && props.instalacion.deliveryBonificado && (
                    <div className='diaNoLaborable'>
                      <InfoOutlinedIcon />
                      <div>Delivery bonificado en compras superiores a {formatMoney(props.instalacion.montoDeliveryBonificado)}</div>
                    </div>
                  )}
                </>
              ) : showInDateRange('2022-09-28', '2022-10-26') ?
              (
                <div className='infoFormaPagosHarlem'>
                  <InfoOutlinedIcon />
                  <div>ENTRADA HARLEM: Seleccioná el botón HARLEM dentro de las opciones del turnero y en la próxima ventana el botón “consumidor final”.</div>
                </div>
              ) : null
          }


        </Col>
      </Row>

      {/* HORARIOS DISPONIBLES */}
      <div className='containerOptionsHorarios'>

        {props.indiceDiaSelected !== undefined && props.listaFechaHora && props.listaFechaHora.length > 0 && props.listaFechaHora[props.indiceDiaSelected] &&
          !props.listaFechaHora[props.indiceDiaSelected].noLaborable &&
          props.listaFechaHora[props.indiceDiaSelected].horarios !== undefined &&
          props.listaFechaHora[props.indiceDiaSelected].horarios.map((h, i) => {
            return <div key={h.idHorario} className={`itemHorario ${h.descripcion === 'COMPLETO' ? 'disabled' : ''}`} onClick={h.descripcion === 'COMPLETO' ? undefined : () => props.handleOnClickHorario(h, i)}>
              <div className='horario'>{h.descripcion}</div>
            </div>
          })}

        {props.indiceDiaSelected !== undefined && props.listaFechaHora && props.listaFechaHora.length > 0 && props.listaFechaHora[props.indiceDiaSelected] &&
          props.listaFechaHora[props.indiceDiaSelected].noLaborable &&
          <Row>
            <Col>
              <div className='diaNoLaborable'>
                <InfoOutlinedIcon />
                <div>{props.listaFechaHora[props.indiceDiaSelected].mensajeNoLaborable}</div>

              </div>
            </Col>

          </Row>

        }
      </div>

      {/* MODAL AVISO DE TOPE DE DIAS */}
      <CustomModal
        open={props.showModalTopeDias}
        onClose={() => { }}
        title=""
        buttonNames={["ENTENDIDO"]}
        buttonActions={
          [props.handleShowMsgTopeDias]
        }
        fullWidth={true}
      >
        <div className='modalTextContent'>
          <Paragraph size="big" weight="regular">
            Para conservar la calidad del servicio, no ofrecemos turnos de entrega superiores a {props.diasAdelante && props.diasAdelante > 1 ? props.diasAdelante + ' días.' : props.diasAdelante + ' día.'}
          </Paragraph>
        </div>
      </CustomModal>
    </div>
  );
}

Turnero.defaultProps = {
  tipo: '',
  showDiv: true,
  listaFechaHora: [],
  diasAdelante: '',
  idDiaSelected: undefined,
  indiceDiaSelected: undefined,
  showModalTopeDias: false,
  handleOnClickDia: f => f,
  handleOnClickSiguiente: f => f,
  handleOnClickAnterior: f => f,
  handleOnClickHorario: f => f,
  handleShowMsgTopeDias: f => f,
}

Turnero.propTypes = {
  showDiv: PropTypes.bool.isRequired,
  listaFechaHora: PropTypes.array.isRequired,
  diasAdelante: PropTypes.number.isRequired,
  idDiaSelected: PropTypes.number.isRequired,
  indiceDiaSelected: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  cantItemShow: PropTypes.number.isRequired,
  tipo: PropTypes.string.isRequired,
  showModalTopeDias: PropTypes.bool.isRequired,
  instalacion: PropTypes.object.isRequired,
  handleOnClickDia: PropTypes.func.isRequired,
  handleOnClickSiguiente: PropTypes.func.isRequired,
  handleOnClickAnterior: PropTypes.func.isRequired,
  handleOnClickHorario: PropTypes.func.isRequired,
  handleShowMsgTopeDias: PropTypes.func.isRequired,
};

export default withStyles(styles)(Turnero);

