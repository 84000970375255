import React from 'react';
import apiClient from '../../../apiClient';

const useArticuloAlternativa = ({ itemId, item, cantidadInicial, alternativaSeleccionadaInicial, onConfirm }) => {
  const [articulo, setArticulo] = React.useState();
  const [cantidad, setCantidad] = React.useState(cantidadInicial);
  const cantidadAlternativasPermitidas = 1;  
  const [alternativasSeleccionadas, setAlternativasSeleccionadas] = React.useState({});

  React.useEffect(() => {
    if (item) {
      setArticulo(item);
    }
    else {
      apiClient.get(`/api/Articulo/GetArticulo?idArticulo=${itemId}`)
      .then(({ data }) => {
        setArticulo(data);
      });
    }
  }, []);

  const alternativas = React.useMemo(() => articulo ? articulo.articuloAlternativas : [], [articulo]);

  React.useEffect(() => {
    const alternativaSelected = alternativas.find((x) => x.alternativa === alternativaSeleccionadaInicial);
    if (alternativaSelected) {
      onSelectAlternativa(alternativaSelected.idArticuloAlternativa, true);
    }
  }, [alternativas]);

  const alternativasChecked = React.useMemo(() => {
    return alternativas.map((x) => ({
      id: x.idArticuloAlternativa,
      descripcion: x.alternativa,
      checked: alternativasSeleccionadas[x.idArticuloAlternativa] || false,
    }));
  }, [alternativas, alternativasSeleccionadas]);

  const cantidadAlternativasSeleccionadas = React.useMemo(() => {
    return Object.values(alternativasSeleccionadas).filter((x) => x === true).length;
  }, [alternativasSeleccionadas]);

  const onSelectAlternativa = (id, checked) => {
    const result = {...alternativasSeleccionadas};

    /*
      * si la cantidad maxima permitida es 1: funciona como un radio group, dejando seleccionado siempre la ultima opcion en la que se hizo click
      * si la cantidad maxima permitida es > 1: no deja seguir seleccionado al llegar a la cantidad maxima. Se obliga a deseleccionar una opcion primero
    */
    if (checked && cantidadAlternativasSeleccionadas >= cantidadAlternativasPermitidas) {
      if (cantidadAlternativasPermitidas > 1) return;

      // deselecciono todas las alternativas
      Object.keys(result).forEach((key) => result[key] = false);
    }
    
    result[id] = checked;
    setAlternativasSeleccionadas(result);
  };

  const onAgregarAlCarrito = () => {
    const alternativaSeleccionada = alternativasChecked.find((x) => alternativasSeleccionadas[x.id] === true);
    const descripcionSeleccionada = alternativaSeleccionada ? alternativaSeleccionada.descripcion : undefined;
    onConfirm(cantidad, descripcionSeleccionada);
  };

  return {
    articulo,
    cantidad,
    setCantidad,
    cantidadAlternativasPermitidas,
    cantidadAlternativasSeleccionadas,
    alternativas: alternativasChecked,
    onSelectAlternativa,
    onAgregarAlCarrito,
  }
};

export default useArticuloAlternativa;
