import React from 'react'
import Heading from '../UI/Texts/Heading';
import Paragraph from '../UI/Texts/Paragraph';
import RadioButton from '../UI/Buttons/Radio';
import CustomButton from '../Common/wrappers/CustomButton';
import PropTypes from 'prop-types'

const PedidoItem = ({
    pedido,
    instalacion,
    verMasPedidosDetalle,
    toFixedCustomize,
    classes,
    handleChangeRadioButtonIndividual,
    handleOnClickVerMasPedidosDetalle
}) => {

    const hasDiscount = (detalle) => {
        if (detalle.precioListaDescuento)
            return <><span className='precioTachado'>
                ${toFixedCustomize(detalle.precioLista)}
            </span><br />
                <Heading tag="h6" weight="semi_bold" color="black-text">
                    {`$${toFixedCustomize(detalle.precioListaDescuento)}`}
                </Heading></>
        return <Heading tag="h6" weight="semi_bold" color="black-text">
            {`$${toFixedCustomize(detalle.precioLista)}`}
        </Heading>
    }


    return (
        <div className='itemPedidos'>
            {pedido && pedido.listaPedidoDetalle.map((pd, indice) => {
                if ((!verMasPedidosDetalle && indice < 3) || verMasPedidosDetalle) {
                    return <div key={pd.idPedidoDetalle} className='item'>
                        <div className='containerImgDesc'>
                            {pd.imagenArticulo ?
                                <div className={`imgPedidoDetalle ${pd.imagenArticulo ? '' : 'noImage'}`}>
                                    <img src={pd.imagenArticulo} alt=''></img>
                                </div>
                                :
                                <div className='imageDefault'>
                                    <img src={instalacion.logoCard} alt=''></img>
                                    <div>Producto sin foto</div>
                                </div>
                            }
                            <div className='containerInfoPd'>
                                <div className='nombreArticuloPedido'>
                                    <Paragraph size="big" weight="regular" color="black-text">{pd.descripcion.toLowerCase()}</Paragraph>
                                </div>
                                <div className='alternativa'>{pd.alternativa}</div>
                                <div className='unidadesPrecio'>
                                    <Paragraph size="big" weight="regular" color="dark-grey-text">{pd.productoPesable ? `${pd.cantidad} kg ` : `${pd.cantidad} u `}${pd.precioListaDescuento ? toFixedCustomize(pd.precioListaDescuento / pd.cantidad) : toFixedCustomize(pd.precioUnitario)}</Paragraph>
                                </div>
                                <div className='unidadesPrecio unidadesPrecioAlignRight'>
                                    {hasDiscount(pd)}
                                </div>
                            </div>
                        </div>
                        <div className='radioGroupContainer'>
                            <RadioButton
                                classes={{ root: classes.rootRadioGroup }}
                                aria-label="gender"
                                name="gender1"
                                value={pd.permisoReemplazo}
                                handleChange={(event) => handleChangeRadioButtonIndividual(event, pd.idPedidoDetalle)}
                                buttons={[{ value: true, label: "Criterio Alvear" }, { value: false, label: "No reemplazar" }]} />
                        </div>
                    </div>
                }
                else {
                    return undefined
                }
            })}
            {!verMasPedidosDetalle && pedido && pedido.listaPedidoDetalle && pedido.listaPedidoDetalle.length > 3 &&
                <div className='containerBtnPagar'>
                    <CustomButton color="secondary" onClick={handleOnClickVerMasPedidosDetalle} classProp={`btnVerMas`} message="Ver todos" />
                </div>}
        </div>)

}

PedidoItem.propTypes = {
    toFixedCustomize: PropTypes.func.isRequired,
    handleOnClickVerMasPedidosDetalle: PropTypes.func.isRequired,
    handleChangeRadioButtonIndividual: PropTypes.func.isRequired,
    pedido: PropTypes.object.isRequired,
    instalacion: PropTypes.object.isRequired,
    verMasPedidosDetalle: PropTypes.bool.isRequired,
    classes: PropTypes.string.isRequired,
}

export default PedidoItem;