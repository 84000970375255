import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'

import './talleColorGrid.css'

class TalleColorGrid extends React.Component {


  handleFocusTextfield = (event) => event.target.select();

  render() {
    return (
      <div className='talleColorGrid'>
        {this.props.listaTalleColor !== undefined &&
          <div>
            {/* encabezado */}
            <div className='rowContainer'>

              {/* header cell invisible -> primera de la izquierda */}
              <div className='cell header fake'><div>Talle</div></div>

              {/* armo el header con los talles de la lista */}
              {this.props.listaTalleColor.talles.map((t) => {
                return <div className='cell header'>
                  <div>{t.nombre}</div>
                </div>
              })}
            </div>

            {/* armo las filas con los valores */}
            {this.props.listaTalleColor.colores.map((color, colorIndex) => {
              return <div className='rowContainer'>
                <div className='cell'>
                  {color.esImagen ?
                    <div className='imageContainer'>
                      <img src={color.valor} />
                    </div>
                    :
                    <div className="bordeColor" style={{ backgroundColor: color.valor }}></div>
                  }
                </div>

                {color.cantidades.map((cantidad, cantidadIndex) => {
                  return <div className='cell'>
                    {this.props.modoEdicion && color.disponibles[cantidadIndex] !== 0 ?
                      <TextField
                        value={cantidad}
                        type="number"
                        inputProps={{ min: "0", step: "1" }}
                        onChange={(e) => { this.props.handleOnCantidadChange(e, colorIndex, cantidadIndex) }}
                        onFocus={this.handleFocusTextfield}
                      />
                      :
                      <div>{!this.props.modoEdicion && cantidad !== 0 ? cantidad : '-'}</div>
                    }
                  </div>
                })}
              </div>
            })}

          </div>
        }
      </div>
    )
  }
}

TalleColorGrid.propTypes = {
  listaTalleColor: PropTypes.object.isRequired,
  modoEdicion: PropTypes.bool.isRequired,
  handleOnCantidadChange: PropTypes.func
}

TalleColorGrid.defaultProps = {
  modoEdicion: false,
  handleOnCantidadChange: f => f
}

export default TalleColorGrid