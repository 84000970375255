import React from 'react'
import PropTypes from 'prop-types'
import CustomModal from '../UI/Modals/CustomModal'
import Paragraph from '../UI/Texts/Paragraph'
import './styles.css'

const PedidoConfirmacion = ({
  classes,
  openModalCompraExitosa,
  handleOnClickEntendido
}) => {
  return <CustomModal
    open={openModalCompraExitosa}
    onClose={() => { }}
    title="COMPRA EXITOSA"
    buttonNames={["ENTENDIDO!"]}
    buttonActions={
      [handleOnClickEntendido]
    }
  >
    <div className='modalTextContent'>
      <Paragraph size="big" weight="regular">
        En la brevedad recibirás un mail con el resumen de la compra.
      </Paragraph>
      <Paragraph size="big" weight="regular">
        Gracias por elegir Alvear Online
      </Paragraph>
    </div>
  </CustomModal>
}


PedidoConfirmacion.propTypes = {
  classes: PropTypes.string.isRequired,
  openModalCompraExitosa: PropTypes.func.isRequired,
  handleOnClickEntendido: PropTypes.func.isRequired,
}

export default PedidoConfirmacion;