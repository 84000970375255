import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid'
import ContenidoCatalogoContainer from '../../containers/ContenidoCatalogo'
import './styles.css'

class Proveedores extends React.Component {

  render() {

    return (
        <div className='containerProveedores'>
            {/*<div>No se encontraron resultados</div>*/}

            {this.props.existeCatalogo === true &&
                <ContenidoCatalogoContainer
                    history={this.props.history}
                    idCatalogoSeleccionado={this.props.catalogos[0].id}
                    catalogoUnico={true}
                    esOferta={true}
                    codigoProveedor={this.props.codigoProveedor}
                />
            }

            {!this.props.poseeArticulos && !this.props.loadingCatalogo &&
            <div className='textNoArituclos'>No se encontraron resultados</div>}
            
        </div>
    );
  }
}

Proveedores.defaultProps = {
}

Proveedores.propTypes = {
    existeCatalogo: PropTypes.bool.isRequired,
    history: PropTypes.object,
    catalogos:  PropTypes.array,
    codigoProveedor: PropTypes.string.isRequired,
    loadingCatalogo: PropTypes.bool.isRequired,
    poseeArticulos: PropTypes.bool.isRequired,
};

export default Proveedores;

