import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import { ShoppingCart } from '@material-ui/icons';
import './pedido.css'
import ItemPedido from './itemPedido'
import InfiniteScroll from "react-infinite-scroll-component";

class Pedido extends React.Component {

    

    render() {

        
        return (
            <div>
                
                <Tabs
                    value = {this.props.tabIndex}
                    onChange={this.props.handleChangeTabIndex}
                    indicatorColor = "primary"
                    
                    variant="fullWidth"
                   >
                    <Tab label="Mis Pedidos" />
                    {this.props.pedidosCliente !== undefined &&  this.props.pedidosCliente.length > 0 ? 
                    <Tab  label="Todos los Pedidos"/> : ''}                
                </Tabs>
                

                <div className='listaItemPedido'>
                <Row style={{margin: '0px'}}>
                {this.props.tabIndex === 0 ? // SI ESTAMOS EN LA OPCION 1 ES DECIR MIS PEDIDOS
                 
                    this.props.pedidosUser !== undefined && this.props.pedidosUser.length > 0 ? 
                        this.props.pedidosUser.map((item, index) => {
                            return(
                            <Col md  = {4}> 
                                <ItemPedido    
                                    item={item} 
                                    handleOnVer = {this.props.handleOnVer}
                                    cliente = {false} //Esta bandera es para ver si renderizo el nombre de usuario o no.
                                />
                            </Col> )             
                    })
                    :
                '' 
                : this.props.pedidosCliente !== undefined && this.props.pedidosCliente.length > 0 ? 
                this.props.pedidosCliente.map((item, index) => {
                    return(
                    <Col md = {4}> 
                        <ItemPedido 
                            item={item} 
                            handleOnVer = {this.props.handleOnVer}
                            cliente = {true} //Esta bandera es para ver si renderizo el nombre de usuario o no.
                            />
                    </Col> )             
                })
                 :
                ''
                }
                
                </Row>
                
                </div>
                
            </div>
        )
    }
}


Pedido.propTypes = {
    tabIndex: PropTypes.number.isRequired,  
    pedidosUser: PropTypes.array.isRequired,
    pedidosCliente: PropTypes.array.isRequired
    };

Pedido.defaultProps = { 
    
}

export default Pedido