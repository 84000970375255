import React from "react";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { COLORS } from "../../../styles/colors";

const styles = (theme) => ({
  customButton: {
    borderRadius: "25px",
    border: "1px solid transparent",
    padding: "15px 28px 15px 28px !important",
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "20px",
    textAlign: "center",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      padding: "11px,24px,11px,24px !important",
    },
  },
  primary: {
    backgroundColor: COLORS.darkPurple,
    color: COLORS.white,
    "&:hover": {
      backgroundColor: "var(--purple)",
    },
    "&:disabled": {
      color: COLORS.white,
      backgroundColor: COLORS.darkPurple,
      borderColor: COLORS.darkPurple,
      opacity: 0.5,
    },
  },
  secondary: {
    backgroundColor: COLORS.white,
    color: COLORS.darkPurple,
    borderColor: COLORS.darkPurple,
    "&:hover": {
      backgroundColor: "var(--lightViolet)",
      color: "var(--darkPurple)",
    },
    "&:disabled": {
      backgroundColor: COLORS.white,
      color: COLORS.darkPurple,
      opacity: 0.5,
    },
  },
  success: {
    backgroundColor: COLORS.green,
    color: COLORS.white,
    "&:hover": {
      backgroundColor: "var(--darkGreen)",
    },
    "&:disabled": {
      backgroundColor: COLORS.green,
      color: COLORS.white,
      opacity: 0.5,
    },
  },
});

const CustomButton = ({
  classes,
  type = "button",
  message = "Custom Button",
  endIcon = null,
  startIcon = null,
  isFullWidth = false,
  color = "primary",
  onClick = null,
  disabled = false,
  size = "medium",
  classProp = null,
  style = null,
}) => {
  const buttonProps = {
    type: type,
    onClick: onClick,
    color: "default",
    startIcon: startIcon,
    variant: "contained",
    disabled: disabled,
    size: size,
    className: `${classes.customButton} ${classes[color]} ${classProp}`,
    fullWidth: isFullWidth,
    styles: style,
  };

  return <Button {...buttonProps}>{message}</Button>;
};

CustomButton.propTypes = {
  classes: PropTypes.object.isRequired,
  endIcon: PropTypes.element,
  startIcon: PropTypes.element,
  message: PropTypes.string,
  type: PropTypes.string,
  isFullWidth: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.oneOf(["primary", "secondary", "success"]),
  disabled: PropTypes.bool,
};

export default withStyles(styles)(CustomButton);
