import React from 'react'
import PropTypes from 'prop-types'
import FormHelperText from '@material-ui/core/FormHelperText'
import CustomButton from '../Common/wrappers/CustomButton';
import CustomInput from '../Common/textInput/CustomInput'

class RestablecerContraseniaForm extends React.Component {

    componentWillUnmount = () => {
        // limpio el mensaje de error
        this.props.limpiarDatos()
    }

    render() {
        return (
            <div className='restablecerContraseniaForm'>
                <div className='containerForm'>

                    <div className='logoContainer center'>
                        <img src={this.props.logo} alt='Logo' />
                    </div>

                    <div className='inputContainer'>
                        <CustomInput
                            placeholder="Escribe la nueva contraseña"
                            value={this.props.loginData.nuevaContrasenia}
                            type="password"
                            name="nuevaContrasenia"
                            onChange={this.props.handleOnDataChange}
                            error={this.props.errores.nuevaContrasenia !== undefined}
                        />
                        {this.props.errores.nuevaContrasenia !== undefined &&
                            <FormHelperText error>{this.props.errores.nuevaContrasenia}</FormHelperText>}
                    </div>

                    <div className='inputContainer'>
                        <CustomInput
                            placeholder="Reescribe la nueva contraseña"
                            value={this.props.loginData.repeticionNuevaContrasenia}
                            type="password"
                            name="repeticionNuevaContrasenia"
                            onChange={this.props.handleOnDataChange}
                            error={this.props.errores.repeticionNuevaContrasenia !== undefined}
                        />
                        {this.props.errores.repeticionNuevaContrasenia !== undefined &&
                            <FormHelperText error>{this.props.errores.repeticionNuevaContrasenia}</FormHelperText>}
                    </div>
                    <div className='myLabel error' style={{ visibility: this.props.errorAutenticacion ? 'visible' : 'hidden' }} >{this.props.errorAutenticacion || ''}</div>
                    <div className='buttonContainer'>
                        {/*<Button className='btnSecundario' onClick={this.props.handleOnCancelar}>CANCELAR</Button>*/}
                        <CustomButton color="secondary" onClick={this.props.handleOnRestablecerContrasenia} size="large" message="RESTABLECER" />
                    </div>
                </div>
            </div>
        )
    }
}

RestablecerContraseniaForm.propTypes = {
    loginData: PropTypes.object.isRequired,
    errores: PropTypes.object.isRequired,
    handleOnDataChange: PropTypes.func.isRequired,
    handleOnRestablecerContrasenia: PropTypes.func.isRequired,
    handleOnCancelar: PropTypes.func.isRequired,
    errorAutenticacion: PropTypes.string,
    limpiarDatos: PropTypes.func.isRequired,
}

export default RestablecerContraseniaForm