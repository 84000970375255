import React from 'react'
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import DomicilioEntrega from '../../components/DomicilioEntrega'
import {
    geocodeByPlaceId,
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';
  import * as Notification from '../../components/Common/notification';
  import * as sessionActions from '../../reducers/SessionReducer/actions'

class DomicilioEntregaContainer extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            tipoUbicacionElegida: undefined,
            position: {
                lat: -31.638377,
                lng: -60.7041932
            },
            formUbicacion:{
                domicilio: undefined,
                pisoDepto: undefined,
                cp: undefined,
                localidad: undefined,
                opcional: undefined,
            },
            errores: {},
            zoom: 13,
            existeSeleccion: false,
            ultimoDomicilioBuscado: undefined,
            openModalBorrarDireccion: false,
            idDomicilioEntrega: undefined,
            openModalNoEncuentraDomicilio: false,
            domicilioAlternativo: undefined,


            country: undefined,
            administrativeAreaLevel1: undefined,
            administrativeAreaLevel2: undefined,
            locality: undefined,

            error: undefined
        }
    }

    /*
    setError(error) {
        if(error) {
            this.setState({
                formUbicacion:{
                    domicilio: this.state.formUbicacion.domicilio,
                    pisoDepto: undefined,
                    cp: undefined,
                    localidad: undefined,
                    opcional: undefined,
                },
                error: error
            })    
        }                         
    }
    */

    handleOnClickUbicacion = (value, limpiar, geocoder) => {
        
        if(limpiar === true){
            this.setState({
                tipoUbicacionElegida: undefined,
                position: {
                    lat: -31.638377,
                    lng: -60.7041932
                },
                formUbicacion:{
                    domicilio: undefined,
                    pisoDepto: undefined,
                    cp: undefined,
                    localidad: undefined,
                    opcional: undefined,
                },
                errores: {},
                zoom: 13,
                existeSeleccion: false,
                ultimoDomicilioBuscado: undefined
            })
        }
        else{
            this.setState({tipoUbicacionElegida: value}, () => {    
                if(value === 1){
                  this.geolocate(geocoder)
                }
            })
        }
        
    }

    geolocate = (geocoder) => {
        
        let self = this
        if(navigator.geolocation){
            var success = function(position){
                let latitud = position.coords.latitude
                let longitud = position.coords.longitude

                let pos = {
                    lat: latitud,
                    lng: longitud
                }
                
                let postalCode = undefined
                let localidad = undefined
                let domicilio = undefined

                let country = undefined
                let administrativeAreaLevel1 = undefined
                let administrativeAreaLevel2 = undefined
                let locality = undefined

                if(geocoder){
                    geocoder.geocode({ location: pos}, (results, status) => {
                        if (status === "OK") {                        
                            if(results[0] && results[0].address_components && results[0].address_components.length > 0){
    
                                domicilio = results[0].formatted_address
    
                                results[0].address_components.forEach(item => {
                                    if(item.types.find(x => x === "postal_code") !== undefined){
                                        postalCode = item.long_name.substring(1, 5)
                                    }
                                    if(item.types.find(x => x === "locality") !== undefined && item.types.find( x => x === "political") !== undefined ){
                                        //localidad = item.long_name
                                        locality = item.long_name
                                    }
                                    if(item.types.find(x => x === "administrative_area_level_2") !== undefined && item.types.find( x => x === "political") !== undefined ){
                                        localidad = item.long_name
                                        administrativeAreaLevel2 = item.long_name
                                    }
                                    if(item.types.find(x => x === "administrative_area_level_1") !== undefined && item.types.find( x => x === "political") !== undefined ){
                                        localidad = localidad && localidad.length > 0 ? localidad + ", " + item.long_name : item.long_name
                                        administrativeAreaLevel1 = item.long_name
                                    }
                                    /*if(item.types.find(x => x === "administrative_area_level_2") !== undefined && item.types.find( x => x === "political") !== undefined ){
                                        administrativeAreaLevel2 = item.long_name
                                    }*/
                                    if(item.types.find(x => x === "country") !== undefined && item.types.find( x => x === "political") !== undefined ){
                                        country = item.long_name
                                    }
                                });
    
                                let form = self.state.formUbicacion
                                form.cp = postalCode
                                form.localidad = localidad
                                form.domicilio = domicilio

                                let ultimoDomicilioBuscado = {
                                    domicilio: domicilio,
                                    position: pos
                                }
    
                                self.setState({
                                    form: form,
                                    ultimoDomicilioBuscado: ultimoDomicilioBuscado,
                                    country: country,
                                    administrativeAreaLevel1: administrativeAreaLevel1,
                                    administrativeAreaLevel2: administrativeAreaLevel2,
                                    locality: locality
                                })
                            } else {
                                Notification.showInfo('No se encontró su ubicación')
                            }
                        } else {
                            Notification.showInfo('No se encontró su ubicación')
                        }
                    });
                }
                

                self.setState({
                    position: pos,
                    zoom: 16,
                    existeSeleccion: true
                })
            }

            navigator.geolocation.getCurrentPosition(
                success, 
                function(msg){
                    self.setState({tipoUbicacionElegida: undefined}, () => {
                        Notification.showInfo('Debe dar permisos a la aplicación para poder encontrar su ubicación.')
                    });
                },
                {maximumAge:0, timeout:5000, enableHighAccuracy: true}
            );
        }
    }

    /*
    validateAddress = async (address) => {
        let errores = {}
        geocodeByAddress(address)
            .then(res => {
                if(res[0] && res[0].address_components && res[0].address_components.length > 0){
                    res[0].address_components.forEach(item => {
                        if(item.long_name === 'Rosario') {
                            errores.domicilio = 'Domicilio debe encontrarse dentro del rango'
                            this.setState({errores: errores})
                            return false;                           
                        }
                    })
                }            
            })
            return true;            
    }
    */

    handleSelect = (selected) => {
        
        let postalCode = undefined
        let localidad = undefined
        let errores = {}

        geocodeByAddress(selected)
            .then(res => {

                    let country = undefined
                    let administrativeAreaLevel1 = undefined
                    let administrativeAreaLevel2 = undefined
                    let locality = undefined
                    
                    if(res[0] && res[0].address_components && res[0].address_components.length > 0){
                        res[0].address_components.forEach(item => {
                            if(item.types.find(x => x === "postal_code") !== undefined){
                                postalCode = item.long_name.substring(1, 5)
                            }
                            if(item.types.find(x => x === "locality") !== undefined && item.types.find( x => x === "political") !== undefined ){
                                //localidad = item.long_name
                                locality = item.long_name
                            }
                            if(item.types.find(x => x === "administrative_area_level_2") !== undefined && item.types.find( x => x === "political") !== undefined ){
                                administrativeAreaLevel2 = item.long_name
                                localidad = item.long_name
                            }
                            if(item.types.find(x => x === "administrative_area_level_1") !== undefined && item.types.find( x => x === "political") !== undefined ){
                                localidad = localidad && localidad.length > 0 ? localidad + ", " + item.long_name : item.long_name
                                administrativeAreaLevel1 = item.long_name
                            }
                            if(item.types.find(x => x === "country") !== undefined && item.types.find( x => x === "political") !== undefined ){
                                country = item.long_name
                            }
                        });
                    };

                    let position = {...this.state.position}

                    if(res[0] && res[0].geometry && res[0].geometry.location){
                        position.lat = res[0].geometry.location.lat()
                        position.lng = res[0].geometry.location.lng()
                    }

                    let form = this.state.formUbicacion
                    form.cp = postalCode
                    form.localidad = localidad
                    form.domicilio = selected
                    
                    let ultimoDomicilioBuscado = {
                        domicilio: selected,
                        position: position
                    }

                    this.setState({
                        form: form,
                        position: position,
                        zoom: 16,
                        existeSeleccion: true,
                        ultimoDomicilioBuscado: ultimoDomicilioBuscado,
                        country: country,
                        administrativeAreaLevel1: administrativeAreaLevel1,
                        administrativeAreaLevel2: administrativeAreaLevel2,
                        locality: locality
                    });
            })
            .catch(error => {
                console.log('error', error); // eslint-disable-line no-console
            });
    }

    handleChangeAutocomplete = address => {
        
        let form = this.state.formUbicacion
        form.domicilio = address
        this.setState({ formUbicacion: form });
    };

    handleChangeForm = (e) => {
        let value = e.target.value
        let name = e.target.name

        let form = this.state.formUbicacion
        form[name] = value

        this.setState({ formUbicacion: form });
    };

    handleOnClickSave = () => {
        let valida = true;
        let errores = {};


        if(this.state.formUbicacion.domicilio === undefined || this.state.formUbicacion.domicilio === null || this.state.formUbicacion.domicilio === ''){
            errores.domicilio = 'Domicilio no puede ser vacío y debe ser valido'
            valida = false
        }

        /*if(this.state.formUbicacion.cp === undefined || this.state.formUbicacion.cp === null || this.state.formUbicacion.cp === ''){
            errores.cp = 'Seleccione una localidad'
            valida = false
        }*/

        if(this.state.formUbicacion.localidad === undefined || this.state.formUbicacion.localidad === null || this.state.formUbicacion.localidad === ''){
            errores.localidad = 'Domicilio no puede ser vacío y debe ser valido'
            valida = false
        }

        if(this.state.ultimoDomicilioBuscado === undefined){
            errores.domicilio = 'Realice una búsqueda por domicilio'
            valida = false
        }

        if(this.state.ultimoDomicilioBuscado !== undefined && this.state.formUbicacion.domicilio !== undefined &&this.state.ultimoDomicilioBuscado.domicilio !== this.state.formUbicacion.domicilio){
            errores.domicilio = 'El domicilio no coincide con la búsqueda realizada. Seleccione nuevamente.'
            valida = false
        }

        if(this.state.ultimoDomicilioBuscado !== undefined && this.state.ultimoDomicilioBuscado.position && this.state.position &&
            (this.state.ultimoDomicilioBuscado.position.lat !== this.state.position.lat || this.state.ultimoDomicilioBuscado.position.lng !== this.state.position.lng)){
            errores.domicilio = 'El domicilio no coincide con la búsqueda realizada. Seleccione nuevamente.'
            valida = false
        }

        if(!valida){
            this.setState({errores: errores})
            return
        }
        else{
            if(this.state.existeSeleccion === false){
                errores.domicilio = 'Realice una búsqueda por domicilio'
                valida = false

                this.setState({errores: errores})
                return
            }
        }

        this.setState({errores:{}}, () => {
            let payload = {
                domicilio: this.state.formUbicacion.domicilio ? this.state.formUbicacion.domicilio.trim() : undefined,
                pisoDepto: this.state.formUbicacion.pisoDepto ? this.state.formUbicacion.pisoDepto.trim() : undefined,
                cp: this.state.formUbicacion.cp ? this.state.formUbicacion.cp.trim() : undefined,
                opcional: this.state.formUbicacion.opcional ? this.state.formUbicacion.opcional.trim() : undefined,
                idUsuario: this.props.usuario.id,
                idInstalacion: this.props.instalacion.id,
                latitud: this.state.ultimoDomicilioBuscado.position.lat,
                longitud: this.state.ultimoDomicilioBuscado.position.lng,
                country: this.state.country,
                administrativeAreaLevel1: this.state.administrativeAreaLevel1,
                administrativeAreaLevel2: this.state.administrativeAreaLevel2,
                locality: this.state.locality,
            }
      
            payload.callback = (domicilio) => {
              this.props.handleAfterSaveNuevoDomicilio(domicilio.idDomicilioEntrega)
              Notification.showSuccess(`Domicilio guardado con éxito`)
            }
      
            payload.callbackError = (error) => {
              Notification.showError('Error al guardar domicilio: ' + error)
            }
      
            this.props.sagaSaveDireccionEntrega(payload)
        })
    }

    handleOnClickDeleteDireccion = (idDireccion, confirma, cancela) => {

        if(cancela === true){
            //limpio datos del estado, cierro modal
            this.setState({openModalBorrarDireccion: false, idDomicilioEntrega: undefined})
            return
        }

        if(confirma === true){

            let payload = {}
            payload.idDomicilioEntrega = this.state.idDomicilioEntrega
            
            payload.callback = (domicilio) => {
                //limpio datos del estado, cierro modal
                this.setState({openModalBorrarDireccion: false, idDomicilioEntrega: undefined}, () => {
                    Notification.showSuccess(`Domicilio eliminado con éxito`)
                })
            }
    
            payload.callbackError = (error) => {
                Notification.showError('Error al eliminar domicilio: ' + error)
            }
            //llamar a la saga
            this.props.sagaEliminarDireccion(payload)
            
            
        }
        else{
            //abro modal
            this.setState({openModalBorrarDireccion: true, idDomicilioEntrega: idDireccion})
        }
    }

    handleOnClicNoEncuentraDomicilio = (value) => {
        this.setState({openModalNoEncuentraDomicilio: value})
    }

    handleChangeDomicilioAlternativo = (e) => {
        let value = e.target.value
        let name = e.target.name

        this.setState({ [name]: value });
    };

    handleOnClickSaveDomicilioAlternativo = () =>{

        let valida = true
        let errores = {}

        if(this.state.domicilioAlternativo === undefined || this.state.domicilioAlternativo === null || this.state.domicilioAlternativo === ''){
            errores.domicilioAlternativo = 'Domicilio no puede ser vacío'
            this.setState({errores: errores})
            return
        }

        this.setState({errores:{}}, () => {

            let payload = {
                domicilio: this.state.domicilioAlternativo.trim(),
                pisoDepto: undefined,
                cp: undefined,
                opcional: undefined,
                idUsuario: this.props.usuario.id,
                idInstalacion: this.props.instalacion.id,
                latitud: undefined,
                longitud: undefined,
                faltaGeoreferenciar: true
            }
      
            payload.callback = (domicilio) => {
              this.props.handleAfterSaveNuevoDomicilio(domicilio.idDomicilioEntrega)
              Notification.showSuccess(`Domicilio guardado con éxito`)
            }
      
            payload.callbackError = (error) => {
              Notification.showError('Error al guardar domicilio: ' + error)
            }
      
            this.props.sagaSaveDireccionEntrega(payload)
        })
    }

    render() {

        return (
            <DomicilioEntrega
                domicilios={this.props.domicilios}
                domicilioSeleccionado={this.props.domicilioSeleccionado}
                tipoUbicacionElegida={this.state.tipoUbicacionElegida}
                position={this.state.position}
                formUbicacion={this.state.formUbicacion}
                errores={this.state.errores}
                zoom={this.state.zoom}
                existeSeleccion={this.state.existeSeleccion}
                openModalBorrarDireccion={this.state.openModalBorrarDireccion}
                openModalNoEncuentraDomicilio={this.state.openModalNoEncuentraDomicilio}
                handleOnClickUbicacion={this.handleOnClickUbicacion}
                handleSelect={this.handleSelect}
                handleChangeAutocomplete={this.handleChangeAutocomplete}
                handleChangeForm={this.handleChangeForm}
                handleChangeDomicilioAlternativo={this.handleChangeDomicilioAlternativo}
                handleChangeRadioButton={this.props.handleChangeRadioButtonDomicilio}
                handleOnClickSave={this.handleOnClickSave}
                handleOnClickDeleteDireccion={this.handleOnClickDeleteDireccion}
                handleOnClicNoEncuentraDomicilio={this.handleOnClicNoEncuentraDomicilio}
                handleOnClickSaveDomicilioAlternativo={this.handleOnClickSaveDomicilioAlternativo}
            />
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ...sessionActions
    }, dispatch)
}

function mapStateToProps(state) {
    return {
    }
}

DomicilioEntregaContainer.propTypes = {
    domicilioSeleccionado: PropTypes.string.isRequired,
    domicilios: PropTypes.array.isRequired,
    handleChangeRadioButtonDomicilio: PropTypes.func.isRequired,
    handleAfterSaveNuevoDomicilio: PropTypes.func.isRequired,
    handleOnAfterSaveDomicilioAlternativo: PropTypes.func.isRequired,
    instalacion: PropTypes.object.isRequired,
    usuario: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DomicilioEntregaContainer)