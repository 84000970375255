import { fromJS } from 'immutable'
import * as ReducerActions from './actions'

const initialState = fromJS({
    data:{
        calendarioPickup: {calendario:undefined},
        calendarioDelivery: {calendario:undefined},
    }
})

export default function calendarioReducer(state = initialState, action) {

    if(action.type === ReducerActions.SET_CALENDARIO_PICKUP) {
        return state.setIn(["data", "calendarioPickup"], action.payload)
    }

    if(action.type === ReducerActions.SET_CALENDARIO_DELIVERY) {
      return state.setIn(["data", "calendarioDelivery"], action.payload)
  }

    return state
}