import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import { withStyles } from '@material-ui/core/styles';
import ItemCatalogo from './itemCatalogo'
import './catalogo.css'

const styles = theme => ({
});


class Catalogo extends React.Component {

  render() {

    const { classes } = this.props;

    return (
      <div>

        {/* Catalogos */}
        <Row>
          <Col xs={12} >
            {/* LISTA CATALOGOS ACTIVOS Y PUBLICADOS */}
            <div className='catalogoPublicados'>
              {this.props.catalogos.map((c) => {
                return <ItemCatalogo
                  item={c}
                  instalacion={this.props.instalacion}
                  mostrarNombre={this.props.instalacion.mostrarNombre}
                />

              })}
            </div>

          </Col>
        </Row>
      </div>
    )
  }
}

Catalogo.propTypes = {
  classes: PropTypes.object.isRequired,
  catalogos: PropTypes.array.isRequired,
  instalacion: PropTypes.object.isRequired,
};

Catalogo.defaultProps = {
  catalogos: [],
  instalacion: {},
}

export default withStyles(styles)(Catalogo)