import React from 'react';
import PropTypes from 'prop-types';
import useSelectorCantidad from './useSelectorCantidad';
import './styles.css';

const SelectorCantidad = ({
  value,
  onChange,
  incremento,
  productoPesable,
  cantidadLimMin,
  cantidadLimMax,
  customClass,
}) => {

  const {
    handleOnIncrement,
    handleOnDecrement,
    handleOnChangeInput,
    handleOnFocusInput,
  } = useSelectorCantidad({
    value,
    onChange,
    incremento,
    cantidadLimMin,
    cantidadLimMax,
    productoPesable,
  });

  return (
    <div className={`selectorCantidad ${customClass}`}>
      <div onClick={handleOnDecrement} className='btn'>
        <img
          alt=""
          height="13"
          src={require('../../../images/iconoMenos.png')}
        />
      </div>
      {!productoPesable && cantidadLimMin === 1 && incremento === 1 ?
        <input
          //type='text' 
          inputMode="numeric"
          type="number" min="0" step="1"
          className='cantidad cantidadInput'
          value={value}
          onInput={handleOnChangeInput}
          onFocus={handleOnFocusInput}
        />
        :
        <div className='cantidad'>
          {productoPesable ? value.toFixed(2) : value}
        </div>
      }
      <div onClick={handleOnIncrement} className='btn'>
        <img
          alt=""
          width="12"
          src={require('../../../images/iconoMas.png')}
        />
      </div>
    </div>
  );
};

SelectorCantidad.defaultProps = {
  value: 0,
  onChange: () => { },
  incremento: undefined,
  productoPesable: false,
  cantidadLimMin: undefined,
  cantidadLimMax: undefined,
  customClass: "",
};

SelectorCantidad.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  incremento: PropTypes.number,
  productoPesable: PropTypes.bool,
  cantidadLimMin: PropTypes.number,
  cantidadLimMax: PropTypes.number,
  customClass: PropTypes.string,
};

export default SelectorCantidad;
