import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as pedidoActions from '../../reducers/PedidoReducer/actions'
import { pedidoDataSelector } from '../../selectors/pedidoSelector'
import { instalacionSelector } from '../../selectors/sessionSelector'
import ControlActividadModal from '../../components/ControlActividadModal'
import MensajeCanceladoModal from '../../components/ControlActividadModal/mensajeCancelado'

class ControlActividadModalContainer extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            seconds: this.props.instalacion.tiempoDecisionPedidoBorrador,
            open: true
        }
        
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);

        this.startTimer()
    }
    
    startTimer() {
        if (this.timer == 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }
    
    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        
        // Check if we're at zero.
        if (seconds == 0) { 
            clearInterval(this.timer);
            this.setState({
                seconds: seconds,
                open: false
            });
            this.handleOnCancelar()
        }
        else{
            this.setState({
                seconds: seconds,
            });
        }
    }

    handleOnCancelar = () => {
        //armo el model de updateEstadoPedido
        let model = {}
        model.id = this.props.dataPedido.pedido.id
        // 5 = estadoPedido => Abandonado
        model.idEstadoPedido = 5
        this.props.sagaUpdateEstadoPedidoAbandonado(model)
        //abro el modal de pedido cancelado
        this.setState({open: false});
    }

    render() {
        return (
            <div>
                {this.state.open &&
                <ControlActividadModal
                    handleOnClose={this.props.handleOnClose}
                    handleOnCancelar={this.handleOnCancelar}
                    second={this.state.seconds}
                    open={this.state.open}
                />}
                
                {!this.state.open &&
                <MensajeCanceladoModal 
                    handleOnClose={this.props.handleOnClose}
                    open={!this.state.open}
                />}
            </div>
        )   
    }
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        ...pedidoActions,
        
	}, dispatch)
}

function mapStateToProps(state) {
	return {
        instalacion: instalacionSelector(state),
        dataPedido: pedidoDataSelector(state),
	}
}

ControlActividadModalContainer.propTypes = {
    handleOnClose: PropTypes.func
}

ControlActividadModalContainer.defaultProps = {
    handleOnClose: f => f
}

export default connect(mapStateToProps, mapDispatchToProps)(ControlActividadModalContainer)