import _axios from '../../apiClient'

export function getPedido(payload) {
    return _axios.get(`/api/Pedido/Get`,
    { 
        params: { id: payload } 
    },
    {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}

export function updateEstadoPedido(payload) {
  return _axios.post(`/api/Pedido/UpdateEstadoPedido`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}

export function removePedidoDetalle(payload) {
  return _axios.post(`/api/Pedido/QuitarPedidoDetalle`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}