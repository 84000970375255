import { fromJS } from 'immutable'
import * as ReducerActions from './actions'

const initialState = fromJS({
    instalacion: undefined,
    usuario: undefined,
    resultadoAutenticacion: {
        error: undefined,
        autenticado: false,
        restablecerContrasenia: false,
        claveProvisoriaGenerada: false,
        token: undefined,
        usuarioId: undefined,
    },
    datosAdicionalesCargados: false,
    preguntasFrecuentes: []
})

export default function sessionReducer(state = initialState, action) {
    
    if(action.type === ReducerActions.INITIALIZE_FORM) {
        return state.set("usuario", initialState.get('usuario'))
                    .set("resultadoAutenticacion", initialState.get('resultadoAutenticacion'))
    }
    
    if(action.type === ReducerActions.SET_FLAG_DATOS_ADICIONALES) {
        return state.set("datosAdicionalesCargados", fromJS(action.payload)) 
    }

    if(action.type === ReducerActions.SAVE_INSTALACION) {
        return state.set("instalacion", fromJS(action.payload))
    }

    if(action.type === ReducerActions.SAVE_DEFAULT_RESULTADO_AUTENTICACION){
        return state.set("resultadoAutenticacion", initialState.get("resultadoAutenticacion"))
    }

    if(action.type === ReducerActions.SAVE_RESULTADO_AUTENTICACION) {
        let myState = state.toJS()

        return fromJS({
            ...myState,
            resultadoAutenticacion: {
                ...myState.resultadoAutenticacion, 
                ...action.payload
            }
        })
    }

    if(action.type === ReducerActions.SAVE_USUARIO) {
        return state.set("usuario", fromJS(action.payload))
    }

    if(action.type === ReducerActions.SAVE_IMAGEN_LOGIN){
        
        let myState = state.toJS()
        
        let myInstalacion = {...myState.instalacion}
        myInstalacion.fondoLogin = action.payload

        return fromJS({
            ...myState,
            instalacion: myInstalacion
        })
    }

    if(action.type === ReducerActions.SAVE_IMAGEN_DESCUENTOS){
        
        let myState = state.toJS()
        
        let myInstalacion = {...myState.instalacion}
        myInstalacion.bandaDescuento = action.payload

        return fromJS({
            ...myState,
            instalacion: myInstalacion
        })
    }

    if(action.type === ReducerActions.SET_NOTIFICACION_VISTA){
        
        let myState = state.toJS()
        
        let usuario = {...myState.usuario}
        
        if(usuario !== undefined && usuario.notificaciones && usuario.notificaciones.length > 0){
            
            usuario.notificaciones.forEach(n => {
                if(n.idNotificacion === action.payload){
                    n.visto = true
                }
            });

            //usuario.notificacionesNoVistas = usuario.notificacionesNoVistas - 1 

            return fromJS({
                ...myState,
                usuario: usuario
            })
        }
    }

    if(action.type === ReducerActions.SET_NOTIFICACIONES){
        
        let myState = state.toJS()
        
        let usuario = {...myState.usuario}
        
        if(usuario !== undefined && usuario.notificaciones && usuario.notificaciones.length > 0 &&
            action.payload && action.payload.length > 0){
            
            action.payload.forEach(n => {
                usuario.notificaciones.push(n)
            });

            return fromJS({
                ...myState,
                usuario: usuario
            })
        }
    }

    if(action.type === ReducerActions.SET_DIRECCIONES_ENTREGA){
        let myState = state.toJS()
        
        let usuario = {...myState.usuario}
        
        if(usuario !== undefined && usuario.domiciliosEntrega && usuario.domiciliosEntrega.length > 0 && action.payload){
            usuario.domiciliosEntrega.push(action.payload)
        }
        else{
            let domicilios = []
            domicilios.push(action.payload)
            usuario.domiciliosEntrega = domicilios
        }

        return fromJS({
            ...myState,
            usuario: usuario
        })
    }

    if(action.type === ReducerActions.DELETE_DIRECCION_ENTREGA){
        let myState = state.toJS()
        
        let usuario = {...myState.usuario}
        
        if(usuario !== undefined && usuario.domiciliosEntrega && usuario.domiciliosEntrega.length > 0 && action.payload){
            usuario.domiciliosEntrega = usuario.domiciliosEntrega.filter(x => x.idDomicilioEntrega !== action.payload)
        }

        return fromJS({
            ...myState,
            usuario: usuario
        })
    }

    if(action.type === ReducerActions.SET_PREGUNTAS_FRECUENTES) {
        return state.set("preguntasFrecuentes", fromJS(action.payload))
    }

    return state
}