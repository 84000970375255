import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Button, MenuItem, CircularProgress } from '@material-ui/core';
import Selector from './selector';
import Filtros from './filtros';
import useFiltros from './useFiltros';
import DrawerRepetirPedido from './DrawerRepetirPedido';
import CustomButton from '../../Common/wrappers/CustomButton';

const BusquedaArticulos = (props) => {
  const {
    pedido,
    filterBusqueda,
    cantidadArticulosFiltrados,
    buscandoCatalogo,
    errorBusqueda,
    vistaFavoritos,
    ordenamiento,
    handleChangeOrdenamiento,
    handleOnClickVerTodosLosProductos,
    getSubFiltrosArticulos,
    subFiltrosArticulos,
    handleOnChangeFiltrosArticulos,
  } = props;
  
  const [repetirPedido, setRepetirPedido] = React.useState(false);
  const [cerrarModal, setCerrarModal] = React.useState(false);

  const {
    subfiltrosSeleccionados,
    onSelectionChange,
    onFilter,
    clearSelection,
    cantidadFiltrosAplicados,
  } = useFiltros({ filterBusqueda, getSubFiltrosArticulos, subFiltrosArticulos, handleOnChangeFiltrosArticulos, cerrarModal, setCerrarModal });

  
  
  return (
    <>
      <Row className={`tituloOrdenContainer ${pedido !== undefined ? 'tituloOrdenContainerAux' : ''}`}>
        {/* MENSAJE RESULTADO DE BUSQUEDA*/}
        {!vistaFavoritos && !buscandoCatalogo && filterBusqueda !== undefined && 
          cantidadArticulosFiltrados !== undefined && cantidadArticulosFiltrados === 0 &&
          <div className='tituloPageArticulos'>
            No se encontraron productos para '{filterBusqueda}'
          </div>
        }

        { !buscandoCatalogo && filterBusqueda !== undefined && 
          cantidadArticulosFiltrados !== undefined && cantidadArticulosFiltrados > 0 &&
          <div className={`tituloPageArticulos ${pedido !== undefined ? 'tituloPageArticulosAux' : ''} resumenBusqueda`}>
            { cantidadArticulosFiltrados === 1 ? `1 producto encontrado` : `${cantidadArticulosFiltrados} productos encontrados`} para '{filterBusqueda}'
          </div>
        }

        { cantidadFiltrosAplicados > 0 && !buscandoCatalogo && (
          <div className='resumenSubFiltros'>
            { cantidadFiltrosAplicados === 1 ? <span>{`Tienes 1 filtro aplicado.`}</span> : <span>{`Tienes ${cantidadFiltrosAplicados} filtros aplicados.`}</span>}
            <span className="btnQuitar" onClick={clearSelection}>Quitar</span>
          </div>
        )}

        {!buscandoCatalogo && errorBusqueda && 
          <div className='tituloPageArticulos'>
            Falló la búsqueda de productos. Recargue la página o haga una búsqueda.
          </div>
        } 


        {cantidadArticulosFiltrados === 0 && vistaFavoritos && 
        <div style={{width: '100%'}}>
          <div className='tituloPageArticulos' style={{justifyContent: 'center'}}>
            Haz tu primera compra para poder ver tus favoritos
          </div>
          <div className='btnBusquedaDefault'>
            <Button onClick={handleOnClickVerTodosLosProductos} autoFocus>
              VER TODOS LOS PRODUCTOS
            </Button>
          </div>
        </div>
        } 

        {/* loader de busquedas */}
        {buscandoCatalogo && filterBusqueda !== undefined && !vistaFavoritos &&
          <div className='tituloPageArticulos'>
            Buscando productos para '{filterBusqueda}'
            <CircularProgress />
          </div>
        }

        {buscandoCatalogo && (filterBusqueda === undefined || vistaFavoritos) &&
          <div className='tituloPageArticulos'>
            Buscando productos
            <CircularProgress />
          </div>
        }
      </Row>  

      
      <Row  between={vistaFavoritos ? "xs" : null} className={'selectorContainer' }>
        {/* COMBO ORDENAMIENTO */}
        {cantidadArticulosFiltrados > 0 && !errorBusqueda && !buscandoCatalogo && filterBusqueda !== undefined && (
        <Col xs={12} sm={6} md={4} lg={3}>
          <Selector value={ordenamiento} placeholder="Ordenar Por" onChange={handleChangeOrdenamiento}>
              <MenuItem className='itemSeleccion' value={1}>Ofertas</MenuItem>
              <MenuItem className='itemSeleccion' value={2}>Menor Precio</MenuItem>
              <MenuItem className='itemSeleccion' value={3}>Mayor Precio</MenuItem>
          </Selector>
        </Col>)}

        {/* COMBO FILTRO */}
        {!vistaFavoritos && 
        <Col xs={12} sm={6} md={4} lg={3}>
          <Filtros
            show={!errorBusqueda && !buscandoCatalogo && filterBusqueda !== undefined}
            subFiltrosArticulos={subFiltrosArticulos}
            subfiltrosSeleccionados={subfiltrosSeleccionados}
            onSelectionChange={onSelectionChange}
            onFilter={onFilter}
            setCerrarModal={setCerrarModal}
          />
        </Col>}
       
        {vistaFavoritos && filterBusqueda === 'FAVORITOS' && 
        <Col xs={12} sm={6} md={4} lg={2}>        
            <CustomButton message="Repetir pedido" onClick={() => {setRepetirPedido(!repetirPedido)}} size="small" color="secondary"/>
        </Col>
        } 
      </Row>
      {repetirPedido && <DrawerRepetirPedido handleOnClose={() => setRepetirPedido(false)} show={repetirPedido} />}

    </>
  );
};

export default BusquedaArticulos;
