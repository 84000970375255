import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import './style.css'

const RadioButton = ({buttons, handleChange, disabled, value, name, aria_label, classes}) => {

  const onChange = (event) => {
    handleChange(event)
  };

  
  const RadioPurple = withStyles({
    root: {
      color: 'var(--purple)',
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  const displayButtons = () => buttons.map(button =>
    <FormControlLabel className="RadioButton" classes={{ label: classes.label }} value={button.value}
      control={<RadioPurple className="RadioButton" />} label={button.label} disabled={button.disabled}/>)

  return (
    <div className="RadioButtonWrapper">
      <RadioGroup
        style={{ display: 'block' }}
        aria-label={aria_label}
        name={name}
        value={value}
        disabled={disabled}
        onChange={onChange}
      >
        {displayButtons()}
      </RadioGroup>
    </div>
  );
}

RadioButton.defaultProps = {
  buttons: [],
  handleChange: () => {},
}

RadioButton.propTypes = {
  buttons: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default RadioButton;