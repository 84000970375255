import React from 'react'
import PropTypes from 'prop-types'
import TalleColorGrid from './talleColorGrid'
import { formatMoney } from '../Common/decimalHelper'
import CustomModal from '../UI/Modals/CustomModal'
import Paragraph from '../UI/Texts/Paragraph'
import './styles.css'

class DetallePedidoModal extends React.Component {
  render() {
    return (
      <CustomModal
        open={true}
        onClose={this.handleClose}
        title=""
        buttonNames={["VOLVER", this.props.detallePedido !== undefined && this.props.detallePedido.id === 0 ? 'AGREGAR' : 'QUITAR']}
        buttonActions={
          [() => { this.props.handleOnClose(false) }, () => { this.props.handleOnClose(true) }]
        }
      >
        <div className='modalTextContent'>
          {this.props.detallePedido !== undefined && this.props.detallePedido.articulo !== undefined ?
            <>
              <Paragraph size="big" weight="regular">
                {this.props.detallePedido.articulo.nombre}
              </Paragraph>
              <div className='bandaPrecios'>
                {this.props.bandaPrecios.map((bp, i) => {
                  return <Paragraph size="big" weight="regular">
                    {`${bp.nombre}: ${formatMoney(bp.precio)} ${i !== this.props.bandaPrecios.length - 1 ? '- ' : ''}`}
                  </Paragraph>


                })}
              </div>
              <Paragraph size="big" weight="regular">
                LO ESTÁS PAGANDO
              </Paragraph>
              <Paragraph size="big" weight="bold">
                {formatMoney(this.props.precioPagado)}
              </Paragraph>
              <div className='talleColorContainer'>
                <TalleColorGrid
                  listaTalleColor={this.props.detallePedido.articulo.listaTalleColor}
                  modoEdicion={this.props.modoEdicion}
                  handleOnCantidadChange={this.props.handleOnCantidadChange}
                />
              </div>
            </>
            :
            <Paragraph size="big" weight="regular">
              Cargando...
            </Paragraph>
          }
        </div>
      </CustomModal>
    )
  }
}

DetallePedidoModal.propTypes = {
  modoEdicion: PropTypes.bool.isRequired,
  detallePedido: PropTypes.object.isRequired,
  bandaPrecios: PropTypes.object.isRequired,
  precioPagado: PropTypes.number.isRequired,
  handleOnCantidadChange: PropTypes.func,
  handleOnClose: PropTypes.func,

  //disabledButton: PropTypes.bool.isRequired,
}

DetallePedidoModal.defaultProps = {
  modoEdicion: true,
  bandaPrecios: [],
  precioPagado: 0,
  handleOnCantidadChange: f => f,
  handleOnClose: f => f
}

export default DetallePedidoModal