import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import FormHelperText from '@material-ui/core/FormHelperText'
import CustomButton from '../Common/wrappers/CustomButton'
import CustomInput from '../Common/textInput/CustomInput'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

class LoginForm extends React.Component {
  render() {
    return (
      <div className='loginForm'>
        <div className='containerForm'>
          <div className='logoContainer center'>
            <img alt='Logo' src={this.props.logo} />
          </div>
          <div className='inputContainer'>
            <CustomInput placeholder="Email"
              onChange={this.props.handleOnDataChange}
              name="email"
              value={this.props.loginData.email}
              error={this.props.errores.email !== undefined} />
            {this.props.errores.email !== undefined &&
              <FormHelperText error>{this.props.errores.email}</FormHelperText>}
          </div>
          <div className='inputContainer' style={{ marginBottom: '0px' }}>
            <CustomInput
              placeholder="Contraseña"
              value={this.props.loginData.contrasenia}
              type="password"
              name="contrasenia"
              onChange={this.props.handleOnDataChange}
              onKeyPress={this.props.handleOnContraseniaKeyPress}
              error={this.props.errores.email !== undefined}
              icon={<LockOutlinedIcon />}
            />
            {this.props.errores.contrasenia !== undefined &&
              <FormHelperText error>{this.props.errores.contrasenia}</FormHelperText>}
          </div>
          <div className='myLabel link' onClick={this.props.handleOnOlvidoContrasenia}>Olvidé mi contraseña</div>
          <div className='containerBtn'>
            <CustomButton color="secondary" onClick={this.props.handleOnAutenticar} size="small" message="INGRESÁ" />
          </div>
          <div className='myLabel labelRegistrate' style={{ textAlign: 'center' }}>Primera vez que compras en Alvear Online? <span className='link'><Link to='/RegistrarUsuario'>Registrate</Link></span></div>
          <div className='myLabel errorLogin' style={{ visibility: this.props.errorAutenticacion ? 'visible' : 'hidden' }} >{this.props.errorAutenticacion || ''}</div>
        </div>
      </div>
    )
  }
}

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  logo: PropTypes.object.isRequired,
  loginData: PropTypes.object.isRequired,
  errores: PropTypes.object.isRequired,
  handleOnDataChange: PropTypes.func.isRequired,
  handleOnContraseniaKeyPress: PropTypes.func.isRequired,
  handleOnAutenticar: PropTypes.func.isRequired,
  handleOnOlvidoContrasenia: PropTypes.func.isRequired,
  errorAutenticacion: PropTypes.string
}

export default LoginForm