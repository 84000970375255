import React from 'react'
import PropTypes from 'prop-types'
import CustomModal from '../UI/Modals/CustomModal'
import Paragraph from '../UI/Texts/Paragraph'

import './styles.css'

class ControlActividadModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      timer: this.props.second
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.second !== this.props.second) {
      this.setState({
        timer: nextProps.second
      })
    }
  }

  render() {
    return (
      <CustomModal
        open={this.props.open}
        onClose={this.handleClose}
        title="Querés continuar con el pedido?"
        buttonNames={["TODAVÍA ESTOY PIDIENDO", "YA TERMINÉ, CONFIRMAR!", "CANCELAR PEDIDO AHORA"]}
        buttonActions={
          [() => { this.props.handleOnClose(0) }, () => { this.props.handleOnClose(1) }, () => { this.props.handleOnCancelar() }]
        }
      >
        <div className='modalTextContent'>
          <Paragraph size="big" weight="regular">
            {`Si no respondes en ${this.state.timer} segundos se cancelará el pedido.`}
          </Paragraph>
        </div>
      </CustomModal>
    )
  }
}

ControlActividadModal.propTypes = {
  handleOnClose: PropTypes.func,
  handleOnCancelar: PropTypes.func,
  seconds: PropTypes.object,
  open: PropTypes.object,
}

ControlActividadModal.defaultProps = {
  handleOnClose: f => f
}

export default ControlActividadModal