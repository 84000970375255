import axios from 'axios'

export const _dev = process.env.NODE_ENV === 'development'

let BASE_URL = _dev
  ? 'http://localhost:44331'
  : process.env.REACT_APP_ENVIROMENT === 'testing' ? 'http://192.168.11.10:7773' :  'https://alvearonline.com.ar:9993' // URL backend para deploy'

  //'https://alvearonline.com.ar:9993'
  // TEST 'http://192.168.11.10:7773'

  BASE_URL = 'https://staging.alvearonline.com.ar/backonline'


const _axios = axios.create({
  baseURL: BASE_URL,
  timeout: 60000,
  withCredentials: true 
})

// agrego un interceptor a las respuestas del cliente api...
_axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
    
  // si se produjo un error de permisos, redirijo al login
  if(error.response.status === 401)
  {
    window.location.hash = '/Login'
    return false
  }

  return Promise.reject(error)
});

export default _axios