import { createSelector } from 'reselect'

const registrarUsuarioReducerDataSelector = state => state.registrarUsuarioReducer.getIn(["data"]).toJS()
export const registrarUsuarioDataSelector = createSelector(
    registrarUsuarioReducerDataSelector, (dataField) => {      
      
      // cliente
      let myCliente = undefined
      if(dataField.cliente)
      {
        // cargo los datos del cliente
        myCliente = {}
        myCliente.id = dataField.cliente.idCliente
        myCliente.nombreFantasia = dataField.cliente.nombreFantasia
        myCliente.razonSocial = dataField.cliente.razonSocial
        myCliente.cuit = dataField.cliente.cuit
        
        // cargo los datos de contacto del cliente
        myCliente.datosContacto = undefined
        if(dataField.cliente.datosContacto)
        {
            myCliente.datosContacto = {}
            myCliente.datosContacto.id = dataField.cliente.datosContacto.idDatosContacto
            myCliente.datosContacto.idProvincia = dataField.cliente.datosContacto.idProvincia
            myCliente.datosContacto.idLocalidad = dataField.cliente.datosContacto.idLocalidad
            myCliente.datosContacto.email = dataField.cliente.datosContacto.correo
            myCliente.datosContacto.telefono = dataField.cliente.datosContacto.telefono
            myCliente.datosContacto.calle = dataField.cliente.datosContacto.calle
            myCliente.datosContacto.numero = dataField.cliente.datosContacto.numero
            myCliente.datosContacto.instagram = dataField.cliente.datosContacto.instagram
        }
      }
      
      return {
        cliente: myCliente,
        usuarioCreado: dataField.usuarioCreado,
        paises: dataField.paises,
        paisDefault: dataField.paisDefault
      }
    }
  )