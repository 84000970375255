import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons'
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TurneroContainer from '../../containers/TurneroContainer';
import Dropdown from '../UI/Dropdown/Dropdown'
import Heading from '../UI/Texts/Heading'
import Paragraph from '../UI/Texts/Paragraph'
import './styles.css'
import {
  Select,
  MenuItem,
} from '@material-ui/core';
import { Row, Col } from 'react-flexbox-grid'

const styles = (theme) => ({
  drawer: {
    flexShrink: 0,
    opacity: 1
  },
  drawerPaper: {
    background: '#EFF0F8 0% 0% no-repeat padding-box',
    opacity: 1,
    zIndex: 1501,
    border: 'none'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  icon: {
    color: '#B4C4D5'
  },
  select: {
    textAlign: 'left',
    font: '400 16px/23px Poppins',
    letterSspacing: '0px',
    opacity: 1,
    paddingLeft: '10px'
  },
});

class Calendario extends React.Component {

  toFixedCustomize = (num) => {
    var resultado = num.toFixed(6).slice(0, -4)
    return resultado
  }

  render() {

    const { classes } = this.props;

    return (
      <Drawer
        className={`${classes.drawer} drawerWidth`}
        anchor="right"
        open={true}
        classes={{ paper: classes.drawerPaper }}
        onClose={this.props.handleOnClose}
      >
        <div className='containerCalendario'>

          <div type="button" className='arrowCloseResumen' onClick={this.props.handleOnClose}>
            <img src={require('../../images/flechaIzquierda.png')} />
          </div>

          <div className='tituloCalendario'><Heading tag="h5" weight="bold">Turnos disponibles</Heading></div>

          <div className='notaCalendario'>
            <Paragraph size="big" weight="regular">Considera que mientras vos estás efectuando tu compra,
              otros clientes pueden estar cerrando pedidos,
              consumiendo cupos que en estos momentos figuran como disponibles.
            </Paragraph>
          </div>

          <div id='scrollItemsCalendario' className={`CalendarioItems`}>

            <Dropdown summary={<div>
              <Typography style={{ color: 'white', fontSize: '13px' }}>Calendario</Typography>
              <Typography className={`${classes.heading} labelNota`}>PICKUP</Typography>
            </div>}
              detail={<><Row>
                <div style={{ padding: '0px 24px', background: 'white', marginLeft: '10px', marginRight: '10px' }}>
                  <div className='notaCalendario'>
                    <Paragraph size="big" weight="semi_bold">Elige la sucursal por donde vas a retirar tu pedido
                    </Paragraph>
                  </div>
                </div>
              </Row>
                <Row>
                  <Col xs={12} style={{ padding: '0px 24px' }}>
                    <div className='inputContainer'>
                      <span class="label">Sucursal</span>
                      <Select
                        classes={{
                          icon: classes.icon,
                          select: classes.select
                        }}
                        style={{ width: '100%' }}
                        IconComponent={ExpandMoreIcon}
                        value={this.props.sucursalPickupSeleccionada}
                        onChange={this.props.handleOnChangeSucursalPickup}
                        name="sucursalPickup"
                      >
                        {this.props.sucursalesPickup.map((x) => (
                          <MenuItem value={x.idSucursal}>{x.nombre} {x.direccion}</MenuItem>
                        ))}
                      </Select>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <div style={{ marginTop: '10px' }}>
                      <TurneroContainer
                        showDiv={false}
                        listaFechaHora={this.props.calendarioPickup.calendario}
                        diasAdelante={this.props.calendarioPickup.diasAdelante}
                        tipo='PICKUP'
                        showModalTopeDias={false}
                        handleOnClickDia={() => { }}
                        handleOnClickHorario={() => { }}
                        indiceDiaSelected={this.props.calendarioPickup.calendario && this.props.calendarioPickup.calendario.length > 0 ? 0 : undefined}
                        idHorarioSelected={this.props.idHorarioSelected}
                        idDiaSelected={this.props.calendarioPickup.calendario && this.props.calendarioPickup.calendario.length > 0 ? this.props.calendarioPickup.calendario[0].idDiaCalendario : undefined}
                      />
                    </div>
                  </Col>
                </Row></>} />
            <Dropdown summary={<div>
              <Typography style={{ color: 'white', fontSize: '13px' }}>Calendario</Typography>
              <Typography className={`${classes.heading} labelNota`}>DELIVERY</Typography>
            </div>} detail={<div>
              <TurneroContainer
                showDiv={false}
                listaFechaHora={this.props.calendarioDelivery.calendario}
                diasAdelante={this.props.calendarioDelivery.diasAdelante}
                tipo='DELIVERY'
                showModalTopeDias={false}
                handleOnClickDia={() => { }}
                handleOnClickHorario={() => { }}
                indiceDiaSelected={this.props.calendarioDelivery.calendario && this.props.calendarioDelivery.calendario.length > 0 ? 0 : undefined}
                idHorarioSelected={this.props.idHorarioSelected}
                idDiaSelected={this.props.calendarioDelivery.calendario && this.props.calendarioDelivery.calendario.length > 0 ? this.props.calendarioDelivery.calendario[0].idDiaCalendario : undefined}
              />
            </div>} />
          </div>
        </div>
      </Drawer>
    );
  }
}
Calendario.defaultProps = {
  open: false,


}
Calendario.propTypes = {
  calendarioPickup: PropTypes.array.isRequired,
  calendarioDelivery: PropTypes.array.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleOnClickDia: PropTypes.func.isRequired,
  diasAdelante: PropTypes.number.isRequired,
  idDiaSelected: PropTypes.number.isRequired,
  indiceDiaSelected: PropTypes.number.isRequired,
  idTipoCalendario: PropTypes.number.isRequired,
  sucursalesPickup: PropTypes.array.isRequired,
  sucursalPickupSeleccionada: PropTypes.number.isRequired,
  handleOnChangeSucursalPickup: PropTypes.func.isRequired,

};

export default withStyles(styles, { withTheme: true })(Calendario);