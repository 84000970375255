import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as detallePedidoActions from '../../reducers/DetallePedidoReducer/actions'
import { detallePedidoDataSelector, errorSelector } from '../../selectors/detallePedidoSelector'
import { instalacionSelector } from '../../selectors/sessionSelector'
import * as Notification from '../../components/Common/notification'
import DetallePedidoModal from '../../components/DetallePedidoModal'
import { formatMoney } from '../../components/Common/decimalHelper'

class DetallePedidoModalContainer extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            inicializando: true,
            detallePedido: undefined,
            bandaPrecios: undefined,
            precioPagado: undefined,
        }

        // inicilizo el forulario
        this.props.initializeForm()

        // busco el artículo
        this.props.sagaFetchArticulo({ idArticulo:this.props.idArticulo, idPedidoDetalle: this.props.idPedidoDetalle })
    }

    componentDidUpdate = () => {

        // verifico que se haya inicializado el artículo y, si se pasó un idPedido detalle como parámetro, el pedido detalle
        if(this.state.inicializando === true && this.props.data.detallePedido.articulo !== undefined && this.props.idPedidoDetalle === this.props.data.detallePedido.id) {
            this.setState({ 
                detallePedido: this.props.data.detallePedido,
                inicializando: false
            }, 
            this.setBandaPrecios)
        }

        if(this.props.error === true) {
            this.props.handleOnClose(false, undefined)
        }
    }

    setBandaPrecios = () => {

        let bandaPrecios = []
        let precioPagado = undefined
        let articulo = this.props.data.detallePedido.articulo

        // recorro la lista de banda de descuentos de la instalación
        for(var i=0; i<this.props.instalacion.listaBandaDescuento.length; i++) 
        {
            let bandaDescuento = this.props.instalacion.listaBandaDescuento[i]
            // calculo el porcentaje de descuento y lo cargo en la banda de precios
            let descuento = articulo.precioLista * bandaDescuento.porcentajeDescuento / 100
            let precio = articulo.precioLista - descuento
            bandaPrecios.push({ nombre: `Precio ${i+1}`, precio: precio})

            // verifico si la banda de descuento es la que aplica al precio del pedido
            if(bandaDescuento.limiteInferior <= this.props.totalPedido && (!bandaDescuento.limiteSuperior || bandaDescuento.limiteSuperior >= this.props.totalPedido))
                precioPagado = precio
        }

        // agrego el precio sugerido si la configuracion lo permite
        if(this.props.instalacion !== undefined && this.props.instalacion.mostrarPrecioSugerido){
            bandaPrecios.push({ nombre: 'Sugerido', precio: articulo.precioLista}) /* El precio sugerido a partir de ahora es igual al precio de lista * (this.props.instalacion.porcentajePrecioSugerido/100)*/
        }
        if(precioPagado === undefined)
            precioPagado = articulo.precioLista

        // actualizo el estado
        this.setState({ bandaPrecios: bandaPrecios, precioPagado: precioPagado })
    }

    handleOnCantidadChange = (event, colorIndex, cantidadIndex) => {
        
        let cantidad = parseInt(event.target.value)

        // valido que la cantidad solicitada no supere al stock disponible
        let detallePedido = {...this.state.detallePedido}
        let stockDisponible = detallePedido.articulo.listaTalleColor.colores[colorIndex].disponibles[cantidadIndex]
        if(cantidad > stockDisponible)
        {
            let mensajeError = 'La cantidad solicitada supera el stock disponible'
            Notification.showError(mensajeError);
            return
        }

        // actualizo la cantidad del talle-color
        detallePedido.articulo.listaTalleColor.colores[colorIndex].cantidades[cantidadIndex] = cantidad

        // actualizo el estado
        this.setState({ detallePedido: detallePedido })
    }

    handleOnClose = (confirma) => {
        
        // valor de retorno del modal
        let detallePedido = undefined
        detallePedido = this.getDetallePedido()
        if(confirma && this.state.detallePedido.id === 0) {
            //detallePedido = this.getDetallePedido()

            // valido el detalle de pedido
            if(!this.validarDetallePedido(detallePedido)){
                return
            }
        }
        // llamo a la función de cierre
        this.props.handleOnClose(confirma, detallePedido,this.state.precioPagado)
    }

    getDetallePedido = () => {
        // armo un detalle de pedido para retornar del modal
        let articulo = { ...this.state.detallePedido.articulo }
        
        let myDetallePedido = {}
        myDetallePedido.idArticulo = articulo.id
        myDetallePedido.nombre = articulo.nombre
        myDetallePedido.talleColor = []
        
        // armo un array con las cantidades pedidas por talle-color
        for(var i=0; i<articulo.listaTalleColor.colores.length; i++) {
            let item = articulo.listaTalleColor.colores[i]

            // itero el array de cantidades
            for(var j=0; j<item.cantidades.length; j++)
            {
                // sólo agrego los talle-color con cantidad mayor a cero
                if(item.cantidades[j] === 0 || Number.isNaN(item.cantidades[j]))
                    continue
                // creo un objeto que identifica el talle-color con su cantidad
                let newTalleColor = {
                    idTalleColor: item.idTalleColor[j],
                    cantidad: item.cantidades[j]
                }

                // agrego el talle-color al detalle de pedido
                myDetallePedido.talleColor.push(newTalleColor)
            }
        }

        return myDetallePedido
    }

    validarDetallePedido = (detallePedido) => {
        
        let valida = true
        
        //Si no pidio articulos
        if(detallePedido.talleColor.length === 0) {
            valida = false
            Notification.showError('Debe pedir al menos 1 articulo')
        }
        
        return valida
    }

    render() {
        return (
            <div>
                {!this.state.inicializando &&
                <DetallePedidoModal 
                    modoEdicion={this.props.idPedidoDetalle === 0}
                    detallePedido={this.state.detallePedido}
                    bandaPrecios={this.state.bandaPrecios}
                    precioPagado={this.state.precioPagado}
                    handleOnCantidadChange={this.handleOnCantidadChange}
                    handleOnClose={this.handleOnClose}
                />
                }
            </div>
        )   
    }
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        ...detallePedidoActions
	}, dispatch)
}

function mapStateToProps(state) {
	return {
        data: detallePedidoDataSelector(state),
        instalacion: instalacionSelector(state),
        error: errorSelector(state)
	}
}

DetallePedidoModalContainer.propTypes = {
    idArticulo: PropTypes.number,
    idPedidoDetalle: PropTypes.number,
    totalPedido: PropTypes.number,
    handleOnClose: PropTypes.func
}

DetallePedidoModalContainer.defaultProps = {
    idPedidoDetalle: 0,
    handleOnClose: f => f
}

export default connect(mapStateToProps, mapDispatchToProps)(DetallePedidoModalContainer)