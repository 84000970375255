import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import CircularProgress from '@material-ui/core/CircularProgress';
import { formatMoney } from '../Common/decimalHelper'
import { ShoppingCart, MoreVert } from '@material-ui/icons';
import './pedido.css'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';


class ItemPedido extends React.Component {
    
    constructor(props){
        super(props)
        this.state = {
    
            anchorEl:null
        }
    }

    handleOnClickMenu = event => {
        this.setState({anchorEl: event.currentTarget})
    }

    handleOnCloseMenu = () => {
        this.setState({anchorEl:null})
    }

    

    render() {

        const open = Boolean(this.state.anchorEl);

        function formatMoney(n, c, d, t) {
            var c = isNaN(c = Math.abs(c)) ? 2 : c,
              d = d == undefined ? "," : d,
              t = t == undefined ? "." : t,
              s = n < 0 ? "-" : "",
              i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
              j = (j = i.length) > 3 ? j % 3 : 0;
          
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
          };    

        return (
            <div className='itemPedido' style={{ margin: '0px' }} >
                
                <div className='imageContainer' onClick = {() => {this.props.handleOnVer(this.props.item.idPedido)}}>
                    <ShoppingCart className='icon'/>
                </div>
                
                <div className='descriptionContainer' >
                    <Row>
                        <Col xs={12}>
                            <div className ='primary'>{this.props.item.idPedido}</div>
                        </Col>
                    </Row>
                    <Row top="xs" className='noMargin'>
                        <Col xs={8} onClick = {() => {this.props.handleOnVer(this.props.item.idPedido)}}>
                            <div className='secondary'> {this.props.item.fechaRegistro} </div>
                            {this.props.cliente ? <div className='overflow secondary'> {this.props.item.nombreUsuario}</div> : null}                  
                            <div className='secondary'>{`${this.props.item.estadoPedido}`}</div>
                        </Col>
                        <Col xs={4} className='precio'>{'$' + formatMoney(this.props.item.precioFinal) } 
                            <MoreVert  className='options' onClick = {this.handleOnClickMenu} />  
                                <Menu 
                                    anchorEl = {this.state.anchorEl}
                                    open={open}
                                    onClose = {this.handleOnCloseMenu}
                                    >
                                    <MenuItem onClick = {() => {this.props.handleOnVer(this.props.item.idPedido)}}>Ver</MenuItem>
                                </Menu>
                                                    
                        </Col>
                        
                        
                    </Row>
                </div>
            </div>
        )
    }
}

ItemPedido.propTypes = {
    item: PropTypes.object.isRequired,
    cliente: PropTypes.bool.isRequired,
    handleOnVer: PropTypes.func.isRequired
}

ItemPedido.defaultProps = {
    handleOnClick: f => f
}

export default ItemPedido