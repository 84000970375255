import { fromJS } from 'immutable'
import * as ReducerActions from './actions'

const initialState = fromJS({
    data:{
        sucursales: undefined
    }
})

export default function sucursalesReducer(state = initialState, action) {

    if(action.type === ReducerActions.SET_SUCURSALES) {
        return state.setIn(["data", "sucursales"], action.payload)
    }

    return state
}