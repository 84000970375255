export const COLORS = {
  purple: "#702352",
  darkPurple: "#401356",
  green: "#46C082",
  greenHover: "#39A16C",
  grayLigth: "#f3f3f3",
  grayDark: "#555555",
  grayMidle: "#dadada",
  grayHover: "#ECEAEB",
  orange: "#F68523",
  orangeDark: "#cf6a71",
  black: "#30272c",
  white: "#FFFFFF",
};
