import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import LoginForm from './loginForm'
import RestablecerContraseniaForm from './restablecerContraseniaForm'
import OlvidoContraseniaForm from './olvidoContraseniaForm'

import './styles.css'

class Login extends React.Component {

    componentDidMount = () => {
        
        // centro la imagen del login
        this.centerLogoImage()

        // agrego un evento que se lanza al cambiar el tamaño del browser
        window.addEventListener("resize", this.centerLogoImage);
    }

    componentWillUnmount = () => {

        // quito el evento de cambiar tamaño del browser
        window.removeEventListener("resize", this.centerLogoImage);
    }

    centerLogoImage = () => {

        // verifico si la instalación usa una imagen de fondo para el login
        if(this.props.instalacion.fondoLogin === undefined)
            return
        
        // obtengo el contenedor de la imagen del login
        let imageContainer = document.getElementById('fondoLoginContainer')

        // calculo cuándo debo mover la imagen en X y en Y para que quede centrada
        let offsetWidth =  imageContainer.scrollWidth - imageContainer.clientWidth
        if(offsetWidth < 0)
            offsetWidth = 0
        
        let offsetHeight = imageContainer.scrollHeight - imageContainer.clientHeight
        if(offsetHeight < 0)
            offsetHeight = 0

        // centro la imagen
        let image = document.getElementById('fondoLoginImg')
        image.style.transform = `translate(-${offsetWidth/2}px,-${offsetHeight/2}px)`
    }

    render() {
      return (
        <div className='login'>
          <Row style={{margin:'0px'}}>
            <Col xs={12} style={{padding:'0px'}}> 
              <div className={`loginFormContainer ${this.props.instalacion.fondoLogin == null ? 'noImage' : ''}`}>
                <div id='fondoLoginContainer'>
                  {this.props.instalacion.fondoLogin !== undefined &&
                  <img alt="imagen logo Alvear" style={{height: '100%'}} id='fondoLoginImg' src={this.props.instalacion.fondoLogin} />}
                </div>

                {/* FORM LOGIN */}
                {this.props.formToShow === 'login' && 
                <LoginForm
                  logo={this.props.instalacion.logoLogin}
                  loginData={this.props.loginData}
                  errores={this.props.errores}
                  handleOnDataChange={this.props.handleOnDataChange}
                  handleOnContraseniaKeyPress={this.props.handleOnContraseniaKeyPress}
                  handleOnAutenticar={this.props.handleOnAutenticar}
                  handleOnOlvidoContrasenia={this.props.handleOnOlvidoContraseniaClick}
                  errorAutenticacion={this.props.resultadoAutenticacion.error}
                />}

                {/* FORM RESTABLECER CONTRASEÑA */}
                {this.props.formToShow === 'restablecerContrasenia' &&
                <RestablecerContraseniaForm
                  logo={this.props.instalacion.logoLogin}
                  loginData={this.props.loginData} 
                  errores={this.props.errores}
                  handleOnDataChange={this.props.handleOnDataChange}
                  handleOnRestablecerContrasenia={this.props.handleOnRestablecerContrasenia}
                  handleOnCancelar={this.props.handleOnCancelarRestablecerContrasenia}
                  errorAutenticacion={this.props.resultadoAutenticacion.error}
                  limpiarDatos={this.props.limpiarDatos}
                />}

                {/* FORM OLVIDÓ CONTRASEÑA */}
                {this.props.formToShow === 'olvidoContrasenia' && 
                <OlvidoContraseniaForm
                  logo={this.props.instalacion.logoLogin}
                  loginData={this.props.loginData}
                  handleOnDataChange={this.props.handleOnDataChange}
                  errores={this.props.errores}
                  handleOnOlvidoContrasenia={this.props.handleOnOlvidoContrasenia}
                  handleOnCancelar={this.props.handleOnCancelarOlvidoContrasenia}
                  errorAutenticacion={this.props.resultadoAutenticacion.error}
                  claveProvisoriaGenerada={this.props.resultadoAutenticacion.claveProvisoriaGenerada}
                  handleOnConfirmarCódigo={this.props.handleOnConfirmarCódigo}
                />}
              </div>
            </Col>
          </Row>   
        </div>
        )
    }
}

Login.propTypes = {
    instalacion: PropTypes.object.isRequired,
    formToShow: PropTypes.string.isRequired,
    loginData: PropTypes.object.isRequired,
    errores: PropTypes.object.isRequired,
    handleOnDataChange: PropTypes.func.isRequired,
    handleOnContraseniaKeyPress: PropTypes.func.isRequired,
    handleOnAutenticar: PropTypes.func.isRequired,
    handleOnRestablecerContrasenia: PropTypes.func.isRequired,
    handleOnCancelarRestablecerContrasenia: PropTypes.func.isRequired,
    handleOnOlvidoContraseniaClick: PropTypes.func.isRequired,
    handleOnOlvidoContrasenia: PropTypes.func.isRequired,
    handleOnCancelarOlvidoContrasenia: PropTypes.func.isRequired,
    resultadoAutenticacion: PropTypes.object.isRequired,
    handleOnConfirmarCódigo: PropTypes.func.isRequired,
    limpiarDatos: PropTypes.func.isRequired,
}

export default Login