import { fromJS } from 'immutable'
import * as ReducerActions from './actions'

const initialState = fromJS({
    loading: {
        show: false,
        message: ''
    },
    header: {
        show: true,
        showDrawer: true,
        showBackButton: false,
        handleOnBackClick: undefined,
        extraContent: undefined
    },
    footer: {
        show: true,
    },
    timer: {
        reiniciar: false,
        tiempoAgotado: false,
        pedidoCargado: true
    },
    inicializandoUsuario: true,
    permiteUsuarioAnonimo: false,
    showBusqueda: false,
    limpiarFiltro: false,
    vistaFavoritos: false,
})

export default function layoutReducer(state = initialState, action) {
    
    if(action.type === ReducerActions.SAVE_LOADING) {
        return state.set("loading", action.payload)
    }

    if(action.type === ReducerActions.SAVE_HEADER_DEFAULT){
        return state.set("header", initialState.get("header"))
    }

    if(action.type === ReducerActions.SAVE_HEADER_SHOW){
        return state.setIn(["header", "show"], action.payload)
    }

    if(action.type === ReducerActions.SAVE_HEADER){

        let myState = state.toJS()

        return fromJS({
            ...myState,
            header: {
                ...myState.header, 
                ...action.payload
            }
        })
    }

    if(action.type === ReducerActions.SAVE_FOOTER_SHOW){
        return state.setIn(["footer", "show"], action.payload)
    }

    if(action.type === ReducerActions.SET_TIMER_REINICIAR){
        return state.setIn(["timer", "reiniciar"], action.payload)
    }

    if(action.type === ReducerActions.SET_TIMER_BANDERA_PEDIDO){
        return state.setIn(["timer", "pedidoCargado"], action.payload)
    }

    if(action.type === ReducerActions.SET_TIMER_TIEMPO_AGOTADO){
        return state.setIn(["timer", "tiempoAgotado"], action.payload)
    }

    if(action.type === ReducerActions.SAVE_INICIALIZANDO_USUARIO){
        return state.set("inicializandoUsuario", action.payload)
    }

    if(action.type === ReducerActions.SAVE_PERMITE_USUARIO_ANONIMO){
        return state.set("permiteUsuarioAnonimo", action.payload)
    }

    if(action.type === ReducerActions.SET_SHOW_BUSQUEDA){
        return state.set("showBusqueda", action.payload)
    }

    if(action.type === ReducerActions.SET_LIMPIAR_FILTRO){
        return state.set("limpiarFiltro", action.payload)
    }

    if(action.type === ReducerActions.SET_VISTA_FAVORITOS){
        return state.set("vistaFavoritos", action.payload)
    }

    return state
}