import _axios from '../../apiClient'

export function getCalendario(payload) {
  return _axios.post(`/api/Turnero/GetCalendario`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}