import React from 'react'
import { FONT_WEIGHT, PARAGRAPH_SIZE } from './utils'
import PropTypes from 'prop-types';
import './style.css'


const Paragraph = ({ size, weight, children, color }) => (
  <p className={`paragraph ${FONT_WEIGHT[weight]} ${PARAGRAPH_SIZE[size]} ${color}`}>{children}</p>
)

Paragraph.propTypes = {
  size: PropTypes.string.isRequired,
  weight: PropTypes.string.isRequired,
}

export default Paragraph