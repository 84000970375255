import { takeLatest, all, put } from 'redux-saga/effects'
import * as calls from './calls'
import * as reducerActions from '../../reducers/PedidoReducer/actions'
import * as layoutActions from '../../reducers/LayoutReducer/actions'
import * as catalogoActions from '../../reducers/CatalogoReducer/actions'
import * as Notification from '../../components/Common/notification'

function* genGetPedido(e){

    let payload = {}
    payload.callbackFinalizarCompra = e.payload.callbackFinalizarCompra

    try {
        // muestro el loader
        yield put(layoutActions.setLoading({ show: true, message: 'Recuperando pedido' }))
        
        // llamo a la acción que busca el pedido en estado borrador
        let pedido = yield calls.getPedido(e.payload.idUsuario)

        if(pedido === ""){
            pedido = undefined
        }

        if(pedido === undefined && payload.callbackFinalizarCompra !== undefined){
            payload.callbackFinalizarCompra()
        }

        // almaceno el pedido en el reducer
        yield put(reducerActions.setPedidoBorrador(pedido))
    }
    catch (error) 
    {
        // muestro el mensaje de error
        //Notification.showError('Error al recuperar el pedido') //TODO: descomentar notificacion
        if(payload.callbackFinalizarCompra !== undefined){
            payload.callbackFinalizarCompra()
        }

        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
    finally 
    {
        // oculto el loader
        yield put(layoutActions.setLoading({ show: false }))
    }
}

function* genAddPedidoDetalle(e){
    try {
        // muestro el loader
        yield put(layoutActions.setLoading({ show: true, message: 'Agregando detalle de pedido' }))
        
        // llamo a la acción que crea el pedido borrador
        let nuevoPedido = yield calls.addPedidoDetalle(e.payload)
        //llamo al callback para mostrar los mensajes de informacion
        e.payload.callbackFunctionAfter(nuevoPedido)

        let id
        nuevoPedido.pedidoDetalles.forEach(pd => {
            if(pd.idArticulo === e.payload.idArticulo) {
                id = pd.idPedidoDetalle
            }
        })
        // almaceno el pedido nuevo en el reducer
        yield put(reducerActions.setPedidoBorrador(nuevoPedido))

        
        if(window.location.hash === "#/FinalizarCompra"){
            yield put(reducerActions.setShowResumenPedido(false))
        }
        else{
            yield put(reducerActions.setShowResumenPedido(true))
        }
        yield put(reducerActions.setIdPedidoDetalle(id))

        
    }
    catch (error) 
    {
        // muestro el mensaje de error
        Notification.showInfo(error.error)
        console.log(error);
        e.payload.callbackFunctionError()

        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
    finally 
    {
        // oculto el loader
        yield put(layoutActions.setLoading({ show: false }))
    }
}

function* genRemovePedidoDetalle(e){
    try {
        //armo el payload nuevo, sin el idArticulo que no es necesario
        let payload = {}
        payload.idPedido = e.payload.idPedido
        payload.idUsuario = e.payload.idUsuario
        payload.idPedidoDetalle = e.payload.idPedidoDetalle

        // muestro el loader
        yield put(layoutActions.setLoading({ show: true, message: 'Quitando detalle de pedido' }))
        
        // llamo a la acción que elimina el pedidoDetalle
        let pedidoActualizado = yield calls.removePedidoDetalle(payload)
        
        // almaceno el pedido nuevo en el reducer
        yield put(reducerActions.setPedidoBorrador(pedidoActualizado))
    }
    catch (error) 
    {
        // muestro el mensaje de error
        Notification.showError('Error al quitar detalle de pedido')

        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
    finally 
    {
        // oculto el loader
        yield put(layoutActions.setLoading({ show: false }))
    }
}

function* genUpdateEstadoPedidoAbandonado(e){
    try {
        // muestro el loader
        yield put(layoutActions.setLoading({ show: true, message: 'Cancelando pedido' }))

        // llamo a la acción que crea el pedido borrador
        yield calls.updateEstadoPedidoAbandonado(e.payload)

        // almaceno el pedido nuevo en el reducer
        //yield put(reducerActions.setPedido(undefined))
    }
    catch (error) 
    {
        // muestro el mensaje de error
        Notification.showError('Error al cancelar el pedido')

        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
    finally 
    {
        // oculto el loader
        yield put(layoutActions.setLoading({ show: false }))
    }
}

function* genGetPedidosByUser(e){
    try {
        
        // llamo a la acción que busca el pedido en estado borrador
        let pedidos = yield calls.getPedidosByUser(e.payload)

        // almaceno el pedido en el reducer
        yield put(reducerActions.sagaSetPedidosUser(pedidos))
    }
    catch (error) 
    {
        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
    
}

function* genGetPedidosByCliente(e){
    try {
        
        // llamo a la acción que busca el pedido en estado borrador
        let pedidos = yield calls.getPedidosByCliente(e.payload)

        // almaceno el pedido en el reducer
        yield put(reducerActions.sagaSetPedidosCliente(pedidos))
    }
    catch (error) 
    {
        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
    
}

function* genGetPedidosBorradorByCliente(e){
    try {
        
        // almaceno el pedido en el reducer
        yield put(reducerActions.sagaSetPedidosBorradorCliente(undefined))

        // llamo a la acción que busca el pedido en estado borrador
        let pedidos = yield calls.getPedidosBorradorByCliente(e.payload)

        // almaceno el pedido en el reducer
        yield put(reducerActions.sagaSetPedidosBorradorCliente(pedidos))
    }
    catch (error) 
    {
        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
    
}

function* genActualizarFechaPeidoBorrador(e){
    try {
        // llamo a la acción que actualiza la fecha
        yield calls.actualizarFechaPedidoBorrador(e.payload)
    }
    catch (error) 
    {
        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
    
}

function* genConfirmarEliminarPedido(e) {
    try {
        let payload = {}
        payload.callback = e.payload.callback
        e.payload.callback = undefined
        // muestro el loader
        let mensajeLoader = `${e.payload.IdEstadoPedido === 2 ? 'Confirmando' : 'Borrando'} pedido`
        
        yield put(layoutActions.setLoading({ show: true, message: mensajeLoader }))
    
        // llamo a la acción que actualiza el estado del pedido
        yield calls.ConfirmarEliminarPedido(e.payload)
        
        // muestro el mensaje de éxito para el borrado
        if(e.payload.IdEstadoPedido !== 2){
            let mensajeExito = `El pedido fue borrado`
            Notification.showSuccess(mensajeExito)
        }
        
        if(payload.callback){
            payload.callback()
        }
    
        yield put(reducerActions.setPedidoBorrador(undefined))
        yield put(reducerActions.setShowResumenPedido(false))
    }
    catch (error) {
        // muestro el mensaje de error
        Notification.showError(error.error)
        
        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
    finally {
        // oculto el loader
        yield put(layoutActions.setLoading({ show: false }))
    }
}

function* genSendEmailConfirmado(e) {
    try {
        // llamo a la acción que actualiza el estado del pedido
        yield calls.sendEmailConfirmado(e.payload)
        
    }
    catch (error) {
        // muestro el mensaje de error
        Notification.showError(error.error)
        
        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
    finally {
        // oculto el loader
        yield put(layoutActions.setLoading({ show: false }))
    }
  }

  
function* genSetPedidoDetalleReemplazo(e){
    try {
        // muestro el loader
        yield put(layoutActions.setLoading({ show: true, message: "Actualizando detalle" }))

        // llamo a la acción que busca el pedido en estado borrador
        let resultado = yield calls.setPedidoDetalleReemplazo(e.payload)

        yield put(reducerActions.setPedidoDetalleReemplazo(e.payload))

        if(e.payload.callback){
            e.payload.callback()
        }
    }
    catch (error) 
    {
        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
    finally {
        // oculto el loader
        yield put(layoutActions.setLoading({ show: false }))
    }
    
}

function* genSetPedidoPagoMP(e){
    let callbackError = e.payload.callbackError
    try {
        // muestro el loader
        yield put(layoutActions.setLoading({ show: true, message: "Procesando Pago..." }))

        // llamo a la acción que busca el pedido en estado borrador
        let resultado = yield calls.setPedidoPagoMP(e.payload)

        if (resultado.exitoso==false)
        {
            Notification.showError('Error al procesar el pago: ' + resultado.estado)
            if(e.payload.callbackError){
                e.payload.callbackError()
            }
        }
        
        if(e.payload.callback){
            e.payload.callback(resultado)
        }

        yield put(layoutActions.setLoading({ show: false }))
    }
    catch (error) 
    {
        if (error.Message) {
            console.log(error.Message)
            return;
        }
        if(callbackError){
            callbackError()
        }
        yield put(layoutActions.setLoading({ show: false }))
    }
    
}

function* genGenerarCheckout(e){
    
    try {
        // muestro el loader
        yield put(layoutActions.setLoading({ show: true, message: "Generando checkout..." }))

        // llamo a la acción que busca el pedido en estado borrador
        let resultado = yield calls.generarCheckout(e.payload)
        
        if(e.payload.callback){
            e.payload.callback(resultado)
        }

        yield put(layoutActions.setLoading({ show: false }))
    }
    catch (error) 
    {
        if (error.Message) {
            console.log(error.Message)
            return;
        }
        
        if(e.payload.callbackError){
            e.payload.callbackError()
        }

        yield put(layoutActions.setLoading({ show: false }))
    }
}

function* genConfirmarPedidoMobbex(e) {
    try {
        // muestro el loader
        let mensajeLoader = `Confirmando pedido`
        
        yield put(layoutActions.setLoading({ show: true, message: mensajeLoader }))
    
        // llamo a la acción que actualiza el estado del pedido
        yield calls.ConfirmarPedidoMobbex(e.payload)
        
        if(e.payload.callback){
            e.payload.callback()
        }
    
        yield put(reducerActions.setPedidoBorrador(undefined))
        yield put(reducerActions.setShowResumenPedido(false))
    }
    catch (error) {
        // muestro el mensaje de error
        Notification.showError(error.error)
        
        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
    finally {
        // oculto el loader
        yield put(layoutActions.setLoading({ show: false }))
    }
}

function* genGetPreferenciasUltimoPedido(e){

    try {
        // llamo a la acción que busca las preferencias del ultimo pedido
        let preferencias = yield calls.getPreferenciasUltimoPedido(e.payload)

        if (e.payload.callback) e.payload.callback(preferencias);
    }
    catch (error) 
    {
        if (error.Message) {
            console.log(error.Message)
            return;
        }
    }
}


function* defaultSaga() {
    yield all([
      takeLatest(reducerActions.SAGA_FETCH_PEDIDO, genGetPedido),
      takeLatest(reducerActions.SAGA_ADD_PEDIDO_DETALLE, genAddPedidoDetalle),
      takeLatest(reducerActions.SAGA_REMOVE_PEDIDO_DETALLE, genRemovePedidoDetalle),
      takeLatest(reducerActions.SAGA_UPDATE_ESTADOPEDIDO_ABANDONADO, genUpdateEstadoPedidoAbandonado),
      takeLatest(reducerActions.GET_PEDIDOSUSER, genGetPedidosByUser),
      takeLatest(reducerActions.GET_PEDIDOSCLIENTE, genGetPedidosByCliente),
      takeLatest(reducerActions.GET_PEDIDOS_BORRADOR_CLIENTE, genGetPedidosBorradorByCliente),
      takeLatest(reducerActions.ACTUALIZAR_FECHA_PEDIDO_BORRADOR, genActualizarFechaPeidoBorrador),
      takeLatest(reducerActions.SAGA_CONFIRMAR_ELIMINAR_PEDIDO, genConfirmarEliminarPedido),
      takeLatest(reducerActions.SAGA_ENVIAR_MAIL_CONFIRMACION, genSendEmailConfirmado),
      takeLatest(reducerActions.SAGA_SET_PEDIDODETALLE_REEMPLAZO, genSetPedidoDetalleReemplazo),
      takeLatest(reducerActions.SAGA_SET_PEDIDO_PAGO_MP,genSetPedidoPagoMP),
      takeLatest(reducerActions.SAGA_GENERAR_CHECKOUT,genGenerarCheckout),
      takeLatest(reducerActions.SAGA_CONFIRMAR_PEDIDO_MOBBEX, genConfirmarPedidoMobbex),
      takeLatest(reducerActions.SAGA_FETCH_PREFERENCIAS_ULTIMO_PEDIDO, genGetPreferenciasUltimoPedido),
    ])
}
  
export const sagas = [
    defaultSaga
]