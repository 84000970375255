import { fromJS } from 'immutable'
import * as ReducerActions from './actions'

const initialState = fromJS({
  data: {
    pedido: undefined
  },
  redirigir: false
})

export default function confirmarPedidoReducer(state = initialState, action) {

  if (action.type === ReducerActions.INITIALIZE_FORM)
    return initialState

  if (action.type === ReducerActions.SAVE_PEDIDO) {
    return state.setIn(["data", "pedido"], action.payload)
  }

  if (action.type === ReducerActions.SAVE_REDIRIGIR) {
    return state.set("redirigir", action.payload)
  }

  return state
}