import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as Notification from '../../components/Common/notification';
import { loadingSelector, headerSelector, footerSelector, inicializandoUsuarioSelector, 
  permiteUsuarioAnonimoSelector, timerSelector, showBusquedaSelector,limpiarFiltroSelector } from '../../selectors/layoutSelector'
import { instalacionSelector, usuarioSelector, resultadoAutenticacionSelector, preguntasFrecuentesSelector } from '../../selectors/sessionSelector'
import { pedidoDataSelector, showResumenPedidoSelector } from '../../selectors/pedidoSelector'
import { catalogoDataSelector } from '../../selectors/catalogoSelector'
import * as layoutActions from '../../reducers/LayoutReducer/actions'
import * as sessionActions from '../../reducers/SessionReducer/actions'
import * as pedidoActions from '../../reducers/PedidoReducer/actions'
import * as catalogoActions from '../../reducers/CatalogoReducer/actions' 
import * as confirmarPedidoActions from '../../reducers/ConfirmarPedidoReducer/actions'
import * as detallePedidoActions from '../../reducers/DetallePedidoReducer/actions'
import * as sucursalesActions from '../../reducers/SucursalesReducer/actions' 
import Layout from '../../components/Layout'
import ControlActividadModalContainer from '../../containers/ControlActividadModal'
import { sucursalesSelector } from '../../selectors/sucursalesSelector'

import CircularProgress from '@material-ui/core/CircularProgress'
import './layout.css'

class LayoutContainer extends React.Component {

  constructor(props) {
    super(props)
      
    this.state = {
      drawerOpen: false,
      pedidoCargado: false,
      cargandoInstalacion: true,
      cargandoUsuario: true,
      tiempoAgotado: false,
      seconds: 0,
      filterArticulos: undefined,
      openResumenPedido: false,
      openNotificaciones: false,
      openCalendario:false,
      pageNotificaciones: 1,
      filtroLimpio: false,
      shouldShow: null,
      openModalIdentificate: false
    }
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);

    //para whatsapp
    this.lastScroll = null;

    this.handleScroll = this.handleScroll.bind(this);
    
    // verifico si la instalación está identificada
    if (this.props.instalacion === undefined) {
      // busco la configuración de la instalación
      let url = encodeURIComponent(window.location.href)
      this.props.sagaFetchInstalacion(url)
    }

    // busco el usuario logeado
    this.props.sagaFetchUsuarioLogeado();
  }

  componentDidMount() {
    
    window.addEventListener('scroll', this.handleScroll, { passive: true });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(evt) {
    const lastScroll = window.scrollY;

    if (lastScroll === this.lastScroll) {
      return;
    }

    const shouldShow = (this.lastScroll !== null) ? (lastScroll < this.lastScroll) : null;

    if (shouldShow !== this.state.shouldShow) {
      this.setState((prevState, props) => ({
        ...prevState,
        shouldShow,
      }));
    }

    this.lastScroll = lastScroll;
  }

  componentDidUpdate = () => {
    if (this.state.cargandoInstalacion === true && this.props.instalacion !== undefined) {
      // compruebo si se cargo la instalacion y seteo el parametro de tiempo de actividad del usuario
      this.setState({
        cargandoInstalacion: false,
        seconds: this.props.instalacion.tiempoActividadDelUsuario
      }, () =>{
        this.props.sagaGetPreguntasFrecuentes({nombre: "Preguntas frecuentes"})
      })
    }

    //cargo todas las sucursales
    if (this.props.sucursales === undefined && this.state.cargandoInstalacion === true && this.props.instalacion !== undefined) {
      this.props.sagaFetchSucusales({idInstalacion: this.props.instalacion.id});
    }

    if (this.state.cargandoUsuario === true && this.props.usuario !== undefined) {
      // compruebo si hay usuario loggueado y busco pedido en borrador si es que tiene uno
      if (this.props.resultadoAutenticacion.autenticado === true) {
        this.setState({ cargandoUsuario: false })
        this.props.sagaFetchPedidoBorrador({idUsuario: this.props.usuario.id})
      }
    }
    if (this.state.pedidoCargado === false && this.props.dataPedido.pedido !== undefined) {
      // compruebo si existe pedido borrador e inicializo timer de control de actividad
      this.setState({ pedidoCargado: true }, this.startTimer)
    }

    if(this.state.filtroLimpio === false && this.props.limpiarFiltro === true && this.state.filterArticulos !== undefined && this.state.filterArticulos !== ""){
      this.setState({filterArticulos: undefined, filtroLimpio: true}, () => {
        this.props.setLimpiarFiltro(false)
        this.props.setCatalogoSeleccionado(undefined)
        this.props.setFilterBusqueda(undefined)
        this.props.setSubrubroSeleccionado({nombre: undefined, id: undefined, esRubro: false})
      })
    }

    if (this.props.timer.pedidoCargado === false) {
      // el usuario confirmo pedido
      this.setState({ pedidoCargado: false })
      this.props.setPedidoBorrador(undefined)
      this.props.setTiempoAgotado(false)
      this.props.setBanderaPedido(true)
    }
    if (this.props.timer.reiniciar === true) {
      clearInterval(this.timer);
      this.timer = 0

      this.setState({
        tiempoAgotado: false,
        seconds: this.props.instalacion.tiempoActividadDelUsuario,
      }, this.startTimer)

      this.props.setTiempoAgotado(false)
      this.props.setTimerReiniciar(false)
    }
    if (this.props.dataPedido.pedido === undefined && this.state.pedidoCargado === true) {
      clearInterval(this.timer);
      this.timer = 0

      this.setState({
        pedidoCargado: false,
        seconds: this.props.instalacion.tiempoActividadDelUsuario,
      })
    }
  }

  startTimer() {
    //inicializo el timer
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.timer);
      this.timer = 0
      //seteo tiempoAgotado en el reductor para filtrar en caso de estar en /confirmarPedido
      this.props.setTiempoAgotado(true)
      //seteo tiempoAgotado en true para mostrar el modal de control de actividad
      this.setState({
        seconds: seconds,
        tiempoAgotado: true
      });
    }
    else {
      this.setState({
        seconds: seconds,
      });
    }
  }

  handleDrawerOpen = () => {
    this.setState({ drawerOpen: true });
  }

  handleDrawerClose = (limpiarBusqueda) => {
    let filterArticulos = this.state.filterArticulos
    if(limpiarBusqueda){
      filterArticulos= undefined
      this.props.setFilterBusqueda(undefined)
      this.props.setSubrubroSeleccionado({nombre: undefined, id: undefined, esRubro: false})
    }
    this.setState({ drawerOpen: false, filterArticulos:filterArticulos });
  }

  handleOnControlActividad = (accion) => {
    switch (accion) {
      case 0: {
        //CASO: TODAVIA ESTOY PIDIENDO!

        //cierro modal y pongo timer en 0
        clearInterval(this.timer);
        this.timer = 0
        //seteo tiempoAgotado en el reductor para filtrar en caso de estar en /confirmarPedido
        this.props.setTiempoAgotado(false)
        //actualizo la fecha del pedido
        this.props.actualizarFechaPedidoBorrador(this.props.dataPedido.pedido.id)
        //reinicio timer
        this.setState({
          seconds: this.props.instalacion.tiempoActividadDelUsuario,
          tiempoAgotado: false,
        }, this.startTimer)
        break
      }
      case 1: {
        //CASO: YA TERMINE, CONFIRMAR!

        //pongo timer en 0
        clearInterval(this.timer);
        this.timer = 0
        this.setState({
          tiempoAgotado: false,
          seconds: this.props.instalacion.tiempoActividadDelUsuario,
        })
        //Redirigir a confirmar pedido
        window.location.hash = `/ConfirmarPedido/${this.props.dataPedido.pedido.id}`
        break
      }
      case 3: {
        //CASO: CANCELAR PEDIDO AHORA ---> ENTENDIDO
        //en esta instancia el pedido ya fue pasado a estado abandonado

        //seteo timer en 0
        clearInterval(this.timer);
        this.timer = 0
        //seteo tiempoAgotado en el reductor para filtrar en caso de estar en /confirmarPedido
        this.props.setTiempoAgotado(false)
        //Pongo el pedido como undefined
        this.props.setPedidoBorrador(undefined)
        //Reinicio estado como al iniciar sin un pedido en borrador
        this.setState({
          tiempoAgotado: false,
          pedidoCargado: false,
          seconds: this.props.instalacion.tiempoActividadDelUsuario,
        })
        //Si no estoy en Catalogo, redirigir a /Catalogo
        if (window.location.hash === '#/Catalogo') {
          window.location.reload()
        }
        else {
          window.location.hash = `/Catalogo`
        }
        break
      }
      default: {
        break
      }
    }
  }

  handleOnCerrarSesion = () => {
    
    //Si no tiene un pedido en borrador cierro session y redirecciono al Login, si no pregunto si quiere seguir con el pedido
    if (this.props.dataPedido.pedido === undefined || this.props.instalacion.tiempoActividadDelUsuario === 0) {
      //cierro drawer
      this.setState({
        drawerOpen: false,
        cargandoUsuario: true
      });
      //cierro session
      this.props.sagaLogOutUsuario()

      //Limpio los reductores
      //catalogo
      this.props.initializeFormCatalogo()

      //pedido
      this.props.initializeFormPedido()

      //session
      this.props.initializeFormSession()

      //confirmarPedido
      this.props.initializeFormConfirmarPedido()

      //detallePedido
      this.props.initializeFormDetallePedido()

      //Redirecciono al login
      window.location.hash = `/Login`
    }
    else {
      //cierro drawer y abro modal de control de actividad
      this.setState({
        drawerOpen: false,
        tiempoAgotado: true
      });
    }
  }

  handleOnClickLogo = () => {
    window.location.hash = `/`
    this.props.setHeader({
      showDrawer: true,
      showBackButton: false,
    })
    this.setState({
      drawerOpen: false,
      filterArticulos: undefined
    });
    //seteo vistaFavoritos en false
    this.props.setVistaFavoritos(false)
    this.props.setFilterBusqueda(undefined)
    this.props.setSubrubroSeleccionado({nombre: undefined, id: undefined, esRubro: false})
    this.props.setOfertaSeleccionada(undefined)
  }

  handleOnChangeFilter = (event, idSubrubro, esRubro, oferta) => {
    
    let value = event.target.value
    this.props.setLimpiarFiltro(false)
    
    if(idSubrubro !== undefined){
      this.setState({filterArticulos: value, filtroLimpio: false}, () => {
        let payload = {
          nombre: value,
          id: idSubrubro,
          esRubro: esRubro
        }
        //seteo vistaFavoritos en false
        this.props.setVistaFavoritos(false)

        this.props.setFilterBusqueda(undefined)
        this.props.setSubrubroSeleccionado(payload)
        this.props.setOfertaSeleccionada(undefined)

        window.location.hash = "/"
      })
    }
    else if (oferta) {
      this.setState({ filterArticulos: value, filtroLimpio: false });
      
      //seteo vistaFavoritos en false
      this.props.setVistaFavoritos(false)

      this.props.setFilterBusqueda(undefined)
      this.props.setOfertaSeleccionada(oferta)
      this.props.setSubrubroSeleccionado({ nombre: undefined, id: undefined, esRubro: false })

      window.location.hash = "/"
    }
    else{
      this.setState({filterArticulos: value, filtroLimpio: false}, () => {
      
        setTimeout(() => {
          if (value.length > 2 && value === this.state.filterArticulos) {
            //seteo vistaFavoritos en false
            this.props.setVistaFavoritos(false)
            // Aqui se evita llamar mas de unas vez a la API dentro de los 300ms
            this.props.setFilterBusqueda(value)
            this.props.setSubrubroSeleccionado({nombre: undefined, id: undefined, esRubro: false})

            window.location.hash = "/"
          }
        }, 500)
  
      })
    }
  }

  handleOnClearBusqueda = () => {
    this.setState({filterArticulos: undefined}, () => {
      this.props.setVistaFavoritos(false)
      this.props.setFilterBusqueda(undefined)
      this.props.setSubrubroSeleccionado({nombre: undefined, id: undefined, esRubro: false})
      this.props.setOfertaSeleccionada(undefined)
    })
  }

  handleOnClickIniciarSesion = () => {
    this.setState({drawerOpen: false}, () => {
      window.location.hash = '/Login'
    });
  }

  /* Funciones sobre pedidos */
  handleOnVaciarCartito = () => {
    this.props.setOpenModalVaciarCarrito(true)
  }

  handleOnClickVerCarrito = () => {
    let value = !this.state.openResumenPedido

    this.setState({openResumenPedido: value})
  }

  handleAgregarQuitarUnidad = (cantidad, articulo, alternativa) => {

    if(this.props.usuario === undefined){
      window.location.hash = '/Login'
      return
    }

    if(this.props.usuario === undefined){
      return
    }
    
    if(cantidad === 0){
      //si existe un pedido hago la llamada, si no no
      if(this.props.dataPedido && this.props.dataPedido.pedido && this.props.dataPedido.pedido.listaPedidoDetalle){
        let pedidoDetalle = this.props.dataPedido.pedido.listaPedidoDetalle.filter(x => x.idArticulo === articulo.idArticulo)
        
        //si ya existe un pedido detalle hago la llamada si no no
        if(pedidoDetalle.length > 0){
          let payload = {
            idPedido: this.props.dataPedido.pedido.id,
            idUsuario: this.props.usuario.id,
            idPedidoDetalle: pedidoDetalle[0].idPedidoDetalle
          }

          this.props.sagaRemovePedidoDetalle(payload)
        }
        else{
          return
        }
      }
      else return
    }
    else{
      //armo el model agregar el PedidoDetalle
      let addPedidoModel = {}
      addPedidoModel.idInstalacion = this.props.instalacion.id
      addPedidoModel.idCliente = this.props.usuario.idCliente
      addPedidoModel.usuarioRegistro = this.props.usuario.id
      addPedidoModel.Items = [{alternativa, cantidad, idArticulo: articulo.idArticulo, idCatalogoEntrada: articulo.idCatalogoEntrada}]
      addPedidoModel.callbackFunctionAfter = () => { 
        Notification.showSuccess(`Se ${cantidad > 1 ? 'agregaron' : 'agregó'} a su pedido ${cantidad} ${cantidad > 1 ? 'unidedades' : 'unidad'} de ${articulo.nombre}.`)
      }

        addPedidoModel.callbackFunctionError = () => {
        }

      if (this.props.dataPedido.pedido !== undefined) {
        this.props.setTimerReiniciar(true)
      }

      //agrego el pedidoDetalle
      this.props.sagaAddPedidoDetalle(addPedidoModel)
    }
    
  }

  handleOnClickBasesCondiciones = () => {

      if(this.props.instalacion === undefined || !this.props.instalacion.basesYCondiciones){
        Notification.showInfo("Intente nuevamente")
        return
      }
      
      //descargo el pdf de bases y condiciones      
      fetch(this.props.instalacion.basesYCondiciones).then(function(t) {
        return t.blob().then((b)=>{
            var a = document.createElement("a");
            a.href = URL.createObjectURL(b);
            a.setAttribute("download", "Bases y Condiciones");
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a)
        }
        );
      });

      //oculto menú
      this.setState({ drawerOpen: false });
  }

  /* fin funciones sobre pedidos */

  handleOnCloseNotificaciones = () => {
    this.setState({openNotificaciones: false})
  }
  handleOnCloseCalendario = () => {
    this.setState({openCalendario: false})
  }

  handleOnClickOpenNotidicaciones = () => {
    this.setState({openNotificaciones: true}, () => {
      if(this.props.usuario.notificacionesNoVistas > 0){
        this.props.sagaMarcarNotificacionesVistas(this.props.usuario.id)
      }
    })
  }
  handleOnClickOpenCalendario = () => {
    this.setState({openCalendario:true})
  }
  handleOnClickNotificaciones = (idNotificacion) => {
    if(!idNotificacion){
      return
    }
    
    this.props.sagaMarcarNotificacionVista(idNotificacion)
  }

  handleOnClickVerMas = () => {
    if(this.props.usuario === undefined){
      return
    }

    let payload = {
      idCliente: this.props.usuario.idCliente,
      page: this.state.pageNotificaciones + 1
    }

    payload.callback = () => {
      let page = this.state.pageNotificaciones + 1
      this.setState({pageNotificaciones: page})
    }

    this.props.sagaBuscarMasNotificaciones(payload)
  }

  handleOnClickWhatsApp = () => {
    if(this.props.instalacion === undefined || !this.props.instalacion.datosContacto ||
      !this.props.instalacion.datosContacto.telefono){
      return
    }

    window.open(`https://wa.me/`+ this.props.instalacion.datosContacto.telefono.replace(/\s/g, '').replace('-', '').replace('+549', "54"))
  }

  handleOnClickFavoritos = () => {

    if(this.props.usuario === undefined || this.props.usuario.id === undefined){
      this.setState({openModalIdentificate: true})
      return
    }

    let filter = "FAVORITOS"
    this.props.setVistaFavoritos(true)
    this.props.setFilterBusqueda(filter)
    
    this.setState({filterArticulos: filter, filtroLimpio: false}, () => {
      
      this.props.setSubrubroSeleccionado({nombre: undefined, id: undefined, esRubro: false})
    })
  }

  handleOnClickIdentificate = () => {
    this.setState({openModalIdentificate: false}, () => {
      window.location.hash = '/Login'    
    })
  }

  render() {

    let showLayout = this.props.resultadoAutenticacion.autenticado === true || this.props.permiteUsuarioAnonimo === true

    return (
      <div>
        {showLayout === false &&
          <div class="outer">
            <div class="middle">
              <div class="inner">
                <CircularProgress />
                <div className='loaderDescripcion'>Cargando aplicación...</div>
              </div>
            </div>
          </div>}

        <div style={{ visibility: showLayout ? 'visible' : 'hidden' }}>
          {this.props.inicializandoUsuario === false &&
            <div>
              <Layout
                drawerOpen={this.state.drawerOpen}
                handleDrawerOpen={this.handleDrawerOpen}
                handleDrawerClose={this.handleDrawerClose}
                loading={this.props.loading}
                instalacion={this.props.instalacion}
                header={this.props.header}
                footer={this.props.footer}
                handleOnCerrarSesion={this.handleOnCerrarSesion}
                handleOnClickLogo={this.handleOnClickLogo}
                preguntasFrecuentes={this.props.preguntasFrecuentes}
                pedido={this.props.dataPedido.pedido}
                usuario={this.props.usuario}
                showResumenPedido={this.props.showResumenPedido}
                filterArticulos={this.state.filterArticulos}
                handleOnChangeFilter={this.handleOnChangeFilter}
                handleOnClearBusqueda={this.handleOnClearBusqueda}
                handleOnClickIniciarSesion={this.handleOnClickIniciarSesion}
                handleOnClickCheckout={this.handleOnClickCheckout}
                handleOnClickVerCarrito={this.handleOnClickVerCarrito}
                openResumenPedido={this.state.openResumenPedido}
                showBusqueda={this.props.showFieldBusqueda}
                handleAgregarQuitarUnidad={this.handleAgregarQuitarUnidad}
                handleOnClickBasesCondiciones={this.handleOnClickBasesCondiciones}
                handleOnVaciarCartito={this.handleOnVaciarCartito}
                handleOnCloseCalendario={this.handleOnCloseCalendario}
                handleOnClickWhatsApp={this.handleOnClickWhatsApp}
                shouldShow={this.state.shouldShow}
                openCalendario={this.state.openCalendario}
                openNotificaciones={this.state.openNotificaciones}
                handleOnClickOpenNotidicaciones={this.handleOnClickOpenNotidicaciones}
                handleOnCloseNotificaciones={this.handleOnCloseNotificaciones}
                handleOnClickNotificaciones={this.handleOnClickNotificaciones}
                handleOnClickVerMas={this.handleOnClickVerMas}
                handleOnClickOpenCalendario={this.handleOnClickOpenCalendario}
                handleOnClickFavoritos={this.handleOnClickFavoritos}
                handleOnClickIdentificate={this.handleOnClickIdentificate}
                openModalIdentificate={this.state.openModalIdentificate}
              />

              {this.props.instalacion && this.props.instalacion.tiempoActividadDelUsuario > 0 && this.state.tiempoAgotado && this.props.dataPedido.pedido !== undefined &&
                <ControlActividadModalContainer
                  handleOnClose={this.handleOnControlActividad}
                />
              }
            </div>}
        </div>
      </div>)
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...catalogoActions,
    ...layoutActions,
    ...pedidoActions,
    ...sessionActions,
    ...confirmarPedidoActions,
    ...detallePedidoActions,
    ...sucursalesActions
  }, dispatch)
}

function mapStateToProps(state) {
  return {
    instalacion: instalacionSelector(state),
    usuario: usuarioSelector(state),
    resultadoAutenticacion: resultadoAutenticacionSelector(state),
    inicializandoUsuario: inicializandoUsuarioSelector(state),
    permiteUsuarioAnonimo: permiteUsuarioAnonimoSelector(state),
    loading: loadingSelector(state),
    header: headerSelector(state),
    footer: footerSelector(state),
    timer: timerSelector(state),
    dataPedido: pedidoDataSelector(state),
    dataTimer: catalogoDataSelector(state),
    showResumenPedido: showResumenPedidoSelector(state),
    showFieldBusqueda: showBusquedaSelector(state),
    limpiarFiltro: limpiarFiltroSelector(state),
    preguntasFrecuentes: preguntasFrecuentesSelector(state),
    sucursales: sucursalesSelector(state)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutContainer)