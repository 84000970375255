import { createSelector } from 'reselect'

const confirmarCorreoReducerDataSelector = state => state.confirmarCorreoReducer.getIn(["data"]).toJS()
export const confirmarCorreoDataSelector = createSelector(
    confirmarCorreoReducerDataSelector, (dataField) => {      
            
    // creo el objeto usuario
    let myUsuario = undefined
    if(dataField.usuario){
        myUsuario = {}

        myUsuario.id = dataField.usuario.id
        myUsuario.nombreUsuario = dataField.usuario.nombreUsuario
        myUsuario.email = dataField.usuario.email
        myUsuario.confirmado = dataField.usuario.confirmado
        myUsuario.habilitado = dataField.usuario.habilitado
        myUsuario.token = dataField.usuario.tokenRestablecimientoContrasenia
    }

    return {
        usuario: myUsuario
    }
})