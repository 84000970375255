import React, { useState } from "react";
import { InputBase } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { withStyles } from "@material-ui/core/styles";
import custominput from "./customInput.css";

const styles = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "330px",
    height: "50px",
    fontFamily: "Poppins",
    backgroundColor: "white",
    borderRadius: "8px",
    border: "1px solid #DADADA",
    "&:focus-within": {
      border: "1px solid #702352",
      boxShadow: "0px 0px 3px 0px #702352",
    },
  },
  input: {
    flex: 1,
    backgroundColor: "transparent",
    color: "#555555",
  },
  inputError: {
    borderColor: "#F85039",
  },
});

const CustomInput = ({
  classes,
  placeholder = "",
  disabled = false,
  icon = null,
  name = null,
  value = null,
  error = null,
  onChange,
  type = null,
  onKeyPress = null,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const asciiRegex = /^[ -~]+$/;
    const hasError =
      !asciiRegex.test(inputValue) || (error !== null && error !== undefined);
    setInputValue(inputValue);
    onChange(event, hasError);
  };

  return (
    <div className={`${classes.root}${error ? classes.inputError : ""} `}>
      <InputBase
        fullWidth
        value={value || inputValue}
        onChange={handleInputChange}
        className={`inputStyle ${classes.input} `}
        endAdornment={
          icon ? (
            <IconButton className={`${disabled ? "disabledIconButton" : null}`}>
              {icon}
            </IconButton>
          ) : null
        }
        disabled={disabled}
        placeholder={placeholder}
        error={error}
        name={name}
        type={type === "password" ? "password" : null}
        onKeyPress={onKeyPress}
      />
    </div>
  );
};

export default withStyles(styles)(CustomInput);
