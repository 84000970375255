import { createSelector } from 'reselect'

const calendarioReducerSelector = state => state.calendarioReducer.getIn(["data"]).toJS()

export const calendarioPickupSelector = createSelector(

    calendarioReducerSelector, (dataField) => { 
        
    return dataField.calendarioPickup
  }
)

export const calendarioDeliverySelector = createSelector(
    calendarioReducerSelector, (dataField) => {    
    return dataField.calendarioDelivery
  }
)