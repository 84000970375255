import React from 'react'
import { Route } from 'react-router-dom'

import TestContainer from '../containers/Test'
import LoginContainer from '../containers/Login'
import CatalogoContainer from '../containers/Catalogo'
import ConfirmarPedidoContainer from '../containers/ConfirmarPedido'
import ContenidoCatalogoContainer from '../containers/ContenidoCatalogo'
import RegistrarUsuarioContainer from '../containers/RegistrarUsuario'
import ControlActividadModalContainer from '../containers/ControlActividadModal'
import ConfirmarCorreoContainer from '../containers/ConfirmarCorreo'
import PedidoContainer from '../containers/Pedido'
import FinalizarCompraContainer from '../containers/FinalizarCompraContainer'
import ProveedoresContainer from '../containers/ProveedoresContainer'

export const routes = () => {
    return (
        <div>
            <Route path="/" exact component={CatalogoContainer} />
            <Route path="/Login/:idUsuario?/:token?/:idUsuarioToken?" component={LoginContainer} />
            <Route path="/Catalogo" component={CatalogoContainer} />
            {/*<Route path="/ConfirmarPedido/:idPedido" component={ConfirmarPedidoContainer} />*/}
            {/*<Route path="/Pedido/todos/:idUsuario" render={(x)=>{return <PedidoContainer {...x} todos></PedidoContainer>}} />*/}
            {/*<Route path="/Pedido/:idUsuario" component={PedidoContainer} exact />*/}
            {/*<Route path="/ContenidoCatalogo/:idCatalogo" component={ContenidoCatalogoContainer} />*/}
            <Route path="/RegistrarUsuario/:idUsuario?/:token?" component={RegistrarUsuarioContainer} />
            <Route path="/ControlActividadModal" component={ControlActividadModalContainer} />
            {/*<Route path="/ConfirmarCorreoElectronico/:idUsuario/:token" component={ConfirmarCorreoContainer} />*/}
            <Route path="/FinalizarCompra/" component={FinalizarCompraContainer} />
            {<Route path="/Proveedores/:codigoProveedor?" component={ProveedoresContainer} />}
        </div>
    );
}