import { fromJS } from 'immutable'
import * as ReducerActions from './actions'

const initialState = fromJS({
    data: {
        testData: []
    }
})

export default function testReducer(state = initialState, action) {
    
    if(action.type === ReducerActions.SAVE_TEST_DATA){
        return state.setIn(["data", "testData"], action.payload)
    }

    return state
}