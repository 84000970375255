import { takeEvery, all, put } from 'redux-saga/effects'
import * as calls from './calls'
import * as reducerActions from '../../reducers/CalendarioReducer/actions'
import * as Notification from '../../components/Common/notification'
import * as layoutActions from '../../reducers/LayoutReducer/actions'


function* genGetCalendario(e) {

    try {
        // muestro el loader
        yield put(layoutActions.setLoading({ show: true,  message: 'Buscando calendario' }))

        // llamo a la acción que busca los datos del calendario
        let calendario = yield calls.getCalendario(e.payload)

        if (e.payload.idTipoCalendario === 1) {
          yield put(reducerActions.setCalendarioPickup(calendario))  
        } else {
          yield put(reducerActions.setCalendarioDelivery(calendario))
        }
    }
    catch(error) {
        // muestro el mensaje de error
        Notification.showError(error.error)
    }
    finally {
        // oculto el loader
        yield put(layoutActions.setLoading({ show: false }))
    }
    
}

function* defaultSaga() {
    yield all([
      takeEvery(reducerActions.SAGA_FETCH_CALENDARIO, genGetCalendario),
    ])
  }
  
  export const sagas = [
    defaultSaga
  ]