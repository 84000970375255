import { fromJS } from 'immutable'
import * as ReducerActions from './actions'

const initialState = fromJS({
    data: {
        cliente: undefined,
        usuarioCreado: false,
        paises: undefined,
        paisDefault: undefined
    }
})

export default function registrarUsuarioReducer(state = initialState, action) {

    if(action.type === ReducerActions.INITIALIZE_FORM)
        return initialState

    if (action.type === ReducerActions.SAVE_CLIENTE) {
        return state.setIn(["data", "cliente"], action.payload)
    }

    if(action.type === ReducerActions.SAVE_USUARIO_CREADO) {
        return state.setIn(["data", "usuarioCreado"], action.payload)
    }

    if(action.type === ReducerActions.SET_PAISES) {
        return state.setIn(["data", "paises"], action.payload)
    }

    if(action.type === ReducerActions.SET_PAIS_DEFAULT) {
        return state.setIn(["data", "paisDefault"], action.payload)
    }

    return state
}