// acciones
export const SAGA_FETCH_PROVINCIAS = 'provinciaLocalidadReducer/SAGA_FETCH_PROVINCIAS'
export const SAGA_FETCH_LOCALIDADES = 'provinciaLocalidadReducer/SAGA_FETCH_LOCALIDADES'

export const SAVE_PROVINCIAS = 'provinciaLocalidadReducer/SAVE_PROVINCIAS'
export const SAVE_LOCALIDADES = 'provinciaLocalidadReducer/SAVE_LOCALIDADES'
export const SAVE_LOCALIDADES_ACTUALIZADAS = 'provinciaLocalidadReducer/SAVE_LOCALIDADES_ACTUALIZADAS'

export const GET_LOCALIDADES_COMBO = 'provinciaLocalidadReducer/GET_LOCALIDADES_COMBO'
export const SET_LOCALIDADES_COMBO = 'provinciaLocalidadReducer/SET_LOCALIDADES_COMBO'

export const SAGA_FETCH_SUGERENCIAS_LOCALIDADES = 'provinciaLocalidadReducer/SAGA_FETCH_SUGERENCIAS_LOCALIDADES'
export const SAVE_SUGERENCIAS_LOCALIDADES = 'provinciaLocalidadReducer/SAVE_SUGERENCIAS_LOCALIDADES'

// funciones
export const saveSugerenciasLocalidades = (payload) => ({
    type: SAVE_SUGERENCIAS_LOCALIDADES,
    payload
})

export const sagaFetchSugerenciasLocalidades = (payload) => ({
    type: SAGA_FETCH_SUGERENCIAS_LOCALIDADES,
    payload
})

export const sagaFetchProvincias = (payload) => ({
    type: SAGA_FETCH_PROVINCIAS,
    payload
})

export const setProvincias = (payload) => ({
    type: SAVE_PROVINCIAS,
    payload
})

export const sagaFetchLocalidades = (payload) => ({
    type: SAGA_FETCH_LOCALIDADES,
    payload
})

export const setLocalidades = (payload) => ({
    type: SAVE_LOCALIDADES,
    payload
})

export const setLocalidadesActualizadas = (payload) => ({
    type: SAVE_LOCALIDADES_ACTUALIZADAS,
    payload
})

export const sagaGetLocalidadesCombo = (payload) => ({
    type: GET_LOCALIDADES_COMBO,
    payload
})

export const setLocalidadesCombo = (payload) => ({
    type:  SET_LOCALIDADES_COMBO,
    payload
})