import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import CircularProgress from '@material-ui/core/CircularProgress';
import ItemCarrito from '../ItemCarrito/ItemCarrito'
import Heading from '../UI/Texts/Heading'
import Paragraph from '../UI/Texts/Paragraph'
import { Link } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import DeleteIcon from '@material-ui/icons/Delete';
import './styles.css'
import CustomButton from '../Common/wrappers/CustomButton';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawer: {
    flexShrink: 0,
    opacity: 1
  },
  drawerPaper: {
    background: '#EFF0F8 0% 0% no-repeat padding-box',
    opacity: 1,
    zIndex: 1200,
    border: 'none'
  },
  content: {
    flexGrow: 1,
    padding: '0px',
    marginTop: '30px',
    flexGrow: 1,
  },
});

const VaciarCarritooTooltip = withStyles((theme) => ({
  tooltip: {
    background: '#000000 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '4px',
    padding: '0px',
    opacity: '1 !important',
    margin: '7px 0 4px 25px !important'
  },
}))(Tooltip);

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

class DrawerResumenPedido extends React.Component {

  toFixedCustomize = (num) => {
    var resultado = num.toFixed(6).slice(0, -4)
    return resultado
  }

  render() {

    const { classes } = this.props

    return (
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        className={`${classes.drawer} drawerWidthPedido ${this.props.isPersistent ? 'drawerPersistent' : 'drawerClasic'}`}
        variant={`${this.props.isPersistent ? "persistent" : 'temporary'}`}
        anchor="right"
        open={this.props.open}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={this.props.handleOnClose}
      >
        <div className='containerResumenPedido'>
          {/* INFO USUARIO */}
          <div className='containerInfoUsuario'>
            <div type="button" className='flechaIzq' onClick={this.props.handleOnClose}>
              <img src={require('../../images/flechaIzquierda.png')} />
            </div>
            <div className='imgUsuario'>
              <img src={require('../../images/usuarioM.png')}></img>
            </div>
            <div>
              <div className='labelNombre'><Paragraph size="medium" weight="bold">{`Hola ${this.props.usuario.nombre}`}</Paragraph></div>
              <div className='labelEmail'><Paragraph size="medium" weight="regular">{this.props.usuario.nombreUsuario}</Paragraph></div>
            </div>
          </div>
          {/* MI CARRITO */}
          <div className='containerMiCarrito'>
            <div className='tituloCarrito'>
              <Heading tag="h4" weight="bold">Mi carrito</Heading>
            </div>
            <VaciarCarritooTooltip
              TransitionComponent={Zoom}
              placement="bottom-end"
              style={{ opacity: '1' }}
              enterTouchDelay={10}
              title={
                <div className='containerTooltipVaciarCarrito'>
                  Vaciar carrito
                </div>
              }>
              <div className='vaciarCarrito'>
                <DeleteIcon onClick={this.props.handleOnVaciarCartito} />
              </div>
            </VaciarCarritooTooltip>
          </div>

          <div id='scrollItemsPedido' className={`itemPedidos ${this.props.pedido.precioLista !== this.props.pedido.precioFinal ? '' : 'itemPedidosAux'}`}>
            {this.props.loading !== true && this.props.pedido.listaPedidoDetalle.map((pd) => {
              return <ItemCarrito
                pedidoDetalle={pd}
                handleAgregarQuitarUnidad={this.props.handleAgregarQuitarUnidad}
                logoCard={this.props.instalacion.logoCard}
              />
            })}
            {this.props.loading === true &&
              <div className='containerLoaderResumen'>
                <CircularProgress />
              </div>
            }
          </div>
          {/* SEPARADOR */}
          <div className='separador'></div>
          {/* SUBTOTAL Y DESCUENTOS */}
          <div className='infoPrecios'>
            <div>
              <Heading tag="h6" weight="light">Subtotal</Heading>
            </div>
            <div className='subtotal'>
              <Heading tag="h6" weight="bold">{`$${this.toFixedCustomize(this.props.pedido.precioLista)}`}</Heading>
            </div>
          </div>
          {this.props.pedido.precioLista !== this.props.pedido.precioFinal &&
            <div className='infoPrecios'>
              <div><Heading tag="h6" weight="light">Ahorro clientazo</Heading></div>
              <div className='ahorro'><Heading tag="h6" weight="bold">{`-$${this.toFixedCustomize(this.toFixedCustomize(this.props.pedido.precioLista) - this.toFixedCustomize(this.props.pedido.precioFinal))}`}</Heading></div>
            </div>}
          {/* SEPARADOR */}
          <div className='separador'></div>
          {/* TOTAL */}
          <div className='infoTotal'>
            <div className='total'><div><Heading tag="h5" weight="bold">Total</Heading></div></div>
            <div className='price'><div><Heading tag="h4" weight="bold">{`$${this.toFixedCustomize(this.props.pedido.precioFinal)}`}</Heading></div></div>
          </div>
          {/* BUTTON */}
          {(this.props.instalacion.montoCompraMinimoPermitido === undefined ||
            this.props.instalacion.montoCompraMinimoPermitido === null ||
            this.props.instalacion.montoCompraMinimoPermitido === 0 ||
            (this.props.instalacion.montoCompraMinimoPermitido > 0 && this.props.pedido.precioFinal >= this.props.instalacion.montoCompraMinimoPermitido)) ?

            <div className='containerBtnCheckout'>
              <Link to={'/FinalizarCompra'}>
                <CustomButton classProp={"btnCheckout"} color="success" message="Comprar"
                // onClick={this.props.handleOnClickCheckout}
                />
              </Link>
            </div>
            :

            <div className='textMontoMínimo'>
              Monto mínimo de compra ${this.props.instalacion.montoCompraMinimoPermitido.toFixed(2)}
            </div>
          }
        </div>
      </SwipeableDrawer>
    );
  }
}

DrawerResumenPedido.defaultProps = {
  open: true
}

DrawerResumenPedido.propTypes = {
  usuario: PropTypes.object.isRequired,
  pedido: PropTypes.object.isRequired,
  instalacion: PropTypes.object.isRequired,
  handleOnClickCheckout: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  isPersistent: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  handleOnClose: PropTypes.func,
  handleAgregarQuitarUnidad: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  handleOnVaciarCartito: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(DrawerResumenPedido);

