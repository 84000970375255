// acciones
export const INITIALIZE_FORM = 'catalogo/INITIALIZE_FORM'

export const SAGA_FETCH_CATALOGOS_ACTIVOS = 'catalogo/SAGA_FETCH_CATALOGOS_ACTIVOS'

export const SAVE_CATALOGOS_ACTIVOS = 'catalogo/SAVE_CATALOGOS_ACTIVOS'

export const SAGA_FETCH_CATALOGO_SELECCIONADO = 'catalogo/SAGA_FETCH_CATALOGO_SELECCIONADO'

export const SAVE_CATALOGO_SELECCIONADO = 'catalogo/SAVE_CATALOGO_SELECCIONADO'

export const SET_DISPONIBILIDAD_STOCK_ARTICULO = 'catalogo/SET_DISPONIBILIDAD_STOCK_ARTICULO'

export const SAGA_ID_CATALOGO_PUBLICADO_ACTUAL = 'catalogo/SAGA_ID_CATALOGO_PUBLICADO_ACTUAL'

export const SET_BUSCANDO_CATALOGO = 'catalogo/SET_BUSCANDO_CATALOGO'

export const SET_FILTER_BUSQUEDA = 'catalogo/SET_FILTER_BUSQUEDA'
export const SET_SUBRUBRO_SELECCIONADO = 'catalogo/SET_SUBRUBRO_SELECCIONADO'
export const SET_OFERTA_SELECCIONADA = 'catalogo/SET_OFERTA_SELECCIONADA'

export const SAGA_FETCH_RUBROS_SUBRUBROS = 'catalogo/SAGA_FETCH_RUBROS_SUBRUBROS'
export const SAVE_RUBROS_SUBRUBROS = 'catalogo/SAVE_RUBROS_SUBRUBROS'

export const SAGA_FETCH_SUBFILTROS_ARTICULOS = 'catalogo/SAGA_FETCH_SUBFILTROS_ARTICULOS'
export const SAVE_SUBFILTROS_ARTICULOS = 'catalogo/SAVE_SUBFILTROS_ARTICULOS'

//timer
export const SET_TIEMPO_TIMER = 'catalogo/SET_TIEMPO_TIMER'

export const INICIALIZAR_TIMER = 'catalogo/INICIALIZAR_TIMER'

export const REINICIAR_TIMER = 'catalogo/REINICIAR_TIMER'

export const setTiempoTimer = (payload) =>({
    type: SET_TIEMPO_TIMER,
    payload
})

export const iniciarTimer = (payload) =>({
    type: INICIALIZAR_TIMER,
    payload
})

export const reiniciarTimer = (payload) =>({
    type: REINICIAR_TIMER,
    payload
})


// funciones
export const sagaFetchRubrosSubrubros = (payload) =>({
    type: SAGA_FETCH_RUBROS_SUBRUBROS,
    payload
})

export const saveRubrosSubrubros = (payload) =>({
    type: SAVE_RUBROS_SUBRUBROS,
    payload
})

export const setFilterBusqueda = (payload) =>({
    type: SET_FILTER_BUSQUEDA,
    payload
})

export const setSubrubroSeleccionado = (payload) =>({
    type: SET_SUBRUBRO_SELECCIONADO,
    payload
})

export const setOfertaSeleccionada = (payload) => ({
    type: SET_OFERTA_SELECCIONADA,
    payload
})

export const setBuscandoCatalogo = (payload) =>({
    type: SET_BUSCANDO_CATALOGO,
    payload
})

export const sagaFetchCatalogosActivos = (payload) =>({
    type: SAGA_FETCH_CATALOGOS_ACTIVOS,
    payload
})

export const setCatalogosActivos = (payload) => ({
    type: SAVE_CATALOGOS_ACTIVOS,
    payload
})

export const sagaFetchCatalogoSeleccionado = (payload) =>({
    type: SAGA_FETCH_CATALOGO_SELECCIONADO,
    payload
})

export const setCatalogoSeleccionado = (payload) => ({
    type: SAVE_CATALOGO_SELECCIONADO,
    payload
})

export const initializeFormCatalogo = (payload) => ({
    type: INITIALIZE_FORM,
    payload
})

export const setDisponibilidadStockArticulo = (payload) => ({
    type: SET_DISPONIBILIDAD_STOCK_ARTICULO,
    payload
})

export const sagaGetIdCatalogoPublicadoActual = (payload) => ({
    type: SAGA_ID_CATALOGO_PUBLICADO_ACTUAL,
    payload
})

export const sagaFetchSubFiltrosArticulos = (payload) => ({
    type: SAGA_FETCH_SUBFILTROS_ARTICULOS,
    payload
})

export const setSubFiltrosArticulos = (payload) => ({
    type: SAVE_SUBFILTROS_ARTICULOS,
    payload
})