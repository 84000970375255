import { takeLatest, all, put } from 'redux-saga/effects'
import * as calls from './calls'
import * as reducerActions from '../../reducers/TurneroReducer/actions'
import * as Notification from '../../components/Common/notification'
import * as layoutActions from '../../reducers/LayoutReducer/actions'

function* genGetCalendario(e) {

    try {
        // muestro el loader
        yield put(layoutActions.setLoading({ show: true,  message: 'Buscando calendario' }))

        // llamo a la acción que persiste el usuario
        let calendario = yield calls.getCalendario(e.payload)

        yield put(reducerActions.setCalendario(calendario))

        if(e.payload.callBack){
            e.payload.callBack()
        }
    }
    catch(error) {
        // muestro el mensaje de error
        Notification.showError(error.error)
    }
    finally {
        // oculto el loader
        yield put(layoutActions.setLoading({ show: false }))
    }
    
}

function* defaultSaga() {
    yield all([
      takeLatest(reducerActions.SAGA_FETCH_CALENDARIO, genGetCalendario),
    ])
  }
  
  export const sagas = [
    defaultSaga
  ]