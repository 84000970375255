import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Catalogo from '../../components/Catalogo'
import * as catalogoActions from '../../reducers/CatalogoReducer/actions'
import { catalogoDataSelector } from '../../selectors/catalogoSelector'
import { instalacionSelector } from '../../selectors/sessionSelector'
import ContenidoCatalogoContainer from '../../containers/ContenidoCatalogo'
import * as sessionActions from '../../reducers/SessionReducer/actions'
import * as pedidoActions from '../../reducers/PedidoReducer/actions'
import {pedidoDataSelector} from '../../selectors/pedidoSelector'
import {usuarioSelector} from '../../selectors/sessionSelector'
import Pedido from '../../components/Pedido'


class PedidoContainer extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            tabIndex: props.todos?1:0,
            
        }

        window.scrollTo(0, 0)

        
        // obtengo pedidos activos
        if (this.props.dataUser !== undefined){
            let payload = {}
            payload.idUsuario = this.props.dataUser.id
            payload.idCliente = this.props.dataUser.idCliente

            this.props.sagaGetPedidosUser(payload)
            this.props.sagaGetPedidosCliente(payload)
        }
    }

    handleChangeTabIndex = (event, value) => {
        this.setState({ tabIndex:value });
      };

    handleOnVer = (id) => {
        this.props.history.push('/ConfirmarPedido/'+ id)
    }


    render() {
               
        return (

            <div>
                <Pedido handleChangeTabIndex = {this.handleChangeTabIndex}
                        tabIndex = {this.state.tabIndex}
                        pedidosCliente = {this.props.dataPedidos.pedidosCliente}
                        pedidosUser = {this.props.dataPedidos.pedidosUser}
                        handleOnVer = {this.handleOnVer}
                        />
              
            </div>
        )
    }
}


function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        ...sessionActions,
        ...pedidoActions
	}, dispatch)
}

function mapStateToProps(state) {
	return {
        dataUser: usuarioSelector(state),
        dataPedidos: pedidoDataSelector(state)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PedidoContainer)
