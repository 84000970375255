import * as Noty from 'noty';
import 'noty/lib/themes/mint.css'
import 'noty/lib/noty.css'

Noty.overrideDefaults({
    layout: 'bottomCenter',
    theme: 'mint',
    timeout: 5000,
    progressBar: false,
    animation: {
        open : null,
        close: null
    }
});

export function showSuccess (mensaje) {
    new Noty({
        type: 'success',
        text: mensaje || ''

    }).show();
}

export function showError (mensaje) {
    new Noty({
        type: 'error',
        text: mensaje || ''
    }).show();
}

export function showInfo (mensaje) {
    new Noty({
        type: 'information',
        text: mensaje || ''
    }).show();
}

export function showSuccessLongDuration (mensaje) {
    new Noty({
        type: 'success',
        text: mensaje || '',
        closeWith:['button'],
        timeout: 15000

    }).show();
}

export function showInfoLongDuration (mensaje) {
    new Noty({
        type: 'information',
        text: mensaje || '',
        closeWith:['button'],
        timeout: 15000

    }).show();
}