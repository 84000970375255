import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Radio, FormControlLabel } from '@material-ui/core';
import { DeleteForever as DeleteForeverIcon } from '@material-ui/icons';
import DeleteDatoFacturacionModal from './deleteDatoFacturacionModal';

const styles = theme => ({
  label: {
    font: '500 16px/20px Poppins',
    letterSpacing: '0px',
    color: '#0C3653',
  },
  rootContent: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '-5px 4px 10px #8E8E8E29',
    borderRadius: '21px',
    opacity: 1,
    minWidth: '300px',
    padding: '0px !important'
  },
})

const RadioPurple = withStyles({ root: { color: 'var(--purple)' }, checked: {} })((props) => <Radio color="default" {...props} />);

const DatosFacturacionOptions = (props) => {
  const {
    classes,
    clienteDatosFacturacion,
    showModalDeleteDatoFacturacion,
    handleOnSelectDatoFacturacion,
    handleOnClickDeleteDatoFacturacion,
    handleOnDeleteFacturacion,
    handleOnAgregarDatosFacturacion,
  } = props;

  return (
    <div className='containerDatosFacturacionOptions'>
      {clienteDatosFacturacion.filter(x => x.activo).map((cdf) => (
        <div className='containerRadioBtn'>
          <FormControlLabel
            onChange={() => handleOnSelectDatoFacturacion({ id: cdf.id, cuit: cdf.cuit, nombreRazonSocial: cdf.nombreRazonSocial })}
            classes={{ label: classes.label }}
            value={cdf.id}
            control={<RadioPurple />}
            label={`${cdf.nombreRazonSocial}, CUIT ${cdf.cuit}, ${cdf.condicionIVA.nombre}`}
          />

          <div>
            <DeleteForeverIcon
              onClick={() => handleOnClickDeleteDatoFacturacion(cdf.id)}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </div>
      ))}

      <div className='containerBtnsForm'>
        <div className="btn" onClick={handleOnAgregarDatosFacturacion}>Agregar</div>
      </div>

      {/* MODAL ELIMINACION DATO FACTURACION */}
      {showModalDeleteDatoFacturacion &&
        <DeleteDatoFacturacionModal
          handleOnDelete={handleOnDeleteFacturacion}
          handleOnCancel={() => handleOnClickDeleteDatoFacturacion(undefined)}
        />
      }
    </div>
  );
};

export default withStyles(styles)(DatosFacturacionOptions)
