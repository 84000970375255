import _axios from '../../apiClient'

export function getPedido(payload) {
    return _axios.get(`/api/Pedido/GetPedidoBorradorPorUsuario`,
    { 
      params: { idUsuario: payload } 
    },
    {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}

export function addPedidoDetalle(payload) {
  
  return _axios.post(`/api/Pedido/UpdatePedido`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}

export function removePedidoDetalle(payload) {
  return _axios.post(`/api/Pedido/QuitarPedidoDetalleBorrador`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}

export function setPedidoDetalleReemplazo(payload) {
  return _axios.post(`/api/Pedido/SetPedidoDetalleReemplazo`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}

export function updateEstadoPedidoAbandonado(payload) {
  return _axios.post(`/api/Pedido/UpdateEstadoPedidoAbandonado`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}

export function getPedidosByUser(payload) {
  return _axios.get(`/api/Pedido/GetPedidosByUser`,
  { 
    params: { idUsuario: payload.idUsuario } 
  },
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}

export function getPedidosByCliente(payload) {
  return _axios.get(`/api/Pedido/GetPedidosByCliente`,
  { 
    params: { idCliente: payload.idCliente } 
  },
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}
export function getPedidosBorradorByCliente(payload) {
  return _axios.get(`/api/Pedido/GetPedidoBorradorPorCliente`,
  { 
    params: { idUsuario: payload.idUsuario } 
  },
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}

export function actualizarFechaPedidoBorrador(payload) {
  return _axios.get(`/api/Pedido/ActualizarFechaPedidoBorrador`,
    { 
      params: { idPedido: payload } 
    },
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}

export function ConfirmarEliminarPedido(payload) {
  return _axios.post(`/api/Pedido/UpdateEstadoPedido`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
  }


export function setPedidoPagoMP(payload) {
  return _axios.post(`/api/Pedido/setPedidoPagoMP`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}

export function generarCheckout(payload) {
  return _axios.post(`/api/Pedido/GenerarCheckoutMobbex`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}

export function ConfirmarPedidoMobbex(payload) {
  return _axios.post(`/api/Pedido/ConfirmarPedidoMobbex`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}

export function sendEmailConfirmado(payload) {
  return _axios.post(`/api/Pedido/SendEmailConfirmado`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}

export function getPreferenciasUltimoPedido(payload) {
  return _axios.get(`/api/Pedido/GetPreferenciasUltimoPedido`,
  { 
    params: { idCliente: payload.idCliente } 
  },
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}
