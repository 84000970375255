import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Row, Col } from 'react-flexbox-grid'
import {
  InputBase,
  Input,
  Select,
  MenuItem,
} from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import CuitMask from './cuitMask';
import DatosFacturacionRepetidosModal from './datosFacturacionRepetidosModal';
import CustomInput from '../Common/textInput/CustomInput'

const styles = theme => ({
  icon: {
    color: '#B4C4D5'
  },
  select: {
    textAlign: 'left',
    font: '400 16px/23px Poppins',
    letterSspacing: '0px',
    color: '#B4C4D5',
    opacity: 1,
  },
})

const FormularioCondicionIVA = (props) => {
  const {
    condicionesIVA,
    formularioCondicionIVA,
    erroresFormulario,
    handleOnChangeFormularioCondicionIVA,
    handleOnChangeTipoFacturacion,
    handleOnSaveFormularioCondicionIVA,
    handleOnReutilizarDatosFacturacion,
    handleOnCancelarReutilarDatos,
    classes,
  } = props;

  return (
    <div className="formularioCondicionIVA">
      <Row>
        <Col xs={12}>
          <div className='inputContainer'>
            <span class="label">CUIT</span>
            <Input
              placeholder="CUIT"
              value={formularioCondicionIVA.cuit}
              onChange={handleOnChangeFormularioCondicionIVA}
              name="cuit"
              inputComponent={CuitMask}
              error={erroresFormulario.cuit !== undefined}
            />
            {erroresFormulario.cuit && <FormHelperText error>{erroresFormulario.cuit}</FormHelperText>}
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div className='inputContainer'>
            <span class="label">Nombre / Razón social</span>
            <CustomInput
              placeholder="Nombre o Razón social"
              value={formularioCondicionIVA.nombreRazonSocial}
              onChange={handleOnChangeFormularioCondicionIVA}
              name="nombreRazonSocial"
              error={erroresFormulario.nombreRazonSocial !== undefined}
            />
            {erroresFormulario.nombreRazonSocial && <FormHelperText error>{erroresFormulario.nombreRazonSocial}</FormHelperText>}
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div className='inputContainer'>
            <span class="label">Domicilio</span>
            <CustomInput
              placeholder="Domicilio"
              value={formularioCondicionIVA.domicilio}
              onChange={handleOnChangeFormularioCondicionIVA}
              name="domicilio"
              error={erroresFormulario.domicilio !== undefined}
            />
            {erroresFormulario.domicilio && <FormHelperText error>{erroresFormulario.domicilio}</FormHelperText>}
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div className='inputContainer'>
            <span class="label">Condición frente al IVA</span>
            <Select
              classes={{
                icon: classes.icon,
                select: classes.select
              }}
              IconComponent={ExpandMoreIcon}
              value={formularioCondicionIVA.condicionIVA}
              onChange={handleOnChangeFormularioCondicionIVA}
              name="condicionIVA"
              error={erroresFormulario.condicionIVA !== undefined}
            >
              {condicionesIVA.map((x) => (
                <MenuItem value={x.id}>{x.nombre}</MenuItem>
              ))}
            </Select>
            {erroresFormulario.condicionIVA && <FormHelperText error>{erroresFormulario.condicionIVA}</FormHelperText>}
          </div>
        </Col>
      </Row>

      <div className='containerBtnsForm'>
        <div className={`btn btnSmall`} onClick={() => handleOnChangeTipoFacturacion(undefined)}>CANCELAR</div>
        <div className={`btn btnSmall`} onClick={handleOnSaveFormularioCondicionIVA}>GUARDAR</div>
      </div>

      {erroresFormulario.datosRepetidos &&
        <DatosFacturacionRepetidosModal
          handleOnConfirm={(id) => handleOnReutilizarDatosFacturacion({ id, cuit: formularioCondicionIVA.cuit, nombreRazonSocial: formularioCondicionIVA.nombreRazonSocial })}
          handleOnCancel={handleOnCancelarReutilarDatos}
        />
      }
    </div>
  );
};

export default withStyles(styles)(FormularioCondicionIVA)