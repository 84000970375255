// acciones
export const SAGA_FETCH_TEST_DATA = 'test/SAGA_FETCH_TEST_DATA'

export const SAVE_TEST_DATA = 'test/SAVE_TEST_DATA'

// funciones
export const sagaFetchTestData = (payload) =>({
    type: SAGA_FETCH_TEST_DATA,
    payload
})


export const setTestData = (payload) => ({
    type: SAVE_TEST_DATA,
    payload
})