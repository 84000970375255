import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as confirmarPedidoActions from '../../reducers/ConfirmarPedidoReducer/actions'
import * as layoutActions from '../../reducers/LayoutReducer/actions'
import { timerSelector } from '../../selectors/layoutSelector'
import { confirmarPedidoDataSelector, confirmarPedidoRedirigirSelector } from '../../selectors/confirmarPedidoSelector'
import { instalacionSelector, usuarioSelector } from '../../selectors/sessionSelector'
import HeaderPedido from '../../components/ConfirmarPedido/headerPedido'
import ConfirmarPedido from '../../components/ConfirmarPedido'
import * as pedidoActions from '../../reducers/PedidoReducer/actions'
import * as catalogoActions from '../../reducers/CatalogoReducer/actions'
import * as Notification from '../../components/Common/notification';

// acciones que puede lanzar la confirmación del diálogo
const accionesDialogo = {
  ACTUALIZAR_ESTADO: 1
}

// estados de pedido
const estadosPedido = {
  CONFIRMADO: 2,
  ELIMINADO: 6
}

class ConfirmarPedidoContainer extends React.Component {

  constructor(props) {
    super(props)

    window.scrollTo(0, 0)

    this.state = {
      dialogo: {
        show: false,
        titulo: '',
        cuerpo: '',
        botonConfirmar: '',
        botonCancelar: '',
      },
      accion: undefined,
      estadoPedido: undefined,
      idPedidoDetalle: undefined,
      totalPedido: undefined
    }

    // inicializo el formulario
    this.props.initializeForm()

    // obtengo el pedido
    this.props.sagaFetchPedido(this.props.match.params.idPedido)
  }

  componentDidUpdate = () => {
    if (this.props.data.pedido !== undefined && this.props.data.pedido.precioFinal !== this.state.totalPedido) {

      // si cambió el total del pedido, actualizo el header
      this.setState({ totalPedido: this.props.data.pedido.precioFinal }, this.setHeader)
    }

    if (this.props.redirigir) {
      // redirijo hacia los catalogos publicados
      window.location.hash = '/Pedido/' + this.props.dataUser.id
    }
  }

  componentWillUnmount = () => {
    // vuelvo el header a su versión original
    this.props.setHeaderDefault()
  }

  setHeader = () => {

    // armo el header
    this.props.setHeader({
      showDrawer: false,
      showBackButton: true,
      handleOnBackClick: this.handleOnBackClick,
      extraContent: <HeaderPedido
        pedido={this.props.data.pedido}
        handleOnConfirmar={this.handleOnConfirmar}
        handleOnBorrar={this.handleOnBorrar}
      />
    })
  }

  getImagenesPedidoDetalles = () => {

    // busco la imágen de cada uno de los detalles del pedido
    let detallesPedido = this.props.data.pedido.detalles

    for (var i = 0; i < detallesPedido.length; i++) {
      this.props.sagaFetchImagenItem(detallesPedido[i].id)
    }
  }

  handleOnConfirmar = () => {
    this.setState({
      dialogo: {
        show: true,
        cuerpo: 'Está por confirmar el pedido',
        botonConfirmar: 'CONFIRMAR',
        botonCancelar: 'VOLVER'
      },
      accion: accionesDialogo.ACTUALIZAR_ESTADO,
      estadoPedido: estadosPedido.CONFIRMADO,

    })
  }

  handleOnBorrar = () => {
    this.setState({
      dialogo: {
        show: true,
        cuerpo: 'Está por borrar el pedido',
        botonConfirmar: 'BORRAR',
        botonCancelar: 'VOLVER'
      },
      accion: accionesDialogo.ACTUALIZAR_ESTADO,
      estadoPedido: estadosPedido.ELIMINADO
    })
  }

  handleOnConfirmarDialogClose = (confirma) => {
    // verifico si se confirmó el modal
    if (confirma === true) {
      if (this.state.accion === accionesDialogo.ACTUALIZAR_ESTADO) {
        if (this.props.timer.tiempoAgotado && this.state.estadoPedido == estadosPedido.CONFIRMADO) {
          this.props.setBanderaPedido(false)
        }
        else {
          if (this.props.timer.tiempoAgotado && this.state.estadoPedido == estadosPedido.ELIMINADO) {
            //his.props.setTiempoAgotado(false)
            //this.props.setPedido(undefined)
            this.props.setBanderaPedido(false)
          }
        }
        // actualizo el estado del pedido
        this.props.sagaUpdateEstadoPedido({
          Id: this.props.match.params.idPedido,
          IdEstadoPedido: this.state.estadoPedido
        })
      }
    }

    // actualizo el estado
    this.setState({
      dialogo: {
        ...this.state.dialogo,
        show: false
      },
      accion: undefined,
      estadoPedido: undefined,
      idArticulo: undefined,
      idPedidoDetalle: undefined
    })
  }

  handleOnItemPedidoClick = (item) => {
    this.setState({
      idArticulo: item.idArticulo,
      idPedidoDetalle: item.id
    })
  }

  handleOnPedidoDetalleModalClose = (confirma, detallePedido) => {
    if (confirma === true) {

      let payload = {
        IdPedido: this.props.match.params.idPedido,
        IdPedidoDetalle: this.state.idPedidoDetalle,
      }
      //funciojn para actualizar el pedidoBorrador del pedidoReducer
      payload.callbackFunctionAfter = () => { this.props.sagaFetchPedidoBorrador(this.props.dataUser.id) }
      // quito el detalle del pedido
      this.props.sagaRemovePedidoDetalleCP(payload)
    }

    this.setState({
      idArticulo: undefined,
      idPedidoDetalle: undefined
    })
  }

  handleOnBackClick = () => {
    if (this.props.timer.tiempoAgotado) {
      this.props.setTimerReiniciar(true)
    }
    //window.location.hash = '/Catalogo'

    this.props.history.push('/Pedido/' + this.props.dataUser.id)
  }

  handleIrAlCatalogo = () => {
    let payload = {}
    payload.callBack = (idcatalogo) => {
      if(idcatalogo !== "" && idcatalogo !== undefined && idcatalogo !== null){
        this.props.history.push('/ContenidoCatalogo/' + idcatalogo)
      }
      else{
        Notification.showError(`No existen catalogos publicados actualmente`)
      } 
    }

    this.props.sagaGetIdCatalogoPublicadoActual(payload)
  }

  render() {

    return (
      <ConfirmarPedido
        pedido={this.props.data.pedido}
        handleOnItemPedidoClick={this.handleOnItemPedidoClick}
        idArticulo={this.state.idArticulo}
        idPedidoDetalle={this.state.idPedidoDetalle}
        handleOnPedidoDetalleModalClose={this.handleOnPedidoDetalleModalClose}
        handleIrAlCatalogo={this.handleIrAlCatalogo}

        /* propiedades de diálogo */
        mostrarDialogo={this.state.dialogo.show}
        handleOnConfirmarDialogClose={this.handleOnConfirmarDialogClose}
        tituloDialogo={this.state.dialogo.titulo}
        cuerpoDialogo={this.state.dialogo.cuerpo}
        botonConfirmarDialogo={this.state.dialogo.botonConfirmar}
        botonCancelarDialogo={this.state.dialogo.botonCancelar}
      />
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...catalogoActions,
    ...confirmarPedidoActions,
    ...layoutActions,
    ...pedidoActions,
  }, dispatch)
}

function mapStateToProps(state) {
  return {
    data: confirmarPedidoDataSelector(state),
    dataUser: usuarioSelector(state),
    redirigir: confirmarPedidoRedirigirSelector(state),
    instalacion: instalacionSelector(state),
    timer: timerSelector(state)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmarPedidoContainer)