import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { clienteSelector, instalacionSelector, usuarioSelector } from '../../../selectors/sessionSelector';
import { connect } from 'react-redux';
import apiClient from '../../../apiClient';
import CircularProgress from '@material-ui/core/CircularProgress';
import './repetir.css'
import moment from 'moment';
import 'moment/locale/es';
import { Button } from '@material-ui/core';
import { bindActionCreators } from 'redux'
import * as pedidoActions from'../../../reducers/PedidoReducer/actions'
import * as Notification from '../../../components/Common/notification';
import { getPedidosBorradorByCliente } from '../../../sagas/PedidoSaga/calls';

moment.locale('es');

const drawerWidth = 360;

const styles = (theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    opacity: 1
  },
  drawerPaper: {
    width: drawerWidth,
    background: '#EBEFFF 0% 0% no-repeat padding-box',
    opacity: 1,
    zIndex: 1501,
    border: 'none'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});

const DrawerRepetirPedido = ({ classes, handleOnClose, show, instalacion, cliente, sagaAddPedidoDetalle, usuario }) => {
  const [pedidos, setPedidos] = React.useState([]);
  const [detallePedido, setDetallePedido] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [verTodos, setVerTodos] = React.useState(false);

  const fetchPedidos = () => {
    setLoading(true);
    apiClient.get(`/api/Pedido/GetPedidosARepetir?idCliente=${cliente}&idInstalacion=${instalacion.id}`)
    .then(({ data }) => {
      setPedidos(data.sort((a,b) => b.idPedido - a.idPedido));
    })
    .finally(() => setLoading(false))
  }

  const getPedidoDetalle = (id) => {
    apiClient.get(`/api/Pedido/GetPedidosARepetirDetalle?idPedido=${id}`)
    .then(({ data }) => {
      setDetallePedido({id, data})
    })
  }

  const hacerPedido = async () => {
          //armo el model agregar el PedidoDetalle
          let filtrados = detallePedido.data.filter(x => x.disponible);
          let borrador = null
          await getPedidosBorradorByCliente({idUsuario: usuario.id}).then((res) => borrador = res)
          
          if(borrador){
            const detalleIds = borrador.pedidoDetalles.map(x => x.idArticulo)
            filtrados = filtrados.filter(x => !detalleIds.includes(x.idArticulo))
          }
          if(filtrados.length > 0) {
            const addPedidoModel = {Items: []};
            addPedidoModel.idInstalacion = instalacion.id
            addPedidoModel.idCliente = cliente
            filtrados.forEach(async (dp) => {
              addPedidoModel.Items.push({
                alternativa: dp.alternativa,
                idCatalogoEntrada: dp.idCatalogoEntrada,
                idArticulo: dp.idArticulo,
                cantidad: dp.cantidad,
              })
            });
            addPedidoModel.usuarioRegistro = usuario.id
  
            addPedidoModel.callbackFunctionAfter = () => { 
              Notification.showSuccess(`Se agregaron a su pedido los articulos.`);
            }
  
            addPedidoModel.callbackFunctionError = () => { }
            //agrego el pedidoDetalle
  
            await sagaAddPedidoDetalle(addPedidoModel)
          }
          
          closeDrawer();
  }

  const closeDrawer = () => {
    handleOnClose();
    setDetallePedido(null);
    setPedidos([])
  }

  React.useEffect(() => {
    if(show){
      fetchPedidos();
    }
  },[show])


    return (
      <Drawer
        className={`${classes.drawer}`}
        anchor="right"
        open={true}
        classes={{ paper: classes.drawerPaper }}
        onClose={() => closeDrawer()}
      >
        <div className='containerCalendario'>
          <IconButton
            color="default"
            aria-label="Back"
            className='arrowCloseResumen'
            onClick={() => closeDrawer()}
          >
            <ArrowBackIcon />
          </IconButton>
          {!detallePedido ?
            <>
              <div className='tituloCalendario'>Tus Pedidos</div>
              {loading ? 
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 30}}>
                  <CircularProgress />
                </div> 
                :
                <>
                  {pedidos.length > 0 ? 
                  <>
                    <div className='notaCalendario'>
                      Estos son tus últimos pedidos, elige el que quieres repetir.
                    </div>
                  
                    <div className={`CalendarioItems`}>
                      {pedidos.map(x => (
                        <div className={`pedido ${x.descripcion === 'PICK-UP' ? 'pedidoPickUp' : 'pedidoDelivery'}`} onClick={() => getPedidoDetalle(x.idPedido)}>
                          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <p className='idPedido'>#{x.idPedido}</p>
                            <ArrowForwardIosIcon />
                          </div>
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <div className='letra'>
                              {x.descripcion === 'PICK-UP' ? 'P' : 'D'}
                            </div>
                            <div>
                              <p className='descripcion'>{x.descripcion}</p>
                              <p style={{margin: 0}}>{moment(x.fechaTurno).format('ddd DD/MM')} {x.nombre}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                  :
                  <>
                    <div className='notaCalendario'>
                      Todavía no hay hecho ningún pedido. Cuando lo hagas, podrás verlos aquí y repetir cualquiera de estas compras
                    </div>
                  </>
                  }
                </>
                }
            </>
            :
            <>
              <div className='tituloCalendario'>Repetir pedido #{detallePedido.id}</div>
                {detallePedido.data.filter(x => !x.disponible).length > 0 ? 
                <>
                  <div className='notaCalendario'>
                    Algunos de los artículos  del pedido #{detallePedido.id} están discontinuados o actualmente no se ofrecen en el catálogo de productos.
                  </div>
                  <div>
                    {detallePedido.data.filter(x => !x.disponible).map(y => (
                      <div className='stock'>
                        <div className="imageContainerRepe">
                          {y.listaImagenesArticulos.length > 0 ? 
                            <div className='imgPedidoDetalleRepe'>
                              <img alt="" src={y.listaImagenesArticulos[0].path}></img>
                            </div>
                            :
                            <div className='imageDefaultRepe'>
                              <div>Producto sin foto</div>
                            </div>
                          }
                        </div>
                        <div>
                          <p className='stockName'>{y.nombre}</p>
                          <p className='sinStockText'>No disponible</p>
                        </div>
                      </div>
                    ))}
                    <div>
                      <div className='notaCalendarioCentrado'>
                        Agregaremos {detallePedido.data.filter(x => x.disponible).length} artículo{detallePedido.data.filter(x => x.disponible).length > 1 && 's'} a tu carrito de compras
                      </div>
                      {((detallePedido.data.filter(x => x.disponible).length > 3) && (!verTodos))?
                      detallePedido.data.slice(0,3).filter(x => x.disponible).map(y => (
                        <div className='stock'>
                          <div className="imageContainerRepe">
                            {y.listaImagenesArticulos.length > 0 ? 
                              <div className='imgPedidoDetalleRepe'>
                                <img alt="" src={y.listaImagenesArticulos[0].path}></img>
                              </div>
                              :
                              <div className='imageDefaultRepe'>
                                <div>Producto sin foto</div>
                              </div>
                            }
                          </div>
                          <div>
                            <p className='stockName'>{y.nombre}</p>
                            <p className='stockName' marginTop='4px'>{`Cant: ${y.cantidad} ${(y.productoPesable)?'kg':'u'} `}</p>
                          </div>
                        </div>
                      ))
                      :
                      detallePedido.data.filter(x => x.disponible).map(y => (
                        <div className='stock'>
                          <div className="imageContainerRepe">
                            {y.listaImagenesArticulos.length > 0 ? 
                              <div className='imgPedidoDetalleRepe'>
                                <img alt="" src={y.listaImagenesArticulos[0].path}></img>
                              </div>
                              :
                              <div className='imageDefaultRepe'>
                                <div>Producto sin foto</div>
                              </div>
                            }
                          </div>
                          <div>
                            <p className='stockName'>{y.nombre}</p>
                            <p className='stockName' marginTop='4px'>{`Cant: ${y.cantidad} ${(y.productoPesable)?'kg':'u'} `}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
                :
                <div>
                  <div className='notaCalendarioCentrado'>
                    Agregaremos {detallePedido.data.length} artículo{detallePedido.data.length > 1 && 's'} a tu carrito de compras
                  </div>
                  {((detallePedido.data.filter(x => x.disponible).length > 3) && (!verTodos))?
                  detallePedido.data.slice(0,3).filter(x => x.disponible).map(y => (
                    <div className='stock'>
                      <div className="imageContainerRepe">
                        {y.listaImagenesArticulos.length > 0 ? 
                          <div className='imgPedidoDetalleRepe'>
                            <img alt="" src={y.listaImagenesArticulos[0].path}></img>
                          </div>
                          :
                          <div className='imageDefaultRepe'>
                            <div>Producto sin foto</div>
                          </div>
                        }
                      </div>
                      <div>
                        <p className='stockName'>{y.nombre}</p>
                        <p className='stockName' marginTop='4px'>{`Cant: ${y.cantidad} ${(y.productoPesable)?'kg':'u'} `}</p>
                      </div>
                    </div>
                  ))
                  :
                  detallePedido.data.filter(x => x.disponible).map(y => (
                    <div className='stock'>
                      <div className="imageContainerRepe">
                        {y.listaImagenesArticulos.length > 0 ? 
                          <div className='imgPedidoDetalleRepe'>
                            <img alt="" src={y.listaImagenesArticulos[0].path}></img>
                          </div>
                          :
                          <div className='imageDefaultRepe'>
                            <div>Producto sin foto</div>
                          </div>
                        }
                      </div>
                      <div>
                        <p className='stockName'>{y.nombre}</p>
                        <p className='stockName' marginTop='4px'>{`Cant: ${y.cantidad} ${(y.productoPesable)?'kg':'u'} `}</p>
                      </div>
                    </div> 
                  ))}
                </div>
                }

              {!verTodos && /*(detallePedido.data.filter(x => x.disponible).length > 3) &&*/
              <Button onClick={() => setVerTodos(true)} size="small" className='btnVerTodos'>
                Ver Todos
              </Button>  
              }

              <Button onClick={() => hacerPedido()} size="small" className='btnRepetirPedido' style={{marginTop:((!verTodos)?'17px':'34.5px')}}>
                Repetir pedido
              </Button>
            </>
        
          }
        </div>
      </Drawer>
    );
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      ...pedidoActions,
    }, dispatch)
  }
  
  function mapStateToProps(state) {
    return {
      instalacion: instalacionSelector(state),
      cliente: clienteSelector(state),
      usuario: usuarioSelector(state),
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(DrawerRepetirPedido));