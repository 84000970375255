// acciones
export const SAGA_FETCH_CONDICIONES_IVA = 'facturacionReducer/SAGA_FETCH_CONDICIONES_IVA'
export const SAGA_FETCH_DATOS_FACTURACION = 'facturacionReducer/SAGA_FETCH_DATOS_FACTURACION'
export const SAGA_SAVE_DATOS_FACTURACION = 'facturacionReducer/SAGA_SAVE_DATOS_FACTURACION'
export const SAGA_SET_DATOS_FACTURACION_ACTIVO = 'facturacionReducer/SAGA_SET_DATOS_FACTURACION_ACTIVO'

export const SAVE_CONDICIONES_IVA = 'facturacionReducer/SAVE_CONDICIONES_IVA'
export const SAVE_DATOS_FACTURACION = 'facturacionReducer/SAVE_DATOS_FACTURACION'
export const SAVE_SET_DATOS_FACTURACION_ACTIVO = 'facturacionReducer/SAVE_SET_DATOS_FACTURACION_ACTIVO'

// funciones
export const sagaFetchCondicionesIVA = (payload) => ({
    type: SAGA_FETCH_CONDICIONES_IVA,
    payload
})

export const sagaFetchDatosFacturacion = (payload) => ({
    type: SAGA_FETCH_DATOS_FACTURACION,
    payload
})

export const sagaSaveDatosFacturacion = (payload) => ({
    type: SAGA_SAVE_DATOS_FACTURACION,
    payload
})

export const sagaSetDatosFacturacionActivo = (payload) => ({
    type: SAGA_SET_DATOS_FACTURACION_ACTIVO,
    payload
})

export const setCondicionesIVA = (payload) => ({
    type: SAVE_CONDICIONES_IVA,
    payload
})

export const setDatosFacturacion = (payload) => ({
    type: SAVE_DATOS_FACTURACION,
    payload
})

export const setDatosFacturacionActivo = (payload) => ({
    type: SAVE_SET_DATOS_FACTURACION_ACTIVO,
    payload
})