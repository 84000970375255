import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Catalogo from '../../components/Catalogo'
import CatalogoSinPortada from '../../components/Catalogo/catalogoSinPortada'
import * as catalogoActions from '../../reducers/CatalogoReducer/actions'
import * as sessionActions from '../../reducers/SessionReducer/actions'
import * as layoutActions from '../../reducers/LayoutReducer/actions';
import { catalogoDataSelector } from '../../selectors/catalogoSelector'
import { instalacionSelector } from '../../selectors/sessionSelector'
import ContenidoCatalogoContainer from '../../containers/ContenidoCatalogo'
import './catalogoContainer.css'

class CatalogoContainer extends React.Component {

  constructor(props) {
    super(props)

    window.scrollTo(0, 0)

    // obtengo catalogos activos
    let payload = {}
    payload.idInstalacion = this.props.instalacion.id
    payload.callBack = () => {
      if (this.props.data.catalogos !== undefined && this.props.data.catalogos.length === 1 && this.props.instalacion.verPortadaCatalogo === false) {
        //document.getElementById('footer').getElementsByClassName('descuentos')[0].hidden = true
        let footer = document.getElementById('footer')
        if(footer !== undefined && footer !== null && footer.getElementsByClassName('descuentos').length > 0){
          footer.getElementsByClassName('descuentos')[0].hidden = true
        }
      }
    }

    this.props.sagaFetchCatalogosActivos(payload)
  }

  componentWillUnmount = () => {

    let footer = document.getElementById('footer')
    if(footer !== null && footer !== undefined && footer.getElementsByClassName('descuentos').length > 0){
      footer.getElementsByClassName('descuentos')[0].hidden = false
    }

    this.props.setPermiteUsuarioAnonimo(false)
    
  }

  componentDidMount = () => {
    // indico que el Login se puede ver aunque el usuario no esté logeado
    this.props.setPermiteUsuarioAnonimo(true);
  }

  handleVerCatalogo = (idCatalogo) => {
    window.location.hash = `/ContenidoCatalogo/${idCatalogo}`
  }

  render() {

    return (

      <div>

        {/* Muestro los articulos del unico catalogo activo */}
        {this.props.data.catalogos !== undefined && this.props.data.catalogos.length === 1 && this.props.instalacion.verPortadaCatalogo === true &&
          <ContenidoCatalogoContainer
            history={this.props.history}
            idCatalogoSeleccionado={this.props.data.catalogos[0].id}
            catalogoUnico={true}
          />
        }

        {this.props.data.catalogos !== undefined && this.props.data.catalogos.length === 1 && this.props.instalacion.verPortadaCatalogo === false &&
          <CatalogoSinPortada
            catalogo={this.props.data.catalogos[0]}
            instalacion={this.props.instalacion}
            handleVerCatalogo={this.handleVerCatalogo}
          />
        }

        {this.props.data.catalogos === undefined &&
          <div className='noExisteCatalogo'>No existen catálogos publicados.</div>
        }

        {/* Muestro los catalogos activos */}
        {this.props.data.catalogos !== undefined && this.props.data.catalogos.length > 1 &&
          <Catalogo
            catalogos={this.props.data.catalogos}
            instalacion={this.props.instalacion}
          />
        }

      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...catalogoActions,
    ...sessionActions,
    ...layoutActions
  }, dispatch)
}

function mapStateToProps(state) {
  return {
    data: catalogoDataSelector(state),
    instalacion: instalacionSelector(state)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CatalogoContainer)