import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as calendarioActions from '../../reducers/CalendarioReducer/actions'
import { calendarioPickupSelector,calendarioDeliverySelector } from '../../selectors/calendarioSelector'
import { usuarioSelector, instalacionSelector } from '../../selectors/sessionSelector'
import { sucursalesSelector } from '../../selectors/sucursalesSelector'
import Calendario from '../../components/Calendario'
import PropTypes from 'prop-types';
class CalendarioContainer extends React.Component {

    constructor(props) {
        super(props)
     
        this.getCalendario(1)   // pickup
        this.getCalendario(2)   // delivery

        this.state = {
          sucursalesPickup: [],
          sucursalPickupSeleccionada: undefined
        }
    }
    
    getCalendario = (idTipoCalendario) => {
      let sucursales = [];
      this.props.sucursales.forEach(sucursal => {
        let formaEntrega = sucursal.formasDeEntrega.find(x => x.idFormaEntrega === idTipoCalendario)
        if(formaEntrega)
          sucursales.push({
            idSucursal: sucursal.idSucursal,
            nombre: sucursal.nombre,
            direccion: sucursal.direccion,
            porDefecto: formaEntrega.porDefecto
          })
      })
      let id = this.props.instalacion.id;
      console.log({sucursales})
      switch (idTipoCalendario) {
        case 1:
          id = this.props.usuario.ultimaSucursalPickup || sucursales.find(x => x.porDefecto === true).idSucursal;
          break;
      
        case 2:
          id = this.props.usuario.ultimaSucursalDelivery || sucursales.find(x => x.porDefecto === true).idSucursal;
          break;
        
        default:
          break;
      }

      const payload = {
        idTipoCalendario: idTipoCalendario,
        idInstalacion: id || this.props.instalacion.id,
        idRecurso: id || 1
      }

      this.props.sagaFetchCalendario(payload);
    }

    handleOnChangeSucursalPickup = (idSucursal) => {
      this.setState({sucursalPickupSeleccionada: idSucursal});
      const payload = {
        idTipoCalendario: 1,
        idInstalacion: idSucursal,
        idRecurso: idSucursal || 1
      }

      this.props.sagaFetchCalendario(payload);
    }

    componentDidUpdate = () => {
    }

    
    componentWillReceiveProps(nextProps){
      if(this.state.sucursalesPickup.length === 0){
        let sucursales = [];
        nextProps.sucursales.map(sucursal => {
          let formaEntrega = sucursal.formasDeEntrega.find(x => x.idFormaEntrega === 1)
          if(formaEntrega)
            sucursales.push({
              idSucursal: sucursal.idSucursal,
              nombre: sucursal.nombre,
              direccion: sucursal.direccion,
              porDefecto: formaEntrega.porDefecto
            })
        })
        this.setState({sucursalesPickup: sucursales, sucursalPickupSeleccionada: this.props.usuario.ultimaSucursalPickup || Number(sucursales.find(x => x.porDefecto === true).idSucursal)})
      }
      this.render()
  }

    render() {
       
        return (
            <Calendario
              calendarioPickup={this.props.calendarioPickup}
              calendarioDelivery={this.props.calendarioDelivery}
              handleOnClose={this.props.handleOnClose}
              sucursalesPickup={this.state.sucursalesPickup}
              sucursalPickupSeleccionada={this.state.sucursalPickupSeleccionada}
              handleOnChangeSucursalPickup={(e) => this.handleOnChangeSucursalPickup(e.target.value)}
            />
        )
    }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...calendarioActions,
    
  }, dispatch)
}

function mapStateToProps(state) {
  return {
    instalacion: instalacionSelector(state),
    calendarioPickup: calendarioPickupSelector(state),
    calendarioDelivery:calendarioDeliverySelector(state),
    usuario: usuarioSelector(state),
    sucursales: sucursalesSelector(state)
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(CalendarioContainer)