import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-flexbox-grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import {
  Close as CloseIcon,
} from "@material-ui/icons";
import Heading from '../../UI/Texts/Heading'
import Paragraph from '../../UI/Texts/Paragraph'
import CircularProgress from "@material-ui/core/CircularProgress";

const ModalOfertas = ({
  classes,
  openModalOfertas,
  handleOnClickCloseModalOfertas,
  ofertas,
  handleOnClickOferta }) => {

  return <Dialog
    classes={{
      root: classes.rootDialog,
      paperScrollPaper: classes.rootContent,
    }}
    open={openModalOfertas}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    maxWidth="sm"
    fullWidth={true}
  >
    <DialogContent classes={{ root: classes.rootContent }}>
      <Row style={{ margin: "0px" }}>
        <Col xs={12} md={12} lg={12} style={{ padding: "0px" }}>
          <div className="containerModal">
            <div className="containerTitle">
              <div className="label"><Heading tag="h4" weight="bold">Ofertas</Heading></div>
              <div style={{ display: "flex" }}>
                <CloseIcon
                  cursor="pointer"
                  fontSize="medium"
                  onClick={handleOnClickCloseModalOfertas}
                />
              </div>
            </div>

            {ofertas === undefined && (
              <div className="loader">
                <CircularProgress />
              </div>
            )}

            {ofertas && (
              <div className="containerOfertas">
                {ofertas.map((o) => (
                  <ExpansionPanelDetails style={{ padding: "0px" }}>
                    <div
                      className="ofertaItem"
                      onClick={() => {
                        handleOnClickOferta(o);
                      }}
                    >
                      <Typography className={`${classes.heading} label`}>
                      <Paragraph size="big" weight="bold">{o.nombre}</Paragraph>
                      </Typography>
                    </div>
                  </ExpansionPanelDetails>
                ))}
              </div>
            )}

            {ofertas && ofertas.length === 0 && (
              <div className="labelNoEncontrado">
                No se encontraron ofertas para mostrar
              </div>
            )}
          </div>
        </Col>
      </Row>
    </DialogContent>
  </Dialog>
}

ModalOfertas.propTypes = {
  classes: PropTypes.any.isRequired,
  openModalOfertas: PropTypes.func.isRequired,
  handleOnClickCloseModalOfertas: PropTypes.func.isRequired,
  ofertas: PropTypes.array.isRequired,
  handleOnClickOferta: PropTypes.func.isRequired,
}

export default ModalOfertas;