import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formatMoney } from '../../components/Common/decimalHelper';
import * as Notification from '../../components/Common/notification';
import Articulos from '../../components/MostrarArticulos';
import * as catalogoActions from '../../reducers/CatalogoReducer/actions';
import * as layoutActions from '../../reducers/LayoutReducer/actions';
import * as pedidoActions from '../../reducers/PedidoReducer/actions';
import * as sessionActions from '../../reducers/SessionReducer/actions';
import { catalogoDataSelector } from '../../selectors/catalogoSelector';
import { pedidoDataSelector, pedidosBorradorCliente, openModalVaciarCarritoSelector } from '../../selectors/pedidoSelector';
import { clienteSelector, instalacionSelector, usuarioSelector } from '../../selectors/sessionSelector';
import { vistaFavoritosSelector } from '../../selectors/layoutSelector';

const PAGE_SIZE = 20

const estadosPedido = {
  CONFIRMADO: 2,
  ELIMINADO: 6
}

class ContenidoCatalogoContainer extends React.Component {

  constructor(props) {
    super(props)

    window.scrollTo(0, 0)

    this.state = {
      cargandoCatalogo: true,
      cargandoPedido: true,
      mostrarArticulos: false,
      listaArticulos: undefined,
      idArticulo: undefined,
      idPedidoDetalle: undefined,
      idCatalogoEntrada: undefined,
      backCatalogos: false,
      backRubros: false,
      bandaDescuentoActual: 0,
      proximaBandaDescuento: 0,
      mostrarModalBorradores: true,
      listaArticulosPedidos: undefined,
      //Para mostrar la banda de precios de cada articulo
      bandaDePrecios: '',
      page: 2,
      pageSize: PAGE_SIZE,
      filter: undefined,
      idCatalogo: this.props.match && this.props.match.params && this.props.match.params.idCatalogo ? this.props.match.params.idCatalogo : this.props.idCatalogoSeleccionado,
      filtroCargado: false,
      idInstalacion: this.props.instalacion ? this.props.instalacion.id : 0,
      ordenamiento: 1,
      errorBusqueda: false,
      openModalAvisoDeRetiro: false,
      idSubrubroSeleccionado: undefined,
      esRubro: false,
      subrubroSeleccionado: false,
      ofertaSeleccionada: this.props.oferta || this.props.dataCatalogo.ofertaSeleccionada,

      showModalOpcionesProducto: false,
      filtrosArticulosSeleccionados: [],
    }

    //veo si hay pedidos en borrador del mismo cliente
    if (this.props.usuario !== undefined) {
      let payload = {}
      payload.idUsuario = this.props.usuario.id

      this.props.sagaFetchPedidoBorrador(payload)
    }

    if ((this.props.oferta && this.props.oferta.idOferta) || this.props.codigoProveedor) {
      this.props.setLimpiarFiltro(true)
    }

    // obtengo catalogo selccionado
    let payload = {
      idCatalogo: this.state.idCatalogo,
      filter: this.state.filter,
      orderBy: undefined,
      page: 1,
      pageSize: this.state.pageSize + 20,
      idInstalacion: this.state.idInstalacion,
      idSubrubro: this.state.idSubrubroSeleccionado,
      esRubro: this.state.esRubro,
      vistaFavoritos: this.props.vistaFavoritos,
      idUsuario: this.props.usuario !== undefined ? this.props.usuario.id : undefined,
      idOferta: this.state.ofertaSeleccionada ? this.state.ofertaSeleccionada.idOferta : undefined,
      codigoProveedor: this.props.codigoProveedor
    }

    payload.callback = () => {
      this.setState({ cargandoCatalogo: false, page: 1, filtroCargado: false, errorBusqueda: true })
    }

    if (!(this.props.dataCatalogo && (this.props.dataCatalogo.filterBusqueda || (this.props.dataCatalogo.subrubroSeleccionado && this.props.dataCatalogo.subrubroSeleccionado.id))) || this.props.vistaFavoritos) {
      this.props.sagaFetchCatalogoSeleccionado(payload)
    }

  }


  componentDidMount = () => {
    // indico que el Login se puede ver aunque el usuario no esté logeado
    this.props.setPermiteUsuarioAnonimo(true);
  }

  componentWillUnmount = () => {

    this.props.setPermiteUsuarioAnonimo(false)
    this.props.setPedidoBorrador(undefined)
  }

  componentDidUpdate = (prevProps) => {
    const ofertaSeleccionada = this.props.oferta || this.props.dataCatalogo.ofertaSeleccionada;

    if ((this.props.vistaFavoritos === true && prevProps.vistaFavoritos === false)) {

      this.props.setBuscandoCatalogo(true)
      this.props.setCatalogoSeleccionado(undefined)

      // obtengo catalogo selccionado
      let payload = {
        idCatalogo: this.state.idCatalogo,
        filter: undefined,
        orderBy: undefined,
        page: 1,
        pageSize: this.state.pageSize + 20,
        idInstalacion: this.state.idInstalacion,
        idSubrubro: undefined,
        esRubro: false,
        vistaFavoritos: this.props.vistaFavoritos,
        idUsuario: this.props.usuario !== undefined ? this.props.usuario.id : undefined,
        idOferta: ofertaSeleccionada ? ofertaSeleccionada.idOferta : undefined,
        codigoProveedor: this.props.codigoProveedor
      }

      payload.callback = () => {

        this.setState({ cargandoCatalogo: false, page: 1, filtroCargado: false, errorBusqueda: true })
      }

      this.setState({ filter: this.props.dataCatalogo.filterBusqueda }, () => {

        this.props.sagaFetchCatalogoSeleccionado(payload)
      })

    }

    if ((this.props.vistaFavoritos === false && prevProps.vistaFavoritos === true)) {

      this.props.setBuscandoCatalogo(true)
      this.props.setCatalogoSeleccionado(undefined)

      // obtengo catalogo selccionado
      let payload = {
        idCatalogo: this.state.idCatalogo,
        filter: undefined,
        orderBy: undefined,
        page: 1,
        pageSize: this.state.pageSize + 20,
        idInstalacion: this.state.idInstalacion,
        idSubrubro: undefined,
        esRubro: false,
        vistaFavoritos: false,
        idUsuario: this.props.usuario !== undefined ? this.props.usuario.id : undefined,
        idOferta: ofertaSeleccionada ? ofertaSeleccionada.idOferta : undefined,
        codigoProveedor: this.props.codigoProveedor
      }

      payload.callback = () => {

        this.setState({ cargandoCatalogo: false, page: 1, filtroCargado: false, errorBusqueda: true })
      }

      this.setState({ filter: this.props.dataCatalogo.filterBusqueda }, () => {

        this.props.sagaFetchCatalogoSeleccionado(payload)
      })

    }

    if (this.state.cargandoCatalogo === true && this.props.dataCatalogo.catalogoSeleccionado !== undefined && !this.props.dataCatalogo.buscandoCatalogo) {
      // compruebo contenido del catalogo

      this.setState({ cargandoCatalogo: false })
    }

    if (this.props.esOferta !== true && this.props.vistaFavoritos === false && this.state.filtroCargado === false && this.props.dataCatalogo.filterBusqueda !== this.state.filter && this.props.dataCatalogo.subrubroSeleccionado.id === undefined) {
      this.setState({ filtroCargado: true, filter: this.props.dataCatalogo.filterBusqueda, idSubrubroSeleccionado: undefined }, () => {
        this.buscarArticulosByFilter(this.props.dataCatalogo.filterBusqueda)
      })

    }

    const idPrevOferta = prevProps.oferta ? prevProps.oferta.idOferta : undefined;
    const idOferta = this.props.oferta ? this.props.oferta.idOferta : undefined;
    const cambioOferta = idPrevOferta !== idOferta;

    const idPrevOfertaSeleccionada = prevProps.dataCatalogo.ofertaSeleccionada ? prevProps.dataCatalogo.ofertaSeleccionada.idOferta : undefined;
    const idOfertaSeleccionada = this.props.dataCatalogo.ofertaSeleccionada ? this.props.dataCatalogo.ofertaSeleccionada.idOferta : undefined;
    const cambioOfertaSeleccionada = idPrevOfertaSeleccionada !== idOfertaSeleccionada;

    if (this.props.esOferta !== true &&
      this.props.dataCatalogo.filterBusqueda === undefined &&
      this.state.subrubroSeleccionado === false &&
      this.props.dataCatalogo.subrubroSeleccionado !== undefined &&
      (this.props.dataCatalogo.subrubroSeleccionado.id !== this.state.idSubrubroSeleccionado || cambioOferta || cambioOfertaSeleccionada)) {

      this.setState({
        subrubroSeleccionado: true,
        filter: undefined,
        idSubrubroSeleccionado: this.props.dataCatalogo.subrubroSeleccionado.id,
        esRubro: this.props.dataCatalogo.subrubroSeleccionado.esRubro,
        ofertaSeleccionada: ofertaSeleccionada,
      }, () => {
        this.buscarArticulosByFilter(undefined)
      })

    }

    if (this.state.cargandoPedido === true && this.props.dataPedido.pedido !== undefined) {
      // compruebo contenido del catalogo
      this.setState({ cargandoPedido: false }, () => {
        this.comprobarBanda()
        this.setListaArticulosPedidos()
      })
    }

    if (prevProps.dataPedido && this.props.dataPedido && JSON.stringify(this.props.dataPedido.pedido) !== JSON.stringify(prevProps.dataPedido.pedido)) {
      this.setListaArticulosPedidos()
    }
  }

  setListaArticulosPedidos = () => {

    if (this.props.dataPedido !== undefined && this.props.dataPedido.pedido != null) {

      let listaArticulosPedidos = {}

      this.props.dataPedido.pedido.listaPedidoDetalle.forEach((pd) => {
        listaArticulosPedidos[pd.idArticulo] = pd.cantidad
      })

      this.setState({ listaArticulosPedidos: listaArticulosPedidos })
    }
    else {
      this.setState({ listaArticulosPedidos: {} })
    }
  }

  actualizarDisponibilidadStockDeArticulo = () => {

    let lista = [...this.state.listaArticulos]

    lista.forEach((a) => {
      if (a.idArticulo === this.props.dataCatalogo.idArticuloStock) {
        a.stockDisponible = this.props.dataCatalogo.stockDisponible
      }
    })

    this.setState({
      listaArticulos: lista,
    })

    let payload = {}
    payload.idArticulo = undefined
    payload.disponible = undefined

    this.props.setDisponibilidadStockArticulo(payload)
  }

  comprobarBanda = () => {
    //seteo banda descuento del pedido
    this.setState({ bandaDescuentoActual: this.props.dataPedido.pedido.idBandaDescuento })
  }

  //si esta viendo los articulos de un catalogo y existe mas de un subrubro o mas de un catalogo puede volver atras
  //si esta viendo los articulos de un catalogo y no existe mas de un subrubro o mas de un catalogo no puede volver atras
  //si esta viendo los subrubros de un catalogo y existe mas de un catalogo puede volver atras
  //si esta viendo los subrubros de un catalogo y no existe mas de un de catalogo no puede volver atras
  handleOnBackClick = () => {
    if (this.state.backRubros) {
      if (this.props.catalogoUnico) {
        this.props.setHeader({
          showDrawer: true,
          showBackButton: false,
        })
      }
      this.setState({
        backRubros: false,
        mostrarArticulos: false
      })
      window.scrollTo(0, 0)
    }
    else {
      if (this.state.backCatalogos) {
        this.props.setHeader({
          showDrawer: true,
          showBackButton: false,
        })
        window.location.hash = '/Catalogo'
      }
    }

  }

  //armo la lista de articulos de un subrubro
  obtenerArticulosDeUnSubRubro = (idRubro, idSubRubro) => {

    if (this.props.dataCatalogo.catalogoSeleccionado === undefined || this.props.instalacion === undefined)
      return undefined


    let rubro = this.props.dataCatalogo.catalogoSeleccionado.listaRubros.find(r => r.idRubro === idRubro);

    let subRubro = rubro.listaSubRubros.find(s => s.idSubrubro === idSubRubro);

    //agrego a los articulos informacion sobre las bandas de descuentos para armar la lista de precios de los mismos
    let articulos = [...subRubro.listaArticulos]
    articulos = articulos.map((a) => {

      let preciosBanda = []

      this.props.instalacion.listaBandaDescuento.forEach((bd) => {
        let bandaPrecio = {}
        bandaPrecio.idBandaDescuento = bd.idBandaDescuento
        bandaPrecio.idBandaDescuentoPorcentaje = bd.idBandaDescuentoPorcentaje
        bandaPrecio.precio = (a.precioLista - (a.precioLista * (bd.porcentajeDescuento / 100))).toFixed(2)
        bandaPrecio.nombre = bd.nombre

        preciosBanda.push(bandaPrecio)
      })

      a.preciosBanda = preciosBanda
      a.precioSugerido = (a.precioLista * (this.props.instalacion.porcentajePrecioSugerido / 100)).toFixed(2)

      //verifico si el articulo esta en el pedido borrador del cliente y si tiene stock
      let resultado = this.estaPedido(a.idArticulo)
      a.estaPedido = resultado.estaPedido
      a.idPedidoDetalle = resultado.idPedidoDetalle

      return a
    })

    return articulos
  }

  //consulto si un articulo está pedido
  estaPedido = (idArticulo) => {

    let resultado = {}

    if (this.props.dataPedido.pedido === undefined) return false

    this.props.dataPedido.pedido.listaPedidoDetalle.forEach(pd => {
      if (pd.idArticulo === idArticulo) {
        resultado.estaPedido = true
        resultado.idPedidoDetalle = pd.idPedidoDetalle
      }
    })

    return resultado
  }

  //consulto si un articulo está agotado
  estaAgotado = (articulo) => {

    let estaAgotado = true

    articulo.listaTalleColor.forEach(tc => {
      if (tc.stock > 0) estaAgotado = false
    })

    return estaAgotado
  }

  //seteo idArticulo e idCatalogoEntrada para añadir el nuevo pedidoDetalle
  handleOnButtonPedilo = (idArticulo, idCatalogoEntrada) => {
    this.setState({
      idArticulo: idArticulo,
      idCatalogoEntrada: idCatalogoEntrada,
    })
  }

  //Manejo el agregado o quitado de pedidoDetalle
  handleOnPedidoDetalleModalClose = (confirma, detallePedido, precioPagado) => {

    let articuloId = this.state.idArticulo
    let catalogoEntradaId = this.state.idCatalogoEntrada
    let idPedidoDetalle = this.state.idPedidoDetalle
    if (confirma === true && this.state.idPedidoDetalle === undefined) {
      //armo el model agregar el PedidoDetalle
      let addPedidoModel = {}
      addPedidoModel.idInstalacion = this.props.instalacion.id
      addPedidoModel.idCliente = this.props.usuario.idCliente
      addPedidoModel.usuarioRegistro = this.props.usuario.id
      addPedidoModel.idCatalogoEntrada = catalogoEntradaId
      addPedidoModel.idArticulo = articuloId
      let listaTalleColor = []
      let cantidad = 0
      detallePedido.talleColor.forEach((dp) => {
        let talleColor = {}
        talleColor.idTalleColor = dp.idTalleColor
        talleColor.cantidad = dp.cantidad
        cantidad = cantidad + dp.cantidad
        listaTalleColor.push(talleColor)
      })

      addPedidoModel.listaTalleColor = listaTalleColor
      addPedidoModel.callbackFunctionAfter = () => { this.afterAddDetallePedido(articuloId, cantidad, detallePedido.nombre, precioPagado) }

      addPedidoModel.callbackFunctionError = () => {
        this.setState({
          idArticulo: undefined,
          idCatalogoEntrada: undefined,
          idPedidoDetalle: undefined
        })
      }

      if (this.props.dataPedido.pedido !== undefined) {
        this.props.setTimerReiniciar(true)
      }

      //agrego el pedidoDetalle
      this.props.sagaAddPedidoDetalle(addPedidoModel)
    }
    else {
      if (confirma === true && this.state.idPedidoDetalle !== undefined) {

        //armo el modal para eliminar el pedidoDetalle
        let removePedidoModel = {}
        removePedidoModel.idPedido = this.props.dataPedido.pedido.id
        removePedidoModel.idUsuario = this.props.usuario.id
        removePedidoModel.idPedidoDetalle = idPedidoDetalle
        //Agrego el idArticulo para tenerlo en la saga (no se usa en el model del controller)
        removePedidoModel.idArticulo = articuloId

        if (this.props.dataPedido.pedido !== undefined) {
          this.props.setTimerReiniciar(true)
        }
        //Eliminar el pedidoDetalle
        this.props.sagaRemovePedidoDetalle(removePedidoModel)
        //Elimino la etiqueta PEDIDO del articulo y lo seteo como no pedido
        this.marcarArticuloComoNoPedido(articuloId)
      }
      else {
        this.setState({
          idArticulo: undefined,
          idCatalogoEntrada: undefined,
          idPedidoDetalle: undefined,
        })
      }
    }
  }

  afterAddDetallePedido = (articuloId, cantidad, nombre, precioPagado) => {
    if (this.props.instalacion.promosBandaDescuentos) {
      this.controlBandaDescuento(articuloId, cantidad, nombre, precioPagado)
    }
  }

  //Notificaciones sobre el pedido
  controlBandaDescuento = (articuloId, cantidad, nombre, precioPagado) => {
    let precioTotal = 0
    let articulo = this.state.listaArticulos.filter((a) => a.idArticulo === articuloId)
    precioTotal = (this.props.dataPedido.pedido !== undefined) ? ((articulo[0].precioLista * cantidad) + this.props.dataPedido.pedido.precioLista) : (articulo[0].precioLista * cantidad)


    //Mensaje para cantidad de articulos mayor a 1
    if (cantidad > 1) {
      Notification.showSuccess(`Se agregaron a su pedido ${cantidad} unidades de ${nombre} por ${formatMoney(cantidad * precioPagado)}`)
    }
    //Mensaje para cantidad de articulos igual a 1
    else {
      Notification.showSuccess(`Se agregó a su pedido 1 unidad de ${nombre} por ${formatMoney(cantidad * precioPagado)}`)
    }

    if (this.props.dataPedido.pedido === undefined || this.props.dataPedido.pedido.idBandaDescuento === 0) {
      let i = 0

      //obtengo las banda de descuentos de la instalacion
      let listaBandas = this.props.instalacion.listaBandaDescuento
      //Obtengo la banda con limite inferior mas alto que haya superado y muestro info (si es que supero alguna o tiene alguna banda por superar)

      while (i < listaBandas.length) {

        if (precioTotal > listaBandas[i].limiteInferior) {
          //Notification.showInfo(`Su compra superó los ${formatMoney(listaBandas[i].limiteInferior)} y accedió al ${listaBandas[i].nombre}`)
          return
        }
        i++
      }
      //si no supero ninguna banda, muestro informacion sobre la primer banda a superar
      //Notification.showInfo(`Si su compra supera los ${formatMoney(primeraBanda.limiteInferior)} puede acceder a los precios indicados como ${primeraBanda.nombre}`)
    }
    else {

      let bandaActual = (this.props.instalacion.listaBandaDescuento.filter((b) => b.idBandaDescuentoPorcentaje === this.props.dataPedido.pedido.idBandaDescuento))[0]
      let bandasSuperiores = this.props.instalacion.listaBandaDescuento.filter((banda) => bandaActual.limiteSuperior !== null && banda.limiteInferior > bandaActual.limiteSuperior)

      let i = 0
      //si supera alguna banda muestro informacion sobre la banda superada
      while (i < bandasSuperiores.length) {
        if (precioTotal > bandasSuperiores[i].limiteInferior) {
          //Notification.showInfo(`Su compra superó los ${formatMoney(bandasSuperiores[i].limiteInferior)} y accedió al ${bandasSuperiores[i].nombre}`)
          return
        }
        i++
      }
      //si existe banda superior, muestro info sobre los descuentos de dicha banda si la alcanzara
      let bandaInmediataSuperior = bandasSuperiores[bandasSuperiores.length - 1]
      if (bandaInmediataSuperior !== undefined) {
        //Notification.showInfo(`Si su compra supera los ${formatMoney(bandaInmediataSuperior.limiteInferior)} puede acceder a los precios indicados como ${bandaInmediataSuperior.nombre}`)
        return
      }
    }
  }

  //Marca articulo como pedido para mostrar la etiqueta PEDIDO y asignar idPedidoDetalle al articulo para poder quitarlo
  marcarArticuloComoPedido = (idPedidoDetalle) => {
    //asigno articulo como pedido y lo enlazo al pedidoDetalle agregado
    this.state.listaArticulos.forEach((a) => {
      if (a.idArticulo === this.state.idArticulo) {
        a.estaPedido = true
        a.idPedidoDetalle = idPedidoDetalle
      }
    })

    this.setState({
      idArticulo: undefined,
      idCatalogoEntrada: undefined,
      idPedidoDetalle: undefined,
    })
  }

  //Marca articulo como NO pedido para eliminar la etiqueta PEDIDO
  marcarArticuloComoNoPedido = (articuloId) => {
    this.state.listaArticulos.forEach((a) => {
      if (a.idArticulo === articuloId) {
        a.estaPedido = false
        a.idPedidoDetalle = undefined
      }
    })

    this.setState({
      idArticulo: undefined,
      idCatalogoEntrada: undefined,
      idPedidoDetalle: undefined,
    })

  }

  handleOnBorrar = (idArticulo, idPedidoDetalle) => {
    this.setState({
      idArticulo: idArticulo,
      idPedidoDetalle: idPedidoDetalle,
    })
  }

  handleOnCloseModal = () => {
    this.setState({
      mostrarModalBorradores: false,
    })
  }

  getBandaDePreciosCasoDos = (precioListaPedido, precioListaItem) => {

    let precios = ''

    if (this.props.instalacion.promosBandaDescuentos) {

      let i = 0
      let listaBandas = this.props.instalacion.listaBandaDescuento
      let flag = false
      let idBanda = 0

      while (i < listaBandas.length && flag === false) {
        if (precioListaPedido > listaBandas[i].limiteInferior) {
          flag = true
          idBanda = listaBandas[i].idBandaDescuento
        }
        i++
      }

      listaBandas = listaBandas.filter(x => x.idBandaDescuento !== idBanda)

      for (i = 0; i < listaBandas.length; i++) {
        precios = precios + listaBandas[i].nombre + ": $" + (precioListaItem - (precioListaItem * (listaBandas[i].porcentajeDescuento / 100)))
        if (!(i === (listaBandas.length - 1))) {
          precios = precios + ' - '
        }
        else {
          if (this.props.instalacion.mostrarPrecioSugerido && flag) {
            precios = precios + " - Sugerido: $" + precioListaItem
          }
        }
      }
    }

    return precios
  }

  agregarQuitarUnidad = (cantidad, articulo, alternativa) => {

    if (this.props.usuario === undefined) {
      window.location.hash = '/Login'
      return
    }
    if (cantidad === 0) {
      //si existe un pedido hago la llamada, si no no
      if (this.props.dataPedido && this.props.dataPedido.pedido && this.props.dataPedido.pedido.listaPedidoDetalle) {
        let pedidoDetalle = this.props.dataPedido.pedido.listaPedidoDetalle.filter(x => x.idArticulo === articulo.idArticulo)

        //si ya existe un pedido detalle hago la llamada si no no
        if (pedidoDetalle.length > 0) {
          let payload = {
            idPedido: this.props.dataPedido.pedido.id,
            idUsuario: this.props.usuario.id,
            idPedidoDetalle: pedidoDetalle[0].idPedidoDetalle
          }

          this.props.sagaRemovePedidoDetalle(payload)
        }
        else {
          return
        }
      }
      else return
    }
    else {
      //armo el model agregar el PedidoDetalle
      let addPedidoModel = {};
      addPedidoModel.idInstalacion = this.props.instalacion.id;
      addPedidoModel.idCliente = this.props.usuario.idCliente;
      addPedidoModel.usuarioRegistro = this.props.usuario.id;
      addPedidoModel.Items = [{ alternativa, cantidad, idArticulo: articulo.idArticulo, idCatalogoEntrada: articulo.idCatalogoEntrada }]

      addPedidoModel.callbackFunctionAfter = (pedido) => {
        Notification.showSuccess(`Se ${cantidad > 1 ? 'agregaron' : 'agregó'} a su pedido ${cantidad} ${articulo.productoPesable ? 'kg' : cantidad > 1 ? 'unidades' : 'unidad'} de ${articulo.nombre}.`)

        let showModalOpcionesProducto = false;
        try {
          const localStorageKey = 'ALVEAR_ONLINE_pedido_borrador_data';
          const localStorageItem = JSON.parse(localStorage.getItem(localStorageKey)) || {};
          const articulosConAlternativas = !!pedido.pedidoDetalles.find((x) => x.alternativa);

          if (localStorageItem.idPedido === pedido.idPedido) {
            if (!localStorageItem.seAgregaronItemsConAlternativas) {
              localStorageItem.seAgregaronItemsConAlternativas = articulosConAlternativas;
              showModalOpcionesProducto = articulosConAlternativas;
            }
          } else {
            localStorageItem.idPedido = pedido.idPedido;
            localStorageItem.seAgregaronItemsConAlternativas = articulosConAlternativas;
            showModalOpcionesProducto = articulosConAlternativas;
          }

          localStorage.setItem(localStorageKey, JSON.stringify(localStorageItem));
        } catch (error) {
          console.error('Error al guardar información de pedido en local storage', error);
        }

        if (showModalOpcionesProducto) {
          this.setState({ showModalOpcionesProducto: true });
        }
      }

      addPedidoModel.callbackFunctionError = () => {
        this.setState({
          idArticulo: undefined,
          idCatalogoEntrada: undefined,
          idPedidoDetalle: undefined
        })
      }

      if (this.props.dataPedido.pedido !== undefined) {
        this.props.setTimerReiniciar(true)
      }

      //agrego el pedidoDetalle
      this.props.sagaAddPedidoDetalle(addPedidoModel)
    }

  }

  getDetallePedido = (cantidad, articulo) => {

    let myDetallePedido = {}
    myDetallePedido.idArticulo = articulo.idArticulo
    myDetallePedido.nombre = articulo.nombre
    myDetallePedido.talleColor = []

    let item = {
      idTalleColor: articulo.listaTalleColor[0].idTalleColor,
      cantidad: cantidad
    }

    myDetallePedido.talleColor.push(item)

    return myDetallePedido
  }

  handleShowResumenPedido = (show) => {
    this.props.setShowResumenPedido(show)
  }

  handleBuscarMasArticulos = () => {

    this.props.setBuscandoCatalogo(true)

    let page = this.state.page

    page = page + 1

    this.setState({ cargandoCatalogo: true, page: page, errorBusqueda: false }, () => {
      let payload = {
        idCatalogo: this.state.idCatalogo,
        filter: this.state.filter,
        subfiltros: this.state.filtrosArticulosSeleccionados.map((x) => `${x.tipo}#${x.valor}`).join('|'),
        orderBy: this.state.filter !== undefined || (this.props.dataCatalogo && this.props.dataCatalogo.subrubroSeleccionado && this.props.dataCatalogo.subrubroSeleccionado.nombre) ? this.state.ordenamiento : undefined,
        page: this.state.page,
        pageSize: this.state.pageSize,
        idInstalacion: this.state.idInstalacion,
        idSubrubro: this.state.idSubrubroSeleccionado,
        esRubro: this.state.esRubro,
        vistaFavoritos: this.props.vistaFavoritos,
        idUsuario: this.props.usuario !== undefined ? this.props.usuario.id : undefined,
        idOferta: this.state.ofertaSeleccionada ? this.state.ofertaSeleccionada.idOferta : undefined,
        codigoProveedor: this.props.codigoProveedor
      }

      payload.callback = () => {
        this.setState({ cargandoCatalogo: false, page: page, filtroCargado: false, errorBusqueda: true })
      }
      this.props.sagaFetchCatalogoSeleccionado(payload)
    })
  }

  buscarArticulosByFilter = (filter) => {

    this.props.setBuscandoCatalogo(true)
    this.props.setCatalogoSeleccionado(undefined)

    this.setState({
      cargandoCatalogo: true,
      page: 2,
      filtroCargado: false,
      subrubroSeleccionado: false,
      ordenamiento: 1,
      errorBusqueda: false,
      filtrosArticulosSeleccionados: filter || this.state.idSubrubroSeleccionado ? this.state.filtrosArticulosSeleccionados : []
    }, () => {


      let payload = {
        idCatalogo: this.state.idCatalogo,
        filter: filter,
        subfiltros: this.state.filtrosArticulosSeleccionados.map((x) => `${x.tipo}#${x.valor}`).join('|'),
        orderBy: undefined,
        page: 1,
        pageSize: this.state.pageSize + 20,
        idInstalacion: this.state.idInstalacion,
        idSubrubro: this.state.idSubrubroSeleccionado,
        esRubro: this.state.esRubro,
        vistaFavoritos: this.props.vistaFavoritos,
        idUsuario: this.props.usuario !== undefined ? this.props.usuario.id : undefined,
        idOferta: this.state.ofertaSeleccionada ? this.state.ofertaSeleccionada.idOferta : undefined,
        codigoProveedor: this.props.codigoProveedor
      }

      payload.callback = () => {
        this.setState({ cargandoCatalogo: false, page: 2, filtroCargado: false, subrubroSeleccionado: false, errorBusqueda: true })
      }

      window.scrollTo(0, 0)

      this.props.sagaFetchCatalogoSeleccionado(payload)
    })
  }

  getSubFiltrosArticulos = () => {
    const payload = {
      idCatalogo: this.state.idCatalogo,
      filter: this.state.filter,
      idSubRubro: this.state.idSubrubroSeleccionado,
      idUsuario: this.props.usuario !== undefined ? this.props.usuario.id : undefined,
      idOferta: this.state.ofertaSeleccionada ? this.state.ofertaSeleccionada.idOferta : undefined,
      codigoProveedor: this.props.codigoProveedor,
      esRubro: this.state.esRubro,
    };
    this.props.sagaFetchSubFiltrosArticulos(payload);
  }

  handleOnChangeFiltrosArticulos = (filtrosSeleccionados) => {
    this.setState({ filtrosArticulosSeleccionados: filtrosSeleccionados }, () => this.buscarArticulosByFilter(this.state.filter));
  };

  handleChangeOrdenamiento = (event) => {

    this.props.setBuscandoCatalogo(true)
    this.props.setCatalogoSeleccionado(undefined)

    let value = event.target.value

    this.setState({ cargandoCatalogo: true, page: 2, filtroCargado: false, ordenamiento: value, errorBusqueda: false }, () => {
      let payload = {
        idCatalogo: this.state.idCatalogo,
        filter: this.state.filter,
        orderBy: this.state.ordenamiento,
        page: 1,
        pageSize: this.state.pageSize + 20,
        idInstalacion: this.state.idInstalacion,
        idSubrubro: this.state.idSubrubroSeleccionado,
        esRubro: this.state.esRubro,
        vistaFavoritos: this.props.vistaFavoritos,
        idUsuario: this.props.usuario !== undefined ? this.props.usuario.id : undefined,
        idOferta: this.state.ofertaSeleccionada ? this.state.ofertaSeleccionada.idOferta : undefined,
        codigoProveedor: this.props.codigoProveedor
      }

      payload.callback = () => {
        this.setState({ cargandoCatalogo: false, page: 2, filtroCargado: false, errorBusqueda: true })
      }

      this.props.sagaFetchCatalogoSeleccionado(payload)
    })
  }

  handleOnClickVaciarCarrtio = (confirma) => {

    if (confirma) {

      if (this.props.dataPedido === undefined || this.props.dataPedido.pedido === undefined) {
        return
      }

      this.props.setOpenModalVaciarCarrito(false)
      //this.props.setBanderaPedido(false)
      // actualizo el estado del pedido

      let payload = {
        Id: this.props.dataPedido.pedido.id,
        IdEstadoPedido: estadosPedido.ELIMINADO,
        IdFormaPagoOnline: undefined
      }

      payload.callback = () => {
      }

      this.props.sagaConfirmarEliminarPedido(payload)
    }
    else {
      this.props.setOpenModalVaciarCarrito(false)
    }
  }

  handleOnShowBusqueda = (show) => {
    this.props.showBusqueda(show)
  }

  handleOnClickCloseModalAviso = () => {
    this.setState({ openModalAvisoDeRetiro: false })
  }

  handleOnClickWhatsApp = () => {
    if (this.props.instalacion === undefined || !this.props.instalacion.datosContacto ||
      !this.props.instalacion.datosContacto.telefono) {
      return
    }

    window.open(`http://wa.me/` + this.props.instalacion.datosContacto.telefono.replace(/\s/g, '').replace('-', '').replace('+549', "54"))
  }

  handleOnClickVerTodosLosProductos = () => {
    this.props.setVistaFavoritos(false)
    this.props.setLimpiarFiltro(true)
  }

  render() {

    return (
      <div>

        {/* Mustro articulos del catalogo */}
        <Articulos
          instalacion={this.props.instalacion}
          pedido={this.props.dataPedido.pedido}
          idArticuloModal={this.state.idArticulo}
          idPedidoDetalleModal={this.state.idPedidoDetalle}
          precioListaPedido={this.props.dataPedido.pedido !== undefined ? this.props.dataPedido.pedido.precioLista : 0}
          handleOnBorrar={this.handleOnBorrar}
          handleOnButtonPedilo={this.handleOnButtonPedilo}
          handleOnPedidoDetalleModalClose={this.handleOnPedidoDetalleModalClose}
          getBandaDePreciosCasoDos={this.getBandaDePreciosCasoDos}
          imagenesArticulos={this.props.dataCatalogo.imagenesArticulos}
          vistaFavoritos={this.props.vistaFavoritos}
          openModalAvisoDeRetiro={this.state.openModalAvisoDeRetiro}
          handleOnClickCloseModalAviso={this.handleOnClickCloseModalAviso}
          handleOnClickWhatsApp={this.handleOnClickWhatsApp}
          handleOnClickVerTodosLosProductos={this.handleOnClickVerTodosLosProductos}
          agregarQuitarUnidad={this.agregarQuitarUnidad}
          handleShowResumenPedido={this.handleShowResumenPedido}
          listaArticulosPedidos={this.state.listaArticulosPedidos}
          handleBuscarMasArticulos={this.handleBuscarMasArticulos}
          cantidadArticulosFiltrados={this.props.dataCatalogo.cantidadArticulosFiltradosCatSel}
          cantidadArticulos={this.props.dataCatalogo.cantidadArticulos}
          filterBusqueda={this.props.dataCatalogo.filterBusqueda ? this.props.dataCatalogo.filterBusqueda : this.props.dataCatalogo.subrubroSeleccionado && this.props.dataCatalogo.subrubroSeleccionado.nombre ? this.props.dataCatalogo.subrubroSeleccionado.nombre : undefined}
          buscandoCatalogo={this.props.dataCatalogo.buscandoCatalogo}
          ordenamiento={this.state.ordenamiento}
          handleChangeOrdenamiento={this.handleChangeOrdenamiento}
          errorBusqueda={this.state.errorBusqueda}
          openModalVaciarCarrito={this.props.openModalVaciarCarrito}
          handleOnClickVaciarCarrtio={this.handleOnClickVaciarCarrtio}
          handleOnShowBusqueda={this.handleOnShowBusqueda}
          listadoSecciones={this.props.dataCatalogo.catalogoSeleccionado ? this.props.dataCatalogo.catalogoSeleccionado.listadoSecciones : undefined}

          oferta={this.state.ofertaSeleccionada}

          showModalOpcionesProducto={this.state.showModalOpcionesProducto}
          handleOnCloseModalOpcionesProducto={() => this.setState({ showModalOpcionesProducto: false })}

          getSubFiltrosArticulos={this.getSubFiltrosArticulos}
          subFiltrosArticulos={this.props.dataCatalogo.subFiltrosArticulos}
          handleOnChangeFiltrosArticulos={this.handleOnChangeFiltrosArticulos}
        />
      </div>
    )
  }
}



function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...catalogoActions,
    ...sessionActions,
    ...pedidoActions,
    ...layoutActions
  }, dispatch)
}

function mapStateToProps(state) {
  return {
    dataCatalogo: catalogoDataSelector(state),
    instalacion: instalacionSelector(state),
    dataPedido: pedidoDataSelector(state),
    usuario: usuarioSelector(state),
    cliente: clienteSelector(state),
    dataBorradores: pedidosBorradorCliente(state),
    openModalVaciarCarrito: openModalVaciarCarritoSelector(state),
    vistaFavoritos: vistaFavoritosSelector(state)
  }
}

ContenidoCatalogoContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  idCatalogoSeleccionado: PropTypes.object.isRequired,
  catalogoUnico: PropTypes.object.isRequired,
  esOferta: PropTypes.bool.isRequired,
  oferta: PropTypes.object,
  codigoProveedor: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContenidoCatalogoContainer)