import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import PropTypes from 'prop-types'
import './style.css'

const Banner = ({ vistaFavoritos, instalacion, filterBusqueda, pedido }) => {
  return (
    <>
      {/* BANNER MOVIL */}
      {!vistaFavoritos && instalacion.listaImagenesBannerMovil && instalacion.listaImagenesBannerMovil.length > 0 && filterBusqueda === undefined &&
        <Carousel className='containerCarousel bannerMobile' infiniteLoop={true} showArrows={false} showThumbs={false} showStatus={false} autoPlay={true} swipeable={false} interval={5000}>
          {instalacion.listaImagenesBannerMovil.map((pathBanner) => {
            return <div className={`containerBanner ${pedido === undefined ? 'bannerMobileAux' : ''}`}>
              <img alt="" className={`imgBanner ${pedido !== undefined ? 'imgBannerAux' : ''}`} src={pathBanner} />
            </div>
          })}
        </Carousel>}

      {/* BANNER DESKTOP */}
      {!vistaFavoritos && instalacion.listaImagenesBannerDesktop && instalacion.listaImagenesBannerDesktop.length > 0 && filterBusqueda === undefined &&
        <Carousel className='containerCarousel bannerMonitor' infiniteLoop={true} showArrows={false} showThumbs={false} showStatus={false} autoPlay={true} swipeable={false} interval={5000}>
          {instalacion.listaImagenesBannerDesktop.map((pathBanner) => {
            return <div className={`containerBanner ${pedido === undefined ? 'bannerMonitorAux' : ''}`}>
              <img alt="" className={`imgBanner ${pedido !== undefined ? 'imgBannerAux' : ''}`} src={pathBanner} />
            </div>
          })}
        </Carousel>}
    </>
  )
}

Banner.propTypes = {
  filterBusqueda: PropTypes.string.isRequired,
  instalacion: PropTypes.object.isRequired,
  pedido: PropTypes.object.isRequired,
  vistaFavoritos: PropTypes.bool.isRequired,
};

export default Banner;