// acciones
export const INITIALIZE_FORM = 'pedidoReducer/INITIALIZE_FORM'
export const SAGA_FETCH_PEDIDO = 'pedidoReducer/SAGA_FETCH_PEDIDO'
export const SAVE_PEDIDO = 'pedidoReducer/SAVE_PEDIDO'
export const SAGA_ADD_PEDIDO_DETALLE = 'pedidoReducer/SAGA_ADD_PEDIDO_DETALLE'
export const SAGA_REMOVE_PEDIDO_DETALLE = 'pedidoReducer/SAGA_REMOVE_PEDIDO_DETALLE'

export const SAGA_UPDATE_ESTADOPEDIDO_ABANDONADO = 'pedidoReducer/SAGA_UPDATE_ESTADOPEDIDO_ABANDONADO'

export const SAVE_ID_PEDIDO_DETALLE = 'pedidoReducer/SAVE_ID_PEDIDO_DETALLE'
export const SET_UNDEFINED_ID_PEDIDO_DETALLE = 'pedidoReducer/SET_UNDEFINED_ID_PEDIDO_DETALLE'

export const GET_PEDIDOSUSER = 'pedidoReducer/GET_PEDIDOSUSER'
export const SET_PEDIDOSUSER = 'pedidoReducer/SET_PEDIDOSUSER'

export const GET_PEDIDOSCLIENTE = 'pedidoReducer/GET_PEDIDOSCLIENTE'
export const SET_PEDIDOSCLIENTE = 'pedidoReducer/SET_PEDIDOSCLIENTE'
export const GET_PEDIDOS_BORRADOR_CLIENTE = 'pedidoReducer/GET_PEDIDOS_BORRADOR_CLIENTE'
export const SET_PEDIDOS_BORRADOR_CLIENTE = 'pedidoReducer/SET_PEDIDOS_BORRADOR_CLIENTE'

export const ACTUALIZAR_FECHA_PEDIDO_BORRADOR = 'pedidoReducer/ACTUALIZAR_FECHA_PEDIDO_BORRADOR'

export const SET_SHOW_RESUMEN_PEDIDO = 'pedidoReducer/SET_SHOW_RESUMEN_PEDIDO'
export const SET_OPEN_MODAL_VACIAR_CARRITO = 'pedidoReducer/SET_OPEN_MODAL_VACIAR_CARRITO'
export const SAGA_CONFIRMAR_ELIMINAR_PEDIDO = 'pedidoReducer/SAGA_CONFIRMAR_ELIMINAR_PEDIDO'

export const SAGA_SET_PEDIDODETALLE_REEMPLAZO = 'pedidoReducer/SAGA_SET_PEDIDODETALLE_REEMPLAZO'
export const SET_PEDIDODETALLE_REEMPLAZO = 'pedidoReducer/SET_PEDIDODETALLE_REEMPLAZO'

export const SAGA_SET_PEDIDO_PAGO_MP='pedidoReducer/SAGA_SET_PEDIDO_PAGO_MP'
export const SAGA_ENVIAR_MAIL_CONFIRMACION='pedidoReducer/SAGA_ENVIAR_MAIL_CONFIRMACION'

export const SAGA_GENERAR_CHECKOUT='pedidoReducer/SAGA_GENERAR_CHECKOUT'

export const SAGA_CONFIRMAR_PEDIDO_MOBBEX = 'pedidoReducer/SAGA_CONFIRMAR_PEDIDO_MOBBEX'

export const SAGA_FETCH_PREFERENCIAS_ULTIMO_PEDIDO = 'pedidoReducer/SAGA_FETCH_PREFERENCIAS_ULTIMO_PEDIDO'

// funciones
export const sagaConfirmarPedidoMobbex = (payload) => ({
    type: SAGA_CONFIRMAR_PEDIDO_MOBBEX,
    payload
})


export const sagaGenerarCheckoutMobbex = (payload) => ({
    type: SAGA_GENERAR_CHECKOUT,
    payload
})

export const sagaSetPedidoDetalleReemplazo = (payload) => ({
    type: SAGA_SET_PEDIDODETALLE_REEMPLAZO,
    payload
})

export const setPedidoDetalleReemplazo = (payload) => ({
    type: SET_PEDIDODETALLE_REEMPLAZO,
    payload
})

export const sagaConfirmarEliminarPedido = (payload) => ({
    type: SAGA_CONFIRMAR_ELIMINAR_PEDIDO,
    payload
})

export const setOpenModalVaciarCarrito = (payload) => ({
    type: SET_OPEN_MODAL_VACIAR_CARRITO,
    payload
})

export const setShowResumenPedido = (payload) => ({
    type: SET_SHOW_RESUMEN_PEDIDO,
    payload
})

export const sagaFetchPedidoBorrador = (payload) => ({
    type: SAGA_FETCH_PEDIDO,
    payload
})

export const sagaUpdateEstadoPedidoAbandonado = (payload) => ({
    type: SAGA_UPDATE_ESTADOPEDIDO_ABANDONADO,
    payload
})

export const setPedidoBorrador = (payload) => ({
    type: SAVE_PEDIDO,
    payload
})

export const setIdPedidoDetalle = (payload) => ({
    type: SAVE_ID_PEDIDO_DETALLE,
    payload
})

export const setUndefinedPedidoDetalle = (payload) => ({
    type: SET_UNDEFINED_ID_PEDIDO_DETALLE,
    payload
})

export const sagaAddPedidoDetalle = (payload) => ({
    type: SAGA_ADD_PEDIDO_DETALLE,
    payload
})

export const sagaRemovePedidoDetalle = (payload) => ({
    type: SAGA_REMOVE_PEDIDO_DETALLE,
    payload
})

export const initializeFormPedido = (payload) => ({
    type: INITIALIZE_FORM,
    payload
})

export const sagaGetPedidosUser = (payload) => ({
    type: GET_PEDIDOSUSER,
    payload
})

export const sagaSetPedidosUser = (payload) => ({
    type: SET_PEDIDOSUSER,
    payload
})

export const sagaGetPedidosCliente = (payload) => ({
    type: GET_PEDIDOSCLIENTE,
    payload
})
export const sagaGetPedidosBorradorCliente = (payload) => ({
    type: GET_PEDIDOS_BORRADOR_CLIENTE,
    payload
})

export const sagaSetPedidosBorradorCliente = (payload) => ({
    type: SET_PEDIDOS_BORRADOR_CLIENTE,
    payload
})

export const sagaSetPedidosCliente = (payload) => ({
    type: SET_PEDIDOSCLIENTE,
    payload
})

export const actualizarFechaPedidoBorrador = (payload) => ({
    type: ACTUALIZAR_FECHA_PEDIDO_BORRADOR,
    payload
})

export const setPedidoPagoMP = (payload) => ({
    type: SAGA_SET_PEDIDO_PAGO_MP,
    payload
})
 
export const sagaEnviarMailConfirmacion = (payload) => ({
    type: SAGA_ENVIAR_MAIL_CONFIRMACION,
    payload
})

export const sagaGetPreferenciasUltimoPedido = (payload) => ({
    type: SAGA_FETCH_PREFERENCIAS_ULTIMO_PEDIDO,
    payload
})