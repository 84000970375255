import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-flexbox-grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import {
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import Heading from '../../UI/Texts/Heading'
import Paragraph from '../../UI/Texts/Paragraph'
import CircularProgress from "@material-ui/core/CircularProgress";

const ModalCategorias = ({
  classes,
  openModalRubros,
  handleOnClickCloseModalRubros,
  rubrosSubrubros,
  handleOnClickSubrubro }) => {
  return <Dialog
    classes={{
      root: classes.rootDialog,
      paperScrollPaper: classes.rootContent,
    }}
    open={openModalRubros}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    maxWidth="sm"
    fullWidth={true}
  >
    <DialogContent classes={{ root: classes.rootContent }}>
      <Row style={{ margin: "0px" }}>
        <Col xs={12} md={12} lg={12} style={{ padding: "0px" }}>
          <div className="containerModal">
            <div className="containerTitle">
              <div className="label"><Heading tag="h4" weight="bold">Categorías</Heading></div>
              <div style={{ display: "flex" }}>
                <CloseIcon
                  cursor="pointer"
                  fontSize="small"
                  onClick={handleOnClickCloseModalRubros}
                />
              </div>
            </div>

            {rubrosSubrubros === undefined && (
              <div className="loader">
                <CircularProgress />
              </div>
            )}

            {rubrosSubrubros && (
              <div id="expandedRubros" className="containerRubros">
                {rubrosSubrubros.length > 0 &&
                  rubrosSubrubros.map((r) => {
                    return (
                      <div style={{ width: "100%" }}>
                        <ExpansionPanel>
                          <ExpansionPanelSummary
                            className="panelRubro"
                            expandIcon={
                              <ExpandMoreIcon
                                style={{ color: "#FFFFFF" }}
                              />
                            }
                            aria-controls={`panel${r.idRubro}a-content`}
                            id={`panel${r.idRubro}a-header`}
                          >
                            <Paragraph size="big" weight="bold">{r.nombre}</Paragraph>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails
                          // style={{ padding: "0px" }}
                          >
                            <div style={{ width: "100%" }}>
                              {r.subrubros &&
                                r.subrubros.map((s) => {

                                  return (
                                    <div
                                      className="labelSubrubro"
                                      onClick={() => {
                                        handleOnClickSubrubro(s);
                                      }}
                                    >
                                      {s.esRubro === true ?
                                        <Paragraph size="big" weight="regular">{`Todos los articulos de ${s.nombre}`}</Paragraph> :
                                        <Paragraph size="big" weight="regular">{s.nombre[0] + s.nombre.slice(1).toLowerCase()}</Paragraph>}
                                    </div>
                                  );
                                })}
                            </div>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      </div>
                    );
                  })}
              </div>
            )}

            {rubrosSubrubros &&
              rubrosSubrubros.length === 0 && (
                <div className="labelNoEncontrado">
                  No se encontraron categorías para mostrar
                </div>
              )}
          </div>
        </Col>
      </Row>
    </DialogContent>
  </Dialog>
}

ModalCategorias.propTypes = {
  classes: PropTypes.any.isRequired,
  openModalRubros: PropTypes.func.isRequired,
  handleOnClickCloseModalRubros: PropTypes.func.isRequired,
  rubrosSubrubros: PropTypes.func.isRequired,
  handleOnClickSubrubro: PropTypes.func.isRequired,
}

export default ModalCategorias;