import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ResumenFacturacion from './resumenFacturacion';
import DatosFacturacionOptions from './datosFacturacionOptions';
import FormularioCondicionIVA from './formularioCondicionIVA';
import CustomButton from '../Common/wrappers/CustomButton';
import './styles.css'

const styles = theme => ({
  label: {
    font: '500 16px/20px Poppins',
    letterSpacing: '0px',
    color: '#0C3653',
  },
  rootContent: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '-5px 4px 10px #8E8E8E29',
    borderRadius: '21px',
    opacity: 1,
    minWidth: '300px',
    padding: '0px !important'
  },
  icon: {
    color: '#B4C4D5'
  },
  select: {
    textAlign: 'left',
    font: '400 16px/23px Poppins',
    letterSspacing: '0px',
    color: '#B4C4D5',
    opacity: 1,
  },
})

class Facturacion extends React.Component {

  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return (
      <div className={`datosFacturacion ${!this.props.tipoFacturacion ? "datosFacturacionBorderTop" : ""}`}>
        {/* RESUMEN FACTURACION */}
        {this.props.isTipoFacturacionSelected &&
          <ResumenFacturacion
            tipoFacturacionEnum={this.props.tipoFacturacionEnum}
            tipoFacturacion={this.props.tipoFacturacion}
            datosFacturacion={this.props.datosFacturacionSelected}
            handleOnChangeTipoFacturacion={this.props.handleOnChangeTipoFacturacion}
          />
        }

        {/* SELECCION TIPO FACTURACION */}
        {!this.props.isTipoFacturacionSelected &&
          <>
            <div className='labelTitle'>Datos de facturación</div>
            {/* BOTONES FACTURACION */}
            {!this.props.tipoFacturacion &&
              <div className="containerBtnsFacturacion">
                <CustomButton color="secondary" onClick={() => this.props.handleOnChangeTipoFacturacion(this.props.tipoFacturacionEnum.CONSUMIDOR_FINAL)}
                  classProp="btnTipoFacturacion"
                  message="Consumidor final" />
                <CustomButton color="secondary" onClick={() => this.props.handleOnChangeTipoFacturacion(this.props.tipoFacturacionEnum.IVA)}
                  classProp="btnTipoFacturacion"
                  message="Con condición de IVA" />
              </div>}
            {/* LISTA DE DATOS DE FACTURACION */}
            {this.props.tipoFacturacion === this.props.tipoFacturacionEnum.IVA && !this.props.showFormularioCondicionIVA &&
              <DatosFacturacionOptions
                clienteDatosFacturacion={this.props.clienteDatosFacturacion}
                showModalDeleteDatoFacturacion={this.props.showModalDeleteDatoFacturacion}
                handleOnSelectDatoFacturacion={this.props.handleOnChangeDatosFacturacion}
                handleOnClickDeleteDatoFacturacion={this.props.handleOnClickDeleteDatoFacturacion}
                handleOnDeleteFacturacion={this.props.handleOnDeleteFacturacion}
                handleOnAgregarDatosFacturacion={this.props.handleOnAgregarDatosFacturacion}
              />
            }

            {/* FORMULARIO ALTA CONDICION IVA */}
            {this.props.tipoFacturacion === this.props.tipoFacturacionEnum.IVA && this.props.showFormularioCondicionIVA &&
              <FormularioCondicionIVA
                condicionesIVA={this.props.condicionesIVA}
                formularioCondicionIVA={this.props.formularioCondicionIVA}
                erroresFormulario={this.props.erroresFormulario}
                handleOnChangeFormularioCondicionIVA={this.props.handleOnChangeFormularioCondicionIVA}
                handleOnChangeTipoFacturacion={this.props.handleOnChangeTipoFacturacion}
                handleOnSaveFormularioCondicionIVA={this.props.handleOnSaveFormularioCondicionIVA}
                handleOnReutilizarDatosFacturacion={this.props.handleOnChangeDatosFacturacion}
                handleOnCancelarReutilarDatos={this.props.handleOnCancelarReutilarDatos}
              />
            }
          </>}
      </div>
    );
  }
}

Facturacion.defaultProps = {
  tipoFacturacion: undefined,
  isTipoFacturacionSelected: false,
  datosFacturacionSelected: {},
  showFormularioCondicionIVA: false,
  formularioCondicionIVA: {},
  erroresFormulario: {},
  condicionesIVA: [],
  clienteDatosFacturacion: [],
  showModalDeleteDatoFacturacion: false,
  handleOnChangeTipoFacturacion: () => { },
  handleOnAgregarDatosFacturacion: () => { },
  handleOnChangeFormularioCondicionIVA: () => { },
  handleOnSaveFormularioCondicionIVA: () => { },
  handleOnCancelarReutilarDatos: () => { },
  handleOnChangeDatosFacturacion: () => { },
  handleOnClickDeleteDatoFacturacion: () => { },
  handleOnDeleteFacturacion: () => { },
}

Facturacion.propTypes = {
  tipoFacturacionEnum: PropTypes.arrayOf(PropTypes.string).isRequired,
  tipoFacturacion: PropTypes.string,
  isTipoFacturacionSelected: PropTypes.bool,
  datosFacturacionSelected: PropTypes.object,
  showFormularioCondicionIVA: PropTypes.bool,
  formularioCondicionIVA: PropTypes.object,
  erroresFormulario: PropTypes.object,
  condicionesIVA: PropTypes.array,
  clienteDatosFacturacion: PropTypes.array,
  showModalDeleteDatoFacturacion: PropTypes.bool,
  handleOnChangeTipoFacturacion: PropTypes.func,
  handleOnAgregarDatosFacturacion: PropTypes.func,
  handleOnChangeFormularioCondicionIVA: PropTypes.func,
  handleOnSaveFormularioCondicionIVA: PropTypes.func,
  handleOnCancelarReutilarDatos: PropTypes.func,
  handleOnChangeDatosFacturacion: PropTypes.func,
  handleOnClickDeleteDatoFacturacion: PropTypes.func,
  handleOnDeleteFacturacion: PropTypes.func,
};

export default withStyles(styles)(Facturacion)



