import { fromJS } from 'immutable'
import * as ReducerActions from './actions'

const initialState = fromJS({
  data: {
    catalogos: undefined,
    catalogoSeleccionado: undefined,
    idArticuloStock: undefined,
    stockDisponible: undefined,
    buscandoCatalogo: false,
    cantidadArticulosFiltradosCatSel: undefined,
    cantidadArticulos: undefined,
    filterBusqueda: undefined,
    rubrosSubrubros: [],
    subrubroSeleccionado: {nombre: undefined, id: undefined},
    ofertaSeleccionada: undefined,
    subFiltrosArticulos: [],
  }
})

export default function testReducer(state = initialState, action) {

  if (action.type === ReducerActions.INITIALIZE_FORM) {
    return initialState
  }

  if (action.type === ReducerActions.SAVE_CATALOGOS_ACTIVOS) {
    return state.setIn(["data", "catalogos"], action.payload)
  }

  if (action.type === ReducerActions.SAVE_CATALOGO_SELECCIONADO) {

    let myState = state.toJS();
    let response = action.payload;
    let mCatalogoSeleccionado = myState.data.catalogoSeleccionado;

    if (myState.data.catalogoSeleccionado != undefined && response != undefined){
      response.listaRubros.forEach(r => {
        let rubro = mCatalogoSeleccionado.listaRubros.find(x => x.idRubro === r.idRubro)
        let indexRubro = mCatalogoSeleccionado.listaRubros.indexOf(rubro)
        if(rubro){
            r.listaSubRubros.forEach(subR => {
                let subRubro = rubro.listaSubRubros.find(x => x.idSubrubro === subR.idSubrubro)
                let indexSubRubro = rubro.listaSubRubros.indexOf(subRubro)
                if(subRubro){
                    subR.listaArticulos.forEach(a => {
                        subRubro.listaArticulos.push(a)
                    });
                    rubro.listaSubRubros[indexSubRubro] = subRubro
                }
                else{
                    rubro.listaSubRubros.push(subR)
                }
            });
            mCatalogoSeleccionado.listaRubros[indexRubro] = rubro
        }
        else{
          mCatalogoSeleccionado.listaRubros.push(r)
        }
      })
      
      response.listadoSecciones.forEach(s => {
        let seccion = mCatalogoSeleccionado.listadoSecciones.find(x => x.nombreSeccion === s.nombreSeccion)
        let indexSeccion = mCatalogoSeleccionado.listadoSecciones.indexOf(seccion)
        if(seccion){
          s.listaArticulos.forEach((a) => {
            seccion.listaArticulos.push(a)
          })
          mCatalogoSeleccionado.listadoSecciones[indexSeccion] = seccion
        }
        else{
          mCatalogoSeleccionado.listadoSecciones.push(s)
        }
      })
      
      mCatalogoSeleccionado.cantidadArticulos = mCatalogoSeleccionado.cantidadArticulos + response.cantidadArticulos

      return fromJS({
        ...myState,
        data: {
            ...myState.data,
            catalogoSeleccionado: mCatalogoSeleccionado,
        }
      })
    }
    else{
      return state.setIn(["data", "catalogoSeleccionado"], action.payload);
    }
  }

  if (action.type === ReducerActions.SET_BUSCANDO_CATALOGO) {
    return state.setIn(["data", "buscandoCatalogo"], action.payload)
  }
  
  if (action.type === ReducerActions.SET_FILTER_BUSQUEDA) {
    return state.setIn(["data", "filterBusqueda"], action.payload)
  }

  if (action.type === ReducerActions.SET_SUBRUBRO_SELECCIONADO) {
    return state.setIn(["data", "subrubroSeleccionado"], action.payload)
  }

  if (action.type === ReducerActions.SET_OFERTA_SELECCIONADA) {
    return state.setIn(["data", "ofertaSeleccionada"], action.payload)
  }

  if (action.type === ReducerActions.SAVE_RUBROS_SUBRUBROS) {
    return state.setIn(["data", "rubrosSubrubros"], action.payload)
  }

  if (action.type === ReducerActions.SET_TIEMPO_TIMER) {
    return state.set("seconds", action.payload)
  }
  
  if (action.type === ReducerActions.SET_DISPONIBILIDAD_STOCK_ARTICULO) {

    let myState = state.toJS()

    let catalogo = myState.data.catalogoSeleccionado

    if (action.payload.idArticulo !== undefined) {
      catalogo.listaRubros.forEach((r) => {
        r.listaSubRubros.forEach((s) => {
          s.listaArticulos.forEach((a) => {
            if (a.idArticulo === action.payload.idArticulo) {
              a.stockDisponible = action.payload.disponible
            }
          })
        })
      })
    }


    return fromJS({
      ...myState,
      data: {
        ...myState.data,
        catalogoSeleccionado: catalogo,
        idArticuloStock: action.payload.idArticulo,
        stockDisponible: action.payload.disponible,
      }
    })
  }

  if (action.type === ReducerActions.SAVE_SUBFILTROS_ARTICULOS) {
    return state.setIn(["data", "subFiltrosArticulos"], action.payload)
  }

  return state
}