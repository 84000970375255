import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import InputAdornment from "@material-ui/core/InputAdornment";
import ModalCategorias from '../Modals/Categorias/Categorias'
import ModalOfertas from '../Modals/Ofertas/Ofertas'
import {
  Clear as ClearIcon,
  ArrowBackIos as ArrowBackIcon,
} from "@material-ui/icons";
import Icon from "@mdi/react";
import { mdiPercentOutline } from "@mdi/js";
import { mdiShape } from "@mdi/js";
import "./styles.css";

const styles = (theme) => ({
  rootDialog: {
    zIndex: "1505 !important",
  },
  rootContent: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "-5px 4px 10px #8E8E8E29",
    borderRadius: "21px",
    opacity: 1,
    maxWidth: "850px",
    minWidth: "290px",
    padding: "0px !important",
    overflow: "hidden",
  },
  rootRadioGroup: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "120px",
  },
  rootFormLabel: {
    marginLeft: "0px",
    marginRight: "0px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});

class SearchHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: this.isMobile(),
      isInputFocused: false,
    };
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.handleResize);
  };

  handleResize = () => {
    const isMobile = this.isMobile();

    if (isMobile !== this.state.isMobile) {
      this.setState({ isMobile });
    }
  };

  isMobile = () => window.innerWidth < 768;

  onInputFocus = () => this.setState({ isInputFocused: true });

  onInputBlur = () => this.setState({ isInputFocused: false });

  toFixedCustomize = (num) => {
    var resultado = num.toFixed(6).slice(0, -4);
    return resultado;
  };

  render() {
    return (
      <div
        className={`buscadorContainer ${this.props.pedido ? "buscadorWithResumen inputSeatchAux" : ""
          }`}
      >
        <InputBase
          sx={{
            "&:focus": {
              border: "1px solid red",
            },
          }}
          className={`inputSearch`}
          placeholder="Buscar"
          onChange={this.props.handleOnChangeFilter}
          onFocus={this.onInputFocus}
          onBlur={() => setTimeout(this.onInputBlur)}
          value={this.props.filterArticulos ? this.props.filterArticulos : ""}
          startAdornment={
            <InputAdornment position="start">
              <div
                style={{
                  margin: "2px 0 0 15px",
                  cursor: "pointer",
                  display: "flex",
                }}
              >
                <img
                  src={require("../../images/search.png")}
                  alt="Icono de búsqueda"
                  className="iconSearch"
                />
              </div>
              {this.props.filterArticulos !== undefined && (
                <div className="iconClearBusqueda">
                  <ClearIcon onClick={this.props.handleOnClearBusqueda} />
                </div>
              )}
            </InputAdornment>
          }
        />

        {(!this.state.isMobile || !this.state.isInputFocused) && (
          <>
            <div
              className={`btn btnCategorias ${this.props.pedido ? "btnCategoriasAux" : ""
                }`}
              onClick={this.props.handleOnClickOpenModalRubros}
            >
              <div style={{ width: "28px", height: "28px", padding: "4px" }}>
                <Icon
                  path={mdiShape}
                  size={1}
                  style={{
                    width: "100%",
                    height: "100%",
                    fill: "white",
                  }}
                />
              </div>

              <div className="label">Categorias</div>
            </div>

            <div
              className="btn btnOfertas"
              onClick={this.props.handleOnClickOpenModalOfertas}
            >
              <div style={{ width: "28px", height: "28px", padding: "4px" }}>
                <Icon
                  path={mdiPercentOutline}
                  size={1}
                  style={{
                    width: "100%",
                    height: "100%",
                    fill: "white",
                  }}
                />
              </div>
              <div className="label">Ofertas</div>
            </div>
          </>
        )}

        {this.state.isMobile && this.state.isInputFocused && (
          <div className="btn arrowBack">
            <ArrowBackIcon />
          </div>
        )}
        <ModalCategorias
          handleOnClickSubrubro={this.props.handleOnClickSubrubro}
          classes={this.props.classes}
          openModalRubros={this.props.openModalRubros}
          handleOnClickCloseModalRubros={this.props.handleOnClickCloseModalRubros}
          rubrosSubrubros={this.props.rubrosSubrubros} />
        <ModalOfertas
          handleOnClickOferta={this.props.handleOnClickOferta}
          classes={this.props.classes}
          openModalOfertas={this.props.openModalOfertas}
          handleOnClickCloseModalOfertas={this.props.handleOnClickCloseModalOfertas}
          ofertas={this.props.ofertas} />
      </div>
    );
  }
}

SearchHeader.defaultProps = {
  open: true,
  openModalRubros: true,

  openModalOfertas: false,
  ofertas: undefined,
};

SearchHeader.propTypes = {
  pedido: PropTypes.object.isRequired,
  openModalRubros: PropTypes.bool.isRequired,
  filterArticulos: PropTypes.string.isRequired,
  handleOnChangeFilter: PropTypes.func.isRequired,
  handleOnClearBusqueda: PropTypes.func.isRequired,
  handleOnClickOpenModalRubros: PropTypes.func.isRequired,
  handleOnClickCloseModalRubros: PropTypes.func.isRequired,
  handleOnClickSubrubro: PropTypes.func.isRequired,
  openModalOfertas: PropTypes.bool,
  ofertas: PropTypes.array,
  handleOnClickOpenModalOfertas: PropTypes.func.isRequired,
  handleOnClickCloseModalOfertas: PropTypes.func.isRequired,
  handleOnClickOferta: PropTypes.func.isRequired,
};

export default withStyles(styles)(SearchHeader);
