// acciones
export const INITIALIZE_FORM = 'confirmarPedidoReducer/INITIALIZE_FORM'
export const SAGA_FETCH_PEDIDO = 'confirmarPedidoReducer/SAGA_FETCH_PEDIDO'
export const SAGA_UPDATE_ESTADO_PEDIDO = 'confirmarPedidoReducer/SAGA_UPDATE_ESTADO_PEDIDO'
export const SAGA_REMOVE_PEDIDO_DETALLE = 'confirmarPedidoReducer/SAGA_REMOVE_PEDIDO_DETALLE'

export const SAVE_PEDIDO = 'confirmarPedidoReducer/SAVE_PEDIDO'
export const SAVE_REDIRIGIR = 'confirmarPedidoReducer/SAVE_REDIRIGIR'

// funciones
export const initializeForm = (payload) => ({
    type: INITIALIZE_FORM,
    payload
})

export const sagaFetchPedido = (payload) =>({
    type: SAGA_FETCH_PEDIDO,
    payload
})

export const setPedido = (payload) => ({
    type: SAVE_PEDIDO,
    payload
})

export const sagaUpdateEstadoPedido = (payload) => ({
    type: SAGA_UPDATE_ESTADO_PEDIDO,
    payload
})

export const sagaRemovePedidoDetalleCP = (payload) => ({
    type: SAGA_REMOVE_PEDIDO_DETALLE,
    payload
})

export const setRedirigir = (payload) => ({
    type: SAVE_REDIRIGIR,
    payload
})

export const initializeFormConfirmarPedido = (payload) => ({
    type: INITIALIZE_FORM,
    payload
})