import _axios from '../../apiClient'

export function getCondicionesIVA() {
    return _axios.get(`/api/CondicionIVA/GetAll`,
    {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}

export function getDatosFacturacion({ idCliente }) {
  return _axios.get(`/api/DatosFacturacion/GetAllByCliente?idCliente=${idCliente}`,
  {
    headers: { 'Content-Type': 'application/json;charset=UTF-8' }
  })
  .then(response => response.data)
  .catch((error) => {
    const data = error.response.data
    throw (data)
  })
}

export function saveDatosFacturacion(payload) {
  return _axios.post(`/api/DatosFacturacion/Save`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}

export function setDatosFacturacionActivo(payload) {
  return _axios.post(`/api/DatosFacturacion/SetActivo`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}