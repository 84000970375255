import React from 'react'
import Paragraph from '../UI/Texts/Paragraph';
import Heading from '../UI/Texts/Heading';
import PropTypes from 'prop-types'

const PedidoHeader = ({
  pedido,
  toFixedCustomize
}) => {
  return <>
    <div className='labelTuTicket'>
      <Heading tag="h2" weight="semi_bold" color="black-text">Tu pedido #{pedido ? pedido.id : ''}</Heading>
    </div>
    <div className='labelPrecio'>
      <Heading tag="h1" weight="semi_bold" color="black-text">${pedido ? toFixedCustomize(pedido.precioFinal) : ''}</Heading>
    </div>
    <div className='labelPrecioIva'>
      <Paragraph size="big" weight="regular" color="dark-grey-text">( IVA incluído )</Paragraph>
    </div>
  </>
}

PedidoHeader.propTypes = {
  pedido: PropTypes.object.isRequired,
  toFixedCustomize: PropTypes.func.isRequired,
}

export default PedidoHeader;