import { takeEvery, all, put } from 'redux-saga/effects'
import * as calls from './calls'
import * as reducerActions from '../../reducers/SucursalesReducer/actions'
import * as Notification from '../../components/Common/notification'
import * as layoutActions from '../../reducers/LayoutReducer/actions'


function* genGetSucursales(e) {

    try {
        // muestro el loader
        yield put(layoutActions.setLoading({ show: true,  message: 'Buscando sucursales' }))

        // llamo a la acción que busca los datos del calendario
        let sucursales = yield calls.getSucursales(e.payload.idInstalacion)

        yield put(reducerActions.setSucursales(sucursales))  
    }
    catch(error) {
        // muestro el mensaje de error
        Notification.showError(error.error)
    }
    finally {
        // oculto el loader
        yield put(layoutActions.setLoading({ show: false }))
    }
    
}

function* defaultSaga() {
    yield all([
      takeEvery(reducerActions.SAGA_FETCH_SUCURSALES, genGetSucursales),
    ])
  }
  
  export const sagas = [
    defaultSaga
  ]