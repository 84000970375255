import React from 'react'
import { FONT_WEIGHT } from './utils'
import PropTypes from 'prop-types';
import './style.css'

const Heading = ({ tag, weight, children, color = ""}) => {
    if (tag === "h6") return <h6 className={`heading ${FONT_WEIGHT[weight]} heading-h6 ${color}`}>{children}</h6>
    if (tag === "h5") return <h5 className={`heading ${FONT_WEIGHT[weight]} heading-h5 ${color}`}>{children}</h5>
    if (tag === "h4") return <h4 className={`heading ${FONT_WEIGHT[weight]} heading-h4 ${color}`}>{children}</h4>
    if (tag === "h3") return <h3 className={`heading ${FONT_WEIGHT[weight]} heading-h3 ${color}`}>{children}</h3>
    if (tag === "h2") return <h2 className={`heading ${FONT_WEIGHT[weight]} heading-h2 ${color}`}>{children}</h2>
    if (tag === "h1") return <h1 className={`heading ${FONT_WEIGHT[weight]} heading-h1 ${color}`}>{children}</h1>
}

Heading.propTypes = {
    tag: PropTypes.string.isRequired,
    weight: PropTypes.string.isRequired,
}

export default Heading