import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux'
import store from './config'
import './fonts';

function getApp () {
    return (
      <Provider store={store}>
        <App />
      </Provider>
    )
  }

  ReactDOM.render(getApp(), document.getElementById('root'))
  serviceWorker.unregister();