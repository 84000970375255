import moment from "moment"

/**
 * If the current date is between the min and max dates, return true, otherwise return false.
 * @param min - The minimum date to check against.
 * @param max - The end of the range.
 * @returns A boolean value.
 */
export const showInDateRange = (min, max) => {
    return moment(moment().format('YYYY-MM-DD')).isBetween(min, max, undefined, '[]')
}