import _axios from '../../apiClient'

export function getCatalogosActivos(payload) {
    return _axios.get(`/api/Catalogo/GetCatalogosActivos`,
    { 
      params: { idInstalacion: payload } 
    },
    {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}

export function getCatalogoSeleccionado(payload) {
  return _axios.get(`/api/Catalogo/GetCatalagoSeleccionado`,
  { 
    params: payload 
  },
  {
    headers: { 'Content-Type': 'application/json;charset=UTF-8' }
  })
  .then(response => response.data)
  .catch((error) => {
    const data = error.response.data
    throw (data)
  })
}

export function getIdCatalogoPublicadoActual() {
  return _axios.get(`/api/Catalogo/GetIdCatalogoPublicado`,
  { 
    params: {  } 
  },
  {
    headers: { 'Content-Type': 'application/json;charset=UTF-8' }
  })
  .then(response => response.data)
  .catch((error) => {
    const data = error.response.data
    throw (data)
  })
}

export function getRubrosSubrubros(payload) {
  return _axios.get(`/api/Catalogo/GetRubrosSubrubros`,
  { 
    params: payload 
  },
  {
    headers: { 'Content-Type': 'application/json;charset=UTF-8' }
  })
  .then(response => response.data)
  .catch((error) => {
    const data = error.response.data
    throw (data)
  })
}

export function getSubFiltrosArticulos(payload) {
  return _axios.get(`/api/Catalogo/GetCatalagoSeleccionadoFiltros`,
  { 
    params: payload 
  },
  {
    headers: { 'Content-Type': 'application/json;charset=UTF-8' }
  })
  .then(response => response.data)
  .catch((error) => {
    const data = error.response.data
    throw (data)
  })
}