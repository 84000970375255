import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import CircularProgress from '@material-ui/core/CircularProgress';
import { formatMoney } from '../Common/decimalHelper'

class ItemPedido extends React.Component {

  render() {
    return (
      <div className='itemPedido' onClick={() => this.props.estadoPedido === 'Borrador' ? this.props.handleOnClick(this.props.item) : ''}>

        <div className='imageContainer'>
          <img src={this.props.item.path} />
        </div>

        <div className='descriptionContainer'>
          <Row>
            <Col xs={8}>
              <div>{this.props.item.descripcion}</div>
              <div className='cantidad'>{`${this.props.item.cantidad} unidades`}</div>
            </Col>
            <Col xs={4} className='precio'>{formatMoney(this.props.item.precio)}</Col>
          </Row>
        </div>
      </div>
    )
  }
}

ItemPedido.propTypes = {
  item: PropTypes.object.isRequired,
  handleOnClick: PropTypes.func
}

ItemPedido.defaultProps = {
  handleOnClick: f => f
}

export default ItemPedido