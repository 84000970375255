import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Tooltip, Zoom } from '@material-ui/core';
import './styles.css';
import Heading from '../../UI/Texts/Heading';
import Paragraph from '../../UI/Texts/Paragraph';

const ClientazoTooltip = withStyles((theme) => ({
  tooltip: {
    background: '#EFF0F8 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '4px',
    padding: '0px',
    opacity: '1 !important',
    margin: '7px 0 4px 25px !important'
  },
}))(Tooltip);

const VisualizadorPrecio = ({ precioLista, precioPromocional }) => {
  return (
    <div className='visualizadorPrecio'>
      {precioPromocional ? 
      <Paragraph size='medium' weight='semi_bold'>
        Antes <span className='precioTachado'>${precioLista.toFixed(2)}</span>
      </Paragraph> 
      :
      <Heading tag='h3' weight='bold'>
        ${precioLista.toFixed(2)}
      </Heading>
      }
      {precioPromocional && <ClientazoTooltip
        TransitionComponent={Zoom}
        placement="bottom-start"
        style={{ opacity: '1' }}
        enterTouchDelay={10}
        title={
          <div className='containerTooltipClientazo'>
            <div className='containerImgTooltip'>
              <img alt="" src={require('../../../images/clientazoLogo2.png')} />
            </div>
          </div>
        }>
        <div><Heading tag='h3' weight='bold'>${precioPromocional.toFixed(2)}</Heading></div>
      </ClientazoTooltip>}
    </div>
  );
};

VisualizadorPrecio.defaultProps = {
  precioLista: 0,
  precioPromocional: undefined,
};

VisualizadorPrecio.propTypes = {
  precioLista: PropTypes.number,
  precioPromocional: PropTypes.number,
};

export default VisualizadorPrecio;
