import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as registrarUsuarioActions from '../../reducers/RegistrarUsuarioReducer/actions'
import * as provinciaLocalidadActions from '../../reducers/ProvinciaLocalidadReducer/actions'
import * as layoutActions from '../../reducers/LayoutReducer/actions'
import * as sessionActions from '../../reducers/SessionReducer/actions'
import { registrarUsuarioDataSelector } from '../../selectors/registrarUsuarioSelector'
import { instalacionSelector, resultadoAutenticacionSelector } from '../../selectors/sessionSelector'
import { headerSelector, footerSelector } from '../../selectors/layoutSelector'
import RegistrarUsuario from '../../components/RegistrarUsuario'


class RegistrarUsuarioContainer extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            usuario: {
                nombre: '',
                apellido: '',
                email: '',
                password: '',
                idInstalacion: this.props.instalacion.id,
                codigoVerificacion: undefined
            },
            buscandoCliente: false,
            buscoCliente: false,
            errores: {},
            localidadText: '',
            update: false,
            buscandoLocalidad: false,
            codigoVerificado: false,
            byUrl: this.props.match && this.props.match.params && this.props.match.params.idUsuario && this.props.match.params.token ? true : false
        }

        // inicializo el formulario
        this.props.initializeForm()


        if(this.state.byUrl === true){
            this.props.setUsuarioCreado(true)
        }

        // oculto el header y el footer
        this.showHeaderAndFooter(false)
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.header.show === true || this.props.footer.show === true) {
            // oculto el header y el footer
            this.showHeaderAndFooter(false)
        }
    }

    componentDidMount = () => {
        this.props.setPermiteUsuarioAnonimo(true)
        this.props.setResultadoAutenticacion({ error: undefined, usuarioId: undefined, token: undefined})
    }

    componentWillUnmount = () => {
        this.props.setPermiteUsuarioAnonimo(false)

        // muestro el header y el footer
        this.showHeaderAndFooter(true)

        this.props.setResultadoAutenticacion({ error: undefined, usuarioId: undefined, token: undefined})

        this.props.setUsuarioCreado(false)
    }

    showHeaderAndFooter = (mostrar) => {
        this.props.setHeaderShow(mostrar)
        this.props.setFooterShow(mostrar)
    }

    handleOnSave = () => {
        
        // valido el formulario
        if(!this.validar())
            return

        
        let usuario = {...this.state.usuario}
        
        let payload = {} //Creo un payload ya que el state tiene algunos datos inutiles para persistir en base
        
        payload.nombre = usuario.nombre.trim()
        payload.apellido = usuario.apellido.trim()
        payload.email = usuario.email.trim()
        payload.password = usuario.password
        payload.idInstalacion = usuario.idInstalacion

        payload.callback = () => {
            let usuario =  {...this.state.usuario}
            usuario.nombre = ''
            usuario.apellido = ''
            usuario.password = ''

            this.setState({errores: {}, usuario: usuario})
        }
        
        // guardo el usuario
        this.props.sagaSaveUsuario(payload)
    }

    validar = () => {
        
        let valida = true

        let usuario = {...this.state.usuario}
        let errores = {}

        if(!usuario.nombre){
            valida = false
            errores.nombre = 'Ingrese el nombre'
        }

        if(!usuario.apellido){
            valida = false
            errores.apellido = 'Ingrese el apellido'
        }

        if (!usuario.email){
            valida = false
            errores.email = 'Ingrese un email'
        }
        else if(usuario.email && !this.validateEmail(usuario.email)){
            valida = false
            errores.email = 'Ingrese un email válido'            
        }

        if(!usuario.password){
            valida = false
            errores.password = 'Ingrese una contraseña'
        }

        // actualizo el estado con los errores
        this.setState({ errores: errores })

        return valida
    }

    validateEmail = (email) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).trim().toLowerCase())
    }

    handleOnDataChange = (event) => {
        let value = event.target.value
        let name = event.target.name

        let usuario = {...this.state.usuario}

        if(name === "codigoVerificacion" || name === "email"){
            usuario[name] = value.trim()
        }
        else{
            usuario[name] = value
        }
        

        this.setState({usuario: usuario})
    }

    handleOnConfirmarCódigo = () => {
        
        // valido que se haya ingresado el email
        if (this.state.usuario.codigoVerificacion === undefined || this.state.usuario.codigoVerificacion === '') {
            this.setState({ errores: { codigoVerificacion: 'Ingrese el código' } })
            return
        }
    
        let payload = {}

        if(this.state.byUrl === true){
            payload = {
                codigo: this.state.usuario.codigoVerificacion,
                usuarioId: this.props.match.params.idUsuario,
                token: this.props.match.params.token,
            }
        }
        else{
            payload = {
                codigo: this.state.usuario.codigoVerificacion,
                usuarioId: this.props.resultadoAutenticacion ? this.props.resultadoAutenticacion.usuarioId : undefined,
                token: this.props.resultadoAutenticacion ? this.props.resultadoAutenticacion.token : undefined,
            }
        }
    
        payload.callback = () => {
            this.setState({ codigoVerificado: true}, () => {
                this.props.setResultadoAutenticacion({error: undefined, usuarioId: undefined, token: undefined })
                //window.location.hash = '/Catalogo'
            })
        }
    
        payload.callbackError = (msg) => {
            
            this.setState({ errores: { codigoVerificacion: msg.error }})
        }
    
        this.setState({ errores: {} } , () => {
            // llamo a la lógica de validación de código
            this.props.sagaValidarCodigoConfirmacionEmail(payload)
        })
    }

    handleOnReenviarCodigo = () => {

        let payload = {}

        if(this.state.byUrl === true){
            payload = {
                usuarioId: this.props.match.params.idUsuario
            }
        }
        else{
            payload = {
                usuarioId: this.props.resultadoAutenticacion ? this.props.resultadoAutenticacion.usuarioId : undefined,
            }
        }

        this.props.sagaReenviarCodigoConfirmacionMail(payload)
    }

    render() {
        return(
            <RegistrarUsuario 
                instalacion={this.props.instalacion}
                usuario={this.state.usuario}
                handleOnNumeroUsuarioChange = {this.handleOnNumeroUsuarioChange}
                handleOnSave={this.handleOnSave}
                errores={this.state.errores}
                usuarioCreado={this.props.data.usuarioCreado}
                handleOnDataChange={this.handleOnDataChange}
                handleOnConfirmarCódigo={this.handleOnConfirmarCódigo}
                handleOnReenviarCodigo={this.handleOnReenviarCodigo}
                codigoVerificado={this.state.codigoVerificado}
            />
        )
    }
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        ...registrarUsuarioActions,
        ...layoutActions,
        ...provinciaLocalidadActions,
        ...sessionActions
	}, dispatch)
}

function mapStateToProps(state) {
	return {
        instalacion: instalacionSelector(state),
        header: headerSelector(state),
        footer: footerSelector(state),
        data: registrarUsuarioDataSelector(state),
        resultadoAutenticacion: resultadoAutenticacionSelector(state),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrarUsuarioContainer)