import PropTypes from 'prop-types';
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as catalogoActions from '../../reducers/CatalogoReducer/actions'
import * as sessionActions from '../../reducers/SessionReducer/actions'
import * as layoutActions from '../../reducers/LayoutReducer/actions';
import { catalogoDataSelector } from '../../selectors/catalogoSelector'
import { instalacionSelector } from '../../selectors/sessionSelector'
import Proveedores from '../../components/Proveedores'

class ProveedoresContainer extends React.Component {

  constructor(props) {
    super(props)

    window.scrollTo(0, 0)

    this.state = {
        codigoProveedor: this.props.match && this.props.match.params ? this.props.match.params.codigoProveedor : undefined
    }

    if(this.state.codigoProveedor === null || this.state.codigoProveedor === undefined || this.state.codigoProveedor === ""){
        window.location.hash = '/'
    }

    // obtengo catalogos activos
    let payload = {}
    payload.idInstalacion = this.props.instalacion.id
    payload.callBack = () => {
      if (this.props.data.catalogos !== undefined && this.props.data.catalogos.length == 1 && this.props.instalacion.verPortadaCatalogo == false) {
        //document.getElementById('footer').getElementsByClassName('descuentos')[0].hidden = true
        let footer = document.getElementById('footer')
        if(footer !== undefined && footer !== null && footer.getElementsByClassName('descuentos').length > 0){
          footer.getElementsByClassName('descuentos')[0].hidden = true
        }
      }
    }

    this.props.setCatalogoSeleccionado(undefined)

    this.props.sagaFetchCatalogosActivos(payload)


  }

  componentWillUnmount = () => {

    let footer = document.getElementById('footer')
    if(footer !== null && footer !== undefined && footer.getElementsByClassName('descuentos').length > 0){
      footer.getElementsByClassName('descuentos')[0].hidden = false
    }

    this.props.setPermiteUsuarioAnonimo(false)
    
  }

  componentDidMount = () => {
    // indico que el Login se puede ver aunque el usuario no esté logeado
    this.props.setPermiteUsuarioAnonimo(true);
  }

  render() {

    return (
        <Proveedores 
            existeCatalogo={this.props.data.catalogos !== undefined && this.props.data.catalogos.length == 1}
            history={this.props.history}
            catalogos={this.props.data.catalogos}
            codigoProveedor={this.state.codigoProveedor}
            poseeArticulos={this.props.data.catalogoSeleccionado && this.props.data.catalogoSeleccionado.listadoSecciones && this.props.data.catalogoSeleccionado.listadoSecciones.length > 0 ? true : false }
            loadingCatalogo={this.props.data.buscandoCatalogo}
        />
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...catalogoActions,
    ...sessionActions,
    ...layoutActions
  }, dispatch)
}

function mapStateToProps(state) {
  return {
    data: catalogoDataSelector(state),
    instalacion: instalacionSelector(state)
  }
}

ProveedoresContainer.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ProveedoresContainer)