import { fromJS } from 'immutable'
import * as ReducerActions from './actions'

const initialState = fromJS({
    data:{
        calendario: undefined
    }
})

export default function turneroReducer(state = initialState, action) {

    if(action.type === ReducerActions.SET_CALENDARIO) {
        return state.setIn(["data", "calendario"], action.payload)
    }

    return state
}