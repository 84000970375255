// acciones
export const INITIALIZE_FORM = 'detallePedidoReducer/INITIALIZE_FORM'
export const SAGA_FETCH_ARTICULO = 'detallePedidoReducer/SAGA_FETCH_ARTICULO'

export const SET_ARTICULO = 'detallePedidoReducer/SET_ARTICULO'
export const SET_PEDIDO_DETALLE = 'detallePedidoReducer/SET_PEDIDO_DETALLE'
export const SET_ERROR = 'detallePedidoReducer/SET_ERROR'

// funciones
export const initializeForm = (payload) => ({
    type: INITIALIZE_FORM,
    payload
})

export const sagaFetchArticulo = (payload) =>({
    type: SAGA_FETCH_ARTICULO,
    payload
})

export const setArticulo = (payload) => ({
    type: SET_ARTICULO,
    payload
})

export const setPedidoDetalle = (payload) => ({
    type: SET_PEDIDO_DETALLE,
    payload
})

export const setError = (payload) => ({
    type: SET_ERROR,
    payload
})

export const initializeFormDetallePedido = (payload) => ({
    type: INITIALIZE_FORM,
    payload
})