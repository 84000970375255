import { fromJS } from 'immutable'
import * as ReducerActions from './actions'

const initialState = fromJS({
    data: { 
        condicionesIVA: undefined,
        clienteDatosFacturacion: undefined,
    }
})

export default function facturacionReducer(state = initialState, action) {
    
    if (action.type === ReducerActions.SAVE_CONDICIONES_IVA) {
        return state.setIn(["data", "condicionesIVA"], action.payload)
    }

    if (action.type === ReducerActions.SAVE_DATOS_FACTURACION) {
        return state.setIn(["data", "clienteDatosFacturacion"], action.payload)
    }

    if (action.type === ReducerActions.SAVE_SET_DATOS_FACTURACION_ACTIVO) {
        let myState = state.toJS();
        const newClienteDatosFacturacion = [...myState.data.clienteDatosFacturacion];
        const cdf = newClienteDatosFacturacion.find(x => x.id === action.payload.id);
        if (cdf) cdf.activo = action.payload.activo;

        return state.setIn(["data", "clienteDatosFacturacion"], newClienteDatosFacturacion);
    }

    return state
}