
import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import { Loop as LoopIcon } from '@material-ui/icons';
import SelectorCantidad from '../Common/selectorCantidad/selectorCantidad';
import ArticuloAlternativa from '../MostrarArticulos/articuloAlternativa/articuloAlternativa';
import Paragraph from '../UI/Texts/Paragraph'
import './styles.css';
let timeouts = [];

function reducer(state, payload) {
  return { ...state, ...payload }
}

const ItemCarrito = ({ pedidoDetalle, handleOnClose, handleAgregarQuitarUnidad, logoCard }) => {
  const [state, dispatch] = useReducer(reducer,
    {
      cantidadPedido: pedidoDetalle.cantidad || 0,
      showArticuloAlternativa: false
    });

  const toFixedCustomize = (num) => {
    var resultado = num.toFixed(6).slice(0, -4)
    return resultado
  }

  const agregarQuitarUnidad = (cantidad, alternativa) => {
    // Aqui se evita llamar mas de unas vez a la API dentro de los 300ms
    dispatch({ cantidadPedido: cantidad, showArticuloAlternativa: false })
    for (var i = 0; i < timeouts.length; i++) {
      clearTimeout(timeouts[i]);
    }
    timeouts.push(setTimeout(() => {
      if (state.cantidadPedido !== cantidad) {
        let articulo = {
          idArticulo: pedidoDetalle.idArticulo,
          nombre: pedidoDetalle.descripcion,
          idCatalogoEntrada: pedidoDetalle.idCatalogoEntrada,
        }

        handleAgregarQuitarUnidad(cantidad, articulo, alternativa);
      }
    }, 1000));
  }

  const onChangeCantidad = (cantidad) => {
    agregarQuitarUnidad(cantidad, pedidoDetalle.alternativa);
  }

  const hasDiscount = (detalle) => {
    if (detalle.precioListaDescuento)
      return <><span className='precioTachado'>
        ${toFixedCustomize(pedidoDetalle.precioLista)}
      </span><br />
        <Paragraph size="big" weight="bold" >
          {`$${toFixedCustomize(pedidoDetalle.precioListaDescuento)}`}
        </Paragraph></>
    return <Paragraph size="big" weight="bold" >
      {`$${toFixedCustomize(pedidoDetalle.precioLista)}`}
    </Paragraph>
  }

  return (
    <div className="itemResumenPedido">
      <div className="imageContainer">
        {pedidoDetalle.imagenArticulo ?
          <div className='imgPedidoDetalle'>
            <img alt="" src={pedidoDetalle.imagenArticulo}></img>
          </div>
          :
          <div className='imageDefault'>
            <img alt="" src={logoCard}></img>
            <div>Producto sin foto</div>
          </div>}
      </div>
      <div className='containerInfoPd'>
        <Row><div className='nombreArticuloPedido'><Paragraph size="big" weight="bold">{pedidoDetalle.descripcion.toLowerCase()}</Paragraph></div></Row>
        {pedidoDetalle.alternativa && (
          <>
            <div className="containerAlternativa" onClick={() => dispatch({ showArticuloAlternativa: true })}>
              <div className="textoAlternativa">{pedidoDetalle.alternativa}</div>
              <div className="iconContainer"><LoopIcon /></div>
            </div>

            {state.showArticuloAlternativa && (
              <ArticuloAlternativa
                open={state.showArticuloAlternativa}
                itemId={pedidoDetalle.idArticulo}
                initialValue={state.cantidadPedido}
                alternativaSeleccionada={pedidoDetalle.alternativa}
                onClose={() => dispatch({ showArticuloAlternativa: false })}
                onConfirm={agregarQuitarUnidad}
              />
            )}
          </>
        )}

        <Row className='unidadesPrecio'>
          <Paragraph size="medium" weight="regular">{`${pedidoDetalle.productoPesable ? `${pedidoDetalle.cantidad} kg` : `${pedidoDetalle.cantidad} u`} ${pedidoDetalle.precioListaDescuento ? toFixedCustomize(pedidoDetalle.precioListaDescuento / pedidoDetalle.cantidad) : toFixedCustomize(pedidoDetalle.precioUnitario)}`}
          </Paragraph>
        </Row>

        <Row className='containerDetalle'>
          <Col style={{ marginRight: '10px' }} xs={6} md={5} className="selectorCantidadContainer">
            <SelectorCantidad
              {...pedidoDetalle}
              value={state.cantidadPedido}
              onChange={onChangeCantidad}
              customClass="selectorItemCarrito" />
          </Col>

          <Col xs={5} md={6} className='precio'>
            {hasDiscount(pedidoDetalle)}
          </Col>
        </Row>
      </div>
    </div>
  );

}

ItemCarrito.defaultProps = {
  open: true,
  handleOnClose: () => { }
}

ItemCarrito.propTypes = {
  pedidoDetalle: PropTypes.object.isRequired,
  handleOnClose: PropTypes.func,
  handleAgregarQuitarUnidad: PropTypes.func.isRequired,
};


export default ItemCarrito;
