import { takeLatest, takeEvery, all, put } from 'redux-saga/effects'
import * as calls from './calls'
import * as reducerActions from '../../reducers/ConfirmarPedidoReducer/actions'
import * as pedidoReducerActions from '../../reducers/PedidoReducer/actions'
import * as layoutActions from '../../reducers/LayoutReducer/actions'
import * as Notification from '../../components/Common/notification'


function* genGetPedido(e) {
  try {

    // muestro el loader
    yield put(layoutActions.setLoading({ show: true, message: 'Recuperando pedido' }))

    // llamo a la acción que busca el pedido
    let pedido = yield calls.getPedido(e.payload)

    // almaceno los datos en el estado del reductor
    yield put(reducerActions.setPedido(pedido))
  }
  catch (error) {
    // muestro el mensaje de error
    Notification.showError('Error al recuperar el pedido')

    if (error.Message) {
      console.log(error.Message)
      return;
    }
  }
  finally {
    // oculto el loader
    yield put(layoutActions.setLoading({ show: false }))
  }
}

function* genUpdateEstadoPedido(e) {
  try {

    // muestro el loader
    let mensajeLoader = `${e.payload.IdEstadoPedido === 2 ? 'Confirmando' : 'Borrando'} pedido`
    yield put(layoutActions.setLoading({ show: true, message: mensajeLoader }))

    // llamo a la acción que actualiza el estado del pedido
    yield calls.updateEstadoPedido(e.payload)

    // muestro el mensaje de éxito
    let mensajeExito = `El pedido fue ${e.payload.IdEstadoPedido === 2 ? 'confirmado' : 'borrado'}`
    Notification.showSuccess(mensajeExito)

    yield put(pedidoReducerActions.setPedidoBorrador(undefined))


    // indico en el reductor que se debe salir de la página actual
    yield put(reducerActions.setRedirigir(true))
  }
  catch (error) {
    // muestro el mensaje de error
    Notification.showError('Error al actualizar el estado del pedido')

    if (error.Message) {
      console.log(error.Message)
      return;
    }
  }
  finally {
    // oculto el loader
    yield put(layoutActions.setLoading({ show: false }))
  }
}

function* genDeletePedidoDetalle(e) {
  try {
    // muestro el loader
    yield put(layoutActions.setLoading({ show: true, message: 'Quitando detalle de pedido' }))

    // llamo a la acción que quita el detalle de pedido (retorna si el pedido fue eliminado)
    let pedidoEliminado = yield calls.removePedidoDetalle(e.payload)

    // busco el pedido actualizado
    let pedido = yield calls.getPedido(e.payload.IdPedido)

    // actualizo el estado del reductor con el pedido actualizado
    yield put(reducerActions.setPedido(pedido))

    // muestro el mensaje de éxito
    Notification.showSuccess('Se quitó el detalle de pedido')

    //llamo a la callback para actualizar le pedidoBorrador del pedidoReducer
    e.payload.callbackFunctionAfter()

    if (pedidoEliminado === true) {
      // si el pedido fue eliminado se debe salir de la página actual
      yield put(reducerActions.setRedirigir(pedidoEliminado))

      // muestro el mensaje de éxito
      Notification.showSuccess('El pedido fue borrado')
    }
  }
  catch (error) {
    // muestro el mensaje de error
    Notification.showError('Error al quitar el detalle del pedido')

    if (error.Message) {
      console.log(error.Message)
      return;
    }
  }
  finally {
    // oculto el loader
    yield put(layoutActions.setLoading({ show: false }))
  }
}

function* defaultSaga() {
  yield all([
    takeLatest(reducerActions.SAGA_FETCH_PEDIDO, genGetPedido),
    takeLatest(reducerActions.SAGA_UPDATE_ESTADO_PEDIDO, genUpdateEstadoPedido),
    takeLatest(reducerActions.SAGA_REMOVE_PEDIDO_DETALLE, genDeletePedidoDetalle)
  ])
}

export const sagas = [
  defaultSaga
]