import React from 'react';
import { DescriptionOutlined as DescriptionOutlinedIcon } from '@material-ui/icons';
import Heading from '../UI/Texts/Heading';

const ResumenFacturacion = (props) => {
  const {
    tipoFacturacionEnum,
    tipoFacturacion,
    datosFacturacion,
    handleOnChangeTipoFacturacion,
  } = props;

  return (
    <div className='containerResumenFacturacion'>
      <div className='resumenDatosFacturacion'>
        <div className='titlePreferencia'>
          <DescriptionOutlinedIcon style={{ marginRight: '10px', color: "#000" }} />
          <Heading tag="h6" weight="semi_bold" color="black-text">Facturar a</Heading></div>
        {tipoFacturacion === tipoFacturacionEnum.CONSUMIDOR_FINAL && <div className='labelPreferencia'>Consumidor final</div>}
        {tipoFacturacion === tipoFacturacionEnum.IVA &&
          <div>
            <Heading tag="h6" weight="regular" color="black-text">{datosFacturacion.nombreRazonSocial} / {datosFacturacion.cuit}</Heading>
          </div>}
      </div>
      <div className='btnCambiar' onClick={() => handleOnChangeTipoFacturacion(undefined)}>Cambiar</div>
    </div>
  );
};

export default ResumenFacturacion;
