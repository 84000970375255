import { createSelector } from 'reselect'

const detallePedidoReducerDataSelector = state => state.detallePedidoReducer.getIn(["data"]).toJS()
export const detallePedidoDataSelector = createSelector(
    detallePedidoReducerDataSelector, (dataField) => {      
      
        // articulo
        let myArticulo = undefined
        if(dataField.articulo)
        {
            myArticulo = {}
            myArticulo.id = dataField.articulo.idArticulo
            myArticulo.tipoArticulo = dataField.articulo.tipoArticulo
            myArticulo.nombre = dataField.articulo.nombre
            myArticulo.precioLista = dataField.articulo.precioLista

            // articulo textil
            if(myArticulo.tipoArticulo === 'Textil') {

                // cargo la lista de talle-color
                myArticulo.listaTalleColor = {
                    talles: [],
                    colores: []
                }

                // armo la lista de colores
                for(var i=0; i<dataField.articulo.talleColor.length; i++)
                {
                    let talleColor = dataField.articulo.talleColor[i]

                    // verifico si el color existe en la lista de colores
                    let valorColor = talleColor.colorCodigo ? talleColor.colorCodigo : talleColor.colorImagen
                    let indexColor = myArticulo.listaTalleColor.colores.findIndex((c)=> { if(c.valor === valorColor) return c })

                    // si no existe...
                    if(indexColor === -1) {
                        let newColor = {
                            valor: valorColor,
                            esImagen: talleColor.colorCodigo ? false : true,
                            idTalleColor: [],
                            disponibles: [], 
                            cantidades: []
                        }
                        myArticulo.listaTalleColor.colores.push(newColor)
                    }
                }

                // armo la lista de talles
                for(var i=0; i<dataField.articulo.talleColor.length; i++)
                {
                    let talleColor = dataField.articulo.talleColor[i]

                    // verifico si el talle existe en la lista de talles
                    let indexTalle = myArticulo.listaTalleColor.talles.findIndex((t)=> { if(t.idTalle === talleColor.idTalle) return t })
                    
                    // si no existe...
                    if(indexTalle === -1) {
                        
                        // agrego el talle a la lista de talles
                        let newTalle = {
                            idTalle: talleColor.idTalle,
                            nombre: talleColor.nombreTalle
                        }
                        myArticulo.listaTalleColor.talles.push(newTalle)

                        // agrego la cantidad a los colores
                        for(var j=0; j<myArticulo.listaTalleColor.colores.length; j++)
                        {
                            myArticulo.listaTalleColor.colores[j].idTalleColor.push(undefined)
                            myArticulo.listaTalleColor.colores[j].disponibles.push(0)
                            myArticulo.listaTalleColor.colores[j].cantidades.push(0)
                        }
                    }
                }

                // cargo los valores de cantidad disponible y el id detalle color
                for(var i=0; i<dataField.articulo.talleColor.length; i++)
                {
                    let talleColor = dataField.articulo.talleColor[i]

                    // busco el index de talle
                    let indexTalle = myArticulo.listaTalleColor.talles.findIndex((t)=> { if(t.idTalle === talleColor.idTalle) return t })

                    // busco el index de color
                    let valorColor = talleColor.colorCodigo ? talleColor.colorCodigo : talleColor.colorImagen
                    let indexColor = myArticulo.listaTalleColor.colores.findIndex((c)=> { if(c.valor === valorColor) return c })
                    
                    // agrego el id de talle color al color
                    myArticulo.listaTalleColor.colores[indexColor].idTalleColor[indexTalle] = talleColor.idTalleColor
                    myArticulo.listaTalleColor.colores[indexColor].disponibles[indexTalle] = talleColor.cantidadStock


                    // si se cargó un detalle de pedido...
                    if(dataField.detallePedido) {

                        // busco el talle-color dentro del datalle textil 
                        let detalleTextilTalleColor = dataField.detallePedido.detalleTextilTalleColor.find((tc) => { if(tc.idTalleColor === talleColor.idTalleColor) return tc })
                        if(detalleTextilTalleColor) {
                            myArticulo.listaTalleColor.colores[indexColor].cantidades[indexTalle] = detalleTextilTalleColor.cantidad
                        }
                    }
                }
            }
        }

        //detalle pedido
        let myDetallePedido = {}
        myDetallePedido.id = 0
        myDetallePedido.articulo = myArticulo
        
        // si se cargó un detalle de pedido cargo sus datos
        if(dataField.detallePedido) {
            myDetallePedido.id = dataField.detallePedido.idPedidoDetalle
        }
        
        return {
            detallePedido: myDetallePedido
        }
    }
  )

const detallePedidoReducerErrorSelector = state => state.detallePedidoReducer.getIn(["error"])
export const errorSelector = createSelector(
    detallePedidoReducerErrorSelector, (errorField) => {    
    return errorField
  }
)