import _axios from '../../apiClient'

export function getProvincias(payload) {
    return _axios.get(`/api/Provincia/GetAll`,
    {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}

export function getLocalidades(payload) {
  return _axios.get(`/api/Localidad/GetByProvincia`,
  { 
      params: { idProvincia: payload } 
  },
  {
    headers: { 'Content-Type': 'application/json;charset=UTF-8' }
  })
  .then(response => response.data)
  .catch((error) => {
    const data = error.response.data
    throw (data)
  })
}

export function getLocalidadesCombo(payload) {
  return _axios.get(`/api/Localidad/GetLocalidadesByFilter`,
  { 
    params: { filtro: payload } 
  },
  {
    headers: { 'Content-Type': 'application/json;charset=UTF-8' }
  })
  .then(response => response.data)
  .catch((error) => {
    const data = error.response.data
    throw (data)
  })
}

export function getAllLocalidades(payload) {
  return _axios.get(`/api/Localidad/GetLocalidades`,
  {
    headers: { 'Content-Type': 'application/json;charset=UTF-8' }
  })
  .then(response => response.data)
  .catch((error) => {
    const data = error.response.data
    throw (data)
  })
}
	
export function sugerenciasLocalidades(payload){
  return _axios.get(`/api/Localidad/SugerenciasLocalidades`,
  {
    params: payload,
    headers: { 'Content-Type': 'application/json;charset=UTF-8' }
  })
  .then(response => response.data)
  .catch((error) => {
    const data = error.response.data
    throw (data)
  })
}