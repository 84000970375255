import _axios from '../../apiClient'

export function getSucursales(idInstalacion) {
  return _axios.get(`/api/Instalacion/GetSucursalesWithFormaEntrega/${idInstalacion}`,
    {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}