export const formatMoney = (decimalNumber) => {
    
    // llevo el valor a 2 decimales
    let formattedValue = decimalNumber.toFixed(2);

    // separo la parte entera y decimal
    let integerPart = formattedValue.split('.')[0];
    let decimalPart = formattedValue.split('.')[1];

    // formateo la parte entera
    let formattedInt = '';
    for (var i = 0; i < integerPart.length; i++) {

        // agrego el punto de mil en caso de ser necesario
        if (i % 3 == 0 && i != 0)
            formattedInt = '.' + formattedInt;

        formattedInt = integerPart[integerPart.length - i - 1] + formattedInt;
    }


    // prefijo
    let prefix = '$ ';

    // resultado final
    let result = prefix + formattedInt + ',' + decimalPart;

    return result;
}