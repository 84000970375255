import _axios from '../../apiClient'

export function getArticulo(payload) {
    return _axios.get(`/api/Articulo/GetArticuloCompleto`,
    { 
        params: { idArticulo: payload } 
    },
    {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' }
    })
    .then(response => response.data)
    .catch((error) => {
      const data = error.response.data
      throw (data)
    })
}

export function getPedidoDetalle(payload) {
  return _axios.get(`/api/Pedido/GetPedidoDetalle`,
  { 
      params: { id: payload } 
  },
  {
    headers: { 'Content-Type': 'application/json;charset=UTF-8' }
  })
  .then(response => response.data)
  .catch((error) => {
    const data = error.response.data
    throw (data)
  })
}