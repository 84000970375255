import { fromJS } from 'immutable'
import * as ReducerActions from './actions'

const initialState = fromJS({
    data: {
        pedido: undefined,
        idPedidoDetalleAgregado: undefined,
        pedidosUser: undefined,
        pedidosCliente:undefined,
        showResumenPedido: false,
        openModalVaciarCarrito: false
    },
})

export default function pedidoReducer(state = initialState, action) {

    if(action.type === ReducerActions.INITIALIZE_FORM) {
        return initialState
    }

    if(action.type === ReducerActions.SET_PEDIDODETALLE_REEMPLAZO) {
        
        let myState = state.toJS();
        let pedido = myState.data.pedido

        if(pedido !== undefined && pedido.pedidoDetalles && pedido.pedidoDetalles.length > 0){

            if(action.payload.pedidoCompleto){
                pedido.pedidoDetalles.forEach(pd => {
                    pd.permisoReemplazo = action.payload.value
                });
            }
            else{
                pedido.pedidoDetalles.forEach(pd => {
                    if(pd.idPedidoDetalle == action.payload.idPedidoDetalle){
                        pd.permisoReemplazo = action.payload.value
                    }
                });
            }
            
            return state.setIn(["data", "pedido"], pedido)
        }
    }
    
    if(action.type === ReducerActions.SET_OPEN_MODAL_VACIAR_CARRITO) {
        return state.setIn(["data", "openModalVaciarCarrito"], action.payload)
    }

    if(action.type === ReducerActions.SAVE_PEDIDO) {
        return state.setIn(["data", "pedido"], action.payload)
    }

    if(action.type === ReducerActions.SAVE_ID_PEDIDO_DETALLE) {
        return state.setIn(["data", "idPedidoDetalleAgregado"], action.payload)
    }
    
    if(action.type === ReducerActions.SET_UNDEFINED_ID_PEDIDO_DETALLE) {
        return state.setIn(["data", "idPedidoDetalleAgregado"], undefined)
    }

    if(action.type === ReducerActions.SET_PEDIDOSUSER) {
        return state.setIn(["data", "pedidosUser"], action.payload)
    }
    
    if(action.type === ReducerActions.SET_PEDIDOSCLIENTE) {
        return state.setIn(["data", "pedidosCliente"], action.payload)
    }

    if(action.type === ReducerActions.SET_PEDIDOS_BORRADOR_CLIENTE) {
        return state.setIn(["data", "pedidosBorradorCliente"], action.payload)
    }

    if(action.type === ReducerActions.SET_SHOW_RESUMEN_PEDIDO) {
        return state.setIn(["data", "showResumenPedido"], action.payload)
    }

    return state
}