import React from 'react'
import PropTypes from 'prop-types'
import FormHelperText from '@material-ui/core/FormHelperText'
import CustomButton from '../Common/wrappers/CustomButton'
import CustomInput from '../Common/textInput/CustomInput'

class OlvidoContraseniaForm extends React.Component {

  render() {
    return (
      <div className='olvidoContraseniaForm'>
        <div className='containerForm'>
          {this.props.claveProvisoriaGenerada === false ?
            <div style={{ width: '100%' }}>
              <div className='logoContainer center'>
                <img src={this.props.logo} alt='Logo' />
              </div>

              <div className='inputContainer' style={{ marginBottom: '0px 0px 10px 0px' }}>
                <CustomInput
                  placeholder="Email"
                  value={this.props.loginData.emailOlvidoContrasenia}
                  name="emailOlvidoContrasenia"
                  onChange={this.props.handleOnDataChange}
                  error={this.props.errores.emailOlvidoContrasenia !== undefined}
                />
                {this.props.errores.emailOlvidoContrasenia !== undefined &&
                  <FormHelperText error>{this.props.errores.emailOlvidoContrasenia}</FormHelperText>}
              </div>
              <div className='myLabel error' style={{ visibility: this.props.errorAutenticacion ? 'visible' : 'hidden' }} >{this.props.errorAutenticacion || ''}</div>
              <div className='buttonContainer'>
                <CustomButton color="secondary" onClick={this.props.handleOnOlvidoContrasenia} size="small" message="Enviar correo" />
              </div>
            </div>
            :
            <div>
              <div className='logoContainer center'>
                <img src={this.props.logo} alt='Logo' />
              </div>

              <div className='labelInfoCodigo'>{`Le enviamos un código a su correo ${this.props.loginData.emailOlvidoContrasenia ? this.props.loginData.emailOlvidoContrasenia : ''}`}</div>

              <div className='inputContainer' style={{ margin: '30px 0px' }}>
                <CustomInput
                  placeholder="Código"
                  value={this.props.loginData.codigoVerificacion}
                  name="codigoVerificacion"
                  onChange={this.props.handleOnDataChange}
                  error={this.props.errores.codigoVerificacion !== undefined}
                />
                {this.props.errores.codigoVerificacion !== undefined &&
                  <FormHelperText error>{this.props.errores.codigoVerificacion}</FormHelperText>}
              </div>

              <div className='buttonContainer'>
                <CustomButton color="secondary" onClick={this.props.handleOnConfirmarCódigo} size="large" message="Confirmar código" />
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

OlvidoContraseniaForm.propTypes = {
  loginData: PropTypes.object.isRequired,
  handleOnDataChange: PropTypes.func.isRequired,
  handleOnCancelar: PropTypes.func.isRequired,
  handleOnOlvidoContrasenia: PropTypes.func.isRequired,
  errores: PropTypes.object.isRequired,
  errorAutenticacion: PropTypes.string,
  claveProvisoriaGenerada: PropTypes.bool.isRequired,
  logo: PropTypes.object.isRequired,
  handleOnConfirmarCódigo: PropTypes.func.isRequired,
}

export default OlvidoContraseniaForm