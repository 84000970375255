import { createSelector } from 'reselect'

const confirmarPedidoReducerDataSelector = state => state.confirmarPedidoReducer.getIn(["data"]).toJS()
export const confirmarPedidoDataSelector = createSelector(
    confirmarPedidoReducerDataSelector, (dataField) => {  
      
      // pedido
      let myPedido = undefined
      if(dataField.pedido)
      {
        // cargo los datos del pedido
        myPedido = {}
        myPedido.precioFinal = dataField.pedido.precioFinal
        myPedido.precioLista = dataField.pedido.precioLista
        myPedido.estadoPedido = dataField.pedido.estadoPedido
        
        // cargo los detalles
        myPedido.detalles = []
        dataField.pedido.pedidoDetalles.forEach((p) => {
          let item = {}
          item.id = p.idDetallePedido
          item.idArticulo = p.idArticulo
          item.cantidad = p.cantidad
          item.descripcion = p.descripcion
          item.precio = p.precioFinal
          item.imagen = p.imagen

          myPedido.detalles.push(item)
        })
        
        // orden los pedidos por id de detalle
        myPedido.detalles.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
      }
      
      return {
        pedido: myPedido
      }
    }
  )

const confirmarPedidoReducerRedirigirSelector = state => state.confirmarPedidoReducer.getIn(["redirigir"])
export const confirmarPedidoRedirigirSelector = createSelector(
  confirmarPedidoReducerRedirigirSelector, (redirigirField) => { 
    return redirigirField
  }
)