import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import './styles.css'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import HomeIcon from '@material-ui/icons/Home';
import DateRangeIcon from '@material-ui/icons/DateRange';
import TurneroContainer from '../../containers/TurneroContainer'
import DomicilioEntregaContainer from '../../containers/DomicilioEntregaContainer'
import FacturacionContainer from '../../containers/FacturacionContainer';
import { formatMoney } from '../Common/decimalHelper'
import Heading from '../UI/Texts/Heading';
import { showInDateRange } from '../../utils';
import CustomButton from '../Common/wrappers/CustomButton';

const preferenciaEntrega = {
  Pickup: 1,
  Delivery: 2
}

const PedidoDatosAdicionales = (
  {
    props,
    preferenciaFacturacionElegida
  }
) => {
  return <div className='containerDatosAdicionales'>
    {/* SELECCION METODO DE PAGO */}
    {!props.preferenciaEntregaElegida && <div className={`formaRetiro ${props.showMetodosPago === 1 ? 'formaRetiroMargin' : ''}`}>
      <Heading tag="h4" weight="semi_bold" color="black-text">¿Cuál es tu preferencia de entrega?</Heading>
      <div className='labelDescripcionFormasEntrega'>
        {showInDateRange('2022-09-28', '2022-10-26') &&
          <>
            <div className='infoFormaPagosHarlem'>HARLEM: Si solo compras tu entrada al Harlem festival, seleccioná la opción: “Pickup/Harlem” En la siguiente ventana deberás seleccionar la opción de turno “Harlem”.</div>
            <div className='infoFormaPagosHarlem'>Si además de tu entrada, compraste otros productos, podés elegir cualquier modalidad de entrega (Pickup o Delivery).</div>
            <div className='infoFormaPagosHarlem'>Importante: si elegís la opción Delivery, "Pago contra entrega" podés pagar únicamente con Mercado pago o Billetera santa fe.</div>
          </>
        }
        <Heading tag="h6" weight="regular" color="dark-grey-text"><b>Pickup:</b> pasar a retirarlo por sucursal. Elige la sucursal de tu preferencia.</Heading>
        <br />
        <Heading tag="h6" weight="regular" color="dark-grey-text"><b>Delivery:</b> envíos a domicilio a Santa Fe, Santo Tome, countries, Sauce Viejo, Colastiné, Villa California y Rincón.</Heading>
      </div>
      {/* BOTONES FORMA ENTREGA*/}
      <div className='containerBtnsPago'>
        <CustomButton color="secondary" onClick={() => props.handleOnClickBtnEntrega(preferenciaEntrega.Pickup)} classProp={`btnPago`} message={showInDateRange('2022-09-28', '2022-10-26') ? 'Pickup/Harlem' : 'Pickup'} />
        <CustomButton color="secondary" onClick={() => props.handleOnClickBtnEntrega(preferenciaEntrega.Delivery)} classProp={`btnPago`} message="Delivery" />
      </div>
    </div>}
    {/* PREFERENCIA DE ENTREGA SELECCIONADA */}
    {props.preferenciaEntregaElegida && props.entregaElegida !== undefined &&
      <div className='containerResumenEntrega'>
        <div>
          <div className='titlePreferencia'><LocalShippingIcon style={{ marginRight: '10px', color: "#000" }} /><Heading tag="h6" weight="semi_bold" color="black-text">Preferencia de entrega</Heading></div>
          {props.entregaElegida === 1 ?
            <Heading tag="h6" weight="regular" color="black-text">Pickup paso a retirarlo por sucursal.</Heading>
            :
            <Heading tag="h6" weight="regular" color="black-text">Delivery envíenlo a domicilio: Santa fe, Santo tome, Countries, Sauce Viejo, Colastiné, Villa California y Rincón.</Heading>
          }
          <div className='btnCambiar' onClick={props.handleOnClickCambiarPreferencia}>Cambiar</div>
        </div>
      </div>
    }
    {/* DOMICILIO DE ENTREGA (DELIVERY) */}
    {props.entregaElegida === 2 &&
      <div className={`containerResumenEntrega ${props.showTurnero ? '' : 'noSeparador'}`}>
        <div>
          <div className='titlePreferencia'><HomeIcon style={{ marginRight: '10px', fontSize: '30px', color: "#000" }} /> <Heading tag="h6" weight="semi_bold" color="black-text">Domicilio de entrega</Heading></div>
          {props.showTurnero &&
            <Heading tag="h6" weight="regular" color="black-text">{props.domicilioSeleccionadoItem ? props.domicilioSeleccionadoItem.domicilio + (props.domicilioSeleccionadoItem.pisoDepto ? " (" + props.domicilioSeleccionadoItem.pisoDepto + (props.domicilioSeleccionadoItem.opcional ? "" : ")") : '') + (props.domicilioSeleccionadoItem.opcional ? (props.domicilioSeleccionadoItem.pisoDepto ? " " : " (") + props.domicilioSeleccionadoItem.opcional + ")" : '') : ""}
            </Heading>}
          {props.showTurnero && <div className='btnCambiar' onClick={props.handleOnClickCambiarDomicilio}>Cambiar</div>}
        </div>
      </div>}
    {props.entregaElegida === 2 && !props.showTurnero &&
      <div className='containerEleccionDomicilio'>
        <DomicilioEntregaContainer
          domicilioSeleccionado={props.domicilioSeleccionado}
          domicilios={props.domicilios}
          handleChangeRadioButtonDomicilio={props.handleChangeRadioButtonDomicilio}
          handleAfterSaveNuevoDomicilio={props.handleAfterSaveNuevoDomicilio}
          handleOnClickSaveDomicilioAlternativo={props.handleOnClickSaveDomicilioAlternativo}
          instalacion={props.instalacion}
          usuario={props.usuario}
        />
      </div>
    }
    {/* PICKUP FECHA HORA RETIRO */}
    {props.fechaHoraSeleccionada && (props.entregaElegida === 1 || props.entregaElegida === 2) &&
      props.fechaSeleccionada !== undefined && props.horaSeleccionada !== undefined &&
      <div className='containerResumenEntrega'>
        <div>
          <div className='titlePreferencia'><DateRangeIcon style={{ marginRight: '10px', color: "#000" }} />
            <Heading tag="h6" weight="semi_bold" color="black-text">{`Fecha y hora de ${props.entregaElegida === 1 ? 'retiro' : 'envío'}`}</Heading>
          </div>
          <Heading tag="h6" weight="regular" color="black-text">{`${props.fechaSeleccionada.descripcionLarga.slice(0, -5)} entre las ${props.horaSeleccionada.descripcion} horas.`}</Heading>
          <div className='btnCambiar' onClick={props.handleOnClickCambiarFechaHora}>Cambiar</div>
        </div>
      </div>}
    {props.showTurnero && !props.fechaHoraSeleccionada && props.entregaElegida !== undefined &&
      <div className='containerTurnos'>
        <TurneroContainer
          listaFechaHora={props.calendario}
          diasAdelante={props.diasAdelante}
          tipo={props.entregaElegida === 1 ? 'pickup' : 'delivery'}
          handleOnClickDia={props.handleOnClickDia}
          handleOnClickHorario={props.handleOnClickHorario}
          sucursalesPickup={props.sucursalesPickup}
          sucursalPickupSeleccionada={props.sucursalPickupSeleccionada}
          handleOnChangeSucursalPickup={props.handleOnChangeSucursalPickup}
        />
      </div>
    }
    {/* SELECCION FACTURACION */}
    {props.permitePago && props.preferenciaEntregaElegida &&
      <FacturacionContainer
        tipoFacturacion={props.datosFacturacion.tipoFacturacion}
        idClienteDatosFacturacion={props.datosFacturacion.idClienteDatosFacturacion}
        onChange={props.handleOnChangeFacturacion}
      />
    }
    {/* SELECCION METODO DE PAGO */}
    {props.permitePago && props.preferenciaEntregaElegida && preferenciaFacturacionElegida && <div className={`metodosPago ${props.showMetodosPago === 1 ? 'metodosPagoMargin' : ''}`}>
      <div className='labelTitle'>Cómo quieres pagar?</div>
      {/* BOTONES MEDIO PAGO */}
      <div className={`containerBtnsPago ${props.entregaElegida === preferenciaEntrega.Delivery ? 'containerBtnsPagoAux' : ''}`}>
        <CustomButton color="secondary" classProp={`btnPago ${props.showMetodosPago === 1 ? 'btnActive' : ''}`} onClick={() => props.handleOnClickBtnFormaPago(1)} size="small" message="Pago Online" />
        {props.entregaElegida !== preferenciaEntrega.Delivery &&
          <CustomButton color="secondary" classProp={`btnPago ${props.showMetodosPago === 4 ? 'btnActive' : ''}`} onClick={() => props.handleOnClickBtnFormaPago(2)} size="small" message="Pago al retirar" />
        }
        {props.entregaElegida === preferenciaEntrega.Delivery &&
          <CustomButton color="secondary" classProp={`btnPago ${props.showMetodosPago === 4 ? 'btnActive' : ''}`} onClick={() => props.handleOnClickBtnFormaPago(4)} size="small" message="Pago al recibir" />
        }
      </div>
    </div>}
    {props.permitePago && props.preferenciaEntregaElegida && props.entregaElegida === 2 && props.instalacion && props.instalacion.cobroDelivery &&
      <Row className="containerInfoFormaPagos">
        <Col>
          {showInDateRange('2022-06-30', '2022-07-06') ?
            <div className='infoFormaPagos'>
              <InfoOutlinedIcon />
              <div>Delivery gratis por una semana, la vigencia es desde el 30-6-22 al 06-07-22</div>
            </div>
            : <div className='infoFormaPagos'>
              <InfoOutlinedIcon />
              <div>A su ticket se le adicionarán {props.instalacion.montoDelivery ? formatMoney(props.instalacion.montoDelivery) : '$-'} de envío a domicilio.</div>
            </div>
          }
        </Col>
      </Row>}
    {props.permitePago &&
      props.preferenciaEntregaElegida &&
      props.instalacion.cobroDelivery &&
      props.showMetodosPago !== 2 &&  // pago al retirar
      props.showMetodosPago !== 4 &&  // pago al recibir
      <Row className="containerInfoFormaPagos">
        <Col>
          {showInDateRange('2022-09-28', '2022-10-26') &&
            <div className='infoFormaPagosHarlem' style={{ marginBottom: 16 }}>
              <InfoOutlinedIcon />
              <div>ENTRADA HARLEM: Seleccioná Pago Online. En las próximas 24 hs vas a recibir un mail por parte de los organizares del Harlem Festival con tu/tus entradas.</div>
            </div>
          }
          <div className='infoFormaPagos'>
            <InfoOutlinedIcon />
            <div>{`Si quieres pagar con Billetera Santa Fe, ingresa la opción: Pago al ${props.entregaElegida === 2 ? 'recibir' : 'retirar'}.`}</div>
          </div>
        </Col>
      </Row>}
    {/* RENDER MODAL MOBBEX */}
    {props.permitePago && props.preferenciaEntregaElegida && /* props.showMetodosPago === 1 &&*/
      <div id="mbbx-container"></div>
    }
    {/* PAGO AL RETIRAR */}
    {props.permitePago && props.preferenciaEntregaElegida && preferenciaFacturacionElegida && props.showMetodosPago === 2 &&
      <div className='containerPagoAlRetirar'>
        <div className='labelDescripcionPago'>
          <p>Podrás pagar tu compra cuando vengas a retirarla.</p>
          <strong>Medios de cobro habilitados:</strong>
          <div>
            <div>Tarjetas de Crédito y débito.</div>
            <div>MODO.</div>
            <div>Mercado pago QR.</div>
            <div>Plus Pagos QR.</div>
          </div>
          <strong>Medios de cobro NO habilitados:</strong>
          <div>
            <div>Efectivo.</div>
            <div>Tarjeta Clientazo corporativa.</div>
          </div>
        </div>
        <div className='containerBtnPagar'>
          {/* <Button className='btnCheckout' onClick={props.handleOnClickFinalizarCompra} disabled={props.disabledPagar}>
            Finalizar Compra
    </Button> */}
          <CustomButton color="success" classProp={"btnCheckout"} message="Finalizar Compra" disabled={props.disabledPagar}
            onClick={props.handleOnClickFinalizarCompra}
          />
        </div>
      </div>}
    {/* PAGO BILLETERA SANTA FE EN ENTREGA */}
    {props.permitePago && props.preferenciaEntregaElegida && props.showMetodosPago === 4 &&
      <div className='containerPagoAlRetirar'>
        <div className='labelDescripcionPago'>
          <p>Podrás pagar tu compra en la entrega.</p>
          <strong>Medios de cobros habilitados:</strong>
          <div>
            <div>Tarjeta de Crédito (Solo en un pago) y Tarjeta de Débito.</div>
            <div>MODO</div>
            <div>Mercado Pago QR</div>
            <div>Plus Pagos QR</div>
          </div>
          <strong>Medios de cobro NO habilitados para delivery:</strong>
          <div>
            <div>Efectivo.</div>
            <div>Tarjeta Clientazo corporativa.</div>
          </div>
        </div>
        <div className='containerBtnPagar'>
          {/*<Button className='btnCheckout' onClick={props.handleOnClickFinalizarCompra} disabled={props.disabledPagar}>
            Finalizar Compra22
          </Button>*/}
          <CustomButton color="success" classProp={"btnCheckout"} message="Finalizar Compra" disabled={props.disabledPagar}
            onClick={props.handleOnClickFinalizarCompra}
          />
        </div>
      </div>}
  </div>
}

PedidoDatosAdicionales.propTypes = {
  props: PropTypes.object.isRequired,
  preferenciaFacturacionElegida: PropTypes.bool.isRequired,
}

export default PedidoDatosAdicionales;