import React from 'react'
import { HashRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress';
import MyHeader from './header'
import MyFooter from '../UI/Footer/Footer'
import DrawerResumenPedido from '../DrawerResumenPedido'
import DrawerNotificaciones from '../DrawerNotificaciones'
import CalendarioContainer from '../../containers/CalendarioContainer'
import FeedbackButton from "@smiledev/react-web-feedback"
import CustomModal from '../UI/Modals/CustomModal'
import OfertasContainer from '../../containers/OfertasContainer'
import { Route } from 'react-router-dom'
import './layout.css'
import { routes } from '../../config/routes'

const drawerWidth = 350;

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    opacity: 1
  },
  drawerPaper: {
    width: drawerWidth,
    background: '#EFF0F8 0% 0% no-repeat padding-box',
    opacity: 1,
    zIndex: 1500
  },
  content: {
    flexGrow: 1,
    padding: '0px',
    marginTop: '30px',
    flexGrow: 1,
    width: "100%",
  },
  rootWhatsapp: {
    flexGrow: 1,
  },
  show: {
    transform: 'translateY(0)',
    transition: 'transform .5s',
  },
  hide: {
    transform: 'translateY(+135%)',
    transition: 'transform .5s',
  },
  rootDialog: {
    zIndex: 1550,
  },
  rootContent: {
    borderRadius: '21px',
    minWidth: '300px'
  }
});

class Layout extends React.Component {

  render() {
    const { classes } = this.props
    if (this.props.instalacion === undefined)
      return null



    // calculo el alto del header para asignar el margen superior al contenido
    let headerHeight = 80
    if (this.props.header.show === false)
      headerHeight = 0

    let contenidoExtraHeader = document.getElementById('headerContenidoExtra')
    if (contenidoExtraHeader && this.props.header.extraContent !== undefined)
      headerHeight += contenidoExtraHeader.clientHeight

    return (
      <HashRouter>
        <div className={classes.root}>

          {/* HEADER */}
          {this.props.header.show &&
            <MyHeader
              showDrawer={this.props.header.showDrawer}
              showBackButton={this.props.header.showBackButton}
              handleOnBackClick={this.props.header.handleOnBackClick}
              drawerOpen={this.props.drawerOpen}
              handleDrawerOpen={this.props.handleDrawerOpen}
              handleDrawerClose={this.props.handleDrawerClose}
              instalacion={this.props.instalacion}
              contenidoExtra={this.props.header.extraContent}
              handleOnCerrarSesion={this.props.handleOnCerrarSesion}
              handleOnClickLogo={this.props.handleOnClickLogo}

              preguntasFrecuentes={this.props.preguntasFrecuentes}
              usuario={this.props.usuario}
              showResumenPedido={this.props.showResumenPedido}
              pedido={this.props.pedido}
              filterArticulos={this.props.filterArticulos}
              handleOnChangeFilter={this.props.handleOnChangeFilter}
              handleOnClearBusqueda={this.props.handleOnClearBusqueda}
              handleOnClickIniciarSesion={this.props.handleOnClickIniciarSesion}
              handleOnClickVerCarrito={this.props.handleOnClickVerCarrito}
              showBusqueda={this.props.showBusqueda}

              handleOnClickBasesCondiciones={this.props.handleOnClickBasesCondiciones}
              handleOnClickOpenModalRubros={this.props.handleOnClickOpenModalRubros}
              openModalRubros={this.props.openModalRubros}
              handleOnClickOpenCalendario={this.props.handleOnClickOpenCalendario}
              handleOnClickOpenNotidicaciones={this.props.handleOnClickOpenNotidicaciones}
              handleOnClickFavoritos={this.props.handleOnClickFavoritos}
            />
          }

          <main className={`${classes.content} backMain`} style={{ marginTop: `${headerHeight}px` }}>

            {/* div separador cuando se muestra el menú superior */}
            {this.props.showHeader &&
              <div className={classes.toolbar} />
            }

            {routes()}

            {this.props.instalacion && this.props.instalacion.listaOfertas && this.props.instalacion.listaOfertas.length > 0 &&
              this.props.instalacion.listaOfertas.map((o) => {
                return <Route
                  path={`/${o.tag}`}
                  render={(props) => (
                    <OfertasContainer oferta={o} />
                  )}
                />
              })}

            {/* FOOTER */}
            {this.props.footer.show &&
              <MyFooter
                instalacion={this.props.instalacion}
                existePedido={this.props.pedido !== undefined ? true : false}
                handleOnClickBasesCondiciones={this.props.handleOnClickBasesCondiciones}
              />}

          </main>

          {/* DRAWER PARA CELULARES Y TABLET (EXPANDIBLE) */}
          {this.props.pedido !== undefined && this.props.showResumenPedido === true &&
            <DrawerResumenPedido
              usuario={this.props.usuario}
              instalacion={this.props.instalacion}
              pedido={this.props.pedido}
              handleOnClickCheckout={this.props.handleOnClickCheckout}
              open={this.props.openResumenPedido}
              handleOnClose={this.props.handleOnClickVerCarrito}
              handleAgregarQuitarUnidad={this.props.handleAgregarQuitarUnidad}
              loading={this.props.loading.show}
              handleOnVaciarCartito={this.props.handleOnVaciarCartito}
            />}

          {/* DRAWER FIJO PARA RESOLICIONES MAYORES A 768 */}
          {!this.props.openNotificaciones && this.props.pedido !== undefined && this.props.showResumenPedido === true && !this.props.openCalendario &&
            <DrawerResumenPedido
              usuario={this.props.usuario}
              instalacion={this.props.instalacion}
              pedido={this.props.pedido}
              handleOnClickCheckout={this.props.handleOnClickCheckout}
              isPersistent
              handleAgregarQuitarUnidad={this.props.handleAgregarQuitarUnidad}
              loading={this.props.loading.show}
              handleOnVaciarCartito={this.props.handleOnVaciarCartito}
            />}
          {/* CALENDARIO */}
          {this.props.openCalendario &&
            <CalendarioContainer
              usuario={this.props.usuario}
              instalacion={this.props.instalacion}
              handleOnClose={this.props.handleOnCloseCalendario}
              open={this.props.openCalendario}
            />
          }

          {this.props.repetirPedido &&
            <CalendarioContainer
              usuario={this.props.usuario}
              instalacion={this.props.instalacion}
              handleOnClose={this.props.handleOnCloseCalendario}
              open={this.props.openCalendario}
            />
          }

          {/* DRAWER NOTIFICACIONES */}
          {<DrawerNotificaciones
            usuario={this.props.usuario}
            instalacion={this.props.instalacion}
            pedido={this.props.pedido}
            handleOnClose={this.props.handleOnCloseNotificaciones}
            open={this.props.openNotificaciones}
            handleOnClickNotificaciones={this.props.handleOnClickNotificaciones}
            handleOnClickVerMas={this.props.handleOnClickVerMas}

          />}

          {/* WHATSAPP INSTALACION */}
          {this.props.instalacion && this.props.instalacion.datosContacto && this.props.instalacion.datosContacto.telefono &&
            <div
              className={`${classes.rootWhatsapp} 
              ${this.props.shouldShow === null ? '' : (this.props.shouldShow ? classes.show : classes.hide)} 
              ${this.props.pedido !== undefined && this.props.showResumenPedido === true ? 'buttonFlotanteContainerAux' : ''}
              buttonFlotanteContainer`
              }
            >
              <img src={require('../../images/whatsapp.png')} onClick={this.props.handleOnClickWhatsApp} />
            </div>}

          {/* FEEDBACK SMILE */}
          {<FeedbackButton token={'eyJkZXZpY2VfaWQiOjMwNCwiYXBpX2tleSI6InZJTnNBWWlBZ2gifQ=='} />}

          {/* MODAL IDENTIFICATE */}
          <CustomModal
            open={this.props.openModalIdentificate}
            onClose={() => { }}
            title="Necesitamos saber quien sos"
            buttonNames={["IDENTIFICATE"]}
            fullWidth={true}
            buttonActions={
              [this.props.handleOnClickIdentificate]
            }
          >
            <div className='modalTextContent'>
            </div>
          </CustomModal>

          {/* LOADER */}
          {this.props.loading.show &&
            <div id='loader'>
              <div>
                <div className='loaderContainer'>
                  <CircularProgress />
                  <div className='mensaje'>{this.props.loading.message || 'Espere por favor'}</div>
                </div>
              </div>
            </div>}
        </div>
      </HashRouter>
    );
  }
}

Layout.propTypes = {
  drawerOpen: PropTypes.bool.isRequired,
  handleDrawerOpen: PropTypes.func.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
  loading: PropTypes.object.isRequired,
  instalacion: PropTypes.object.isRequired,
  header: PropTypes.object.isRequired,
  footer: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  preguntasFrecuentes: PropTypes.object,
  pedido: PropTypes.object,
  usuario: PropTypes.object,
  handleOnCerrarSesion: PropTypes.func.isRequired,
  handleOnClickLogo: PropTypes.func.isRequired,
  showResumenPedido: PropTypes.bool.isRequired,
  filterArticulos: PropTypes.string.isRequired,
  handleOnChangeFilter: PropTypes.func.isRequired,
  handleOnClearBusqueda: PropTypes.func.isRequired,
  handleOnClickIniciarSesion: PropTypes.func.isRequired,
  handleOnClickCheckout: PropTypes.func.isRequired,
  openResumenPedido: PropTypes.bool.isRequired,
  handleOnClickVerCarrito: PropTypes.func.isRequired,
  showBusqueda: PropTypes.bool.isRequired,
  handleAgregarQuitarUnidad: PropTypes.func.isRequired,

  handleOnClickBasesCondiciones: PropTypes.func.isRequired,
  handleOnVaciarCartito: PropTypes.func.isRequired,
  openCalendario: PropTypes.func.isRequired,
  handleOnClickOpenModalRubros: PropTypes.func.isRequired,
  openModalRubros: PropTypes.bool.isRequired,
  openModalIdentificate: PropTypes.bool.isRequired,
  openNotificaciones: PropTypes.bool.isRequired,
  handleOnCloseNotificaciones: PropTypes.func.isRequired,
  handleOnCloseCalendario: PropTypes.func.isRequired,
  handleOnClickOpenNotidicaciones: PropTypes.func.isRequired,
  handleOnClickOpenCalendario: PropTypes.func.isRequired,
  handleOnClickFavoritos: PropTypes.func.isRequired,
  handleOnClickNotificaciones: PropTypes.func.isRequired,
  handleOnClickVerMas: PropTypes.func.isRequired,
  handleOnClickIdentificate: PropTypes.func.isRequired,
}

export default withStyles(styles, { withTheme: true })(Layout);