// acciones
export const INITIALIZE_FORM = 'turneroReducer/INITIALIZE_FORM'
export const SAGA_FETCH_CALENDARIO = 'turneroReducer/SAGA_FETCH_CALENDARIO'
export const SET_CALENDARIO = 'turneroReducer/SET_CALENDARIO'

// funciones
export const initializeForm = (payload) => ({
    type: INITIALIZE_FORM,
    payload
})

export const sagaFetchCalendario = (payload) => ({
    type: SAGA_FETCH_CALENDARIO,
    payload
})

export const setCalendario = (payload) => ({
    type: SET_CALENDARIO,
    payload
})

