import React from 'react'
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Turnero from '../../components/Turnero'
import { instalacionSelector } from '../../selectors/sessionSelector'

class TurneroContainer extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            indiceDiaSelected: this.props.listaFechaHora && this.props.listaFechaHora.length > 0 ? 0 : undefined,
            idDiaSelected: this.props.listaFechaHora && this.props.listaFechaHora.length > 0 ? this.props.listaFechaHora[0].idDiaCalendario : undefined,
            index: this.props.cantItemShow - 1,
            cantItemShow: this.props.cantItemShow, //cantidad de dias a msotrar 
            idHorarioSelected: undefined,
            indiceHorarioSelected: undefined,
            showModalTopeDias: false,
            showDiv:true,
        }

        if(this.state.indiceDiaSelected !== undefined && this.props.listaFechaHora !== undefined && this.props.listaFechaHora.length > 0){
            this.props.handleOnClickDia(this.props.listaFechaHora[this.state.indiceDiaSelected], this.state.indiceDiaSelected)
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(this.props.indiceDiaSelected !== undefined && this.props.idDiaSelected !== undefined && 
            (prevProps.indiceDiaSelected !== this.props.indiceDiaSelected || prevProps.idDiaSelected !== this.props.idDiaSelected)){
            this.setState({indiceDiaSelected: this.props.indiceDiaSelected, idDiaSelected: this.props.idDiaSelected, index: this.props.cantItemShow - 1, cantItemShow: this.props.cantItemShow});  
        }
        if(this.props.sucursalPickupSeleccionada && prevProps.sucursalPickupSeleccionada &&
            (prevProps.sucursalPickupSeleccionada !== this.props.sucursalPickupSeleccionada || prevProps.listaFechaHora !== this.props.listaFechaHora)){
            this.setState({indiceDiaSelected: 0, idDiaSelected: this.props.listaFechaHora[0].idDiaCalendario, index: this.props.cantItemShow - 1, cantItemShow: this.props.cantItemShow})
        }
        
    }

    handleOnClickDia = (item, indice) => {
        this.setState({idDiaSelected: item.idDiaCalendario, indiceDiaSelected: indice}, () => {
            this.props.handleOnClickDia(item)
        })
    }

    handleOnClickSiguiente = () => {
        let value = this.state.index + 1
        this.setState({index: value})
    }

    handleOnClickAnterior = () => {
        let value = this.state.index - 1
        this.setState({index: value})
    }

    handleOnClickHorario = (item, indice) => {
        this.setState({idHorarioSelected: item.idHorario, indiceHorarioSelected: indice}, () => {
            this.props.handleOnClickHorario(item)
        })
    }

    handleShowMsgTopeDias = () => {
        let showModal = this.state.showModalTopeDias
        this.setState({showModalTopeDias: !showModal})
    } 

    render() {
        return (
            <Turnero
                listaFechaHora={this.props.listaFechaHora}
                diasAdelante={this.props.diasAdelante}
                index={this.state.index}
                tipo={this.props.tipo}
                cantItemShow={this.state.cantItemShow}
                idDiaSelected={this.state.idDiaSelected}
                showDiv={this.props.showDiv}
                indiceDiaSelected={this.state.indiceDiaSelected}
                showModalTopeDias={this.state.showModalTopeDias}
                instalacion={this.props.instalacion}
                handleOnClickDia={this.handleOnClickDia}
                handleOnClickSiguiente={this.handleOnClickSiguiente}
                handleOnClickAnterior={this.handleOnClickAnterior}
                handleOnClickHorario={this.handleOnClickHorario}
                handleShowMsgTopeDias={this.handleShowMsgTopeDias}
                sucursalesPickup={this.props.sucursalesPickup}
                sucursalPickupSeleccionada={this.props.sucursalPickupSeleccionada}
                handleOnChangeSucursalPickup={this.props.handleOnChangeSucursalPickup}
            />
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch)
}

function mapStateToProps(state) {
    return {
        instalacion: instalacionSelector(state),
    }
}

TurneroContainer.defaultProps = {
    cantItemShow: 3,
};

TurneroContainer.propTypes = {
    listaFechaHora: PropTypes.array.isRequired,
    diasAdelante: PropTypes.number.isRequired,
    tipo: PropTypes.string.isRequired,
    handleOnClickDia: PropTypes.func.isRequired,
    handleOnClickHorario: PropTypes.func.isRequired,
    cantItemShow: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(TurneroContainer)