import { createSelector } from 'reselect'

const sucursalesReducerSelector = state => state.sucursalesReducer.getIn(["data"]).toJS()

export const sucursalesSelector = createSelector(

    sucursalesReducerSelector, (dataField) => { 
        
    return dataField.sucursales
  }
)
