import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as sessionActions from '../../reducers/SessionReducer/actions'
import * as layoutActions from '../../reducers/LayoutReducer/actions'
import * as catalogoActions from '../../reducers/CatalogoReducer/actions';
import { instalacionSelector, resultadoAutenticacionSelector, usuarioSelector } from '../../selectors/sessionSelector'
import { headerSelector, footerSelector } from '../../selectors/layoutSelector'
import * as Notification from '../../components/Common/notification'
import Login from '../../components/Login'

class LoginContainer extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      loginData: {
        email: '',
        contrasenia: '',
        nuevaContrasenia: undefined,
        repeticionNuevaContrasenia: undefined,
        emailOlvidoContrasenia: undefined
      },
      errores: {},
      olvidoContrasenia: this.props.match && this.props.match.params && this.props.match.params.idUsuario ? true : false,
      cargandoInstalacion: true,
      byUrl: this.props.match && this.props.match.params && this.props.match.params.idUsuario ? true : false
    }

    // inicializo el formulario
    this.props.initializeForm()

    // oculto el header y el footer
    this.showHeaderAndFooter(false)

    // by url 
    if(this.state.byUrl === true){
      this.props.setResultadoAutenticacion({ claveProvisoriaGenerada: true })
    }

    // si se llamó al login con un token, entonces se debe restablecer la contraseña
    if (this.props.match.params.token !== undefined) {
      this.props.setResultadoAutenticacion({ restablecerContrasenia: true, claveProvisoriaGenerada: true })
    }
  }

  componentDidUpdate = () => {
    if (this.props.header.show === true || this.props.footer.show === true) {
      // oculto el header y el footer
      this.showHeaderAndFooter(false)
    }

    if (this.state.cargandoInstalacion === true && this.props.instalacion !== undefined) {
      this.setState({ cargandoInstalacion: false })
    }
  }

  componentDidMount = () => {
    // indico que el Login se puede ver aunque el usuario no esté logeado
    this.props.setPermiteUsuarioAnonimo(true);
  }

  componentWillUnmount = () => {

    this.props.setPermiteUsuarioAnonimo(false)

    // muestro el header y el footer
    this.showHeaderAndFooter(true)

    // limpio el mensaje de error
    this.props.setResultadoAutenticacion({ error: undefined, restablecerContrasenia: false })
  }

  showHeaderAndFooter = (mostrar) => {
    this.props.setHeaderShow(mostrar)
    this.props.setFooterShow(mostrar)
  }

  handleOnDataChange = (event) => {
    // actualizo el valor de login data
    let loginData = { ...this.state.loginData }
    loginData[event.target.name] = event.target.value

    if(event.target.name === "codigoVerificacion" || event.target.name === "emailOlvidoContrasenia"){
      loginData[event.target.name] = event.target.value.trim()
    }

    this.setState({ loginData: loginData })
  }

  handleOnContraseniaKeyPress = (event) => {
    if (event.key === 'Enter')
      this.handleOnAutenticar()
  }

  handleOnAutenticar = () => {

    if (!this.validateLoginForm()) {
      // limpio el mensaje de error de autenticación
      this.props.setResultadoAutenticacion({ error: undefined })
      return
    }

    // autentico el usuario
    this.props.sagaAutenticarUsuario({
      email: this.state.loginData.email,
      contrasenia: this.state.loginData.contrasenia,
      idInstalacion: this.props.instalacion.id,
      onUsuarioAutenticado: this.handleOnUsuarioAutenticado,
      callbackMailNoConfirmado: this.handleOnUsuarioMailNoConfirmado
    })
  }

  handleOnUsuarioMailNoConfirmado = (idUsuario, token) => {
    window.location.hash = `/RegistrarUsuario/${idUsuario}/${token}`
  }

  handleOnUsuarioAutenticado = () => {

    //limpio el catalogo para evitar que se dupliquen los articulos
    this.props.setBuscandoCatalogo(true)
    this.props.setCatalogoSeleccionado(undefined)

    window.location.hash = '/Catalogo'
  }

  validateLoginForm = () => {
    let valida = true
    let errores = {}


    // valido el email
    if (this.state.loginData.email === undefined || this.state.loginData.email === '') {
      errores.email = 'Ingrese el email'
      valida = false
    }

    // valido la contraseña
    if (this.state.loginData.contrasenia === undefined || this.state.loginData.contrasenia === '') {
      errores.contrasenia = 'Ingrese la contraseña'
      valida = false
    }

    // actualizo el estado con los errores
    this.setState({ errores: errores })

    return valida
  }

  handleOnRestablecerContrasenia = () => {
    if (!this.validateRestablecerContraseniaForm())
      return

    // restablezco la contrasenia del usuario
    let idUsuarioToken = 0
    //Si es distinto de 0 es porque se solicito reestablecer la contraseña desde el sistema interno
    /*if (this.props.match.params.idUsuarioToken !== undefined) {
      idUsuarioToken = parseInt(this.props.match.params.idUsuarioToken)
    }*/

    this.props.sagaRestablecerContrasenia({
      idUsuario: this.props.resultadoAutenticacion ? this.props.resultadoAutenticacion.usuarioId : undefined,
      token: this.props.resultadoAutenticacion ? this.props.resultadoAutenticacion.token : undefined,
      contrasenia: this.state.loginData.nuevaContrasenia,
      idUsuarioToken: idUsuarioToken,
      onContraseniaRestablecida: this.handleOnContraseniaRestablecida
    })
  }

  handleOnContraseniaRestablecida = () => {
    Notification.showSuccess('La contraseña se restableció correctamente')
    window.location.hash = '/Catalogo'
  }

  handleOnCancelarRestablecerContrasenia = () => {
    this.props.setResultadoAutenticacion({ restablecerContrasenia: false, error: undefined })
    window.location.hash = '/Login'
  }

  validateRestablecerContraseniaForm = () => {
    let valida = true
    let errores = {}

    // valido la nueva contraseña provisoria
    if (this.state.loginData.nuevaContrasenia === undefined || this.state.loginData.nuevaContrasenia === '') {
      errores.nuevaContrasenia = 'Ingrese la nueva contraseña provisoria'
      valida = false
    }

    // valido la repetición de la nueva contraseña provisoria
    if (this.state.loginData.repeticionNuevaContrasenia === undefined || this.state.loginData.repeticionNuevaContrasenia === '') {
      errores.repeticionNuevaContrasenia = 'Vuelva a ingresar la contraseña provisoria'
      valida = false
    }
    else if (this.state.loginData.nuevaContrasenia !== this.state.loginData.repeticionNuevaContrasenia) {
      errores.repeticionNuevaContrasenia = 'Las contraseñas no coinciden'
      valida = false
    }

    // actualizo el estado con los errores
    this.setState({ errores: errores })

    return valida
  }

  handleOnOlvidoContraseniaClick = () => {

    // limpio el mensaje de error de autenticación
    this.props.setResultadoAutenticacion({ error: undefined, claveProvisoriaGenerada: false })

    // genero los cambios de estado
    let newState = { ...this.state }
    newState.olvidoContrasenia = true
    newState.loginData.emailOlvidoContrasenia = newState.loginData.email

    if (newState.errores !== undefined)
      newState.errores.emailOlvidoContrasenia = undefined

    // actualizo el estado
    this.setState(newState)
  }

  handleOnOlvidoContrasenia = () => {

    // valido que se haya ingresado el email
    if (this.state.loginData.emailOlvidoContrasenia === undefined || this.state.loginData.emailOlvidoContrasenia === '') {
      this.setState({ errores: { emailOlvidoContrasenia: 'Ingrese el email' } })
      return
    }

    // llamo a la lógica de olvido de contrasenia
    this.props.sagaOlvidoContrasenia({ email: this.state.loginData.emailOlvidoContrasenia })
  }

  handleOnCancelarOlvidoContrasenia = () => {

    // limpio el mensaje de error de autenticación
    this.props.setResultadoAutenticacion({ error: undefined })

    this.setState({ olvidoContrasenia: false })
  }

  getFormToShow = () => {
    let formToShow = 'login'

    if (this.props.resultadoAutenticacion.restablecerContrasenia)
      formToShow = 'restablecerContrasenia'

    if (this.state.olvidoContrasenia)
      formToShow = 'olvidoContrasenia'

    return formToShow
  }

  handleOnConfirmarCódigo = () => {
    
    // valido que se haya ingresado el email
    if (this.state.loginData.codigoVerificacion === undefined || this.state.loginData.codigoVerificacion === '') {
      this.setState({ errores: { codigoVerificacion: 'Ingrese el código' } })
      return
    }

    let payload = {
      codigo: this.state.loginData.codigoVerificacion,
      email: this.state.loginData.emailOlvidoContrasenia,
      usuarioId: this.props.match && this.props.match.params && this.props.match.params.idUsuario ? this.props.match.params.idUsuario : undefined
    }

    payload.callback = () => {
      this.setState({ olvidoContrasenia: false}, () => {
        this.props.setResultadoAutenticacion({error: undefined, restablecerContrasenia: true })
      })
    }

    payload.callbackError = (msg) => {
      this.setState({ errores: { codigoVerificacion: msg }})
    }

    this.setState({ errores: { codigoVerificacion: undefined }} , () => {
      // llamo a la lógica de validación de código
      this.props.sagaValidarCodigo(payload)
    })
  }

  limpiarDatos = () => {
    this.props.setResultadoAutenticacion({token: undefined, usuarioId: true })
  }

  render() {

    let formToShow = this.getFormToShow()

    return (
      <Login
        instalacion={this.props.instalacion}
        formToShow={formToShow}
        errores={this.state.errores}
        loginData={this.state.loginData}
        handleOnDataChange={this.handleOnDataChange}
        handleOnContraseniaKeyPress={this.handleOnContraseniaKeyPress}
        handleOnAutenticar={this.handleOnAutenticar}
        handleOnRestablecerContrasenia={this.handleOnRestablecerContrasenia}
        handleOnCancelarRestablecerContrasenia={this.handleOnCancelarRestablecerContrasenia}
        handleOnOlvidoContraseniaClick={this.handleOnOlvidoContraseniaClick}
        handleOnOlvidoContrasenia={this.handleOnOlvidoContrasenia}
        handleOnCancelarOlvidoContrasenia={this.handleOnCancelarOlvidoContrasenia}
        resultadoAutenticacion={this.props.resultadoAutenticacion}
        handleOnConfirmarCódigo={this.handleOnConfirmarCódigo}
        limpiarDatos={this.limpiarDatos}
      />
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...sessionActions,
    ...layoutActions,
    ...catalogoActions
  }, dispatch)
}

function mapStateToProps(state) {
  return {
    instalacion: instalacionSelector(state),
    resultadoAutenticacion: resultadoAutenticacionSelector(state),
    usuario: usuarioSelector(state),
    header: headerSelector(state),
    footer: footerSelector(state)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)