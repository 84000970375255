// acciones
export const INITIALIZE_FORM = 'sessionReducer/INITIALIZE_FORM'
export const SAGA_FETCH_INSTALACION = 'sessionReducer/SAGA_FETCH_INSTALACION'
export const SAGA_FETCH_USUARIO_LOGEADO = 'sessionReducer/SAGA_FETCH_USUARIO_LOGEADO'
export const SAGA_AUTENTICAR_USUARIO = 'sessionReducer/SAGA_AUTENTICAR_USUARIO'
export const SAGA_LOGOUT_USUARIO = 'sessionReducer/SAGA_LOGOUT_USUARIO'
export const SAGA_RESTABLECER_CONTRASENIA = 'sessionReducer/SAGA_RESTABLECER_CONTRASENIA'
export const SAGA_OLVIDO_CONTRASENIA = 'sessionReducer/SAGA_OLVIDO_CONTRASENIA'
export const SAGA_VALIDAR_CODIGO = 'sessionReducer/SAGA_VALIDAR_CODIGO'
export const SAGA_FETCH_IMAGEN_LOGIN  = 'sessionReducer/SAGA_FETCH_IMAGEN_LOGIN'
export const SAGA_FETCH_IMAGEN_DESCUENTOS  = 'sessionReducer/SAGA_FETCH_IMAGEN_DESCUENTOS'

export const SAVE_INSTALACION = 'sessionReducer/SAVE_INSTALACION'
export const SAVE_DEFAULT_RESULTADO_AUTENTICACION = 'sessionReducer/SAVE_DEFAULT_RESULTADO_AUTENTICACION'
export const SAVE_RESULTADO_AUTENTICACION = 'sessionReducer/SAVE_RESULTADO_AUTENTICACION'
export const SAVE_USUARIO = 'sessionReducer/SAVE_USUARIO'
export const SAVE_CLIENTE = 'sessionReducer/SAVE_CLIENTE'
export const SAVE_IMAGEN_LOGIN = 'sessionReducer/SAVE_IMAGEN_LOGIN'
export const SAVE_IMAGEN_DESCUENTOS = 'sessionReducer/SAVE_IMAGEN_DESCUENTOS'
export const SAGA_SAVE_DATOS_ADICIONALES = 'sessionReducer/SAGA_SAVE_DATOS_ADICIONALES'

export const SET_FLAG_DATOS_ADICIONALES = 'sessionReducer/SET_FLAG_DATOS_ADICIONALES'

export const SAGA_MARCAR_NOTIFICACION_VISTA = 'sessionReducer/SAGA_MARCAR_NOTIFICACION_VISTA'
export const SET_NOTIFICACION_VISTA = 'sessionReducer/SET_NOTIFICACION_VISTA'
export const SAGA_BUSCAR_MAS_NOTIFICACIONES = 'sessionReducer/SAGA_BUSCAR_MAS_NOTIFICACIONES'
export const SET_NOTIFICACIONES = 'sessionReducer/SET_NOTIFICACIONES'
export const SAGA_MARCAR_NOTIFICACIONES_COMO_VISTAS = 'sessionReducer/SAGA_MARCAR_NOTIFICACIONES_COMO_VISTAS'

export const SAGA_SAVE_DIRECCION_ENTREGA = 'sessionReducer/SAGA_SAVE_DIRECCION_ENTREGA'
export const SET_DIRECCIONES_ENTREGA = 'sessionReducer/SET_DIRECCIONES_ENTREGA'
export const SAGA_DELETE_DIRECCION_ENTREGA = 'sessionReducer/SAGA_DELETE_DIRECCION_ENTREGA'
export const DELETE_DIRECCION_ENTREGA = 'sessionReducer/DELETE_DIRECCION_ENTREGA'

export const SAGA_GET_PREGUNTAS_FRECUENTES = 'sessionReducer/SAGA_GET_PREGUNTAS_FRECUENTES'
export const SET_PREGUNTAS_FRECUENTES = 'sessionReducer/SET_PREGUNTAS_FRECUENTES'

// funciones
export const setDeleteDireccionEntrega = (payload) => ({
    type: DELETE_DIRECCION_ENTREGA,
    payload
})

export const sagaEliminarDireccion = (payload) => ({
    type: SAGA_DELETE_DIRECCION_ENTREGA,
    payload
})

export const setdireccionesEntrega = (payload) => ({
    type: SET_DIRECCIONES_ENTREGA,
    payload
})

export const sagaSaveDireccionEntrega = (payload) => ({
    type: SAGA_SAVE_DIRECCION_ENTREGA,
    payload
})

export const sagaGetPreguntasFrecuentes = (payload) => ({
    type: SAGA_GET_PREGUNTAS_FRECUENTES,
    payload
})

export const setPreguntasFrecuentes = (payload) => ({
    type: SET_PREGUNTAS_FRECUENTES,
    payload
})

export const setNotificaciones = (payload) => ({
    type: SET_NOTIFICACIONES,
    payload
})

export const sagaBuscarMasNotificaciones = (payload) => ({
    type: SAGA_BUSCAR_MAS_NOTIFICACIONES,
    payload
})

export const sagaMarcarNotificacionVista = (payload) => ({
    type: SAGA_MARCAR_NOTIFICACION_VISTA,
    payload
})

export const sagaMarcarNotificacionesVistas = (payload) => ({
    type: SAGA_MARCAR_NOTIFICACIONES_COMO_VISTAS,
    payload
})

export const setNotificacionVista = (payload) => ({
    type: SET_NOTIFICACION_VISTA,
    payload
})

export const setFlagDatosAdicionales = (payload) => ({
    type: SET_FLAG_DATOS_ADICIONALES, 
    payload
})

export const sagaSaveDatosAdicionales = (payload) => ({
    type: SAGA_SAVE_DATOS_ADICIONALES,
    payload
})

export const initializeForm = (payload) => ({
    type: INITIALIZE_FORM,
    payload
})

export const sagaFetchInstalacion = (payload) => ({
    type: SAGA_FETCH_INSTALACION,
    payload
})

export const setInstalacion = (payload) => ({
    type: SAVE_INSTALACION,
    payload
})

export const sagaFetchUsuarioLogeado = (payload) => ({
    type: SAGA_FETCH_USUARIO_LOGEADO,
    payload
})

export const sagaAutenticarUsuario = (payload) => ({
    type: SAGA_AUTENTICAR_USUARIO,
    payload
})

export const setDefaultResultadoAutenticacion = (payload) => ({
    type: SAVE_DEFAULT_RESULTADO_AUTENTICACION,
    payload
})

export const setResultadoAutenticacion = (payload) => ({
    type: SAVE_RESULTADO_AUTENTICACION,
    payload
})

export const setUsuario = (payload) => ({
    type: SAVE_USUARIO,
    payload
})

export const sagaRestablecerContrasenia = (payload) => ({
    type: SAGA_RESTABLECER_CONTRASENIA,
    payload
})

export const sagaOlvidoContrasenia = (payload) => ({
    type: SAGA_OLVIDO_CONTRASENIA,
    payload
})

export const sagaValidarCodigo = (payload) => ({
    type: SAGA_VALIDAR_CODIGO,
    payload
})

export const sagaLogOutUsuario = (payload) => ({
    type: SAGA_LOGOUT_USUARIO,
    payload
})

export const initializeFormSession = (payload) => ({
    type: INITIALIZE_FORM,
    payload
})

export const sagaFetchImagenLogin = (payload) =>({
    type: SAGA_FETCH_IMAGEN_LOGIN,
    payload
})

export const setImagenLogin = (payload) => ({
    type: SAVE_IMAGEN_LOGIN,
    payload
})

export const sagaFetchImagenDescuentos = (payload) =>({
    type: SAGA_FETCH_IMAGEN_DESCUENTOS,
    payload
})

export const setImagenDescuentos = (payload) => ({
    type: SAVE_IMAGEN_DESCUENTOS,
    payload
})