import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import { Row } from 'react-flexbox-grid'
import Card from '@material-ui/core/Card'
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import VisualizadorImagenes from './visualizadorImagenes/visualizadorImagenes';
import DatosExtra from './datosExtra/datosExtra';
import SelectorCantidad from '../Common/selectorCantidad/selectorCantidad';
import VisualizadorPrecio from './visualizadorPrecio/visualizadorPrecio';
import ArticuloAlternativa from './articuloAlternativa/articuloAlternativa';
import './style.css'
import 'react-image-gallery/styles/css/image-gallery.css'
import Heading from '../UI/Texts/Heading';

const styles = theme => ({
});

const NombreArticuloTooltip = withStyles((theme) => ({
  tooltip: {
    background: '#000000 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '4px',
    padding: '0px',
    opacity: '1 !important',
    margin: '7px 0 4px 25px !important'
  },
}))(Tooltip);

class ArticuloItem extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      indiceInferior: 0,
      indiceSuperior: 3,
      precioPrincipalCasoDos: 0,
      cantidadPedido: this.props.cantidadInicial,
      showAlternativas: false,
      cantidadInicialAlternativas: 0,
    }
  }

  componentWillReceiveProps = (nextProps, prevProps) => {
    if(prevProps.cantidadInicial !== nextProps.cantidadInicial){
      this.setState({cantidadPedido: nextProps.cantidadInicial})
    }
  }

  onAgregarQuitarCantidad = (cantidad, alternativa) => {
    // Aqui se evita llamar mas de unas vez a la API dentro de los Xms
    this.setState({ cantidadPedido: cantidad, showAlternativas: false }, () => {
      setTimeout(() => {
        if (this.state.cantidadPedido === cantidad) {
          this.props.agregarQuitarUnidad(cantidad, this.props.item, alternativa);
        }
      }, 1000);
    });
  }
  
  onChangeCantidad = (cantidad) => {
    // recupero el detalle de pedido borrador (si existe) del articulo
    let detallePedidoArticulo = undefined;
    let alternativa = undefined;
    if (this.props.pedido) {
      detallePedidoArticulo = this.props.pedido.listaPedidoDetalle.find((x) => x.idArticulo === this.props.item.idArticulo);
      if (detallePedidoArticulo) {
        alternativa = detallePedidoArticulo.alternativa;
      }
    }
    
    // computo si se debe mostrar el modal de alternativas
    const articuloTieneAlternativas = this.props.item.articuloAlternativas && this.props.item.articuloAlternativas.length > 0;
    const mostrarAlternativas = articuloTieneAlternativas && !detallePedidoArticulo;

    if (mostrarAlternativas) {
      this.setState({ showAlternativas: true, cantidadInicialAlternativas: cantidad });
    } else {
      this.onAgregarQuitarCantidad(cantidad, alternativa);
    }
  };

  render() {
    return (
      <div className='articulos'>
        
        <Card className='card'>
          <div className='cardImgContainer'>

            {/* IMAGEN ARTICULO */}
            <VisualizadorImagenes item={this.props.item} />

            {this.props.item.porcentajeDescuento &&  this.props.item.catalogoEntradaActivo &&
            <div className='labelDescuento'>{this.props.item.porcentajeDescuento.toFixed(0)}%</div>}

            {this.props.srcImgOferta &&
            <div className='selloOferta'>
               <img alt="" src={this.props.srcImgOferta} />
            </div>}

          </div>

          {/* NOMBRE ARTICULO */}
          <div className='ContainerCardContent'>
            <div className='nameContainer'>
              {this.props.item.nombre.length > 40 ? 
            
                <NombreArticuloTooltip
                  TransitionComponent={Zoom}
                  placement="bottom-start"
                  style={{opacity: '1'}}
                  enterTouchDelay={10}
                  title={
                    <div className='containerTooltipNombreAritulo'>
                      {this.props.item.nombre}
                    </div>
                  }>
                  <div><Heading tag='h6' weight='bold'>{this.props.item.nombre.substring(0,38)}...</Heading></div>
                </NombreArticuloTooltip>
              :
              <Heading tag='h6' weight='bold'>{this.props.item.nombre}</Heading>
              }
            </div>
            <div className='spaceBetween'>
              <div className='datoExtraYPrecio'>
                {this.props.item.datosExtra ? <DatosExtra {...this.props.item} /> : null }
                {this.props.item.catalogoEntradaActivo && <VisualizadorPrecio precioLista={this.props.item.precioLista} precioPromocional={this.props.item.precioPromocional} />}
              </div>

              {/* SELECTOR DE CANTIDAD */}
              {<Row className={`rowButton ${this.props.item.catalogoEntradaActivo === false ? 'rowButtonFav' : ''}`}>

                {this.props.item.catalogoEntradaActivo ?
                  <SelectorCantidad 
                    value={this.state.cantidadPedido}
                    onChange={this.onChangeCantidad}
                    incremento={this.props.item.incremento}
                    productoPesable={this.props.item.productoPesable}
                    cantidadLimMin={this.props.item.cantidadLimMin}
                    cantidadLimMax={this.props.item.cantidadLimMax}
                  />
                :
                  <div className="noStock">SIN STOCK</div>
                }
              </Row>}
            </div>
          </div>
        </Card>

        {/* MODAL DE ALTERNATIVAS DE ARTICULO */}
        { this.state.showAlternativas && 
        <ArticuloAlternativa
          open={this.state.showAlternativas}
          item={{ ...this.props.item, srcImgOferta: this.props.srcImgOferta }}
          initialValue={this.state.cantidadInicialAlternativas}
          onClose={() => this.setState({ showAlternativas: false })}
          onConfirm={this.onAgregarQuitarCantidad}
        />}

      </div>
    )
  }
}

ArticuloItem.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  instalacion: PropTypes.object.isRequired,
  precioListaPedido: PropTypes.object.isRequired,
  getBandaDePreciosCasoDos: PropTypes.func.isRequired,
  last: PropTypes.bool,
  pedido: PropTypes.object,

  imagenes: PropTypes.object.isRequired,

  handleOnButtonPedilo: PropTypes.func.isRequired,
  handleOnBorrar: PropTypes.func.isRequired,
  agregarQuitarUnidad: PropTypes.func.isRequired,

  srcImgOferta: PropTypes.string,
}


export default withStyles(styles)(ArticuloItem)