import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import Button from '@material-ui/core/Button'
import { Done as DoneIcon, Clear as ClearIcon } from '@material-ui/icons'
import { formatMoney } from '../Common/decimalHelper'
import Fab from '@material-ui/core/Fab'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
    margin: {
      margin: theme.spacing.unit,
    },
    extendedIcon: {
      marginRight: theme.spacing.unit,
    },
})

class HeaderPedido extends React.Component {

    constructor(props){
        super(props)
    }

    render() {

        const { classes} = this.props;

        return (
            <Row className='headerConfirmarPedido' style={{margin: '0px'}}>
                <Col xs={12} style={{padding: '0px'}}>
                    <div className='buttonContainer'>
                        <Row center="xs" style={{margin: '0px'}}>
                            <Col xs={10} className='container'>
                                <div className='importe'>{formatMoney(this.props.pedido.precioFinal)}</div>
                                <div className='text'>Su pedido</div>
                            </Col>
                        </Row>

                        {/*<Button disabled = {this.props.pedido.estadoPedido.descripcion !== 'Borrador'} variant="fab" onClick={this.props.pedido.estadoPedido.descripcion === 'Borrador' ? this.props.handleOnConfirmar : ''} aria-label="Add" className='btnPrincipal button done'>
                            <DoneIcon />
                            CONFIRMAR PEDIDO
                        </Button>*/}

                        <div>   
                            <Fab 
                                disabled = {this.props.pedido.estadoPedido.descripcion !== 'Borrador'}
                                onClick={this.props.pedido.estadoPedido.descripcion === 'Borrador' ? this.props.handleOnConfirmar : ''}
                                variant="extended" 
                                aria-label="Add" 
                                className={`${classes.margin} btn done disabled`}>
                                    <DoneIcon className={classes.extendedIcon} />
                                    CONFIRMAR
                            </Fab>
                        </div>

                        <div>   
                            <Fab 
                                disabled = {this.props.pedido.estadoPedido.descripcion !== 'Borrador'}
                                onClick={this.props.pedido.estadoPedido.descripcion === 'Borrador' ? this.props.handleOnBorrar : ''}
                                variant="extended" 
                                aria-label="Add" 
                                className={`${classes.margin} btn cancel`}>
                                    <ClearIcon className={classes.extendedIcon} />
                                    CANCELAR
                            </Fab>
                        </div>  

                        {/*<Button disabled = {this.props.pedido.estadoPedido.descripcion !== 'Borrador'} variant="fab" onClick={this.props.pedido.estadoPedido.descripcion === 'Borrador' ? this.props.handleOnBorrar : ''} aria-label="Add" className='btnSecundario button cancel'>
                            <ClearIcon />
                            CANCELAR
                        </Button>*/}
                    </div>
                </Col>
            </Row>
        )
    }
}

HeaderPedido.propTypes = {
    pedido: PropTypes.object.isRequired,
    handleOnConfirmar: PropTypes.func.isRequired,
    handleOnBorrar: PropTypes.func.isRequired
}

HeaderPedido.defaultProps = {
    pedido: { precioFinal: 0 },
    handleOnConfirmar: f => f,
    handleOnBorrar: f => f
}

export default withStyles(styles) (HeaderPedido)