import { sagas as testSaga } from './TestSaga'
import { sagas as sessionSaga } from './SessionSaga'
import { sagas as confirmarPedidoSaga } from './ConfirmarPedidoSaga'
import { sagas as catalogoSaga } from './CatalogoSaga'
import { sagas as pedidoSaga } from './PedidoSaga'
import { sagas as detallePedidoSaga } from './DetallePedidoSaga'
import { sagas as registrarUsuarioSaga } from './RegistrarUsuarioSaga'
import { sagas as provinciaLocalidadSaga } from './ProvinciaLocalidadSaga'
import { sagas as confirmarCorreoSaga } from './ConfirmarCorreoSaga'
import { sagas as TurneroSaga } from './TurneroSaga'
import { sagas as CalendarioSaga } from './CalendarioSaga'
import { sagas as FacturacionSaga } from './FacturacionSaga'
import { sagas as SucursalesSaga } from './SucursalesSaga'

export default [
    ...testSaga,
    ...sessionSaga,
    ...confirmarPedidoSaga,
    ...catalogoSaga,
    ...pedidoSaga,
    ...detallePedidoSaga,
    ...registrarUsuarioSaga,
    ...provinciaLocalidadSaga,
    ...confirmarCorreoSaga,
    ...TurneroSaga,
    ...CalendarioSaga,
    ...FacturacionSaga,
    ...SucursalesSaga
]