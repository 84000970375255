import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-flexbox-grid'
import Button from '@material-ui/core/Button'

import './styles.css'

class ConfirmarCorreo extends React.Component {

    render() {
        return (
            <div className='confirmarCorreo'>
                <Row className='logoInstalacion'>
                    <Col xs={12}>
                        <div className='logoContainer center'>
                            <img src={this.props.instalacion.logoSuperior} />
                        </div>
                    </Col>
                </Row>

                {this.props.usuarioHabilitado === true &&
                <Row>
                    <Col xs={12}>
                        <div className='contenido'>
                            Gracias por confirmar su correo! Este es el último paso para ingresar al catálogo.
                        </div>

                        <div className='buttonContainer'>
                            <Link to={`/Login/${this.props.token}`}>
                                <Button className='btnPrincipal'>RESTABLECER CONTRASEÑA</Button>
                            </Link>
                        </div>
                    </Col>
                </Row>}

                {this.props.usuarioHabilitado === false &&
                <Row>
                    <Col xs={12}>
                        <div className='contenido'>
                            {`Gracias por confirmar su correo! Su cuenta se encuentra en proceso de habilitación. En breve, se le informará cuando puede ingresar al catálogo a su correo ${this.props.email}`}
                        </div>

                        <div className='buttonContainer'>
                            <Link to='/Login'>
                                <Button className='btnPrincipal'>ENTENDIDO!</Button>
                            </Link>
                        </div>
                    </Col>
                </Row>}
            </div>
        )
    }
}

ConfirmarCorreo.propTypes = {
    instalacion: PropTypes.object.isRequired,
    usuarioHabilitado: PropTypes.bool,
    correoConfirmado: PropTypes.bool,
    email: PropTypes.string,
    token: PropTypes.string.isRequired
}

export default ConfirmarCorreo