import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as layoutActions from '../../reducers/LayoutReducer/actions'
import { instalacionSelector, usuarioSelector, flagDatosAdicionalesSelector } from '../../selectors/sessionSelector'
import * as sessionActions from '../../reducers/SessionReducer/actions'
import * as pedidoActions from '../../reducers/PedidoReducer/actions'
import * as tuerneroActions from '../../reducers/TurneroReducer/actions'
import * as provLocActions from '../../reducers/ProvinciaLocalidadReducer/actions'
import * as Notification from '../../components/Common/notification';
import * as catalogoActions from '../../reducers/CatalogoReducer/actions';
import FinalizarCompra from '../../components/FinalizarCompra/';
import { pedidoDataSelector } from '../../selectors/pedidoSelector';
import {calendarioSelector} from '../../selectors/turneroSelector';
import { showModal } from "@smiledev/react-web-feedback";
import { sucursalesSelector } from '../../selectors/sucursalesSelector'

// estados de pedido
const estadosPedido = {
  CONFIRMADO: 2,
  ELIMINADO: 6
}

const formaPagoOnline = {
  PagoAlRetirar: 1,
  MercadoPago: 2,
  Mobbex: 3,
  PagoAlRecibir: 4
}

const preferenciaEntrega = {
  Pickup: 1,
  Delivery: 2
}

class FinalizarCompraContainer extends React.Component {

  constructor(props) {
    super(props)

    window.scrollTo(0, 0)

    
    this.state = {
        pedidoCargado: this.props.dataPedido && this.props.dataPedido.pedido !== undefined ? true : false,
        reemplazarProductos: undefined,
        form: {
          telefono: '',
          documento: '',
        },
        formMercadoPago: {
          emailMercadoPago: '',
          informacionTarjeta: '',
          nombreEnTarjeta: '',
          fechavencimiento: '',
          codigoverificacion: '',
          cuotastarjeta: '1',
          tipodocumento: 'DNI',
          numerodocumento: '',
          paimentmethodid:'',
          paimenttypeid:'',
          installments:[{value:'',text:''}],
          //installmentDescriptions:[],
        },
        errores:{},
        showMetodosPago: undefined,
        usuarioCargado: false,
        openModal: true,
        pedidoCargado: false,
        openModalCompraExitosa: false,
        preferenciaEntregaElegida: false,
        entregaElegida: undefined,
        fechaSeleccionada: undefined,
        horaSeleccionada: undefined,
        permitePago: false,
        showTurnero: false,
        
        domicilioSeleccionado: undefined,
        domicilioSeleccionadoItem: undefined,
        ultimoDomicilioEntrega: undefined,
        idUltimoDomicilioEntrega: undefined,
        disabledPagar: false,
        verMasPedidosDetalle: false,

        tipoFacturacion: undefined,
        idClienteDatosFacturacion: undefined,
        
        sucursalesPickup: [],
        sucursalPickupSeleccionada: undefined
    }
    
    if (this.props.usuario === undefined) {
      window.location.hash = '/Login'
    }
    else{
      // obtengo el pedido
      let payload = {}
      payload.idUsuario = this.props.usuario.id
  
      payload.callbackFinalizarCompra = () => {
        window.location.hash = '/Login'
      }
  
      this.props.sagaFetchPedidoBorrador(payload)

      this.props.setShowResumenPedido(false)
    }
    
  }

  componentWillMount = () => {
    this.props.setShowResumenPedido(false)

    const preferenciasPayload = {};
    preferenciasPayload.idCliente = this.props.usuario.idCliente;
    preferenciasPayload.callback = (preferencias) => {
      const { idTipoFacturacion, idClienteDatosFacturacion, domicilioEntrega, idDomicilioEntrega } = preferencias;
      this.setState({ tipoFacturacion: idTipoFacturacion, idClienteDatosFacturacion, ultimoDomicilioEntrega: domicilioEntrega, idUltimoDomicilioEntrega: idDomicilioEntrega });
    }
    this.props.sagaGetPreferenciasUltimoPedido(preferenciasPayload);
  }

  componentDidUpdate = () => {
    if(this.state.usuarioCargado === false && this.props.usuario !== undefined){
      
      let form = {
        telefono: this.props.usuario.telefono ? this.props.usuario.telefono : '',
        documento: this.props.usuario.dni ? this.props.usuario.dni : '',
      }

      let domicilioSeleccionado = undefined
      let showTurnero = false
      if(this.props.usuario.domiciliosEntrega !== undefined && this.props.usuario.domiciliosEntrega.length > 0){
        domicilioSeleccionado = this.props.usuario.domiciliosEntrega[0].idDomicilioEntrega
        showTurnero = true
      }

      this.setState({
        form: form, 
        usuarioCargado: true, 
        domicilioSeleccionado: domicilioSeleccionado,
        showTurnero: showTurnero
      })
    }

    if(this.state.pedidoCargado === false && this.props.dataPedido && this.props.dataPedido.pedido){
      this.setState({pedidoCargado: true}, this.checkPermisosReemplazo)
    }
  }

  componentWillReceiveProps(nextProps){
    if(this.state.sucursalesPickup.length === 0 && nextProps.sucursales){
      let sucursales = [];
      nextProps.sucursales.map(sucursal => {
        let formaEntrega = sucursal.formasDeEntrega.find(x => x.idFormaEntrega === 1)
        if(formaEntrega)
          sucursales.push({
            idSucursal: sucursal.idSucursal,
            nombre: sucursal.nombre,
            direccion: sucursal.direccion,
            porDefecto: formaEntrega.porDefecto
          })
      })
      this.setState({sucursalesPickup: sucursales, sucursalPickupSeleccionada: this.props.usuario.ultimaSucursalPickup || Number(sucursales.find(x => x.porDefecto === true).idSucursal)})
    }
    this.render()
}

  checkPermisosReemplazo = () => {
    if(this.props.dataPedido && this.props.dataPedido.pedido){

      let countTrue = 0
      let countFalse = 0
      this.props.dataPedido.pedido.listaPedidoDetalle.forEach(pd => {
        if(pd.permisoReemplazo === true){
          countTrue = countTrue + 1
        }
        else{
          countFalse = countFalse + 1
        }
      });

      let cantidadPd = this.props.dataPedido.pedido.listaPedidoDetalle.length 

      if(countTrue === cantidadPd){
        this.setState({reemplazarProductos: "1"})
      }
      else if(countFalse === cantidadPd){
        this.setState({reemplazarProductos: "2"})
      }
      else{
        this.setState({reemplazarProductos: undefined})
      }
    }
  }

  
  getSucursalDefecto = (forma) => {
    // 1 es pickup, 2 es delivery
    let sucursales = [];
    this.props.sucursales.map(sucursal => {
      let formaEntrega = sucursal.formasDeEntrega.find(x => x.idFormaEntrega === forma)
      if(formaEntrega)
        sucursales.push({
          idSucursal: sucursal.idSucursal,
          nombre: sucursal.nombre,
          direccion: sucursal.direccion,
          porDefecto: formaEntrega.porDefecto
        })
    })
    return (forma === 1 ? this.props.usuario.ultimaSucursalPickup : this.props.usuario.ultimaSucursalDelivery) || sucursales.find(x => x.porDefecto === true).idSucursal;
    
  }

  handleOnDataChange = (event) => {

    let value = event.target.value
    let name = event.target.name
    
    if(name === "documento" && value !== ""){
      var letterNumber = /^[0-9a-zA-Z]+$/;
      if(value.length > 9 || !value.match(letterNumber)){
        return
      }
    }

    let form = {...this.state.form}

    form[name] = value

    this.setState({form: form})
  }

  handleOnDataChangeMercadoPago = (event) => {

    let value = event.target.value
    let name = event.target.name
    
    if(name === "numerodocumento" && value !== ""){
      var letterNumber = /^[0-9a-zA-Z]+$/;
      if(value.length > 9 || !value.match(letterNumber)){
        return
      }
    }

    if (name==="informacionTarjeta")
    {
      value = value.replace(/ /g,'')
    }

    let formMercadoPago = {...this.state.formMercadoPago}

    formMercadoPago[name] = value

    this.setState({formMercadoPago: formMercadoPago}, () => {
      if (name==="informacionTarjeta")
      {
        if (value.length >= 6) {
          let bin = value.substring(0, 6);
          window.Mercadopago.getPaymentMethod({
              "bin": bin
          }, this.setPaymentMethod);
        }
      }
    })    
  }

  setPaymentMethod = (status, response) =>{
    if (status === 200 && response.length > 0) {
      let paymentMethodId = response[0].id;
      let paymentTypeId=response[0].payment_type_id;

      /*if(response[0].payment_type_id === "debit_card"){
        
        let form = this.state.formMercadoPago
        form.cuotastarjeta = '1'
        form.installments = [{value:'',text:''}]

        this.setState({formMercadoPago: form}, () => 
          Notification.showError(`Operación válida solo con tarjetas de crédito.`)
        )
        
        return
      }*/
        
      let form = {...this.state.formMercadoPago}

      form.paimenttypeid=paymentTypeId;

      form.paimentmethodid = paymentMethodId;

      this.setState({formMercadoPago: form}, this.getInstallments);

       // let element = document.getElementById('payment_method_id');
       // element.value = paymentMethodId;
        //this.getInstallments();
    } else {
      let form = this.state.formMercadoPago
      form.cuotastarjeta = '1'
      form.installments = [{value:'',text:''}]
      this.setState({formMercadoPago: form})
    }
}


  getInstallments = () =>{
    var component=this;
    let form = {...this.state.formMercadoPago}
      window.Mercadopago.getInstallments({

        "bin": form.informacionTarjeta.substring(0, 6),
      
        "payment_method_id":form.paimentmethodid,

        "amount": parseFloat(this.props.dataPedido.pedido.precioFinal)

      }, function (status, response) {
        if (status === 200) {
          form.installments=[];

            //document.getElementById('installments').options.length = 0;
            response[0].payer_costs.forEach(installment => {
              //console.log(installment);

            //var installmentObj=new installment();
            // installmentObj.
            let objectInstallment={value:installment.installments,text:installment.recommended_message};

              form.installments.push(objectInstallment);
              //form.installmentDescriptions.push(installment.recommended_message);
              /*let opt = document.createElement('option');
              opt.text = installment.recommended_message;
              opt.value = installment.installments;
              document.getElementById('installments').appendChild(opt);*/
            });
          
          component.setState({formMercadoPago: form});
        } else {
          let form = this.state.formMercadoPago
          form.cuotastarjeta = '1'
          form.installments = [{value:'',text:''}]
          this.setState({formMercadoPago: form})
        }
    });
  }

  handleOnSaveDatosAdicionales = () => {
    
    let valida = true
    let errores = {}

    // valido el documento
    if (this.state.form.documento === undefined || this.state.form.documento === '' || this.state.form.documento.length < 7) {
      errores.documento = 'Ingrese un Nro. de documento correcto'
      valida = false
    }

    // valido el teléfono
    if (this.state.form.telefono === undefined || this.state.form.telefono === '' || this.state.form.telefono.includes('_') || this.state.form.telefono.includes('.')) {
      errores.telefono = 'Ingrese un teléfono correcto'
      valida = false
    }

    if(!valida){
      this.setState({errores: errores})
      return
    }
    
    this.setState({errores:{}}, () => {
      let payload = {
        dni: this.state.form.documento.trim(),
        telefono: this.state.form.telefono.trim(),
        idUsuario: this.props.usuario.id
      }

      payload.callback = () => {
        Notification.showSuccess(`Datos guardados con éxito`)
        this.setState({openModal: false})
      }

      payload.callbackError = () => {
        Notification.showError(`Error al guardar los datos`)
      }

      this.props.sagaSaveDatosAdicionales(payload)
    })
  }

  sdkResponseHandler = (status,response) =>{
    if (status !== 200 && status !== 201) {

      if(response.cause && response.cause.length > 0){
        let code = response.cause[0].code
        let errores = {...this.props.errores}
        let mensaje = ''

        switch(code){
          case "205": {
            mensaje = "Ingresa el número de tu tarjeta."
            errores.informacionTarjeta = mensaje
            break
          }
          case "208": {
            mensaje = "Elige un mes."
            errores.fechavencimiento = mensaje
            break
          }
          case "209": {
            mensaje = "Elige un año."
            errores.fechavencimiento = mensaje
            break
          }
          case "212": {
            mensaje = "Ingresa tu tipo de documento."
            //errores.fechavencimiento = mensaje
            break
          }
          case "213": {
            mensaje = "Ingresa tu documento."
            errores.numerodocumento = mensaje
            break
          }
          case "214": {
            mensaje = "Ingresa tu documento."
            errores.numerodocumento = mensaje
            break
          }
          case "220": {
            mensaje = "Ingresa tu banco."
            break
          }
          case "221": {
            mensaje = "Ingresa el nombre y apellido."
            errores.nombreEnTarjeta = mensaje
            break
          }
          case "224": {
            mensaje = "Ingresa el código de seguridad."
            errores.codigoverificacion = mensaje
            break
          }
          case "E301": {
            mensaje = "Ingresa un número de tarjeta válido."
            errores.informacionTarjeta = mensaje
            break
          }
          case "E302": {
            mensaje = "Revisa el código de seguridad."
            errores.codigoverificacion = mensaje
            break
          }
          case "316": {
            mensaje = "Ingresa un nombre válido."
            errores.nombreEnTarjeta = mensaje
            break
          }
          case "322": {
            mensaje = "El tipo de documento es inválido."
            //errores.numerodocumento = mensaje
            break
          }
          case "323": {
            mensaje = "Revisa tu documento."
            errores.numerodocumento = mensaje
            break
          }
          case "324": {
            mensaje = "El documento es inválido."
            errores.numerodocumento = mensaje
            break
          }
          case "325": {
            mensaje = "Revise la fecha ingresada"
            errores.fechavencimiento = mensaje
            break
          }
          case "326": {
            mensaje = "Revise la fecha ingresada"
            errores.fechavencimiento = mensaje
            break
          }
          default: {
            mensaje = "Revisa los datos"
            break
          }
        }
        this.setState({errores: errores, disabledPagar: false}, () => {
          Notification.showError(mensaje);
        })
      }
      else{
        this.setState({disabledPagar: false}, () => {
          Notification.showError("Revisa los datos ingresados por favor");
        })
      }
    }
    else
    {
      this.setState({errores:{}}, () => {
        let payload = {
          IdInstalacion:this.props.instalacion.id,
          IdPedido:this.props.dataPedido.pedido.id,
          Total:this.props.dataPedido.pedido.precioFinal,
          Token:response.id,
          Cuotas: this.state.formMercadoPago.cuotastarjeta,
          PayMentMethod:this.state.formMercadoPago.paimentmethodid,
          PayMentMethodType:this.state.formMercadoPago.paimenttypeid,
          email:this.state.formMercadoPago.emailMercadoPago,
          idCalendarioHorario: this.state.horaSeleccionada.idHorario,
          fechaTurno: this.state.fechaSeleccionada.fecha,
          dni: this.state.formMercadoPago.numerodocumento,
          tipoDocumento: this.state.formMercadoPago.tipodocumento,
          tipoEntrega: this.state.entregaElegida ? this.state.entregaElegida : 0,
          IdEstadoPedido: estadosPedido.CONFIRMADO,
          IdFormaPagoOnline: formaPagoOnline.MercadoPago,
          IdFormaEntrega: this.state.entregaElegida ? this.state.entregaElegida : 0,
          Domicilio: this.state.domicilioSeleccionadoItem ? this.state.domicilioSeleccionadoItem.domicilio + (this.state.domicilioSeleccionadoItem.pisoDepto ? " (" + this.state.domicilioSeleccionadoItem.pisoDepto + (this.state.domicilioSeleccionadoItem.opcional ? "" : ")" ) : '') + (this.state.domicilioSeleccionadoItem.opcional ?  (this.state.domicilioSeleccionadoItem.pisoDepto ? " " : " (" ) + this.state.domicilioSeleccionadoItem.opcional + ")" : '') : '',
          FaltaGeoreferenciar: this.state.domicilioSeleccionadoItem && this.state.domicilioSeleccionadoItem.faltaGeorreferenciar === true ? true : undefined,
          IdDomicilioEntrega: this.state.domicilioSeleccionadoItem ? this.state.domicilioSeleccionadoItem.idDomicilioEntrega : undefined,
        }
        
        payload.callback = (estado) => {
          if (estado.exitoso)
          {
              
            this.setState({openModalCompraExitosa: true, disabledPagar: false}, () => {
              Notification.showSuccess(estado.estado)
              window.Mercadopago.clearSession();
            })
              
              // actualizo el estado del pedido
              let payload = {
                Id: this.props.dataPedido.pedido.id,
                IdEstadoPedido: estadosPedido.CONFIRMADO,
                IdFormaPagoOnline: formaPagoOnline.MercadoPago,
                IdCalendarioHorario: this.state.horaSeleccionada.idHorario,
                FechaTurno: this.state.fechaSeleccionada.fecha,
                IdFormaEntrega: this.state.entregaElegida ? this.state.entregaElegida : 0,
                Domicilio: this.state.domicilioSeleccionadoItem ? this.state.domicilioSeleccionadoItem.domicilio + (this.state.domicilioSeleccionadoItem.pisoDepto ? " (" + this.state.domicilioSeleccionadoItem.pisoDepto + (this.state.domicilioSeleccionadoItem.opcional ? "" : ")" ) : '') + (this.state.domicilioSeleccionadoItem.opcional ?  (this.state.domicilioSeleccionadoItem.pisoDepto ? " " : " (" ) + this.state.domicilioSeleccionadoItem.opcional + ")" : '') : '',
                IdInstalacion: this.props.instalacion ? this.props.instalacion.id : 0
              }
              
              /*payload.callback = () => {
                this.setState({openModalCompraExitosa: true})
                window.Mercadopago.clearSession();
              }*/

              this.props.sagaEnviarMailConfirmacion(payload);
          }
        }

        payload.callbackError = (event) => {
          this.setState({disabledPagar: false})
          window.Mercadopago.clearSession();
        }

        //this.props.sagaSaveDatosAdicionales(payload)
        this.props.setPedidoPagoMP(payload);
      })
    }
  } 

  
  
  handleOnPagar = () => {

    if(this.state.disabledPagar === true){
      return
    }

    this.setState({disabledPagar: true})

    let valida = true
    let errores = {}
    // valido el nombre en la tarjeta
    if (this.state.formMercadoPago.nombreEnTarjeta === undefined || this.state.formMercadoPago.nombreEnTarjeta === '' ) {
      errores.nombreEnTarjeta = 'Ingrese el nombre en la tarjeta'
      valida = false
    }
    
    // valido el número de tarjeta
    if (this.state.formMercadoPago.informacionTarjeta=== undefined || this.state.formMercadoPago.informacionTarjeta === '') {
      errores.informacionTarjeta = 'Ingrese el número de tarjeta'
      valida = false
    }

    // valido el vencimiento de la tarjeta
    if (this.state.formMercadoPago.fechavencimiento === undefined ||this.state.formMercadoPago.fechavencimiento=== '' ) {
      errores.fechavencimiento = 'Ingrese la fecha de vencimiento'
      valida = false
    }

       // valido el cod de verificación de la tarjeta
       if (this.state.formMercadoPago.codigoverificacion === undefined ||this.state.formMercadoPago.codigoverificacion=== '' ) {
        errores.codigoverificacion = 'Ingrese el código de seguridad'
        valida = false
      }

           // valido el nro de documento
           if (this.state.formMercadoPago.numerodocumento === undefined ||this.state.formMercadoPago.numerodocumento=== '' ) {
            errores.numerodocumento = 'Ingrese el número de documento'
            valida = false
          }
      // valido el email
      if (this.state.formMercadoPago.emailMercadoPago === undefined ||this.state.formMercadoPago.emailMercadoPago=== '' ) {
      errores.emailMercadoPago = 'Ingrese el e-mail'
      valida = false
    }

    if(!valida){
      this.setState({/*showMetodosPago: undefined,*/ errores: errores, disabledPagar: false})
      return
    }
      
      let data=this.state.formMercadoPago;

      if (data!=null )
      {
        window.Mercadopago.createToken({
          cardNumber: data.informacionTarjeta.trim(),
          securityCode: data.codigoverificacion,
          cardExpirationMonth: data.fechavencimiento.split('/')[0],
          cardExpirationYear: data.fechavencimiento.split('/')[1],
          cardholderName: data.nombreEnTarjeta,
          docType: data.tipodocumento,
          docNumber: data.numerodocumento,
          installments: data.cuotastarjeta
        }, this.sdkResponseHandler);
        //window.Mercadopago.createToken($form, sdkResponseHandler);
    }
  }

  handleChangeRadioButton = (event) => {
    let select = event.target.value
    let value = select

    if(value === "1"){
      value = true
    }
    else{
      value = false
    }

    let payload = {
      value: value,
      idPedidoDetalle: undefined,
      idPedido: this.props.dataPedido && this.props.dataPedido.pedido ? this.props.dataPedido.pedido.id : 0,
      pedidoCompleto: true
    }

    payload.callback = () =>{
      this.setState({reemplazarProductos: select});
    }

    this.props.sagaSetPedidoDetalleReemplazo(payload)
  }

  handleChangeRadioButtonIndividual = (event, idPedidoDetalle) => {
    let value = event.target.value

    if(value === "false"){
      value = false
    }
    else{
      value = true
    }

    if(!idPedidoDetalle){
      return
    }

    let payload = {
      value: value,
      idPedidoDetalle: idPedidoDetalle,
      idPedido: 0,
      pedidoCompleto: false
    }

    payload.callback = () =>{
      this.checkPermisosReemplazo()
    }

    this.props.sagaSetPedidoDetalleReemplazo(payload)
  }

  handleOnClickBtnFormaPago = (value) => {
    this.setState({showMetodosPago: value}, () => {
      if(value === 1){
        this.renderMobbexButton()
      }
    });
  }
  

  handleOnClickFinalizarCompra = () => {

    if(this.props.dataPedido === undefined || this.props.dataPedido.pedido === undefined){
      return
    }

    this.setState({disabledPagar: true})

    //this.props.setOpenModalConfirmarPedido(false)
    //this.props.setBanderaPedido(false)
    
    // actualizo el estado del pedido
    let payload = {
      Id: this.props.dataPedido.pedido.id,
      IdEstadoPedido: estadosPedido.CONFIRMADO,
      IdFormaPagoOnline: this.state.showMetodosPago === 2 ? formaPagoOnline.PagoAlRetirar : formaPagoOnline.PagoAlRecibir,
      IdFormaEntrega: this.state.entregaElegida ? this.state.entregaElegida : 0,
      IdCalendarioHorario: this.state.horaSeleccionada.idHorario,
      FechaTurno: this.state.fechaSeleccionada.fecha,
      Domicilio: this.state.domicilioSeleccionadoItem ? this.state.domicilioSeleccionadoItem.domicilio : undefined,
      IdDomicilioEntrega: this.state.domicilioSeleccionadoItem ? this.state.domicilioSeleccionadoItem.idDomicilioEntrega : undefined,
      FaltaGeoreferenciar: this.state.domicilioSeleccionadoItem && this.state.domicilioSeleccionadoItem.faltaGeorreferenciar === true ? true : undefined,
      IdInstalacion: this.props.instalacion ? this.props.instalacion.id : 0,
      IdTipoFacturacion: this.state.tipoFacturacion,
      IdClienteDatosFacturacion: this.state.idClienteDatosFacturacion,
      EnvioBonificado: ((this.props.instalacion.cobroDelivery) && (this.props.instalacion.deliveryBonificado) && (this.props.dataPedido.pedido.precioFinal > this.props.instalacion.montoDeliveryBonificado))?true:false,
      MontoEnvioBonificado: ((this.props.instalacion.cobroDelivery) && (this.props.instalacion.deliveryBonificado) && (this.props.dataPedido.pedido.precioFinal > this.props.instalacion.montoDeliveryBonificado))?this.props.instalacion.montoDeliveryBonificado:0,
      idSucursal: this.state.sucursalPickupSeleccionada
    }

    payload.callback = () => {
      this.setState({openModalCompraExitosa: true, disabledPagar: false})
    }

    this.props.sagaConfirmarEliminarPedido(payload)
  }

  handleOnClickEntendido = () => {
    this.setState({openModalCompraExitosa: false}, () => {
      //limpio el catalogo para evitar que se dupliquen los articulos
      this.props.setBuscandoCatalogo(true)
      this.props.setCatalogoSeleccionado(undefined)

      window.location.hash = `/Catalogo`
      showModal();
    })
  }

  handleOnClickBackToCatalogo = () => {
    
    //this.props.setLimpiarFiltro(true)

    //limpio el catalogo para evitar que se dupliquen los articulos
    this.props.setBuscandoCatalogo(true)
    this.props.setCatalogoSeleccionado(undefined)

    window.location.hash = `/Catalogo`
  }

  
  handleOnChangeSucursalPickup = (idSucursal) => {
    this.setState({sucursalPickupSeleccionada: idSucursal});
    const payload = {
      idTipoCalendario: 1,
      idInstalacion: idSucursal,
      idRecurso: idSucursal || 1
    }

    this.props.sagaFetchCalendario(payload);
  }
 
  handleOnClickBtnEntrega = (value) => {
    
    if(preferenciaEntrega.Delivery === value){
      
      let payload = {}
      payload.idTipoCalendario = value
      payload.idInstalacion = this.getSucursalDefecto(2)
      payload.idRecurso = this.getSucursalDefecto(2) || 1

      payload.callBack = () => {
        
        let domicilioSeleccionado = undefined
        let domicilioSeleccionadoItem = undefined
        let showTurnero = false
        
        if(this.props.usuario.domiciliosEntrega !== undefined && this.props.usuario.domiciliosEntrega.length > 0){
          if (!this.state.domicilioSeleccionadoItem) {
            domicilioSeleccionado = this.state.ultimoDomicilioEntrega ? this.state.idUltimoDomicilioEntrega : this.props.usuario.domiciliosEntrega[0].idDomicilioEntrega
            domicilioSeleccionadoItem =  this.state.ultimoDomicilioEntrega ? this.props.usuario.domiciliosEntrega.find(x => x.idDomicilioEntrega === this.state.idUltimoDomicilioEntrega) : this.props.usuario.domiciliosEntrega[0]
          }
          else {
            domicilioSeleccionado = this.state.domicilioSeleccionado;
            domicilioSeleccionadoItem = this.state.domicilioSeleccionadoItem;
          }
          
          showTurnero = true
        }

        this.setState({
          preferenciaEntregaElegida: true, 
          entregaElegida: value, 
          domicilioSeleccionado: domicilioSeleccionado,
          domicilioSeleccionadoItem: domicilioSeleccionadoItem,
          showTurnero: showTurnero,
          sucursalPickupSeleccionada: this.getSucursalDefecto(2)
        })
      }

      //busco el calendario 
      this.props.sagaFetchCalendario(payload)
    }
    else{
      let payload = {}
      payload.idTipoCalendario = value
      payload.idInstalacion = this.getSucursalDefecto(1)
      payload.idRecurso = this.getSucursalDefecto(1) || 1

      payload.callBack = () => {
        this.setState({preferenciaEntregaElegida: true, entregaElegida: value, showTurnero: true, sucursalPickupSeleccionada: this.getSucursalDefecto(1)})
      }

      //busco el calendario 
      this.props.sagaFetchCalendario(payload)
    }
    
  }

  handleOnClickCambiarPreferencia = () => {
    this.setState({
      
      preferenciaEntregaElegida: false, 
      entregaElegida: undefined, 
      permitePago: false,
      fechaSeleccionada: undefined, 
      horaSeleccionada: undefined,
      showTurnero: false,
      showMetodosPago: undefined
    })
  }

  handleOnClickDia = (itemDia) => {
    this.setState({fechaSeleccionada: itemDia})
  }

  handleOnClickHorario = (itemHorario) => {
    this.setState({horaSeleccionada: itemHorario, permitePago: true}, () => {
      
    })
  }
  handleOnClickPreferenciaFacturacion = (value) => {
    this.setState({preferenciaFacturacionElegida: false, showMetodoFacturacion:value}, () => {
      
    })
  }

  handleOnClickCambiarFechaHora = () => {
    this.setState({fechaSeleccionada: undefined, horaSeleccionada: undefined, permitePago: false })
  }

  handleOnChangeFacturacion = (tipoFacturacion, idClienteDatosFacturacion) => {
    this.setState({ tipoFacturacion, idClienteDatosFacturacion });
  }
  
  handleOnClickCambiarDomicilio = () => {
    //this.props.setFlagDatosAdicionales(false)
    this.setState({domicilioSeleccionado: undefined, showTurnero: false, horaSeleccionada: undefined, permitePago: false })
  }

  handleChangeRadioButtonDomicilio = (event) => {
    
    let select = event//event.target.value
    let item = undefined

    if(this.props.usuario === undefined || this.props.usuario.domiciliosEntrega === undefined || 
      this.props.usuario.domiciliosEntrega.length === 0){
      return
    }

    this.props.usuario.domiciliosEntrega.forEach(d => {
      if(d.idDomicilioEntrega.toString() === select){
        item = d
      }
    });

    this.setState({domicilioSeleccionado: select, domicilioSeleccionadoItem: item, showTurnero: true});
  }

  handleAfterSaveNuevoDomicilio = (idDomicilio) => {

    if(this.props.usuario === undefined || this.props.usuario.domiciliosEntrega === undefined || 
      this.props.usuario.domiciliosEntrega.length === 0){
      return
    }

    let item = undefined
    let select = idDomicilio.toString()

    this.props.usuario.domiciliosEntrega.forEach(de => {
      if(de.idDomicilioEntrega === idDomicilio){
        item = de
      }
    });
    
    this.setState({domicilioSeleccionado: select, domicilioSeleccionadoItem: item, showTurnero: true});
  }

  renderMobbexButton = () => {
    
    if(this.state.disabledPagar === true){
      return
    }

    this.setState({disabledPagar: true})

    let payload = {
      IdInstalacion:this.props.instalacion.id,
      IdPedido:this.props.dataPedido.pedido.id,
      Total:this.props.dataPedido.pedido.precioFinal,
      idCalendarioHorario: this.state.horaSeleccionada.idHorario,
      fechaTurno: this.state.fechaSeleccionada.fecha,
      tipoEntrega: this.state.entregaElegida ? this.state.entregaElegida : 0,
      IdEstadoPedido: estadosPedido.CONFIRMADO,
      IdFormaPagoOnline: formaPagoOnline.Mobbex,
      IdFormaEntrega: this.state.entregaElegida ? this.state.entregaElegida : 0,
      Domicilio: this.state.domicilioSeleccionadoItem ? this.state.domicilioSeleccionadoItem.domicilio + (this.state.domicilioSeleccionadoItem.pisoDepto ? " (" + this.state.domicilioSeleccionadoItem.pisoDepto + (this.state.domicilioSeleccionadoItem.opcional ? "" : ")" ) : '') + (this.state.domicilioSeleccionadoItem.opcional ?  (this.state.domicilioSeleccionadoItem.pisoDepto ? " " : " (" ) + this.state.domicilioSeleccionadoItem.opcional + ")" : '') : '',
      FaltaGeoreferenciar: this.state.domicilioSeleccionadoItem && this.state.domicilioSeleccionadoItem.faltaGeorreferenciar === true ? true : undefined,
      IdDomicilioEntrega: this.state.domicilioSeleccionadoItem ? this.state.domicilioSeleccionadoItem.idDomicilioEntrega : undefined,
    }

    payload.callback = (response) => {
      if(response.exitoso){
        this.setState({disabledPagar: false}, () => {

          if(!response.idCheckout){
            this.setState({openModalCompraExitosa: true}, () => {
              Notification.showSuccess(response.estado);
            })
            return
          }

          var options = {
            id: response.idCheckout,
            intent: "checkout",
            type: "checkout",
            onResult: (data) => {
                // OnResult es llamado cuando se toca el Botón Cerrar
                document.body.style.overflow = 'auto';
                this.setState({showMetodosPago: undefined})
                window.MobbexEmbed.close();
            },
            onPayment: (data) => {
                if(data && data.data && data.data.status && (data.data.status.code === "200" || data.data.status.code === "3")){
                  window.MobbexEmbed.close();
                  
                  Notification.showSuccess(data.data.status.message);
                  console.log("onPayment return",data.data)

                  let payloadConfirmar = {
                    Id:this.props.dataPedido.pedido.id,
                    IdEstadoPedido: estadosPedido.CONFIRMADO,
                    IdFormaPagoOnline: formaPagoOnline.Mobbex,
                    IdFormaEntrega: this.state.entregaElegida ? this.state.entregaElegida : 0,
                    IdCalendarioHorario: this.state.horaSeleccionada.idHorario,
                    FechaTurno: this.state.fechaSeleccionada.fecha,
                    Domicilio: this.state.domicilioSeleccionadoItem ? this.state.domicilioSeleccionadoItem.domicilio + (this.state.domicilioSeleccionadoItem.pisoDepto ? " (" + this.state.domicilioSeleccionadoItem.pisoDepto + (this.state.domicilioSeleccionadoItem.opcional ? "" : ")" ) : '') + (this.state.domicilioSeleccionadoItem.opcional ?  (this.state.domicilioSeleccionadoItem.pisoDepto ? " " : " (" ) + this.state.domicilioSeleccionadoItem.opcional + ")" : '') : '',
                    IdInstalacion:this.props.instalacion.id,
                    FaltaGeoreferenciar: this.state.domicilioSeleccionadoItem && this.state.domicilioSeleccionadoItem.faltaGeorreferenciar === true ? true : undefined,
                    IdDomicilioEntrega: this.state.domicilioSeleccionadoItem ? this.state.domicilioSeleccionadoItem.idDomicilioEntrega : undefined,
                    IdTipoFacturacion: this.state.tipoFacturacion,
                    IdClienteDatosFacturacion: this.state.idClienteDatosFacturacion,
                    IdCheckout: response.idCheckout,
                    IdPago: data.data.id,
                    Code: data.data.status.code,
                    Status: data.data.status.text,
                    EnvioBonificado: ((this.props.instalacion.cobroDelivery) && (this.props.instalacion.deliveryBonificado) && (this.props.dataPedido.pedido.precioFinal > this.props.instalacion.montoDeliveryBonificado))?true:false,
                    MontoEnvioBonificado: ((this.props.instalacion.cobroDelivery) && (this.props.instalacion.deliveryBonificado) && (this.props.dataPedido.pedido.precioFinal > this.props.instalacion.montoDeliveryBonificado))?this.props.instalacion.montoDeliveryBonificado:0,
                    idSucursal: (this.state.entregaElegida == 2?this.getSucursalDefecto(this.state.entregaElegida):this.state.sucursalPickupSeleccionada)
                  }

                  payloadConfirmar.callback = () => {
                    this.setState({openModalCompraExitosa: true, showMetodosPago: undefined}, () => {
                      Notification.showSuccess("Pago procesado con éxito");
                    })
                  }
                  setTimeout(() => {
                    this.props.sagaConfirmarPedidoMobbex(payloadConfirmar)
                  }, 1000);
                }
            },
            onOpen: () => {
                document.body.style.overflow = 'hidden';
                console.info("Pago iniciado.");
            },
            onClose: (cancelled) => {
                document.body.style.overflow = 'auto';
                this.setState({showMetodosPago: undefined})
                console.info(`${cancelled ? "Cancelado" : "Cerrado"}`);
            },
            onError: (error) => {
                document.body.style.overflow = 'auto';
                this.setState({showMetodosPago: undefined})
                console.error("ERROR: ", error);
            },
          };

          console.log("window.MobbexEmbed", options);
          var mbbxButton = window.MobbexEmbed.init(options);

          mbbxButton.open();
        })
      }
      else{
        this.setState({disabledPagar: false}, () => {
          Notification.showError(response.estado);
        })
      }
    }

    payload.callbackError = () => {
      this.setState({disabledPagar: false}, () => {
        Notification.showError("Error al generar checkout, intente nuevamente.");
      })
    }
    
    this.props.sagaGenerarCheckoutMobbex(payload)
  }

  handleOnClickVerMasPedidosDetalle = () => {

    this.setState({verMasPedidosDetalle: true})
  }
  
  render() {
    return (
      <FinalizarCompra 
        instalacion={this.props.instalacion}
        pedido={this.props.dataPedido.pedido}
        verMasPedidosDetalle={this.state.verMasPedidosDetalle}
        usuario={this.props.usuario}
        reemplazarProductos={this.state.reemplazarProductos}
        form={this.state.form}
        formMercadoPago={this.state.formMercadoPago}
        errores={this.state.errores}
        showMetodosPago={this.state.showMetodosPago}
        tipodocumento={this.state.formMercadoPago.tipodocumento}
        cuotastarjeta={this.state.formMercadoPago.cuotastarjeta}
        installments={this.state.formMercadoPago.installments ? this.state.formMercadoPago.installments : []}
        openModal={this.state.openModal && !this.props.flagDatosAdicionales}
        openModalCompraExitosa={this.state.openModalCompraExitosa}
        preferenciaEntregaElegida={this.state.preferenciaEntregaElegida}
        
        datosFacturacion={{
          tipoFacturacion: this.state.tipoFacturacion,
          idClienteDatosFacturacion: this.state.idClienteDatosFacturacion,
        }}
        handleOnChangeFacturacion={this.handleOnChangeFacturacion}
        
        entregaElegida={this.state.entregaElegida}
        permitePago={this.state.permitePago}
        fechaHoraSeleccionada={this.state.fechaSeleccionada !== undefined && this.state.horaSeleccionada !== undefined}
        fechaSeleccionada={this.state.fechaSeleccionada}
        horaSeleccionada={this.state.horaSeleccionada}
        calendario={this.props.calendario ? this.props.calendario.calendario : undefined}
        diasAdelante={this.props.calendario ? this.props.calendario.diasAdelante : undefined}
        showTurnero={this.state.showTurnero}
        disabledPagar={this.state.disabledPagar}

        domicilioSeleccionado={this.state.domicilioSeleccionado}
        domicilioSeleccionadoItem={this.state.domicilioSeleccionadoItem}
        domicilios={this.props.usuario ? this.props.usuario.domiciliosEntrega : undefined}
        handleChangeRadioButtonDomicilio={this.handleChangeRadioButtonDomicilio}
        handleAfterSaveNuevoDomicilio={this.handleAfterSaveNuevoDomicilio}

        handleOnClickCambiarPreferencia={this.handleOnClickCambiarPreferencia}
        handleOnClickDia={this.handleOnClickDia}
        handleOnClickHorario={this.handleOnClickHorario}
        handleOnClickCambiarFechaHora={this.handleOnClickCambiarFechaHora}
        handleOnClickCambiarDomicilio={this.handleOnClickCambiarDomicilio}
        handleOnClickBtnEntrega={this.handleOnClickBtnEntrega}
        handleOnClickEntendido={this.handleOnClickEntendido}
        handleOnClickBackToCatalogo={this.handleOnClickBackToCatalogo}
        handleOnDataChange={this.handleOnDataChange}
        handleOnDataChangeMercadoPago={this.handleOnDataChangeMercadoPago}
        handleOnSaveDatosAdicionales={this.handleOnSaveDatosAdicionales}
        handleOnPagar={this.handleOnPagar}
        handleChangeRadioButton={this.handleChangeRadioButton}
        handleOnClickBtnFormaPago={this.handleOnClickBtnFormaPago}
        handleOnClickFinalizarCompra={this.handleOnClickFinalizarCompra}
        handleChangeRadioButtonIndividual={this.handleChangeRadioButtonIndividual}
        handleOnClickVerMasPedidosDetalle={this.handleOnClickVerMasPedidosDetalle}
        renderMobbexButton={this.renderMobbexButton}
        
        sucursalesPickup={this.state.sucursalesPickup}
        sucursalPickupSeleccionada={this.state.sucursalPickupSeleccionada}
        handleOnChangeSucursalPickup={(e) => this.handleOnChangeSucursalPickup(e.target.value)}
      />
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...layoutActions,
    ...pedidoActions,
    ...sessionActions,
    ...provLocActions,
    ...tuerneroActions,
    ...catalogoActions
  }, dispatch)
}

function mapStateToProps(state) {
  return {
    usuario: usuarioSelector(state),
    instalacion: instalacionSelector(state),
    dataPedido: pedidoDataSelector(state),
    flagDatosAdicionales: flagDatosAdicionalesSelector(state),
    calendario: calendarioSelector(state),
    sucursales: sucursalesSelector(state)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinalizarCompraContainer)