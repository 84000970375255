import React from 'react'
import PropTypes from 'prop-types'
import CustomModal from '../UI/Modals/CustomModal'

class MyDialog extends React.Component {
  render() {
    return (
      <CustomModal
        open={true}
        onClose={this.handleClose}
        title={this.props.mostrarTitulo ? this.props.titulo : ""}
        buttonNames={this.props.mostrarBotones ? [this.props.botonCancelar, this.props.botonConfirmar] : []}
        fullWidth={true}
        buttonActions={
          this.props.mostrarBotones ? [() => { this.props.handleOnClose(false) }, () => { this.props.handleOnClose(true) }] : []
        }
      >
        {this.props.cuerpo}
      </CustomModal>
    )
  }
}

MyDialog.propTypes = {
  handleOnClose: PropTypes.func.isRequired,
  titulo: PropTypes.string,
  mostrarTitulo: PropTypes.bool,
  cuerpo: PropTypes.node,
  botonConfirmar: PropTypes.string,
  botonCancelar: PropTypes.string,
  mostrarBotones: PropTypes.bool,
}

MyDialog.defaultProps = {
  handleOnClose: f => f,
  titulo: 'Diveria e-commerce',
  mostrarTitulo: false,
  cuerpo: <div></div>,
  botonConfirmar: 'ACEPTAR',
  botonCancelar: 'CANCELAR',
  mostrarBotones: true
}

export default MyDialog