import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../../Common/wrappers/CustomButton";
import "./customModal.css"

const CustomModal = ({
  open,
  onClose,
  title,
  children,
  buttonNames,
  buttonActions,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md" a
      sx={{
        borderRadius: "50px",
      }}
      className="custom-dialog-container"
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          border: "1px solid #dadada",
          padding: "15px 30px",
        }}
      >
        <Typography
          style={{
            color: "#30272C",
            fontFamily: "Poppins",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "130%",
          }}
        >
          {title}
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ padding: "0px", overflowX: "hidden" }}>
        <Typography
          style={{
            color: "#000",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "140%",
            padding: "15px 20px",
          }}
        >
          {children}
        </Typography>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "space-between", padding: "15px 30px" }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: `${buttonNames.length === 1 ? "center" : "flex-start"}`,
            gap: "16px",
          }}
        >
          {buttonNames.map((name, index) => (
            <CustomButton
              key={index}
              onClick={buttonActions[index]}
              message={name}
              variant="contained"
              color={buttonNames.length === 1 ? "primary" : index === 0 ? "secondary" : "primary"}
              classProp={"button-modal"}
            />
          ))}
        </div>
      </DialogActions>
    </Dialog>

  );
};

export default CustomModal;
