import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import Radio from '../../Common/radio';

const ItemAlternativa = ({ id, descripcion, checked, onSelect }) => (
  <Row middle="xs" className={`itemAlternativa ${checked && 'checked'}`} onClick={() => onSelect(id, !checked)}>
    <Col xs={2} sm={1}>
      <Radio checked={checked} />
    </Col>
    <Col xs={10} sm={11}>{descripcion}</Col>
  </Row>
);

export default ItemAlternativa;
