import React from 'react';

const useFiltros = ({ filterBusqueda, getSubFiltrosArticulos, subFiltrosArticulos, handleOnChangeFiltrosArticulos, cerrarModal, setCerrarModal }) => {
  const [subfiltrosSeleccionados, setSubfiltrosSeleccionados] = React.useState([]);
  const lastSelectedValues = React.useRef([]);

  React.useEffect(() => {
    if (cerrarModal) {
      handleOnChangeFiltrosArticulos([]);
      setCerrarModal(false);
    }
    
  }, [cerrarModal]);

  React.useEffect(() => {
    if (filterBusqueda) {
      setTimeout(getSubFiltrosArticulos);
    } else {
      setSubfiltrosSeleccionados([]);
      lastSelectedValues.current = [];
    }
  }, [filterBusqueda]);

  React.useEffect(() => {
    const valoresSubfiltros = subFiltrosArticulos.map((x) => x.valor);
    const newSelectedValues = subfiltrosSeleccionados.filter((x) => valoresSubfiltros.includes(x));
    setSubfiltrosSeleccionados(newSelectedValues);
    lastSelectedValues.current = newSelectedValues;
  }, [JSON.stringify(subFiltrosArticulos)]);

  const onSelectionChange = (newSelectedValue) => {
    setSubfiltrosSeleccionados(newSelectedValue);
  }

  const onFilter = () => {
    const subFiltrosSeleccionados = subFiltrosArticulos.filter((x) => subfiltrosSeleccionados.includes(x.valor));
    if (lastSelectedValues.current.map((x) => x.valor).toString() !== subFiltrosSeleccionados.map((x) => x.valor).toString()) {
      lastSelectedValues.current = subFiltrosSeleccionados;
      handleOnChangeFiltrosArticulos(subFiltrosSeleccionados);
    }
  }

  const clearSelection = () => {
    const newValue = [];
    onSelectionChange(newValue);
    lastSelectedValues.current = newValue;
    handleOnChangeFiltrosArticulos(newValue);
  }

  return {
    subfiltrosSeleccionados,
    onSelectionChange,
    onFilter,
    clearSelection,
    cantidadFiltrosAplicados: lastSelectedValues.current.length,
  }
};

export default useFiltros;
