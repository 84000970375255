import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './styles.css'
import CustomButton from '../Common/wrappers/CustomButton';

const drawerWidth = 350;

const styles = (theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    opacity: 1
  },
  drawerPaper: {
    width: drawerWidth,
    background: '#EFF0F8 0% 0% no-repeat padding-box',
    opacity: 1,
    zIndex: 1501,
    border: 'none'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});

class DrawerNotificaciones extends React.Component {

  toFixedCustomize = (num) => {
    var resultado = num.toFixed(6).slice(0, -4)
    return resultado
  }

  render() {

    const { classes } = this.props

    return (
      <Drawer
        className={`${classes.drawer}`}
        anchor="right"
        open={this.props.open}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={this.props.handleOnClose}
      >
        <div className='containerNotificaciones'>

          <IconButton
            color="default"
            aria-label="Back"
            className='arrowCloseResumen'
            onClick={this.props.handleOnClose}
          >
            <ArrowBackIcon />
          </IconButton>

          <div className='tituloNotificaciones'>Mis notificaciones</div>

          <div id='scrollItemsNotificaciones' className={`notificacionesItems`}>
            {this.props.usuario && this.props.usuario.notificaciones &&
              this.props.usuario.notificaciones.length > 0 ?

              this.props.usuario.notificaciones.map((n) => {
                return <div key={n.idNotificacion} style={{ width: '100%' }}>
                  <ExpansionPanel>
                    <ExpansionPanelSummary
                      onClick={!n.visto ? () => { this.props.handleOnClickNotificaciones(n.idNotificacion) } : undefined}
                      className='panelNotificacion'
                      expandIcon={<ExpandMoreIcon style={{ color: '#FFFFFF' }} />}
                      aria-controls={`panel${n.idNotificacion}a-content`}
                      id={`panel${n.idNotificacion}a-header`}
                    >
                      <div>
                        <div className='containerFechaInfo'>
                          <div>{n.fechaRegistro}</div>
                          {!n.visto && <div>No leído</div>}
                        </div>
                        <Typography className={`${classes.heading} labelNota`}>{n.nota.slice(0, 30)}...</Typography>
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: '0px' }}>
                      <div className='labelNotaCompleta'>
                        {n.nota}
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              })
              :
              <div className='labelNoTienes'>No tienes notificaciones</div>
            }

            {this.props.usuario && this.props.usuario.notificaciones && this.props.usuario.notificaciones.length > 0 &&
              this.props.usuario.totalNotificaciones > this.props.usuario.notificaciones.length &&
              <div className='containerbtnVerMas'>
                <CustomButton onClick={this.props.handleOnClickVerMas} classProp="btnVerMas" message="VER MÁS" />
              </div>}
          </div>
        </div>
      </Drawer>
    );
  }
}

DrawerNotificaciones.defaultProps = {
  open: false
}

DrawerNotificaciones.propTypes = {
  usuario: PropTypes.object.isRequired,
  instalacion: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleOnClose: PropTypes.func,
  handleOnClickNotificaciones: PropTypes.func,
  handleOnClickVerMas: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(DrawerNotificaciones);

