import { createSelector } from 'reselect'

const sessionReducerInstalacionSelector = state => state.sessionReducer.getIn(["instalacion"])
export const instalacionSelector = createSelector(
    sessionReducerInstalacionSelector, (instalacionField) => {
      
      // armo el objeto de instalación
      let myInstalacion = undefined
      if(instalacionField !== undefined)
      {
        instalacionField = instalacionField.toJS()

        myInstalacion = {}
        myInstalacion.id = instalacionField.idInstalacion
        myInstalacion.logoSuperior = instalacionField.logoHorizontal

        myInstalacion.logoFooter = instalacionField.logoFooter
        myInstalacion.logoCard = instalacionField.logoCard
        myInstalacion.logoLogin = instalacionField.logoLogin
        myInstalacion.logoDrawer = instalacionField.logoDrawer
        myInstalacion.imgPiePagina = instalacionField.imgPiePagina
        
        
        myInstalacion.basesYCondiciones = instalacionField.basesYCondiciones

        myInstalacion.fondoLogin = instalacionField.fondoLogin || undefined
        myInstalacion.fondoLoginUrl = instalacionField.fondoLoginUrl
        myInstalacion.mostrarNombre = instalacionField.mostrarNombre

        // datos de contacto
        myInstalacion.datosContacto = {}
        myInstalacion.datosContacto.id = instalacionField.datosContacto.idDatosContacto
        
        if(instalacionField.datosContacto.idDatosContacto !== 0){
          myInstalacion.datosContacto.id = instalacionField.datosContacto.idDatosContacto
          myInstalacion.datosContacto.calle = instalacionField.datosContacto.calle || undefined
          myInstalacion.datosContacto.numero = instalacionField.datosContacto.numero
          myInstalacion.datosContacto.piso = instalacionField.datosContacto.piso
          myInstalacion.datosContacto.departamento = instalacionField.datosContacto.departamento
          myInstalacion.datosContacto.email = instalacionField.datosContacto.correo || undefined
          myInstalacion.datosContacto.telefono = instalacionField.datosContacto.telefono || undefined
          myInstalacion.datosContacto.facebook = instalacionField.datosContacto.facebook || undefined
          myInstalacion.datosContacto.twitter = instalacionField.datosContacto.twitter || undefined
          myInstalacion.datosContacto.instagram = instalacionField.datosContacto.instagram || undefined
        }

        //datos de MP
        myInstalacion.datosMercadoPago={}
        if (instalacionField.datosMercadoPago!=null && instalacionField.datosMercadoPago.publicKey!=null)
        {
          myInstalacion.datosMercadoPago.accessToken=instalacionField.datosMercadoPago.accessToken;
          myInstalacion.datosMercadoPago.publicKey=instalacionField.datosMercadoPago.publicKey;

        }
        

        myInstalacion.rubroTextil = instalacionField.rubroTextil
        myInstalacion.mostrarPrecioSugerido = instalacionField.mostrarPrecioSugerido
        myInstalacion.porcentajePrecioSugerido = instalacionField.porcentajePrecioSugerido
        myInstalacion.promosBandaDescuentos = instalacionField.promosBandaDescuentos
        myInstalacion.bandaDescuento = instalacionField.bandaDescuento

        //parametros tiempo control de actividad del usuario
        myInstalacion.tiempoActividadDelUsuario = instalacionField.tiempoActividadDelUsuario
        myInstalacion.tiempoDecisionPedidoBorrador = instalacionField.tiempoDelAvisoControlActividadDelUsuario

        myInstalacion.ocultarProductosAgotados = instalacionField.ocultarProductosAgotados
        myInstalacion.visualizacionPrecios = instalacionField.visualizacionPrecios
        myInstalacion.verPortadaCatalogo = instalacionField.verPortadaCatalogo
        myInstalacion.verPiePagina = instalacionField.verPiePagina
        

        //banners
        myInstalacion.listaImagenesBannerDesktop = instalacionField.listaImagenesBannerDesktop
        myInstalacion.listaImagenesBannerMovil = instalacionField.listaImagenesBannerMovil

        //delivery
        myInstalacion.montoCompraMinimoPermitido = instalacionField.montoCompraMinimoPermitido
        myInstalacion.cobroDelivery = instalacionField.cobroDelivery
        myInstalacion.montoDelivery = instalacionField.montoDelivery
        myInstalacion.deliveryBonificado = instalacionField.deliveryBonificado
        myInstalacion.montoDeliveryBonificado = instalacionField.montoDeliveryBonificado

        //ofertas
        myInstalacion.listaOfertas = instalacionField.listaOfertas

        let listaBandaDescuento = []

        instalacionField.listaBandaDescuento.forEach(bd => {
          let bandaDescuento = {}

          bandaDescuento.idBandaDescuento = bd.idBandaDescuento
          bandaDescuento.idBandaDescuentoPorcentaje = bd.idBandaDescuentoPorcentaje
          bandaDescuento.nombre = bd.nombre
          bandaDescuento.limiteInferior = bd.limiteInferior
          bandaDescuento.limiteSuperior = bd.limiteSuperior
          bandaDescuento.porcentajeDescuento = bd.porcentajeDescuento

          listaBandaDescuento.push(bandaDescuento)
        });

        myInstalacion.listaBandaDescuento = listaBandaDescuento
      }
    
    
      return myInstalacion
  }
)

const sessionReducerResultadoAutenticacionSelector = state => state.sessionReducer.getIn(["resultadoAutenticacion"])
export const resultadoAutenticacionSelector = createSelector(
  sessionReducerResultadoAutenticacionSelector, (resultadoAutenticacionField) => {    
    return resultadoAutenticacionField.toJS()
  }
)

const sessionReducerUsuarioSelector = state => state.sessionReducer.getIn(["usuario"])
export const usuarioSelector = createSelector(
  sessionReducerUsuarioSelector, (usuarioField) => {    
    
    let myUsuario = undefined
    if(usuarioField)
    {
      let usuario = usuarioField.toJS()
      myUsuario = {}
      myUsuario.id = usuario.idUsuario
      myUsuario.nombreUsuario = usuario.nombreUsuario
      myUsuario.idCliente = usuario.idCliente
      myUsuario.nombre = usuario.nombre
      myUsuario.apellido = usuario.apellido
      myUsuario.telefono = usuario.telefono
      myUsuario.direccion = usuario.direccion
      myUsuario.localidad = usuario.idLocalidad
      myUsuario.nombreLocalidad = usuario.nombreLocalidad
      myUsuario.dni = usuario.dni
      myUsuario.notificaciones = usuario.notificaciones
      myUsuario.notificacionesNoVistas = usuario.notificacionesNoVistas
      myUsuario.totalNotificaciones = usuario.totalNotificaciones
      myUsuario.domiciliosEntrega = usuario.domiciliosEntrega
      myUsuario.ultimaSucursalDelivery = usuario.ultimaSucursalDelivery
      myUsuario.ultimaSucursalPickup = usuario.ultimaSucursalPickup
    }

    return myUsuario
  }
)

const sessionReducerUsuarioAnonimoSelector = state => state.sessionReducer.getIn(["permiteUsuarioAnonimo"])
export const permiteUsuarioAnonimoSelector = createSelector(
  sessionReducerUsuarioAnonimoSelector, (usuarioAnonimoField) => {    
    return usuarioAnonimoField.toJS()
  }
)

const sessionReducerClienteSelector = state => state.sessionReducer.getIn(["usuario"])
export const clienteSelector = createSelector(
  sessionReducerClienteSelector, (usuarioField) => {    
    
    let cliente = 0
    if(usuarioField)
    {
      let usuario = usuarioField.toJS()
      cliente = usuario.idCliente
    }

    return cliente
  }
)

const flagDatosAdicionalesReducerSelector = state => state.sessionReducer.getIn(["datosAdicionalesCargados"])
export const flagDatosAdicionalesSelector = createSelector(
  flagDatosAdicionalesReducerSelector, (flag) => {     
    return flag
  }
)

const preguntasFrecuentesReducerSelector = state => state.sessionReducer.getIn(["preguntasFrecuentes"]).toJS()
export const preguntasFrecuentesSelector = createSelector(
  preguntasFrecuentesReducerSelector, (preguntasFrecuentes) => {    
    return preguntasFrecuentes
  }
)