import PropTypes from 'prop-types';
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as catalogoActions from '../../reducers/CatalogoReducer/actions'
import * as sessionActions from '../../reducers/SessionReducer/actions'
import * as layoutActions from '../../reducers/LayoutReducer/actions';
import { catalogoDataSelector } from '../../selectors/catalogoSelector'
import { instalacionSelector } from '../../selectors/sessionSelector'
import Ofertas from '../../components/Ofertas'

class OfertasContainer extends React.Component {

  constructor(props) {
    super(props)

    window.scrollTo(0, 0)

    // obtengo catalogos activos
    let payload = {}
    payload.idInstalacion = this.props.instalacion.id
    payload.callBack = () => {
      if (this.props.data.catalogos !== undefined && this.props.data.catalogos.length == 1 && this.props.instalacion.verPortadaCatalogo == false) {
        //document.getElementById('footer').getElementsByClassName('descuentos')[0].hidden = true
        let footer = document.getElementById('footer')
        if(footer !== undefined && footer !== null && footer.getElementsByClassName('descuentos').length > 0){
          footer.getElementsByClassName('descuentos')[0].hidden = true
        }
      }
    }

    this.props.setCatalogoSeleccionado(undefined)

    this.props.sagaFetchCatalogosActivos(payload)
  }

  componentWillUnmount = () => {

    let footer = document.getElementById('footer')
    if(footer !== null && footer !== undefined && footer.getElementsByClassName('descuentos').length > 0){
      footer.getElementsByClassName('descuentos')[0].hidden = false
    }

    this.props.setPermiteUsuarioAnonimo(false)
    
  }

  componentDidMount = () => {
    // indico que el Login se puede ver aunque el usuario no esté logeado
    this.props.setPermiteUsuarioAnonimo(true);
  }

  render() {

    return (
        <Ofertas 
            existeCatalogo={this.props.data.catalogos !== undefined && this.props.data.catalogos.length == 1}
            history={this.props.history}
            catalogos={this.props.data.catalogos}
            oferta={this.props.oferta}
        />
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...catalogoActions,
    ...sessionActions,
    ...layoutActions
  }, dispatch)
}

function mapStateToProps(state) {
  return {
    data: catalogoDataSelector(state),
    instalacion: instalacionSelector(state)
  }
}

OfertasContainer.propTypes = {
  oferta: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(OfertasContainer)