import * as React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Test from '../../components/Test'
import * as testActions from '../../reducers/TestReducer/actions'
import { testDataSelector } from '../../selectors/testSelector'

class TestContainer extends React.Component {

    constructor(props) {
        super(props)
        
        // busco los datos de prueba
        this.props.sagaFetchTestData()
    }

    render() {
        return <Test data={this.props.data.testData} />
    }
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		...testActions
	}, dispatch)
}

function mapStateToProps(state) {
	return {
		data: testDataSelector(state)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TestContainer)