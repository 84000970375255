import { fromJS } from 'immutable'
import * as ReducerActions from './actions'

const initialState = fromJS({
    data: {
        articulo: undefined,
        detallePedido: undefined
    },
    error: false
})

export default function detallePedidoReducer(state = initialState, action) {

    if(action.type === ReducerActions.INITIALIZE_FORM)
        return initialState

    if (action.type === ReducerActions.SET_ARTICULO) {
        return state.setIn(["data", "articulo"], action.payload)
    }

    if(action.type === ReducerActions.SET_PEDIDO_DETALLE) {
        return state.setIn(["data", "detallePedido"], action.payload)
    }

    if(action.type === ReducerActions.SET_ERROR) {
        return state.set("error", action.payload)
    }

    return state
}