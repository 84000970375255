import { takeLatest, takeEvery, all, put } from 'redux-saga/effects'
import * as calls from './calls'
import * as reducerActions from '../../reducers/CatalogoReducer/actions'
import * as layoutActions from '../../reducers/LayoutReducer/actions'
import * as Notification from '../../components/Common/notification'


function* genGetCatalogosActivos(e) {
  try {
    // muestro el loader
    yield put(layoutActions.setLoading({ show: true, message: 'Cargando catálogos publicados' }))

    // llamo a la acción que busca los catalogos
    let catalogos = yield calls.getCatalogosActivos(e.payload.idInstalacion)

    // almaceno los datos en el estado del reductor
    yield put(reducerActions.setCatalogosActivos(catalogos))

    e.payload.callBack()
  }
  catch (error) {

    // muestro el mensaje de error
    Notification.showError('No existen catálogos publicados.')

    if (error.Message) {
      console.log(error.Message)
      return;
    }
  }
  finally {
    // oculto el loader
    yield put(layoutActions.setLoading({ show: false }))
  }
}

// Catalogo seleciconado con rubros, subrubros y articulos

function* genGetCatalagoSeleccionado(e) {

  let callback = e.payload.callback

  try {
    e.payload.callback = undefined
    // muestro el loader
    //yield put(layoutActions.setLoading({ show: true }))
    yield put(reducerActions.setBuscandoCatalogo(true))

    // catalogo seleccionado como undefined 
    //yield put(reducerActions.setCatalogoSeleccionado(undefined))

    // llamo a la acción que la instalacion
    let catalogo = yield calls.getCatalogoSeleccionado(e.payload)

    // almaceno los datos en el estado del reductor
    yield put(reducerActions.setCatalogoSeleccionado(catalogo))
  }
  catch (error) {
    if (error.Message) {
      console.log(error.Message)
    }
    if(callback){
      callback()
    }
  }
  finally {
    // oculto el loader
    //yield put(layoutActions.setLoading({ show: false }))
    yield put(reducerActions.setBuscandoCatalogo(false))
  }
}

function* genGetIdCatalogoPublicadoActual(e) {
  try {
    // consulto catalogos publicados
    let idCatalogo = yield calls.getIdCatalogoPublicadoActual()
    
    e.payload.callBack(idCatalogo)
  }
  catch (error) {
    if (error.Message) {
      console.log(error.Message)
      return;
    }
  }
  finally {
  }
}

function* genSagaFetchRubrosSubrubros(e) {
  try {
    
    yield put(reducerActions.saveRubrosSubrubros(undefined))
    // llamo a la acción que busca los catalogos
    let resultado = yield calls.getRubrosSubrubros(e.payload)

    // almaceno los datos en el estado del reductor
    yield put(reducerActions.saveRubrosSubrubros(resultado))

    //e.payload.callBack()
  }
  catch (error) {

    // muestro el mensaje de error
    Notification.showError('Ocurrió un error al buscar las categorias')

    yield put(reducerActions.saveRubrosSubrubros([]))
    if (error.Message) {
      console.log(error.Message)
      return;
    }
  }
  finally {
    
  }
}

function* genSagaFetchSubFiltrosArticulos(e) {
  try {
    // llamo a la acción que busca los subfiltros de articulos
    let resultado = yield calls.getSubFiltrosArticulos(e.payload)

    // almaceno los datos en el estado del reductor
    yield put(reducerActions.setSubFiltrosArticulos(resultado))

    //e.payload.callBack()
  }
  catch (error) {

    // muestro el mensaje de error
    Notification.showError('Ocurrió un error al buscar los filtros de artículos')

    if (error.Message) {
      console.log(error.Message)
      return;
    }
  }
  finally {
    
  }
}

function* defaultSaga() {
  yield all([
    takeLatest(reducerActions.SAGA_FETCH_CATALOGOS_ACTIVOS, genGetCatalogosActivos),
    takeLatest(reducerActions.SAGA_FETCH_CATALOGO_SELECCIONADO, genGetCatalagoSeleccionado),
    takeLatest(reducerActions.SAGA_ID_CATALOGO_PUBLICADO_ACTUAL, genGetIdCatalogoPublicadoActual),
    takeLatest(reducerActions.SAGA_FETCH_RUBROS_SUBRUBROS, genSagaFetchRubrosSubrubros),
    takeLatest(reducerActions.SAGA_FETCH_SUBFILTROS_ARTICULOS, genSagaFetchSubFiltrosArticulos),
  ])
}

export const sagas = [
  defaultSaga
]