import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import { Row, Col } from 'react-flexbox-grid'
import ItemPedido from './itemPedido'
import MyDialog from '../Layout/myDialog'
import DetallePedidoModalContainer from '../../containers/DetallePedidoModal'
import { Link } from "react-router-dom";
import Fab from '@material-ui/core/Fab';
import { ShoppingCart as ShoppingCartIcon } from '@material-ui/icons'
import './styles.css'

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  root: {
    flexGrow: 1,
  },
  show: {
    transform: 'translateY(0)',
    transition: 'transform .5s',
  },
  hide: {
    transform: 'translateY(+110%)',
    transition: 'transform .5s',
  },
})

class ConfirmarPedido extends React.Component {

  render() {

    const { classes } = this.props;

    return (
      <div>
        {this.props.pedido !== undefined &&
          <div>
            {/* LISTA */}
            <div className='listaItemConfirmarPedido'>
              <Row>
                {this.props.pedido.detalles.map((p) => {
                  return <Col xs={12} md={6} lg={4}>
                    <ItemPedido
                      item={p}
                      handleOnClick={this.props.handleOnItemPedidoClick}
                      estadoPedido={this.props.pedido.estadoPedido.descripcion}
                    />
                  </Col>
                })}
              </Row>
            </div>
          </div>}

          {this.props.pedido !== undefined && this.props.pedido.estadoPedido.descripcion === "Borrador" &&
          <div className='buttonFlotante'>
              <Fab onClick={() => this.props.handleIrAlCatalogo()} variant="extended" aria-label="Add" className={`${classes.margin} btn`}>
                <ShoppingCartIcon className={classes.extendedIcon} />
                SEGUIR COMPRANDO
              </Fab>
          </div>
        }

        {/* DIALOG */}
        {this.props.mostrarDialogo &&
          <MyDialog
            handleOnClose={this.props.handleOnConfirmarDialogClose}
            titulo={this.props.tituloDialogo}
            cuerpo={this.props.cuerpoDialogo}
            botonConfirmar={this.props.botonConfirmarDialogo}
            botonCancelar={this.props.botonCancelarDialogo}
          />}

        {/* MODAL DE QUITAR ARTÍCULO */}
        {this.props.pedido !== undefined && this.props.idArticulo !== undefined && this.props.idPedidoDetalle !== undefined &&
          <DetallePedidoModalContainer
            idArticulo={this.props.idArticulo}
            idPedidoDetalle={this.props.idPedidoDetalle}
            totalPedido={this.props.pedido.precioLista}
            handleOnClose={this.props.handleOnPedidoDetalleModalClose}
          />
        }
      </div>
    )
  }
}

ConfirmarPedido.propTypes = {
  pedido: PropTypes.object.isRequired,
  handleOnItemPedidoClick: PropTypes.func,
  idArticulo: PropTypes.number,
  idPedidoDetalle: PropTypes.number,
  handleOnPedidoDetalleModalClose: PropTypes.func,
  handleIrAlCatalogo: PropTypes.func,

  /* propiedades de dialogo */
  mostrarDialogo: PropTypes.bool,
  handleOnConfirmarDialogClose: PropTypes.func.isRequired,
  tituloDialogo: PropTypes.string,
  cuerpoDialogo: PropTypes.string,
  botonConfirmarDialogo: PropTypes.string,
  botonCancelarDialogo: PropTypes.string,
}

ConfirmarPedido.defaultProps = {
  pedido: undefined,
  mostrarDialogo: false,
  handleOnPedidoDetalleModalClose: f => f
}

export default withStyles(styles)(ConfirmarPedido)