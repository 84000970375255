import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import './catalogo.css'
import { Link } from 'react-router-dom'

const styles = theme => ({
});


class ItemCatalogo extends React.Component {

  render() {

    const { classes } = this.props;

    return (
      <div className='item'>
        <Link to={`/ContenidoCatalogo/${this.props.item.id}`}>
          {this.props.item.imagenCatalogo !== null ?
            <img className='imagenCatalogo' src={this.props.item.imagenCatalogo} />
            :
            <div className='imagenCatalogo'>
              <div class="outerC">
                <div class="middleC">
                  <div class="innerC">
                    <div>{this.props.item.nombre}</div>
                  </div>
                </div>
              </div>
            </div>
          }

        </Link>

        {(this.props.mostrarNombre &&
          <div className='nombreCatalogo'>
            <Typography align='center' variant="h4" >{this.props.item.nombre}</Typography>
            <Typography align='center'>Catálogo</Typography>
          </div>)}

      </div>
    )
  }
}

ItemCatalogo.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  mostrarNombre: PropTypes.bool.isRequired,
  instalacion: PropTypes.object.isRequired,
  handleOnCatalogo: PropTypes.func.isRequired
};

ItemCatalogo.defaultProps = {
  handleOnCatalogo: f => f
}

export default withStyles(styles)(ItemCatalogo);