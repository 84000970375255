import React, { Component } from 'react'
import LayoutContainer from './containers/Layout'
import { connect } from 'react-redux'
import './App.css'

class App extends Component {

  componentDidMount = () => {
  }

  render() {
    return (
      <LayoutContainer />
    );
  }
}

function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps)(App);