import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';
import { ArrowBack } from '@material-ui/icons'
import { Row, Col } from 'react-flexbox-grid'
import { Link } from 'react-router-dom'
import { PermMedia, ExitToApp } from '@material-ui/icons'
import GetAppIcon from '@material-ui/icons/GetApp';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpIcon from '@material-ui/icons/Help';
import * as sessionActions from '../../reducers/SessionReducer/actions'
import { usuarioSelector } from '../../selectors/sessionSelector'
import "./myDrawer.css"

const styles = {
  list: {
    width: 300,
  },
  fullList: {
    width: 'auto',
  },
  centerComponent: {
    justifyContent: 'center',
    alignItems: 'center'
  }
};

class MyDrawer extends React.Component {

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  render() {
    const { classes } = this.props;
    const sideList = (
      <div className={classes.list}>
        <List className='menuItem'>
          {this.props.usuario !== undefined &&
            <ListItem
              button key='notificaciones'
              onClick={this.props.handleOnClickOpenNotidicaciones}
            >
              {this.props.usuario.notificacionesNoVistas === 0 &&
                <><ListItemIcon><NotificationsNoneIcon className='iconItemMenu' /></ListItemIcon>
                  <ListItemText className='labelText' primary='Notificaciones' /></>}
              {this.props.usuario.notificacionesNoVistas > 0 &&
                <><ListItemIcon><NotificationsIcon className='iconItemMenu' /></ListItemIcon>
                  <ListItemText className='labelText' primary='Notificaciones' /><div className='notificaciones'>{this.props.usuario.notificacionesNoVistas}</div></>}
            </ListItem>
          }
          <Link to={'/Catalogo'}>
            <ListItem
              button key='catalogo'
              onClick={() => this.props.handleDrawerClose(true)}
            >
              <ListItemIcon><PermMedia className='iconItemMenu' /></ListItemIcon>
              <ListItemText className='labelText' primary='Catálogo' />
            </ListItem>
          </Link>
          {/*this.props.usuarioLogueado && <Link to={this.props.dataUser !== undefined ? `/Pedido/${this.props.dataUser.id}` : '-1'}>
                  <ListItem 
                    button key='pedido'
                    onClick={this.props.handleDrawerClose}
                    >
                    <ListItemIcon><ShoppingCart className='iconItemMenu'/></ListItemIcon>
                    <ListItemText className='labelText' primary='Pedido' /> 
                </ListItem>
              </Link>*/}
          <ListItem
            button key='PreguntasFrecuentes'
          >
            <ExpansionPanel style={{ boxShadow: 'none', borderRadius: '8px', maxWidth: '270px' }}>
              <ExpansionPanelSummary
                className=''
                expandIcon={<ExpandMoreIcon className='iconItemMenu' />}
                aria-controls={`panelPreguntasFrecuentesa-content`}
                id={`panelPreguntasFrecuentesa-header`}
                style={{ padding: '0px', alignItems: 'center' }}
              >
                <ListItemIcon><HelpIcon className='iconItemMenu' /></ListItemIcon>
                <ListItemText className='labelText' primary='Preguntas Frecuentes' />
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ padding: '0px' }}>
                <div id='scrollItemsPF' className='containerPF'>
                  {this.props.preguntasFrecuentes && this.props.preguntasFrecuentes.length > 0 &&
                    this.props.preguntasFrecuentes.map((pf, i) => {
                      return <ExpansionPanel key={pf.idContenido} style={{ boxShadow: 'none' }}>
                        <ExpansionPanelSummary
                          className='panelPregunta'
                          expandIcon={<ExpandMoreIcon className='iconItemMenu' />}
                          aria-controls={`panelPf${i}-content`}
                          id={`panelPf${i}-header`}
                          style={{ padding: '0px' }}
                        >
                          <Typography className={`${classes.heading} textPregunta`}>{pf.textoCorto}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{ padding: '0px' }}>
                          <Typography className={`${classes.heading} textRespuesta`}>{pf.textoLargo}</Typography>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    })
                  }
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </ListItem>
          <ListItem
            button key='basesYCondiciones'
            onClick={this.props.handleOnClickBasesCondiciones}
          >
            <ListItemIcon><GetAppIcon className='iconItemMenu' /></ListItemIcon>
            <ListItemText className='labelText' primary='Bases y condiciones' />
          </ListItem>
        </List>
        <Divider />
        <List className='menuItem'>
          {this.props.usuario !== undefined && <ListItem
            button key='cerrarSesion'
            onClick={() => { this.props.handleOnCerrarSesion() }}
          >
            <ListItemIcon><ExitToApp className='iconItemMenu' /></ListItemIcon>
            <ListItemText className='labelText' primary='Cerrar sesión' />
          </ListItem>}
          {this.props.usuario === undefined &&
            <ListItem
              button key='iniciarSesion'
              onClick={() => { this.props.handleOnClickIniciarSesion() }}
            >
              <ListItemIcon><ExitToApp className='iconItemMenu' /></ListItemIcon>
              <ListItemText className='labelText' primary='Iniciar sesión' />
            </ListItem>
          }
        </List>
      </div>
    );
    return (
      <Drawer
        open={this.props.drawerOpen}
        onClose={this.props.handleDrawerClose}
        className='leftMenudrawer'
        anchor="left"
      >
        <div>
          <Row style={{ margin: '20px 0 0 0' }}>
            <Col xs={2} ></Col>
            <Col xs={7} className='logoContainer center' style={{ textAlign: 'center' }}>
              <img
                alt=""
                width="100px"
                onClick={() => { this.props.handleOnClickLogo() }}
                src={this.props.instalacion.logoDrawer}
              />
            </Col>
            <Col xs={3} >
              <div style={{ display: 'flex', height: '100%' }}>
                <IconButton
                  color="default"
                  aria-label="Close drawer"
                  onClick={this.props.handleDrawerClose}
                  className='headerButton'
                >
                  <ArrowBack className='iconItemMenu' />
                </IconButton>
              </div>
            </Col>
          </Row>
        </div>
        <div
          tabIndex={0}
          role="button"
          onClick={this.toggleDrawer('left', false)}
          onKeyDown={this.toggleDrawer('left', false)}
        >
          {sideList}
        </div>
      </Drawer>
    );
  }
}

MyDrawer.propTypes = {
  drawerOpen: PropTypes.bool.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
  handleOnClickLogo: PropTypes.func.isRequired,
  preguntasFrecuentes: PropTypes.object,
  instalacion: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  usuario: PropTypes.bool.isRequired,
  handleOnCerrarSesion: PropTypes.func.isRequired,
  handleOnClickIniciarSesion: PropTypes.func.isRequired,
  handleOnClickBasesCondiciones: PropTypes.func.isRequired,
  handleOnClickOpenNotidicaciones: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...sessionActions
  }, dispatch)
}

function mapStateToProps(state) {
  return {
    dataUser: usuarioSelector(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyDrawer));

