import React from 'react';
import MaskedInput from 'react-text-mask';

const CuitMask = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/,'-', /\d/, /\d/,/\d/, /\d/, /\d/, /\d/, /\d/, /\d/,'-',/\d/]}
      placeholderChar={' '}
      showMask
      keepCharPositions={false}
      guide={true}
    />
  );
};

export default CuitMask;
