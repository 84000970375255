import { createSelector } from 'reselect'

const catalogoReducerDataSelector = state => state.catalogoReducer.getIn(["data"]).toJS()
export const catalogoDataSelector = createSelector(
    catalogoReducerDataSelector, (dataField) => {      
      
      // catalogos
      let myCatalogos = undefined

      if(dataField.catalogos)
      {
        // cargo los datos de los catalogos
        myCatalogos = []

        dataField.catalogos.forEach((c) => {
            
            let item = {}
            item.nombre = c.nombre
            item.id = c.idCatalogo
            item.imagenCatalogo = c.imagenCatalogo
  
            myCatalogos.push(item)
        })
        
      }

      //Catalogo selecionado por el usuario

      let myCatalogoSeleccionado = undefined

      if(dataField.catalogoSeleccionado)
      {
        // cargo los datos del catalogo seleccionado
        myCatalogoSeleccionado = {}
        
        myCatalogoSeleccionado.nombre = dataField.catalogoSeleccionado.nombre
        myCatalogoSeleccionado.descripcion = dataField.catalogoSeleccionado.descripcion
        myCatalogoSeleccionado.idCatalogo = dataField.catalogoSeleccionado.idCatalogo
        myCatalogoSeleccionado.activo = dataField.catalogoSeleccionado.activo
        myCatalogoSeleccionado.fechaPublicacion = dataField.catalogoSeleccionado.fechaPublicacion
        myCatalogoSeleccionado.fechaRegistro = dataField.catalogoSeleccionado.fechaRegistro
        myCatalogoSeleccionado.imagen = dataField.catalogoSeleccionado.imagen
        
        // compruebo si todos los articulos del catalogo pertenecen a un solo subrubro

        if(dataField.catalogoSeleccionado.listaRubros.length == 1 && dataField.catalogoSeleccionado.listaRubros[0].listaSubRubros.length == 1){
          myCatalogoSeleccionado.articulosMismoSubrubro = true
        }
        else{
          myCatalogoSeleccionado.articulosMismoSubrubro = false
        }


        // cargo los rubros del catalogo seleccionado
        let listaRubros = []

        dataField.catalogoSeleccionado.listaRubros.forEach((r) => {
            
          let rubro = {}
          rubro.idRubro = r.idRubro
          rubro.nombre = r.nombre
          rubro.idInstalacion = r.idInstalacion
          rubro.orden = r.orden
          rubro.activo = r.activo

          // cargo los subrubros de cada rubro

          let listaSubrubros = []

          r.listaSubRubros.forEach((s) => {
            
            let subRubro = {}
            subRubro.idSubrubro = s.idSubrubro
            subRubro.idRubro = s.idRubro
            subRubro.nombre = s.nombre
            subRubro.orden = s.orden
            subRubro.imagen = s.imagen
            subRubro.activo = s.activo

            // cargo los articulos de cada subrubro

            let listaArticulos = []

            s.listaArticulos.forEach((a) => {
            
              let articulo = {}
              articulo.idArticulo = a.idArticulo
              articulo.idCatalogoEntrada = a.idCatalogoEntrada
              articulo.nombre = a.nombre
              articulo.codigoInterno = a.codigoInterno
              articulo.modelo = a.modelo
              articulo.stockDisponible = a.stockDisponible
              articulo.precioLista = a.precioLista
              articulo.fechaRegistro = a.fechaRegistro
              articulo.precioPromocional = a.precioPromocional;
              articulo.porcentajeDescuento = a.porcentajeDescuento;
              articulo.cantidadLimMax = a.cantidadLimMax;
              articulo.activo = a.activo

              // cargo la lista de imágenes de artículo
              articulo.listaImagenesArticulo = a.listaImagenesArticulos
              
              // cargo lista de colores disponibles de los articulos
              let listaColores = []
              a.listaColores.forEach((c) => {
                listaColores.push(c)
              })
              articulo.listaColores = listaColores

              // cargo lista de colores disponibles de los articulos
              let listaMiniaturas = []
              a.listaMiniaturas.forEach((m) => {
                listaMiniaturas.push(m)
              })
              articulo.listaMiniaturas = listaMiniaturas

              // cargo los datos de los talles del articulo
              let tipoTalle = {}
              tipoTalle.idTipoTalle = a.tipoTalle.idTipoTalle
              tipoTalle.nombre = a.tipoTalle.nombre
              tipoTalle.activo = a.tipoTalle.activo

              let listaTalles = []
              a.tipoTalle.listaTalles.forEach((t) => {
                
                let talle = {}
                talle.idTalle = t.idTalle
                talle.nombre = t.nombre
                talle.activo = t.activo

                listaTalles.push(t)
              })
              tipoTalle.listaTalles = listaTalles
              articulo.tipoTalle = tipoTalle

              // cargo los datos de los talleColor del articulo
              let listaTalleColor = []

              a.listaTalleColor.forEach((tc) => {
                
                let talleColor = {}
                talleColor.idTalleColor = tc.idTalleColor
                talleColor.idTalle = tc.idTalle
                talleColor.colorCodigo = tc.colorCodigo
                talleColor.colorPathFoto = tc.colorPathFoto
                talleColor.stock = tc.stock

                listaTalleColor.push(tc)

              })

              articulo.listaTalleColor = listaTalleColor
    
              listaArticulos.push(articulo)
            })
            subRubro.listaArticulos = listaArticulos

            listaSubrubros.push(subRubro)
          })
          rubro.listaSubRubros = listaSubrubros

          listaRubros.push(rubro)
        })

        myCatalogoSeleccionado.listaRubros = listaRubros
        myCatalogoSeleccionado.listadoSecciones = dataField.catalogoSeleccionado.listadoSecciones
      }

      // cantidad de articulos que posee el catalogo seleccionado segun el filtrado (sea null o no)
      let cantidadArticulosFiltradosCatSel = dataField.catalogoSeleccionado ? dataField.catalogoSeleccionado.cantidadArticulosFiltrados : undefined;
      let cantidadArticulos = dataField.catalogoSeleccionado ? dataField.catalogoSeleccionado.cantidadArticulos : undefined
      
      return {
        catalogos: myCatalogos,
        catalogoSeleccionado: myCatalogoSeleccionado,
        imagenesArticulos: dataField.imagenesArticulos,
        idArticuloStock: dataField.idArticuloStock,
        stockDisponible: dataField.stockDisponible,
        buscandoCatalogo: dataField.buscandoCatalogo,
        cantidadArticulosFiltradosCatSel: cantidadArticulosFiltradosCatSel,
        cantidadArticulos: cantidadArticulos,
        filterBusqueda: dataField.filterBusqueda,
        subrubroSeleccionado: dataField.subrubroSeleccionado,
        ofertaSeleccionada: dataField.ofertaSeleccionada,
        subFiltrosArticulos: dataField.subFiltrosArticulos,
      }
    }
)

const rubrosSubrubrosReducerSelector = state => state.catalogoReducer.getIn(["data"]).toJS()
export const rubrosSubrubrosSelector = createSelector(
  rubrosSubrubrosReducerSelector, (data) => {
    return data.rubrosSubrubros
  }
)