import React from 'react'
import Paragraph from '../UI/Texts/Paragraph';
import RadioButton from '../UI/Buttons/Radio';
import PropTypes from 'prop-types'

const PedidoInfoLabel = ({
    classes,
    handleChangeRadioButton,
    reemplazarProductos
}) => {
    return <div className='boxRadioBtn'>
        <div className='labelBoxRadioBtn'>
            <Paragraph size="big" weight="regular" color="dark-grey-text">Contanos tu preferencia en caso de que algún producto no tenga stock.</Paragraph>
            <Paragraph size="big" weight="regular" color="dark-grey-text"><b>Criterio Alvear</b>: El producto faltante será reemplazado por la mejor alternativa disponible de similar característica y precio.</Paragraph>
            <Paragraph size="big" weight="regular" color="dark-grey-text"><b>No reemplazar</b>: El producto faltante se eliminará y se efectuará la devolución por el valor del mismo.</Paragraph>
        </div>
        <div>
            <RadioButton
                classes={{ label: classes.label }}
                aria_label="gender"
                name="gender1"
                buttons={[{ value: "1", label: "Criterio alvear" }, { value: "2", label: "No reemplazar" }]}
                value={reemplazarProductos} handleChange={handleChangeRadioButton} />
        </div>
    </div>
}


PedidoInfoLabel.propTypes = {
    classes: PropTypes.string.isRequired,
    reemplazarProductos: PropTypes.bool.isRequired,
    handleChangeRadioButton: PropTypes.func.isRequired,
}

export default PedidoInfoLabel;